import type { AppState } from "@/contexts/app-context/app-context";

// TODO: implement this as a synced computed table
export class EdgarEntitiesStore {
	appState: AppState;

	constructor(appState: AppState) {
		this.appState = appState;
	}
}
