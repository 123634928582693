import { FieldTypeIcon } from "@/components/table/data-type-indicators";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import type { UserTableState } from "@/contexts/tables/stores/table-store";
import {
	type BooleanField,
	type DatetimeField,
	DatetimeFormat,
	type Field,
	type FieldId,
	FieldType,
	type LookupField,
	type NumberField,
	type RelationshipField,
	type SelectField,
	type TableId,
	type TextField,
} from "@api/schemas";
import * as Form from "@radix-ui/react-form";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import { useState } from "react";

const FieldTypeLabels = {
	[FieldType.text]: "Text",
	[FieldType.boolean]: "Checkbox",
	[FieldType.select]: "Select",
	[FieldType.datetime]: "Time",
	[FieldType.number]: "Number",
	[FieldType.relationship]: "Relationship",
	[FieldType.lookup]: "Lookup",
} as const;

const FieldTypeOption = ({
	fieldType,
}: {
	fieldType: Exclude<FieldType, "view">;
}) => {
	return (
		<div className="flex items-center gap-3">
			<FieldTypeIcon fieldType={fieldType} size={16} className="shrink-0" />
			<h2 className="text-sm">{FieldTypeLabels[fieldType]}</h2>
		</div>
	);
};

class FieldCreatorState {
	// The table view state that this field is being created for
	tableState: UserTableState;

	fieldType!: Exclude<FieldType, "view">;
	fieldName!: string;

	// Required for creating a lookup field
	lookupProperties!: {
		recordLinkFieldId: FieldId | null;
		targetFieldId: FieldId | null;
	};

	// Required for creating a relationship field
	relationshipProperties!: {
		foreignTableId: TableId | null;
	};

	constructor(tableState: UserTableState) {
		this.reset();
		this.tableState = tableState;

		makeAutoObservable(this);
	}

	setFieldName(fieldName: string) {
		this.fieldName = fieldName;
	}

	setFieldType(fieldType: Exclude<FieldType, "view">) {
		this.fieldType = fieldType;
	}

	/**
	 * Returns the fields that can be used as a relationship source for a
	 * lookup field.
	 *
	 * TODO: This could work with any field that has a record link data type?
	 * We can check what tables the links come from?
	 */
	get lookupRecordLinkFieldOptions(): RelationshipField[] | null {
		return this.tableState.sortedFields.filter(
			(field): field is RelationshipField => {
				return field.type === "relationship";
			},
		);
	}

	/**
	 * Returns the fields that can be used as a lookup target for a lookup
	 * field once a relationship source is selected.
	 */
	get lookupTargetFieldOptions(): Field[] | null {
		if (this.lookupProperties.recordLinkFieldId === null) {
			return null;
		}
		return this.tableState
			.getFieldById(this.lookupProperties.recordLinkFieldId)
			.map((field) => {
				const foreignTableId = (field as RelationshipField).properties
					.foreign_table_id;
				const foreignTableFields =
					this.tableState.tablesStore.appState.workspace.fields.getFieldsByTableId(
						foreignTableId,
					);
				return Array.from(foreignTableFields.values());
			})
			.unwrapOr(null);
	}

	/**
	 * List of tables that are not the current table. Used for creating a
	 * relationship field.
	 *
	 * Right now, relationships can't be self-referential, so we don't include
	 * the current table in the list.
	 */
	get foreignTables() {
		return this.tableState.tablesStore.sortedResources.filter(
			(table) => table.table_id !== this.tableState.tableResource.table_id,
		);
	}

	reset() {
		this.fieldType = FieldType.text;
		this.fieldName = "";
		this.lookupProperties = {
			recordLinkFieldId: null,
			targetFieldId: null,
		};
		this.relationshipProperties = {
			foreignTableId: null,
		};
	}

	submit() {
		switch (this.fieldType) {
			case FieldType.relationship:
				if (this.relationshipProperties.foreignTableId) {
					this.tableState.addRelationship({
						name: this.fieldName,
						foreignTableId: this.relationshipProperties.foreignTableId,
					});
				}
				break;

			case FieldType.lookup:
				if (
					this.lookupProperties.recordLinkFieldId &&
					this.lookupProperties.targetFieldId
				) {
					this.tableState.addField<LookupField>({
						name: this.fieldName,
						type: FieldType.lookup,
						properties: {
							record_link_field_id: this.lookupProperties.recordLinkFieldId,
							target_field_id: this.lookupProperties.targetFieldId,
						},
					});
				}
				break;

			case FieldType.text:
				this.tableState.addField<TextField>({
					name: this.fieldName,
					type: this.fieldType,
					properties: {
						default_value: "",
						is_primary: false,
					},
				});
				break;

			case FieldType.boolean:
				this.tableState.addField<BooleanField>({
					name: this.fieldName,
					type: this.fieldType,
					properties: {
						default_value: false,
						is_primary: false,
					},
				});
				break;

			case FieldType.number:
				this.tableState.addField<NumberField>({
					name: this.fieldName,
					type: this.fieldType,
					properties: {
						default_value: null,
						is_primary: false,
					},
				});
				break;

			case FieldType.datetime:
				this.tableState.addField<DatetimeField>({
					name: this.fieldName,
					type: this.fieldType,
					properties: {
						default_value: null,
						is_primary: false,
						time_format: DatetimeFormat.hidden,
					},
				});
				break;

			case FieldType.select:
				this.tableState.addField<SelectField>({
					name: this.fieldName,
					type: this.fieldType,
					properties: {
						default_value: null,
						is_primary: false,
						options: {},
					},
				});
				break;
		}
		this.reset();
	}

	get isValid() {
		if (!this.fieldName) return false;

		switch (this.fieldType) {
			case FieldType.relationship:
				return !!this.relationshipProperties.foreignTableId;
			case FieldType.lookup:
				return !!(
					this.lookupProperties.recordLinkFieldId &&
					this.lookupProperties.targetFieldId
				);
			default:
				return true;
		}
	}
}

export const FieldCreatorPopover = observer(function FieldCreatorPopover({
	tableState,
	children,
}: {
	tableState: UserTableState;
	children: React.ReactNode;
}) {
	const [fieldCreatorState] = useState(() => new FieldCreatorState(tableState));
	const [open, setOpen] = useState(false);

	return (
		<>
			<Popover
				open={open}
				onOpenChange={(prev) => {
					fieldCreatorState.reset();
					setOpen(prev);
				}}
			>
				<PopoverTrigger disabled={!tableState.initState.ready} asChild>
					{children}
				</PopoverTrigger>
				<PopoverContent align="start" className="w-64 p-0">
					<Form.Root
						onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
							e.preventDefault();
							if (fieldCreatorState.isValid) {
								fieldCreatorState.submit();
								setOpen(false);
							}
						}}
					>
						<section className="px-2 py-1">
							<Label>Add a field</Label>
						</section>

						<Separator />

						<Form.Field name="fieldName" className="flex flex-col px-2 pt-2">
							<Form.Label className="font-medium text-sm">Name</Form.Label>
							<Form.Control asChild>
								<Input
									className="mt-2 h-8"
									value={fieldCreatorState.fieldName}
									onChange={(e) =>
										fieldCreatorState.setFieldName(e.target.value)
									}
									autoComplete="off"
									required
								/>
							</Form.Control>
							<Form.Message
								match="valueMissing"
								className="mt-1 text-red-500 text-xs"
							>
								Please enter a field name
							</Form.Message>
						</Form.Field>

						<Form.Field name="fieldType" className="flex flex-col px-2 pt-2">
							<Form.Label className="font-medium text-sm">Type</Form.Label>
							<Form.Control asChild>
								<Select
									value={fieldCreatorState.fieldType}
									onValueChange={(value) =>
										fieldCreatorState.setFieldType(
											value as Exclude<FieldType, "view">,
										)
									}
									required
								>
									<SelectTrigger className="mt-2">
										{fieldCreatorState.fieldType ? (
											<FieldTypeOption
												fieldType={fieldCreatorState.fieldType}
											/>
										) : (
											<SelectValue placeholder="Select..." />
										)}
									</SelectTrigger>
									<SelectContent>
										{Object.values(FieldType)
											.filter((type) => type !== "view")
											.map((fieldType) => (
												<SelectItem key={fieldType} value={fieldType}>
													<FieldTypeOption fieldType={fieldType} />
												</SelectItem>
											))}
									</SelectContent>
								</Select>
							</Form.Control>
						</Form.Field>

						{fieldCreatorState.fieldType === FieldType.relationship && (
							<Form.Field
								name="relatedTable"
								className="flex flex-col px-2 pt-2"
							>
								<Form.Label className="font-medium text-sm">
									Related Table
								</Form.Label>
								<Form.Control asChild>
									<Select
										value={
											fieldCreatorState.relationshipProperties.foreignTableId ??
											undefined
										}
										onValueChange={(value) => {
											fieldCreatorState.relationshipProperties.foreignTableId =
												value as TableId;
										}}
										required
									>
										<SelectTrigger className="mt-2">
											<SelectValue placeholder="Select a table..." />
										</SelectTrigger>
										<SelectContent>
											{fieldCreatorState.foreignTables.map((table) => (
												<SelectItem key={table.table_id} value={table.table_id}>
													{table.name}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
								</Form.Control>
								<Form.Message
									match="valueMissing"
									className="mt-1 text-red-500 text-xs"
								>
									Please select a related table
								</Form.Message>
							</Form.Field>
						)}

						{fieldCreatorState.fieldType === FieldType.lookup && (
							<>
								<Form.Field
									name="relationshipField"
									className="flex flex-col px-2 pt-2"
								>
									<Form.Label className="font-medium text-sm">
										Relationship Field
									</Form.Label>
									<Form.Control asChild>
										<Select
											value={
												fieldCreatorState.lookupProperties.recordLinkFieldId ??
												undefined
											}
											onValueChange={(value) => {
												fieldCreatorState.lookupProperties.recordLinkFieldId =
													value as FieldId;
											}}
											required
										>
											<SelectTrigger className="mt-2">
												<SelectValue placeholder="Select a field..." />
											</SelectTrigger>
											<SelectContent>
												{fieldCreatorState.lookupRecordLinkFieldOptions?.map(
													(field) => (
														<SelectItem
															key={field.field_id}
															value={field.field_id}
														>
															{field.name}
														</SelectItem>
													),
												)}
											</SelectContent>
										</Select>
									</Form.Control>
									<Form.Message
										match="valueMissing"
										className="mt-1 text-red-500 text-xs"
									>
										Please select a relationship field
									</Form.Message>
								</Form.Field>

								<Form.Field
									name="lookupField"
									className="flex flex-col px-2 pt-2"
								>
									<Form.Label className="font-medium text-sm">
										Lookup Field
									</Form.Label>
									<Form.Control asChild>
										<Select
											value={
												fieldCreatorState.lookupProperties.targetFieldId ??
												undefined
											}
											onValueChange={(value) => {
												fieldCreatorState.lookupProperties.targetFieldId =
													value as FieldId;
											}}
											disabled={
												!fieldCreatorState.lookupProperties.recordLinkFieldId
											}
											required
										>
											<SelectTrigger className="mt-2">
												<SelectValue placeholder="Select a field..." />
											</SelectTrigger>
											<SelectContent>
												{fieldCreatorState.lookupTargetFieldOptions?.map(
													(field) => (
														<SelectItem
															key={field.field_id}
															value={field.field_id}
														>
															{field.name}
														</SelectItem>
													),
												)}
											</SelectContent>
										</Select>
									</Form.Control>
									<Form.Message
										match="valueMissing"
										className="mt-1 text-red-500 text-xs"
									>
										Please select a lookup field
									</Form.Message>
								</Form.Field>
							</>
						)}

						<section className="px-2 pt-3 pb-2">
							<Form.Submit asChild>
								<Button className="w-full" type="submit">
									Add column
								</Button>
							</Form.Submit>
						</section>
					</Form.Root>
				</PopoverContent>
			</Popover>
		</>
	);
});
