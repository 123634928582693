import dayjs from "dayjs";
import { useEffect, useState } from "react";

/**
 * A custom hook that tracks the elapsed time since startTime. When the step is
 * still running (i.e. endTime is null), it schedules updates at a frequency
 * that depends on the elapsed time:
 *
 * - Under one minute: update every 100 ms (allowing display of tenths of a second)
 * - Between one minute and one hour: update every second
 * - One hour or more: update every minute.
 *
 * Once endTime is provided, no further updates are scheduled.
 */
const useElapsedTime = (
	startTime: dayjs.Dayjs,
	endTime: dayjs.Dayjs | null,
): number => {
	const [elapsed, setElapsed] = useState<number>(
		dayjs(endTime).diff(startTime, "millisecond"),
	);

	useEffect((): (() => void) | undefined => {
		if (endTime !== null) return;

		let timer: ReturnType<typeof setTimeout>;

		const update = (): void => {
			const newElapsed = dayjs().diff(startTime, "millisecond");
			setElapsed(newElapsed);

			// determine appropriate delay for next update
			let delay = 100; // default: update every 100ms for < 1 min
			if (newElapsed >= 60_000 && newElapsed < 3_600_000) {
				delay = 1000;
			} else if (newElapsed >= 3_600_000) {
				delay = 60_000;
			}

			timer = setTimeout(update, delay);
		};

		timer = setTimeout(update, 100);

		return () => clearTimeout(timer);
	}, [startTime, endTime]);

	return elapsed;
};

/**
 * Formats a duration in milliseconds so that:
 *
 * - For durations less than 1 minute, display seconds with one decimal
 *   (e.g. "0.1 s", "59.9 s").
 * - For durations under an hour, display minutes and seconds (e.g. "1 min 5s").
 * - For durations of one hour or more, display hours and minutes.
 */
const formatDuration = (durationMs: number): string => {
	if (durationMs < 60000) {
		return `${(durationMs / 1000).toFixed(1)} s`;
	}
	if (durationMs < 3600000) {
		const minutes = Math.floor(durationMs / 60000);
		const seconds = Math.floor((durationMs % 60000) / 1000);
		return `${minutes} min${seconds ? ` ${seconds} s` : ""}`;
	}
	const hours = Math.floor(durationMs / 3600000);
	const minutes = Math.floor((durationMs % 3600000) / 60000);
	return `${hours} h${minutes ? ` ${minutes} min` : ""}`;
};

export const useFormattedDuration = (
	startTime: dayjs.Dayjs,
	endTime: dayjs.Dayjs | null,
): string => {
	const elapsedMs = useElapsedTime(startTime, endTime);
	return formatDuration(elapsedMs);
};
