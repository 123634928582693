import type { AppState } from "@/contexts/app-context/app-context";
import type { UserTableState } from "@/contexts/tables/stores/table-store";
import { OptimisticAction, type Transaction } from "@/lib/sync/action-executor";
import {
	deleteRecordsRoute,
	insertEmptyRecordsRoute,
	updateRecordsRoute,
} from "@api/fastAPI";
import type {
	CellValue,
	FieldId,
	Record,
	RecordOrderStr,
	ResourceLink,
	TableId,
} from "@api/schemas";
import {
	generateJitteredKeyBetween,
	generateNJitteredKeysBetween,
} from "fractional-indexing-jittered";
import { ulid } from "ulidx";

function createRecordLink(tableId: TableId): ResourceLink {
	return `/tables/table/${tableId}?record_id=record_${ulid()}` as ResourceLink;
}

export class AddRecordAction extends OptimisticAction<
	AppState,
	{
		precedingRecordLink: ResourceLink | null;
		tableState: UserTableState;
	},
	{
		newRecord: Record;
	},
	void
> {
	async local(tx: Transaction): Promise<{
		newRecord: Record;
	}> {
		let lowerOrder: RecordOrderStr | null;
		let upperOrder: RecordOrderStr | null;

		if (this.args.precedingRecordLink === null) {
			lowerOrder = null;
			upperOrder = this.args.tableState.sortedRecords.at(0)?.order ?? null;
		} else {
			lowerOrder = this.args.tableState.getRecordByLink(
				this.args.precedingRecordLink,
			).order;
			upperOrder =
				this.args.tableState.getRecordAfter(this.args.precedingRecordLink)
					?.order ?? null;
		}

		const newRecord: Record = {
			link: createRecordLink(this.args.tableState.tableId),
			order: generateJitteredKeyBetween(
				lowerOrder,
				upperOrder,
			) as RecordOrderStr,
			cell_values: {},
		};

		this.args.tableState.optimisticMap.insert(tx, newRecord);
		return { newRecord };
	}

	async remote(
		context: { localResult: { newRecord: Record }; tx: Transaction },
		_state: AppState,
	): Promise<void> {
		await insertEmptyRecordsRoute({
			table_id: this.args.tableState.tableId,
			records: [context.localResult.newRecord],
			write_id: context.tx.id,
		});
	}
}

export class MoveRecordsAction extends OptimisticAction<
	AppState,
	{
		followingRecordLink: ResourceLink | null;
		recordLinks: Set<ResourceLink>;
		tableState: UserTableState;
	},
	{
		newRecordOrders: Map<ResourceLink, RecordOrderStr>;
	},
	void
> {
	async local(
		tx: Transaction,
		_state: AppState,
	): Promise<{
		newRecordOrders: Map<ResourceLink, RecordOrderStr>;
	}> {
		const recordsToMove = Array.from(this.args.recordLinks)
			.map((recordLink) => this.args.tableState.getRecordByLink(recordLink))
			.sort((a, b) => a.order.localeCompare(b.order));

		// Save old orders in case we need them
		const oldOrders = new Map<ResourceLink, RecordOrderStr>();
		for (const record of recordsToMove) {
			oldOrders.set(record.link, record.order);
		}

		let lowerOrder: RecordOrderStr | null;
		let upperOrder: RecordOrderStr | null;

		// If followingRecordLink is null, place after the last record
		if (this.args.followingRecordLink === null) {
			lowerOrder = this.args.tableState.sortedRecords.at(-1)?.order ?? null;
			upperOrder = null;
		} else {
			upperOrder = this.args.tableState.getRecordByLink(
				this.args.followingRecordLink,
			).order;
			lowerOrder =
				this.args.tableState.getRecordBefore(this.args.followingRecordLink)
					?.order ?? null;
		}

		// Generate new orders so that recordsToMove fit between lowerOrder and upperOrder
		const newOrders = generateNJitteredKeysBetween(
			lowerOrder,
			upperOrder,
			this.args.recordLinks.size,
		);

		const newRecordOrders = new Map<ResourceLink, RecordOrderStr>();
		for (const [index, record] of recordsToMove.entries()) {
			newRecordOrders.set(record.link, newOrders[index] as RecordOrderStr);
			this.args.tableState.optimisticMap.update(tx, record.link, {
				order: newOrders[index] as RecordOrderStr,
			});
		}
		return { newRecordOrders };
	}

	async remote(
		context: {
			localResult: {
				newRecordOrders: Map<ResourceLink, RecordOrderStr>;
			};
			tx: Transaction;
		},
		_state: AppState,
	): Promise<void> {
		await updateRecordsRoute({
			table_id: this.args.tableState.tableId,
			record_updates: Array.from(
				context.localResult.newRecordOrders.entries(),
			).map(([recordLink, recordOrder]) => ({
				link: recordLink,
				order: recordOrder,
				cell_values: {},
			})),
			write_id: context.tx.id,
		});
	}
}

export class UpdateCellValueAction extends OptimisticAction<
	AppState,
	{
		recordLink: ResourceLink;
		fieldId: FieldId;
		cellValue: CellValue;
		tableState: UserTableState;
	},
	undefined,
	void
> {
	async local(tx: Transaction, _state: AppState): Promise<undefined> {
		const record = this.args.tableState.getRecordByLink(this.args.recordLink);

		const oldValue = record.cell_values[this.args.fieldId];

		// This kind of comparison fails for array types, but false negatives
		// are innocuous here.
		if (oldValue === this.args.cellValue) {
			return;
		}

		this.args.tableState.optimisticMap.update(tx, this.args.recordLink, {
			cell_values: {
				...record.cell_values,
				[this.args.fieldId]: this.args.cellValue,
			},
		});
	}

	async remote(
		context: { localResult: undefined; tx: Transaction },
		_state: AppState,
	): Promise<void> {
		await updateRecordsRoute({
			table_id: this.args.tableState.tableId,
			record_updates: [
				{
					link: this.args.recordLink,
					order: null,
					cell_values: {
						[this.args.fieldId]: this.args.cellValue,
					},
				},
			],
			write_id: context.tx.id,
		});
	}
}

export class DeleteRecordsAction extends OptimisticAction<
	AppState,
	{
		recordLinks: Set<ResourceLink>;
		tableState: UserTableState;
	},
	undefined,
	void
> {
	async local(tx: Transaction, _state: AppState): Promise<undefined> {
		const recordLinksArray = Array.from(this.args.recordLinks);
		for (const recordLink of recordLinksArray) {
			this.args.tableState.optimisticMap.delete(tx, recordLink);
		}
	}

	async remote(
		context: { localResult: undefined; tx: Transaction },
		_state: AppState,
	): Promise<void> {
		await deleteRecordsRoute({
			table_id: this.args.tableState.tableId,
			record_links: Array.from(this.args.recordLinks),
			write_id: context.tx.id,
		});
	}
}
