import type { AppState } from "@/contexts/app-context/app-context";
import { makeAutoObservable } from "mobx";

export class CommandKState {
	appState: AppState;
	value = "";
	private _open = false;

	constructor(appState: AppState) {
		makeAutoObservable(this);
		this.appState = appState;
	}

	toggleOpen = () => {
		this.open = !this.open;
	};

	setValue = (value: string) => {
		this.value = value;
	};

	set open(open: boolean) {
		if (open === false) {
			this.value = "";
		}
		this._open = open;
	}

	get open() {
		return this._open;
	}
}
