import type { AppState } from "@/contexts/app-context/app-context";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { LinkTarget } from "@/contexts/tabs/router-types";
import { useTabStore } from "@/contexts/tabs/use-tab-store";
import { cn } from "@/lib/utils";
import { CustomReactRenderer } from "@/plugins/custom-react-renderer";
import type { ResourceLink as ApiResourceLink } from "@api/schemas";
import {
	FloatingPortal,
	type VirtualElement,
	autoUpdate,
	offset,
	useFloating,
} from "@floating-ui/react";
import { autoPlacement } from "@floating-ui/react";
import type { LinkProps } from "@tanstack/react-router";
import { nodePasteRule } from "@tiptap/core";
import { Link, type LinkOptions } from "@tiptap/extension-link";
import Mention, {
	type MentionOptions as BaseMentionOptions,
	type MentionNodeAttrs,
} from "@tiptap/extension-mention";
import {
	type NodeViewProps,
	NodeViewWrapper,
	ReactNodeViewRenderer,
	mergeAttributes,
} from "@tiptap/react";
import { observer } from "mobx-react-lite";
import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import { FixedSizeList } from "react-window";

// John: Did these type inferences as an exercise. They work, trust.
type SuggestionItemType = {
	id: ApiResourceLink;
};
type MentionOptions = BaseMentionOptions<SuggestionItemType, MentionNodeAttrs>;
type SuggestionOptions = MentionOptions["suggestion"];
type SuggestionProps = Parameters<
	NonNullable<ReturnType<NonNullable<SuggestionOptions["render"]>>["onStart"]>
>[0];

interface ObjectListRef {
	setReference: (element: VirtualElement | null) => void;
	onKeyDown: (event: KeyboardEvent) => boolean;
}

export const ResourceRow = ({
	index,
	data,
	style,
}: {
	index: number;
	data: {
		items: SuggestionItemType[];
		highlightedIndex: number;
		selectHandler: (index: number) => void;
	};
	style: React.CSSProperties;
}) => {
	return (
		<button
			type="button"
			style={style}
			data-highlighted={index === data.highlightedIndex}
			className="block h-fit w-full truncate rounded-none p-1 text-left font-normal text-sm data-[highlighted=true]:bg-neutral-100"
			onClick={() => {
				data.selectHandler(index);
			}}
		>
			<ResourceLinkContent linkProps={{ href: data.items[index].id }} />
		</button>
	);
};

const ResourceList = observer<SuggestionProps, ObjectListRef>(
	forwardRef((props, ref) => {
		const { refs, floatingStyles } = useFloating({
			placement: "top-start",
			whileElementsMounted: autoUpdate,
			strategy: "fixed", // add this
			middleware: [
				offset(5), // optional, adds some spacing
				autoPlacement({
					allowedPlacements: ["top-start", "bottom-start"],
				}),
			],
		});
		const [highlightedIndex, setHighlightedIndex] = useState<number>(0);
		const tabStore = useTabStore();

		const selectHandler = (index: number) => {
			props.command({
				id: props.items[index].id,
			});
		};

		useImperativeHandle(ref, () => ({
			setReference: refs.setReference,
			onKeyDown: (event: KeyboardEvent) => {
				if (event.key === "ArrowUp") {
					setHighlightedIndex(Math.max(0, highlightedIndex - 1));
					return true;
				}
				if (event.key === "ArrowDown") {
					setHighlightedIndex(
						Math.min(props.items.length - 1, highlightedIndex + 1),
					);
					return true;
				}
				if (event.key === "Enter") {
					selectHandler(highlightedIndex);
					return true;
				}
				return false;
			},
		}));

		const listRef = useRef<FixedSizeList>(null);

		useEffect(() => {
			if (listRef.current) {
				listRef.current.scrollToItem(highlightedIndex);
			}
		}, [highlightedIndex]);

		// Use either search results or open tabs based on query
		const displayItems =
			props.query.trim().length > 0
				? props.items
				: tabStore.allTabLocations.map((location) => ({
						id: location.href as ApiResourceLink,
					}));

		return (
			<FloatingPortal root={document.body}>
				<div
					ref={refs.setFloating}
					style={floatingStyles}
					className="z-50 w-96 overflow-y-auto border bg-white"
				>
					{displayItems.length > 0 ? (
						<FixedSizeList
							ref={listRef}
							itemData={{
								items: displayItems,
								highlightedIndex,
								selectHandler,
							}}
							itemSize={28}
							height={320}
							itemCount={displayItems.length}
							width={"100%"}
						>
							{ResourceRow}
						</FixedSizeList>
					) : (
						<div className="p-2 text-neutral-500 text-sm">
							No resources found
						</div>
					)}
				</div>
			</FloatingPortal>
		);
	}),
);

const getSuggestion = (
	items: SuggestionOptions["items"],
): SuggestionOptions => ({
	char: "++",
	allowSpaces: true,
	items: items,
	render: () => {
		let component: CustomReactRenderer<ObjectListRef, SuggestionProps> | null =
			null;
		let virtualElement: VirtualElement | null = null;

		return {
			onStart: (props) => {
				component = new CustomReactRenderer(ResourceList, {
					props,
					editor: props.editor,
				});

				if (!props.clientRect) {
					console.error("No client rect function.");
					return;
				}
				const clientRect = props.clientRect();
				if (!clientRect) {
					console.error("No client rect.");
					return;
				}
				virtualElement = {
					getBoundingClientRect: () => clientRect,
				};
				if (!component.ref) {
					console.error("No component ref.");
					return;
				}
				component.ref.setReference(virtualElement);
			},
			onUpdate(props) {
				// biome-ignore lint/style/noNonNullAssertion: component is set in onStart
				component = component!;
				// biome-ignore lint/style/noNonNullAssertion: component.ref is set in onStart
				component.ref = component.ref!;
				// biome-ignore lint/style/noNonNullAssertion: virtualElement is set in onStart
				virtualElement = virtualElement!;

				component.updateProps(props);
				if (!props.clientRect) {
					console.error("No client rect function.");
					return;
				}
				const clientRect = props.clientRect();
				if (!clientRect) {
					console.error("No client rect.");
					return;
				}
				virtualElement.getBoundingClientRect = () => clientRect;
				component.ref.setReference(virtualElement);
			},
			onKeyDown(props) {
				// biome-ignore lint/style/noNonNullAssertion: component is set in onStart
				component = component!;
				// biome-ignore lint/style/noNonNullAssertion: component.ref is set in onStart
				component.ref = component.ref!;
				return component.ref.onKeyDown(props.event);
			},
			onExit() {
				// biome-ignore lint/style/noNonNullAssertion: component is set in onStart
				component = component!;
				component.destroy();
			},
		};
	},
});

type ResourceLinkComponentProps = {
	linkProps: LinkProps;
	className?: string;
	children: React.ReactNode;
};

const isAbsoluteUrl = (href: string): boolean => {
	try {
		new URL(href);
		return true;
	} catch {
		return false;
	}
};

export const ResourceLinkComponent = observer(function ResourceLinkComponent({
	linkProps,
	className,
	children,
}: ResourceLinkComponentProps) {
	const appContext = useAppContext();
	const tabStore = useTabStore();
	let handler: (event: React.MouseEvent<HTMLAnchorElement>) => void;

	const href = tabStore.linkPropsToHref(linkProps);
	const isAbsolute = isAbsoluteUrl(href);

	if (isAbsolute) {
		// absolute URLs open in a new browser tab instead of in-app
		// maybe we'll want to resolve these to a resource, but this
		// gets tricky if the resource doesn't exist...
		return (
			<a
				href={href}
				target="_blank"
				rel="noopener noreferrer"
				className={className}
			>
				{children}
			</a>
		);
	}

	if (href.startsWith("/message")) {
		handler = (event) => {
			event.preventDefault();
			event.stopPropagation();
			appContext.rightSidebarState.messageTab.router.navigate(linkProps);
		};
	} else {
		handler = (event) => {
			event.preventDefault();
			event.stopPropagation();
			tabStore.openLink(linkProps, LinkTarget.Self);
		};
	}
	return (
		<a href={href} onClick={handler} className={className}>
			{children}
		</a>
	);
});

type ResourceLinkContentProps = {
	linkProps: LinkProps;
	label?: string;
	showIcon?: boolean;
	className?: string;
	iconSize?: number;
};

export const ResourceLinkContent = observer(function ResourceLinkContent({
	linkProps,
	label,
	showIcon = true,
	className,
	iconSize,
}: ResourceLinkContentProps) {
	const tabStore = useTabStore();
	const { icon: Icon, label: defaultLabel } = tabStore.getTabHead(linkProps);

	return (
		<span className={cn("px-0.5 text-neutral-950", className)}>
			{showIcon && <Icon size={iconSize} className="mb-0.5 inline-block" />}
			<span className="ml-1">{label ? label : defaultLabel}</span>
		</span>
	);
});

const ResourceLinkNodeView = observer(function ResourceLinkNodeView(
	props: NodeViewProps,
) {
	const href = props.node.attrs.id;
	const tabStore = useTabStore();
	const { icon: Icon, label: defaultLabel } = tabStore.getTabHead({
		href,
	});

	return (
		<NodeViewWrapper as="span">
			<ResourceLinkComponent
				linkProps={{
					href,
				}}
			>
				{Icon && (
					<>
						{/*
							Insert zero-width spaces to make the icon selectable. 
							See styles for .node-resourceLink::before and .node-resourceLink::after
							in index.css as well
						*/}
						<span className="whitespace-nowrap">{"\u200B"}</span>
						<Icon className="resource-link-icon inline-block" />
						<span className="whitespace-nowrap">{"\u200B"}</span>
					</>
				)}

				{defaultLabel}
			</ResourceLinkComponent>
		</NodeViewWrapper>
	);
});

export const ResourceLink = Mention.extend({
	name: "resourceLink",
	priority: 1002, // Higher priority than Link (1000) to process the nodes first

	group: "inline",
	inline: true,
	draggable: false,
	selectable: true,

	addAttributes() {
		return {
			id: {
				default: null,
				parseHTML: (element) => element.getAttribute("href"),
				// TODO(John): fix this attribute handling
				renderHTML: (attributes) => {
					if (!attributes.id) {
						return {};
					}
					return {
						href: attributes.id,
					};
				},
			},
			label: {
				default: "",
				parseHTML: (element) => element.innerText,
				// We now store the label both as an attribute AND as node content
				renderHTML: () => {
					return {};
				},
			},
		};
	},
	parseHTML() {
		return [
			{
				// Only match links that start with a slash
				tag: `a[href^="/"]`,
			},
		];
	},
	renderHTML({ node, HTMLAttributes }) {
		// Make sure the label is included when rendering HTML
		// Use id as fallback if label is empty
		return ["a", mergeAttributes(HTMLAttributes), node.attrs.label];
	},
	renderText({ node }) {
		// Return in markdown link format
		const label = node.attrs.label || node.attrs.id;
		return `[${label}](${node.attrs.id})`;
	},
	addPasteRules() {
		return [
			nodePasteRule({
				// Only match markdown links with internal paths: [label](/path)
				find: /\[([^\]]+)\]\((\/[a-z0-9_\-/.]+)\)/gi,
				type: this.type,
				getAttributes: (match) => {
					const label = match[1];
					const href = match[2];

					if (!href?.startsWith("/")) {
						return false;
					}

					return { id: href, label };
				},
			}),
			nodePasteRule({
				// Match raw URLs starting with the origin
				find: new RegExp(
					`${escapeRegex(window.location.origin)}(\\/[^\\s]*)`,
					"gi",
				),
				type: this.type,
				getAttributes: (match) => {
					const path = match[1]; // The captured path part starting with /

					// If the path part wasn't captured, treat as invalid.
					if (!path) {
						return false;
					}

					// Use the captured path as id and the full matched URL as label
					return { id: path };
				},
			}),
		];
	},
	addNodeView() {
		return ReactNodeViewRenderer(ResourceLinkNodeView);
	},
});

// Helper function to escape special characters for use in a RegExp
function escapeRegex(string: string): string {
	return string.replace(/[.*+?^${}()|[\]\\\\]/g, "\\\\$&"); // $& means the whole matched string
}

export const getResourceLinkExtension = (appState: AppState) => {
	const localSearchStore = appState.localSearchStore;
	const tabStore = appState.tabStore;
	return ResourceLink.configure({
		suggestion: getSuggestion(({ query }) => {
			if (query.trim().length === 0) {
				return tabStore.allTabLocations.map((location) => ({
					id: location.href as ApiResourceLink,
				}));
			}
			const results = localSearchStore.searchResources(query);
			return results.map((result) => ({
				id: tabStore.resourceRefToHref(result),
				score: result.score,
			}));
		}),
	});
};

// From the Link extension.
// From DOMPurify
// https://github.com/cure53/DOMPurify/blob/main/src/regexp.js
// eslint-disable-next-line no-control-regex
const ATTR_WHITESPACE =
	// biome-ignore lint/suspicious/noControlCharactersInRegex: <explanation>
	/[\u0000-\u0020\u00A0\u1680\u180E\u2000-\u2029\u205F\u3000]/g;
function isAllowedUri(
	uri: string | undefined,
	protocols?: LinkOptions["protocols"],
) {
	const allowedProtocols: string[] = [
		"http",
		"https",
		"ftp",
		"ftps",
		"mailto",
		"tel",
		"callto",
		"sms",
		"cid",
		"xmpp",
	];

	if (protocols) {
		for (const protocol of protocols) {
			const nextProtocol =
				typeof protocol === "string" ? protocol : protocol.scheme;

			if (nextProtocol) {
				allowedProtocols.push(nextProtocol);
			}
		}
	}

	return (
		!uri ||
		uri
			.replace(ATTR_WHITESPACE, "")
			.match(
				new RegExp(
					`^(?:(?:${allowedProtocols.join("|")}):|[^a-z]|[a-z+.\-]+(?:[^a-z+.\-:]|$))`,
					"i",
				),
			)
	);
}

export const CustomLink = Link.extend({
	parseHTML() {
		return [
			{
				tag: "a[href]:not([href^='/'])",
				getAttrs: (dom) => {
					const href = (dom as HTMLElement).getAttribute("href");

					// prevent XSS attacks
					if (!href || !isAllowedUri(href, this.options.protocols)) {
						return false;
					}
					return null;
				},
			},
		];
	},
});
