import { API_ENDPOINT_HTTP } from "@/config";
import type { AppState } from "@/contexts/app-context/app-context";
import openapiHashes from "@/generated/openapi-hashes.json";
import { createMessageId, createWriteId } from "@/lib/id-generators";
import { OptimisticAction, type Transaction } from "@/lib/sync/action-executor";
import { ElectricOptimisticMap } from "@/lib/sync/electric";
import { createMessageRoute } from "@api/fastAPI";
import type { Message, MessageId, UserSessionId } from "@api/schemas";
import dayjs from "dayjs";
import { makeAutoObservable } from "mobx";
import { computedFn } from "mobx-utils";

export function createMessage(props: {
	content: string;
	attachments: string[];
	userSessionId: UserSessionId;
	parentMessageId: MessageId | null;
}): Message {
	const newMessage: Message = {
		message_id: createMessageId(),
		write_id: createWriteId(),
		created_at: new Date().toISOString(),
		user_session_id: props.userSessionId,
		assistant_session_id: null,
		content: props.content,
		attachments: props.attachments,
		parent_message_id: props.parentMessageId,
	};
	return newMessage;
}

export type ThreadRootMessage = Omit<Message, "parent_message_id"> & {
	parent_message_id: null;
};

export class CreateMessageAction extends OptimisticAction<
	AppState,
	{
		content: string;
		parentMessageId: MessageId | null;
		attachments: string[];
		onLocalSuccess?: (message: Message) => void;
	},
	{
		insert: Message;
	},
	void
> {
	async local(tx: Transaction, state: AppState): Promise<{ insert: Message }> {
		if (!state.userSessionId) {
			throw new Error("User session not initialized");
		}
		const newMessage = createMessage({
			content: this.args.content,
			attachments: this.args.attachments,
			userSessionId: state.userSessionId,
			parentMessageId: this.args.parentMessageId,
		});
		state.messageStore.messages.insert(tx, newMessage);
		this.args.onLocalSuccess?.(newMessage);
		return { insert: newMessage };
	}

	async remote(context: { localResult: { insert: Message } }): Promise<void> {
		await createMessageRoute({
			message: context.localResult.insert,
		});
	}
}

export class MessageStore {
	appState: AppState;
	messages: ElectricOptimisticMap<Message, ["message_id"], "write_id">;

	constructor(appState: AppState) {
		makeAutoObservable(this);
		this.appState = appState;
		this.messages = new ElectricOptimisticMap<
			Message,
			["message_id"],
			"write_id"
		>({
			shapeUrl: `${API_ENDPOINT_HTTP}/shapes/messages`,
			pKeyFields: ["message_id"],
			writeIdField: "write_id",
			shapeHash: openapiHashes.Message,
			getBearerToken: this.appState.getTokenOrThrow,
		});
	}

	// Queries
	getThreadReplies = computedFn((threadId: MessageId): Message[] => {
		return Array.from(this.messages.values())
			.flatMap((message) => {
				if (message.parent_message_id !== threadId) return [];
				return this.messages.get(message.message_id).match(
					(message) => [message],
					() => [],
				);
			})
			.sort((a, b) => Date.parse(a.created_at) - Date.parse(b.created_at));
	});

	get threads(): ThreadRootMessage[] {
		return Array.from(this.messages.values())
			.flatMap((message) => {
				if (message.parent_message_id !== null) return [];
				return this.messages.get(message.message_id).match(
					(message) => [message as ThreadRootMessage],
					() => [],
				);
			})
			.sort((a, b) => Date.parse(a.created_at) - Date.parse(b.created_at));
	}

	get threadsByDay(): Map<string, ThreadRootMessage[]> {
		const messagesByDay = new Map<string, ThreadRootMessage[]>();
		for (const thread of this.threads) {
			const dateString = dayjs(thread.created_at).calendar(new Date(), {
				sameDay: "[Today]",
				lastDay: "[Yesterday]",
				lastWeek: "dddd, MMMM D",
				sameElse: "dddd, MMMM D",
			});

			if (!messagesByDay.has(dateString)) {
				messagesByDay.set(dateString, []);
			}

			// biome-ignore lint/style/noNonNullAssertion: <explanation>
			messagesByDay.get(dateString)!.push(thread);
		}

		return messagesByDay;
	}

	createMessage(props: CreateMessageAction["args"]) {
		this.appState.actionQueue.run(
			new CreateMessageAction({
				...props,
			}),
		);
	}
}
