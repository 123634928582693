import { AssistantPresenceIcon } from "@/components/layout/right-sidebar/assistant-presence-icon";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import type { SessionFilters } from "@/contexts/assistant/stores/assistant-session-store";
import { observer } from "mobx-react-lite";

const MAX_SESSIONS_TO_SHOW = 4;

/**
 * Displays a set of assistant presence icons for both active and inactive
 * assistant sessions
 */
export const AssistantPresenceIndicator = observer(
	({
		filters,
	}: {
		filters: SessionFilters;
	}) => {
		const appContext = useAppContext();

		const sessions =
			appContext.assistantSessionStore.filterAssistantSessions(filters);
		const activeSessionIds = sessions.active.map(
			(session) => session.session.assistant_session_id,
		);
		const inactiveSessionIds = sessions.completed.map(
			(session) => session.session.assistant_session_id,
		);

		if (activeSessionIds.length === 0 && inactiveSessionIds.length === 0) {
			return null;
		}

		const sessionsToShow = [...activeSessionIds, ...inactiveSessionIds]
			.slice(0, MAX_SESSIONS_TO_SHOW)
			.reverse();
		const numOtherSessions =
			activeSessionIds.length +
			inactiveSessionIds.length -
			sessionsToShow.length;

		return (
			<div className="flex items-center gap-1">
				<div className="-space-x-2 flex flex-row-reverse items-center space-x-reverse">
					{sessionsToShow.map((assistantSessionId) => (
						<AssistantPresenceIcon
							key={assistantSessionId}
							assistantSessionId={assistantSessionId}
						/>
					))}
				</div>
				{numOtherSessions > 0 && (
					<span className="text-neutral-500 text-xs">+{numOtherSessions}</span>
				)}
			</div>
		);
	},
);
