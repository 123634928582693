import { API_ENDPOINT_HTTP } from "@/config";
import type { AppState } from "@/contexts/app-context/app-context";
import openapiHashes from "@/generated/openapi-hashes.json";
import { createSearchId, createWriteId } from "@/lib/id-generators";
import { OptimisticAction, type Transaction } from "@/lib/sync/action-executor";
import { ElectricOptimisticMap, ElectricSyncedMap } from "@/lib/sync/electric";
import { getSearchEdgarDocumentsRoute, searchRoute } from "@api/fastAPI";
import type {
	EdgarDocumentWithFiling,
	SearchFull,
	SearchId,
	SearchResource,
} from "@api/schemas";
import { makeAutoObservable, runInAction } from "mobx";
import type { Result } from "neverthrow";

export type SearchParams = {
	query: string;
	max_results: number | null;
};

class InitiateSearchAction extends OptimisticAction<
	AppState,
	{
		config: SearchParams;
		onLocalSuccess: (resource: SearchResource) => void;
	},
	SearchResource,
	void
> {
	async local(tx: Transaction, state: AppState) {
		const searchResource: SearchResource = {
			...this.args.config,
			search_id: createSearchId(),
			requested_at: new Date().toISOString(),
			results_loaded_at: null,
			write_id: createWriteId(),
		};
		state.searchStore.map.insert(tx, searchResource);
		this.args.onLocalSuccess(searchResource);
		return searchResource;
	}

	async remote(props: { localResult: SearchResource }, state: AppState) {
		await searchRoute({
			search_resource: props.localResult,
		});
		// Only add to the search history if the search was successful.
		runInAction(() => {
			state.searchStore.recentSearchIds.add(props.localResult.search_id);
			// Hydrate the EDGAR documents for this search. Although we call this
			// every time we read a search from the SearchStore via getFullResult,
			// newly-initiated searches have to trigger this again because the
			// search ID will not be present after we redirect to the tab immediately.
			// (See corresponding comment in getFullResult.)
			state.searchStore.loadEdgarDocuments(props.localResult.search_id);
		});
	}
}

export class SearchStore {
	appState: AppState;
	map: ElectricOptimisticMap<SearchResource, ["search_id"], "write_id">;

	loadedSearches: Map<SearchId, ElectricSyncedMap<SearchFull, ["search_id"]>> =
		new Map();

	// Referenced documents that are not synced. For now, these are only EDGAR
	// documents, but in the future we'll want to add other stores that
	// are too large to sync. These could also be Shapes like loadedSearches,
	// but it's not necessary right now because the EDGAR documents are effectively
	// immutable (whereas for searches the Shape lets us stream synthesis updates
	// easily).
	referencedDocuments: Map<
		SearchId,
		| { status: "loaded"; documents: EdgarDocumentWithFiling[] }
		| { status: "loading" }
	> = new Map();

	// A set of searchIds to render in the recent searches list.
	// We store these separately so that the user can remove entries from this list without
	// altering the underlying resources.
	recentSearchIds: Set<SearchId> = new Set();

	constructor(appState: AppState) {
		makeAutoObservable(this);
		this.appState = appState;
		this.map = new ElectricOptimisticMap({
			shapeUrl: `${API_ENDPOINT_HTTP}/shapes/search_results`,
			pKeyFields: ["search_id"],
			writeIdField: "write_id",
			shapeHash: openapiHashes.SearchResource,
			getBearerToken: this.appState.getTokenOrThrow,
		});
	}

	getResourceById(search_id: SearchId): Result<SearchResource, Error> {
		return this.map.get(search_id);
	}

	loadEdgarDocuments(search_id: SearchId) {
		this.referencedDocuments.set(search_id, { status: "loading" });
		getSearchEdgarDocumentsRoute(search_id).then((res) => {
			this.referencedDocuments.set(search_id, {
				status: "loaded",
				documents: res.data,
			});
		});
	}

	getFullResult(search_id: SearchId): SearchFull | undefined {
		// Note that this will fail when we run a new search, because we navigate
		// to the new search immediately, which triggers this function, but the
		// search_id is not present in our table of saved searches.
		// In this case, `InitiateSearchAction` will trigger a loadEdgarDocuments
		// once the search request returns.
		if (!this.referencedDocuments.has(search_id)) {
			this.loadEdgarDocuments(search_id);
		}

		const cached = this.loadedSearches.get(search_id);
		if (cached) {
			return cached.get(search_id);
		}
		const newShape = new ElectricSyncedMap<SearchFull, ["search_id"]>({
			shapeUrl: `${API_ENDPOINT_HTTP}/shapes/search_results_full/${search_id}`,
			pKeyFields: ["search_id"],
			shapeHash: openapiHashes.SearchFull,
			getBearerToken: () => this.appState.getTokenOrThrow(),
		});

		this.loadedSearches.set(search_id, newShape);
		return newShape.get(search_id);
	}

	/**
	 * Turns the SearchRequest into a SearchResult with empty results.
	 *
	 * Then, sends a search request to the server and updates the SearchResults
	 * object with the data that gets returned.
	 */
	initiateSearch(args: InitiateSearchAction["args"]) {
		const action = new InitiateSearchAction(args);
		this.appState.actionQueue.run(action);
	}

	/**
	 * Returns a chronologically sorted array of SearchResource objects from the recent searches list.
	 *
	 * Retrieves SearchResource objects for all IDs in the recentSearchIds set, filtering out
	 * any failed retrievals. Results are sorted by creation timestamp in descending order
	 * (newest first).
	 *
	 * @returns {SearchResource[]} Array of search resources, sorted by requested_at timestamp
	 */
	get recentSearches(): SearchResource[] {
		const searches = [...this.recentSearchIds]
			.map((id) => this.map.get(id))
			.filter((result) => result.isOk())
			.map((result) => result.value);

		return searches.sort((a, b) => {
			const aDate = new Date(a.requested_at);
			const bDate = new Date(b.requested_at);
			return bDate.getTime() - aDate.getTime();
		});
	}
}
