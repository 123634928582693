import { PDFViewer } from "@/components/pdf/pdfviewer";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { pdfSearchSchema } from "@/contexts/pdfviewer-context";
import { WebpageTabState } from "@/contexts/webpages/tab-state";
import { zodValidator } from "@/lib/zod-validator";
import { getWebpagePdf, getWebpageToc } from "@api/fastAPI";
import type { WebpageId } from "@api/schemas";
import { createFileRoute } from "@tanstack/react-router";
import { observer } from "mobx-react-lite";

const RouteComponent = observer(function RouteComponent() {
	const { tabState } = Route.useLoaderData();
	const appContext = useAppContext();

	const webpage = appContext.workspace.webpages.getResourceById(
		tabState.webpageId,
	);

	if (webpage.isErr()) {
		return <div>Webpage not found</div>;
	}

	return (
		<PDFViewer
			documentId={webpage.value.webpage_id}
			searchParams={tabState.searchParams}
			loader={async () => {
				const resp = await getWebpagePdf(webpage.value.webpage_id, {
					responseType: "arraybuffer",
				});
				return new Uint8Array(resp.data as ArrayBuffer);
			}}
			tocLoader={async () => {
				const resp = await getWebpageToc(webpage.value.webpage_id);
				return resp.data;
			}}
			headerMetadata={{
				title: webpage.value.name,
				authors: webpage.value.authors,
				description: webpage.value.description,
				url: webpage.value.original_url,
			}}
		/>
	);
});

export const Route = createFileRoute("/web/webpage/$webpage-id")({
	component: RouteComponent,
	validateSearch: zodValidator(pdfSearchSchema),
	loaderDeps: ({ search }) => ({ search }),
	loader: ({ params, context: { tab }, deps }) => {
		return {
			tabState: new WebpageTabState(
				tab,
				params["webpage-id"] as WebpageId,
				deps.search,
			),
		};
	},
});
