import { Mark, mergeAttributes } from "@tiptap/core";
import type { ParseRule } from "@tiptap/pm/model";

declare module "@tiptap/core" {
	interface Commands<ReturnType> {
		extract: {
			setExtract: () => ReturnType;
			toggleExtract: () => ReturnType;
			unsetExtract: () => ReturnType;
		};
	}
}

export const ExtractCustomMark = Mark.create({
	name: "extract",

	addOptions() {
		return {
			HTMLAttributes: {
				class: "bg-neutral-100 rounded-xs text-neutral-600 font-semibold",
			},
		};
	},

	parseHTML() {
		return [
			{
				tag: "mark[data-extract]",
			},
			{
				tag: "mark",
				getAttrs: (node): false | null => {
					if (typeof node === "string") return false;
					const attrs = node as HTMLElement;
					// return null to indicate this is a valid mark without specific attributes
					return attrs.hasAttribute("data-other-mark-type") ? false : null;
				},
			},
		] as ParseRule[];
	},

	renderHTML({ HTMLAttributes }) {
		return [
			"mark",
			mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
				"data-extract": "",
			}),
			0,
		];
	},
});
