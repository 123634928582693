import { Input } from "@/components/ui/input";
import { useTablesStore } from "@/contexts/app-context/db-store/db-store-hooks";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import type { Tab } from "@/contexts/tabs/tabs-context";
import { useTabStore } from "@/contexts/tabs/use-tab-store";
import { resourceRefToLinkProps } from "@/lib/paths";
import type { TableId } from "@api/schemas";
import { RouterProvider } from "@tanstack/react-router";
import { Node, type NodeViewProps, mergeAttributes } from "@tiptap/core";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

export interface AddIFrameOptions {
	src: string | null;
}

const IFrameNodeView = observer(function IFrameNodeView(props: NodeViewProps) {
	const src = props.node.attrs.src as AddIFrameOptions["src"];
	const height = props.node.attrs.height as number;
	const appContext = useAppContext();
	const tabStore = useTabStore();
	const localSearchStore = appContext.localSearchStore;
	const tableStore = useTablesStore();
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [tab, setTab] = useState<Tab | null>(null);
	const filteredTables = localSearchStore
		.searchResources(searchQuery, {
			resourceTypes: ["table"],
		})
		.map((table) => tableStore.getResourceById(table.resource_id as TableId))
		.filter((x) => x.isOk())
		.map((x) => x.value);

	useEffect(() => {
		if (src !== null) {
			const newTab = tabStore.createTab({
				initialLocation: {
					href: src,
				},
			});
			setTab(newTab);
		} else {
			setTab(null);
		}
	}, [src, tabStore]);

	const handleResize = (mouseDownEvent: React.MouseEvent<HTMLDivElement>) => {
		mouseDownEvent.preventDefault();
		mouseDownEvent.stopPropagation();

		const startPosition = {
			y: mouseDownEvent.pageY,
		};
		const startHeight = height;
		const iframeElement = mouseDownEvent.currentTarget.parentElement;
		let newHeight = startHeight;

		function onMouseMove(mouseMoveEvent: MouseEvent) {
			newHeight = startHeight + (mouseMoveEvent.pageY - startPosition.y);

			// Set a minimum height to prevent the iframe from becoming too small
			if (newHeight < 200) {
				newHeight = 200;
			}

			// Update visual height without updating the attribute
			if (iframeElement) {
				iframeElement.style.height = `${newHeight}px`;
			}
		}

		function onMouseUp() {
			document.body.removeEventListener("mousemove", onMouseMove);

			// Only update the attribute on mouse release
			props.updateAttributes({
				height: newHeight,
			});
		}

		document.body.addEventListener("mousemove", onMouseMove);
		document.body.addEventListener("mouseup", onMouseUp, { once: true });
	};

	return (
		<NodeViewWrapper
			as="div"
			className="relative w-full rounded-md border"
			style={{ height: `${height}px` }}
			contentEditable={false}
		>
			{props.selected && (
				<div className="pointer-events-none absolute inset-0 z-40 bg-blue-500 opacity-10" />
			)}
			{tab === null ? (
				<div className="flex flex-col gap-2 p-2">
					<Input
						placeholder="Search for a table"
						value={searchQuery}
						onChange={(e) => {
							setSearchQuery(e.target.value);
						}}
					/>
					<div className="flex flex-col">
						{filteredTables.map((table) => (
							<button
								key={table.table_id}
								type="button"
								className="cursor-pointer px-2 py-1 text-left hover:bg-neutral-100"
								onClick={() => {
									props.updateAttributes({
										src: tabStore.linkPropsToHref(
											resourceRefToLinkProps({
												type: "table",
												resource_id: table.table_id,
											}),
										),
									});
								}}
							>
								{table.name}
							</button>
						))}
					</div>
				</div>
			) : (
				<div className="h-full w-full overflow-auto">
					<RouterProvider router={tab.router} />
				</div>
			)}
			{props.editor.isEditable && (
				<div
					className="absolute right-0 bottom-0 left-0 z-50 h-2 cursor-ns-resize bg-transparent hover:bg-neutral-100"
					onMouseDown={handleResize}
				/>
			)}
		</NodeViewWrapper>
	);
});

declare module "@tiptap/core" {
	interface Commands<ReturnType> {
		iframe: {
			addIframe: (options: AddIFrameOptions) => ReturnType;
		};
	}
}

export const IFrame = Node.create({
	name: "iframe",
	group: "block",
	atom: true,

	addAttributes() {
		return {
			src: {
				default: null,
				parseHTML: (element) => element.getAttribute("src"),
				renderHTML: (attributes) => {
					return {
						src: attributes.src,
					};
				},
			},
			height: {
				default: 384, // Default height (h-96 in Tailwind is 24rem = 384px)
				parseHTML: (element) => {
					const height = element.getAttribute("height");
					return height ? Number.parseInt(height, 10) : 384;
				},
				renderHTML: (attributes) => {
					return {
						height: attributes.height,
					};
				},
			},
		};
	},

	parseHTML() {
		return [
			{
				tag: "iframe",
			},
		];
	},

	renderHTML({ HTMLAttributes }) {
		return ["iframe", mergeAttributes(HTMLAttributes), 0];
	},

	addNodeView() {
		return ReactNodeViewRenderer(IFrameNodeView);
	},

	addCommands() {
		return {
			addIframe:
				(options: AddIFrameOptions) =>
				({ tr, dispatch }) => {
					const { selection } = tr;
					const node = this.type.create({
						src: options.src,
					});

					if (dispatch) {
						tr.replaceRangeWith(selection.from, selection.to, node);
						dispatch(tr);
					}
					return true;
				},
		};
	},
});
