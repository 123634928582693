import { API_ENDPOINT_HTTP } from "@/config";
import type { AppState } from "@/contexts/app-context/app-context";
import openapiHashes from "@/generated/openapi-hashes.json";
import { ElectricOptimisticMap } from "@/lib/sync/electric";
import type { User } from "@api/schemas";

export function getDisplayName(user: User): string {
	return user.user_first_name
		? `${user.user_first_name}${user.user_last_name ? ` ${user.user_last_name}` : ""}`
		: user.user_email || "Unknown User";
}

export class UserStore {
	appState: AppState;
	items: ElectricOptimisticMap<User, ["user_id"], "write_id">;

	constructor(appState: AppState) {
		this.appState = appState;
		this.items = new ElectricOptimisticMap<User, ["user_id"], "write_id">({
			shapeUrl: `${API_ENDPOINT_HTTP}/shapes/users`,
			shapeHash: openapiHashes.User,
			pKeyFields: ["user_id"],
			writeIdField: "write_id",
			getBearerToken: this.appState.getTokenOrThrow,
		});
	}
}
