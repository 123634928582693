// From https://github.com/KyleAMathews/electric-notes/blob/main/src/y-electric/utils.ts

const hexStringToUint8Array = (hexString: string) => {
	const cleanHexString = hexString.startsWith("\\x")
		? hexString.slice(2)
		: hexString;
	return new Uint8Array(
		// biome-ignore lint/style/noNonNullAssertion: <explanation>
		cleanHexString
			.match(/.{1,2}/g)!
			.map((byte) => Number.parseInt(byte, 16)),
	);
};

export const byteAToUint8Array = {
	bytea: hexStringToUint8Array,
};

export const byteaToUint8ArrayLazy = {
	bytea: (hexString: string) => {
		return () => hexStringToUint8Array(hexString);
	},
};

export const timestamptzToDate = {
	timestamptz: (timestamp: string) => {
		return new Date(timestamp);
	},
};
