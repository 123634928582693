import { useMessageStore } from "@/contexts/app-context/db-store/db-store-hooks";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import type { ThreadRootMessage } from "@/contexts/messages/stores/message-store";
import { MessageTabState } from "@/contexts/messages/tab-state";
import { MessageComponent } from "@/pages/tabs/message/-components/message-component";
import { MessageInput } from "@/pages/tabs/message/-components/message-input";
import { ResourceLinkComponent } from "@/plugins/resource-link";
import { ArrowBendDownRight } from "@phosphor-icons/react";
import { createFileRoute } from "@tanstack/react-router";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";

const ThreadPreview = observer(function ThreadPreview(props: {
	thread: ThreadRootMessage;
	scrollContainerRef: React.RefObject<HTMLDivElement>;
}) {
	const messageStore = useMessageStore();
	const replies = messageStore.getThreadReplies(props.thread.message_id);

	return (
		<div className="flex w-full flex-col pb-2 hover:bg-neutral-50">
			<MessageComponent message={props.thread} />
			<div className="w-full px-4">
				<ResourceLinkComponent
					linkProps={{
						to: "/message/$message-id",
						params: {
							"message-id": props.thread.message_id,
						},
					}}
					className="flex h-6 w-full items-center justify-start gap-1 border border-transparent px-2 py-3 text-neutral-600 hover:border-inherit hover:bg-white"
				>
					<ArrowBendDownRight size={14} />
					<span className="text-xs">
						{replies.length} {replies.length === 1 ? "reply" : "replies"}
					</span>
				</ResourceLinkComponent>
			</div>
		</div>
	);
});

const ThreadsEmptyState = () => {
	return (
		<div className="flex h-full w-full items-center justify-center">
			<span className="text-neutral-500 text-sm">No threads</span>
		</div>
	);
};

const DateDivider = ({ date }: { date: string }) => {
	return (
		<>
			<div className="sticky top-0 z-10 mx-auto flex h-8 items-center rounded-full border bg-white px-4 font-semibold text-xs">
				{date}
			</div>
			<div className="-mt-4 my-6 w-full border-t" />
		</>
	);
};

let hasScrolled = false;

/**
 * Message home. Shows all threads.
 */
const Threads = observer(function Threads() {
	const { tabState } = Route.useLoaderData();
	const appContext = useAppContext();
	const messageStore = appContext.messageStore;
	const scrollContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!scrollContainerRef.current) {
			return;
		}
		const threadsByDay = messageStore.threadsByDay;

		// Only scroll on initial load or when new threads are added
		if (threadsByDay.size > 0 && !hasScrolled) {
			hasScrolled = true;
			scrollContainerRef.current.scrollTo({
				top: scrollContainerRef.current.scrollHeight,
				behavior: "instant",
			});
		}
	}, [messageStore.threadsByDay]);

	return (
		<>
			<div className="relative h-full overflow-hidden">
				<div
					className="flex h-full flex-col overflow-y-auto py-2"
					ref={scrollContainerRef}
				>
					{messageStore.threadsByDay.size > 0 ? (
						Array.from(messageStore.threadsByDay.entries()).map(
							([date, threads]) => (
								<div key={date} className="flex flex-col pb-1">
									<DateDivider date={date} />
									{threads.map((thread) => (
										<ThreadPreview
											key={thread.message_id}
											thread={thread}
											scrollContainerRef={scrollContainerRef}
										/>
									))}
								</div>
							),
						)
					) : (
						<ThreadsEmptyState />
					)}
				</div>
			</div>
			<MessageInput tabState={tabState} />
		</>
	);
});

export const Route = createFileRoute("/message/")({
	component: Threads,
	loader: ({ context: { tab } }) => {
		const tabState = new MessageTabState(tab, null);
		return {
			tabState,
		};
	},
});
