import {
	getRecordLinkColumn,
	getResourceIndexColumn,
	getResourceIndexSelectorColumn,
} from "@/components/table/columns";
import type { FeedChannelsStore } from "@/contexts/feeds/stores/feed-stores";
import type { TabStore } from "@/contexts/tabs/tabs-context";
import type { FeedChannelId, ResourceLink } from "@api/schemas";
import type { ColumnDef } from "@tanstack/react-table";

// Keep this in sync with the index view in the server
interface FeedsIndexRecord {
	link: ResourceLink;
	name: string;
	feed_channel_id: FeedChannelId;
	created_at: string;
}

/**
 * This represents the table state for the feeds index.
 */
export class FeedsIndex {
	feedChannelsStore: FeedChannelsStore;
	tabStore: TabStore;

	constructor(feedChannelsStore: FeedChannelsStore, tabStore: TabStore) {
		this.feedChannelsStore = feedChannelsStore;
		this.tabStore = tabStore;
	}

	get rows(): FeedsIndexRecord[] {
		return this.feedChannelsStore.map.values().map((feedChannel) => ({
			link: this.tabStore.resourceRefToHref({
				resource_id: feedChannel.feed_channel_id,
				type: "feed-channel",
			}),
			name: feedChannel.name,
			feed_channel_id: feedChannel.feed_channel_id,
			created_at: feedChannel.created_at,
		}));
	}

	get columns(): ColumnDef<FeedsIndexRecord>[] {
		return [
			getResourceIndexSelectorColumn<FeedsIndexRecord>(),
			getRecordLinkColumn({
				resetScroll: true,
				didInit: true,
			}),
			getResourceIndexColumn({
				key: "name",
				name: "Name",
				scalarType: "text",
				width: 200,
			}),
			getResourceIndexColumn({
				key: "created_at",
				name: "Created At",
				scalarType: "timestamptz",
				width: 200,
			}),
		];
	}
}
