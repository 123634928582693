import { FieldTypeIcon } from "@/components/table/data-type-indicators";
import { SortPopover } from "@/components/table/sort-popover";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
	ResizableHandle,
	ResizablePanel,
	ResizablePanelGroup,
} from "@/components/ui/resizable";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useTablesStore } from "@/contexts/app-context/db-store/db-store-hooks";
import {
	DEFAULT_WHERE_EXPRESSION,
	type ParsedQueryParams,
	QueryTabState,
	querySearchSchemaRaw,
} from "@/contexts/query/tab-state";
import { cn } from "@/lib/utils";
import { zodValidator } from "@/lib/zod-validator";
import { FilterBuilder } from "@/pages/tabs/query/-components/filter-builder";
import { QueryResultTable } from "@/pages/tabs/query/-components/query-result-table";
import { TableBrowserSidebar } from "@/pages/tabs/query/-components/table-browser-sidebar";
import type {
	AggregateField,
	AggregateType,
	AndFilterGroup,
	DataType,
	OrFilterGroup,
	TableId,
} from "@api/schemas";
import type { Field } from "@api/schemas";
import type { FieldId } from "@api/schemas";
import { Play } from "@phosphor-icons/react";
import { X } from "@phosphor-icons/react";
import { Plus, Spinner } from "@phosphor-icons/react";
import { createFileRoute } from "@tanstack/react-router";
import { autorun, reaction, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";

const FromTableSelect = observer(() => {
	const { tabState: queryState } = Route.useLoaderData();
	const tablesStore = useTablesStore();

	const currentTableId = queryState.parsedQueryParams.fromTableId ?? "";

	return (
		<Select
			value={currentTableId}
			onValueChange={(value) => {
				// When switching tables, reset field pointers so that we don't
				// have dangling references to the previous table's fields.
				// (Maybe we should key the fields by table ID to avoid this issue)
				runInAction(() => {
					queryState.parsedQueryParams.fromTableId = value as TableId;
					queryState.parsedQueryParams.sortFields = [];
					queryState.parsedQueryParams.selectedFields = [];
					queryState.parsedQueryParams.groupingFields = [];
					queryState.parsedQueryParams.aggregateFields = [];
					queryState.parsedQueryParams.where = {
						type: "and",
						expressions: [],
					};
				});
			}}
			disabled={tablesStore.map.keys.length === 0}
		>
			<SelectTrigger className="w-64">
				<SelectValue
					placeholder={
						tablesStore.map.keys.length === 0 ? "No tables" : "Select table..."
					}
				/>
			</SelectTrigger>
			<SelectContent>
				{tablesStore.sortedResources.map((table) => {
					return (
						<SelectItem key={table.table_id} value={table.table_id}>
							<span>{table.name}</span>
						</SelectItem>
					);
				})}
			</SelectContent>
		</Select>
	);
});

const FieldToggle = (props: {
	field: Field;
	selected: boolean;
	onToggle: () => void;
}) => {
	return (
		<button
			type="button"
			className="flex cursor-pointer items-center gap-2 rounded-sm px-2 py-1 hover:bg-neutral-100"
			onClick={props.onToggle}
		>
			<Checkbox
				className="justify-self-center"
				checked={props.selected}
				// Prevent checkbox click from triggering parent div click
				onClick={(e) => {
					props.onToggle();
					e.stopPropagation();
				}}
			/>
			<span
				className={cn("flex items-center gap-1.5 text-neutral-700 text-sm")}
			>
				<FieldTypeIcon fieldType={props.field.type} />
				<span>{props.field.name}</span>
			</span>
		</button>
	);
};

const SelectFieldsCheckboxes = observer(() => {
	const { tabState: queryState } = Route.useLoaderData();

	const fields = Array.from(queryState.fromTableFields?.values() ?? []);

	const allSelected =
		queryState.selectedFieldsBySourceId.size === fields.length;

	return (
		<div className="flex flex-col">
			<button
				type="button"
				className="flex cursor-pointer items-center gap-2 rounded-sm px-2 py-1 hover:bg-neutral-100"
				onClick={() => {
					queryState.toggleAllFields();
				}}
			>
				<Checkbox
					checked={allSelected}
					onClick={(e) => {
						queryState.toggleAllFields();
						e.stopPropagation();
					}}
				/>
				<span className="text-neutral-700 text-sm">
					{allSelected ? "Deselect all" : "Select all"}
				</span>
			</button>

			<Separator className="my-1" />

			{fields.map(({ field }) => (
				<FieldToggle
					key={field.field_id}
					field={field}
					selected={queryState.selectedFieldsBySourceId.has(field.field_id)}
					onToggle={() => queryState.toggleSelectedField(field.field_id)}
				/>
			))}
		</div>
	);
});

const AGGREGATE_TYPE_LABELS: Record<AggregateType, string> = {
	count: "Count",
	sum: "Sum",
	avg: "Average",
	min: "Minimum",
	max: "Maximum",
	array_agg: "Collect",
};

const AggregateFieldEditor = observer(function AggregateFieldEditor({
	aggregateField,
	fieldsForAggregation,
	queryState,
	index,
}: {
	aggregateField: AggregateField;
	fieldsForAggregation: { field: Field; dataType: DataType }[];
	queryState: QueryTabState;
	index: number;
}) {
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		const dispose = autorun(() => {
			if (inputRef.current) {
				inputRef.current.value = aggregateField.target_field_name;
			}
		});

		return () => dispose();
	}, [aggregateField.target_field_name]);

	return (
		<div key={index} className="flex items-stretch gap-2">
			{/* Field to aggregate */}
			<Select
				value={aggregateField.source_field_id}
				onValueChange={(value) => {
					const sourceField = fieldsForAggregation.find(
						({ field }) => field.field_id === value,
					);

					queryState.updateAggregateField(index, {
						...aggregateField,
						source_field_id: value as FieldId,
						target_field_name: `${sourceField?.field.name ?? "Aggregated field"} (${aggregateField.aggregate_type})`,
					});
				}}
			>
				<SelectTrigger className="w-[180px]">
					<SelectValue placeholder="Select field..." />
				</SelectTrigger>
				<SelectContent>
					{fieldsForAggregation.map(({ field }) => (
						<SelectItem key={field.field_id} value={field.field_id}>
							<span className="flex items-center gap-1.5">
								<FieldTypeIcon
									fieldType={field.type}
									className="text-neutral-500"
								/>
								{field.name}
							</span>
						</SelectItem>
					))}
				</SelectContent>
			</Select>

			{/* Aggregate function */}
			<Select
				value={aggregateField.aggregate_type}
				onValueChange={(value) => {
					const sourceField = fieldsForAggregation.find(
						({ field }) => field.field_id === aggregateField.source_field_id,
					);

					queryState.updateAggregateField(index, {
						...aggregateField,
						aggregate_type: value as AggregateType,
						target_field_name: `${sourceField?.field.name ?? "Aggregated field"} (${value})`,
					});
				}}
			>
				<SelectTrigger className="w-[120px]">
					<SelectValue placeholder="Function..." />
				</SelectTrigger>
				<SelectContent>
					{Object.entries(AGGREGATE_TYPE_LABELS).map(([key, label]) => (
						<SelectItem key={key} value={key}>
							{label}
						</SelectItem>
					))}
				</SelectContent>
			</Select>

			<Input
				ref={inputRef}
				onBlur={(e) => {
					queryState.updateAggregateField(index, {
						...aggregateField,
						target_field_name: e.target.value,
					});
				}}
				className="h-full w-48"
			/>

			{/* Remove button */}
			<Button
				variant="ghost"
				className="h-full p-2"
				onClick={() => queryState.removeAggregateField(index)}
			>
				<X weight="bold" />
			</Button>
		</div>
	);
});

const GroupByFields = observer(() => {
	const { tabState: queryState } = Route.useLoaderData();

	const fields = Array.from(queryState.fromTableFields?.values() ?? []);
	const fieldsForAggregation = fields.filter(
		({ field }) => !queryState.groupingFieldsBySourceId.has(field.field_id),
	);

	if (!fields) return null;

	if (queryState.parsedQueryParams.viewMode === "sql_query") {
		return null;
	}
	if (queryState.parsedQueryParams.queryBuilderViewType === "select") {
		return null;
	}

	return (
		<div className="flex flex-col gap-2">
			<div className="flex flex-col">
				{fields.map(({ field }) => (
					<FieldToggle
						key={field.field_id}
						field={field}
						selected={queryState.groupingFieldsBySourceId.has(field.field_id)}
						onToggle={() => queryState.toggleGroupByField(field.field_id)}
					/>
				))}
			</div>

			<div className="flex flex-col gap-1">
				<h2 className="font-medium text-sm">Aggregations</h2>
				<div className="flex flex-col gap-2 rounded-md border p-2">
					{queryState.parsedQueryParams.aggregateFields.map(
						(aggregateField, index) => (
							<AggregateFieldEditor
								key={`${aggregateField.source_field_id}-${index}`}
								aggregateField={aggregateField}
								queryState={queryState}
								fieldsForAggregation={fieldsForAggregation}
								index={index}
							/>
						),
					)}
					<Button
						variant="ghost"
						size="sm"
						className="justify-start gap-1 px-2"
						onClick={() =>
							queryState.addAggregateField({
								source_field_id: fieldsForAggregation[0].field.field_id,
								aggregate_type: "count",
								target_field_name: `${fieldsForAggregation[0].field.name} (count)`,
							})
						}
					>
						<Plus weight="bold" />
						Add Aggregation
					</Button>
				</div>
			</div>
		</div>
	);
});

const QueryInput = observer(function QueryInput() {
	const { tabState: queryState } = Route.useLoaderData();
	return (
		<div className="flex h-full flex-col">
			<Tabs
				value={queryState.parsedQueryParams.viewMode ?? "query_builder"}
				onValueChange={(newValue) => {
					runInAction(() => {
						queryState.parsedQueryParams.viewMode = newValue as
							| "query_builder"
							| "sql_query";
					});
				}}
				className="flex max-h-[50%] w-full shrink-0 flex-col pb-0"
			>
				<TabsList className="h-10 w-full shrink-0 items-end">
					<TabsTrigger value="query_builder" className="ml-2">
						Query Builder
					</TabsTrigger>
					<TabsTrigger value="sql_query">SQL Query</TabsTrigger>
				</TabsList>

				<TabsContent
					value="query_builder"
					className="mt-0 flex flex-col gap-3 overflow-y-auto p-3"
				>
					<div className="flex flex-col gap-0.5">
						<Label className="font-medium text-sm">Source</Label>
						<FromTableSelect />
					</div>
					<div className="flex flex-col gap-0.5">
						<Label className="font-medium text-sm">View Type</Label>
						<Select
							value={queryState.isSelectMode ? "select" : "group_by"}
							onValueChange={(value) =>
								queryState.setQueryBuilderSubMode(
									value as "select" | "group_by",
								)
							}
						>
							<SelectTrigger className="w-32">
								<SelectValue placeholder="View type..." />
							</SelectTrigger>
							<SelectContent>
								<SelectItem value="select">Select</SelectItem>
								<SelectItem value="group_by">Group By</SelectItem>
							</SelectContent>
						</Select>
					</div>

					{queryState.parsedQueryParams.fromTableId && (
						<div className="flex flex-col gap-2">
							<div className="flex flex-col gap-3 rounded-md border p-3">
								<div className="flex flex-col gap-2">
									<h3 className="font-medium text-sm">Filter</h3>
									<FilterBuilder />
								</div>

								{queryState.isSelectMode ? (
									<div className="flex flex-col gap-2">
										<h3 className="font-medium text-sm">Select Fields</h3>
										<SelectFieldsCheckboxes />
									</div>
								) : (
									<div className="flex flex-col gap-2">
										<h3 className="font-medium text-sm">Group By</h3>
										<GroupByFields />
									</div>
								)}
							</div>
							<div className="flex w-full items-center justify-between">
								<SortPopover sortState={queryState.sortState} />
							</div>
						</div>
					)}
				</TabsContent>

				<TabsContent value="sql_query">
					<div className="flex min-w-0 flex-col gap-2">
						<div className="relative min-h-0 w-full min-w-0 px-2">
							<textarea
								value={queryState.draftSqlQuery}
								onChange={(e) => {
									queryState.setDraftSqlQuery(e.target.value);
								}}
								className="h-48 w-full rounded-md border p-2 font-mono text-sm"
							/>
							<Button
								type="button"
								size="icon"
								variant="ghost"
								onClick={() => {
									queryState.applySqlQuery();
									queryState.runQuery();
								}}
								disabled={
									!queryState.draftSqlQuery.trim() ||
									queryState.result.state === "loading"
								}
								className="absolute bottom-4 left-4 z-10 flex items-center justify-center rounded bg-emerald-50 hover:bg-emerald-100"
							>
								<Play weight="fill" className="text-emerald-500" />
							</Button>
						</div>
					</div>
				</TabsContent>
			</Tabs>

			<div className="flex min-h-[50%] grow items-center justify-center rounded-b-md border-t bg-neutral-50">
				{queryState.result.state === "success" ? (
					<div className="h-full min-h-0 w-full p-3">
						<QueryResultTable />
					</div>
				) : queryState.result.state === "loading" ? (
					<Spinner className="h-8 w-8 animate-spin text-neutral-500" />
				) : queryState.result.state === "empty" ? (
					<span className="font-mono text-neutral-500 text-sm">
						{queryState.result.message}
					</span>
				) : queryState.parsedQueryParams.viewMode === "sql_query" ? (
					<span className="font-mono text-neutral-500 text-sm">
						{queryState.result.error.message}
					</span>
				) : (
					<span className="font-mono text-neutral-500 text-sm">
						{queryState.result.error.message}
					</span>
				)}
			</div>
		</div>
	);
});

const QueryPage = observer(() => {
	const { tabState: queryState } = Route.useLoaderData();

	useEffect(
		function updateUrl() {
			// Update the URL params whenever the query state changes to trigger
			// a new query.
			const dispose = reaction(
				() => ({
					view_mode: queryState.parsedQueryParams.viewMode,
					query_builder_view_type:
						queryState.parsedQueryParams.queryBuilderViewType,

					from_table_id: queryState.parsedQueryParams.fromTableId,
					where: JSON.stringify(queryState.parsedQueryParams.where),

					selected_fields: JSON.stringify(
						queryState.parsedQueryParams.selectedFields,
					),
					grouping_fields: JSON.stringify(
						queryState.parsedQueryParams.groupingFields,
					),
					aggregate_fields: JSON.stringify(
						queryState.parsedQueryParams.aggregateFields,
					),
					sort_fields: JSON.stringify(queryState.parsedQueryParams.sortFields),

					sql_query: queryState.parsedQueryParams.sqlQuery,

					page_size: queryState.parsedQueryParams.pageSize,
					page_idx: queryState.parsedQueryParams.pageIdx,
				}),
				(search) => {
					// TODO: We may want to move to an interface where most queries
					// are stored in the backend, and the frontend works with a query
					// ID for sharing purposes, similar to searches.
					queryState.tab.router.navigate({
						to: "/query",
						search,
					});
				},
			);

			return () => dispose();
		},
		[queryState],
	);

	return (
		<div className="absolute inset-0 flex h-full min-h-0 flex-col">
			<ResizablePanelGroup
				direction="horizontal"
				className="flex min-h-0 flex-grow"
			>
				{/* Table Browser Sidebar */}
				<ResizablePanel
					defaultSize={25}
					minSize={15}
					className="min-h-0 overflow-y-auto"
				>
					<TableBrowserSidebar />
				</ResizablePanel>

				<ResizableHandle />

				{/* Query Form and Results */}
				<ResizablePanel
					defaultSize={75}
					className="min-h-0 min-w-0 overflow-y-auto"
				>
					<QueryInput />
				</ResizablePanel>
			</ResizablePanelGroup>
		</div>
	);
});

function safeParseWhere(str: string): AndFilterGroup | OrFilterGroup {
	try {
		// At runtime, you might also want to check if the parsed object is actually an AndFilterGroup or OrFilterGroup
		return JSON.parse(str);
	} catch {
		return DEFAULT_WHERE_EXPRESSION;
	}
}

export const Route = createFileRoute("/query/")({
	component: QueryPage,
	validateSearch: zodValidator(querySearchSchemaRaw),
	loaderDeps: ({ search }) => ({ search }),
	loader: ({ context: { tab }, deps }) => {
		const rawSearchSchema = querySearchSchemaRaw.parse(deps.search);

		const parsed: ParsedQueryParams = {
			viewMode: rawSearchSchema.view_mode,

			queryBuilderViewType: rawSearchSchema.query_builder_view_type,

			fromTableId: (rawSearchSchema.from_table_id ?? null) as TableId | null,
			where: safeParseWhere(rawSearchSchema.where),

			selectedFields: JSON.parse(
				rawSearchSchema.selected_fields?.trim() ?? "[]",
			),
			groupingFields: JSON.parse(
				rawSearchSchema.grouping_fields?.trim() ?? "[]",
			),
			aggregateFields: JSON.parse(
				rawSearchSchema.aggregate_fields?.trim() ?? "[]",
			),
			sortFields: JSON.parse(rawSearchSchema.sort_fields?.trim() ?? "[]"),

			sqlQuery: rawSearchSchema.sql_query,

			pageSize: rawSearchSchema.page_size,
			pageIdx: rawSearchSchema.page_idx,
		};

		/**
		 * Our autorun call in QueryPage fires a navigate event whenever the
		 * tab state changes. If the tab state already exists, instead of
		 * parsing the URL all over again, rerun the query.
		 */
		if (tab.state instanceof QueryTabState) {
			tab.state.updateQueryState(parsed);
			tab.state.runQuery();

			return {
				tabState: tab.state,
			};
		}

		return {
			tabState: new QueryTabState(tab, parsed),
		};
	},
});
