import { ReactRenderer } from "@tiptap/react";
import { createElement } from "react";

// Taken from ReactRenderer.tsx
// In ResourceLink, we render our ObjectList with the ReactRenderer and pass to it
// a ref. However, the ref is only assigned if the component is a forwardRef.
// Our component is a MobX observer, which makes the forwardRef check fail. This
// custom Renderer has no checks and assumes the ref will be set correctly.
export class CustomReactRenderer<
	R = unknown,
	// biome-ignore lint: <>
	P extends Record<string, any> = {},
> extends ReactRenderer<R, P> {
	render(): void {
		const Component = this.component;
		const props = this.props;
		// biome-ignore lint: <>
		const editor = this.editor as any;

		// @ts-ignore This is a hack to make the ref work
		// biome-ignore lint: <>
		props.ref = (ref: any) => {
			this.ref = ref;
		};

		this.reactElement = createElement(Component, props);

		editor?.contentComponent?.setRenderer(this.id, this);
	}
}
