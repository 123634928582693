import { API_ENDPOINT_HTTP } from "@/config";
import type { AppState } from "@/contexts/app-context/app-context";
import openapiHashes from "@/generated/openapi-hashes.json";
import { createWebSearchId, createWriteId } from "@/lib/id-generators";
import type { Transaction } from "@/lib/sync/action-executor";
import { OptimisticAction } from "@/lib/sync/action-executor";
import { ElectricOptimisticMap, ElectricSyncedMap } from "@/lib/sync/electric";
import { webSearchRoute } from "@api/fastAPI";
import type {
	WebSearchFull,
	WebSearchId,
	WebSearchResource,
} from "@api/schemas";
import { makeAutoObservable, runInAction } from "mobx";
import type { Result } from "neverthrow";

export type WebSearchParams = {
	query: string;
};

class InitiateWebSearchAction extends OptimisticAction<
	AppState,
	{
		config: WebSearchParams;
		onLocalSuccess: (resource: WebSearchResource) => void;
	},
	WebSearchResource,
	void
> {
	async local(tx: Transaction, state: AppState) {
		const webSearchResource: WebSearchResource = {
			...this.args.config,
			web_search_id: createWebSearchId(),
			requested_at: new Date().toISOString(),
			results_loaded_at: null,
			write_id: createWriteId(),
		};
		state.webSearchStore.map.insert(tx, webSearchResource);
		this.args.onLocalSuccess(webSearchResource);
		return webSearchResource;
	}

	async remote(props: { localResult: WebSearchResource }, state: AppState) {
		await webSearchRoute(props.localResult);
		runInAction(() => {
			state.webSearchStore.recentSearchIds.add(props.localResult.web_search_id);
		});
	}
}

export class WebSearchStore {
	appState: AppState;
	map: ElectricOptimisticMap<WebSearchResource, ["web_search_id"], "write_id">;

	// Cache for full web search results
	loadedSearches: Map<
		WebSearchId,
		ElectricSyncedMap<WebSearchFull, ["web_search_id"]>
	> = new Map();

	// A set of searchIds to render in the recent searches list.
	// We store these separately so that the user can remove entries from this list without
	// altering the underlying resources.
	recentSearchIds: Set<WebSearchId> = new Set();

	constructor(appState: AppState) {
		makeAutoObservable(this);
		this.appState = appState;
		this.map = new ElectricOptimisticMap({
			shapeUrl: `${API_ENDPOINT_HTTP}/shapes/web_search_results`,
			pKeyFields: ["web_search_id"],
			writeIdField: "write_id",
			shapeHash: openapiHashes.WebSearchResource,
			getBearerToken: this.appState.getTokenOrThrow,
		});
	}

	getResourceById(webSearchId: WebSearchId): Result<WebSearchResource, Error> {
		return this.map.get(webSearchId);
	}

	getFullResult(webSearchId: WebSearchId): WebSearchFull | undefined {
		const cached = this.loadedSearches.get(webSearchId);
		if (cached) {
			return cached.get(webSearchId);
		}
		const newShape = new ElectricSyncedMap<WebSearchFull, ["web_search_id"]>({
			shapeUrl: `${API_ENDPOINT_HTTP}/shapes/web_search_results_full/${webSearchId}`,
			pKeyFields: ["web_search_id"],
			shapeHash: openapiHashes.WebSearchFull,
			getBearerToken: () => this.appState.getTokenOrThrow(),
		});
		this.loadedSearches.set(webSearchId, newShape);
		return newShape.get(webSearchId);
	}

	async initiateWebSearch(args: InitiateWebSearchAction["args"]) {
		const action = new InitiateWebSearchAction(args);
		this.appState.actionQueue.run(action);
	}

	/**
	 * Returns a chronologically sorted array of WebSearchResource objects from the recent searches list.
	 *
	 * Retrieves WebSearchResource objects for all IDs in the recentSearchIds set, filtering out
	 * any failed retrievals. Results are sorted by creation timestamp in descending order
	 * (newest first).
	 *
	 * @returns {WebSearchResource[]} Array of web search resources, sorted by created_at timestamp
	 */
	get recentSearches(): WebSearchResource[] {
		const searches = [...this.recentSearchIds]
			.map((id) => this.map.get(id))
			.filter((result) => result.isOk())
			.map((result) => result.value);

		return searches.sort((a, b) => {
			const aDate = new Date(a.requested_at);
			const bDate = new Date(b.requested_at);
			return bDate.getTime() - aDate.getTime();
		});
	}
}
