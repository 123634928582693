import { StreamingEditor, TextEditor } from "@/components/editor";
import { AssistantPresenceIcon } from "@/components/layout/right-sidebar/assistant-presence-icon";
import {
	FailedInteractionComponent,
	InteractionEventComponent,
} from "@/components/layout/right-sidebar/interaction-event-component";
import {
	type EndStatus,
	StatusBadge,
} from "@/components/layout/right-sidebar/running-status";
import { SpanComponent } from "@/components/layout/right-sidebar/spans";
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
	useAssistantSessionStore,
	useSpanStore,
} from "@/contexts/app-context/db-store/db-store-hooks";
import { getStepEndStatus } from "@/contexts/assistant/stores/step-store";
import type {
	AssistantContent,
	AssistantSessionId,
	CompletedForkStepState,
	CompletedInteractStepState,
	ErroredInteractStepState,
	InteractionEvent,
	RunningForkStepState,
	RunningInteractStepState,
	Span,
	StepId,
	StepShape,
	StepState,
} from "@api/schemas";
import { CaretDown, PersonSimpleRun, Power } from "@phosphor-icons/react";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";

function getThoughtsFromRawResponse(
	rawResponse: AssistantContent,
): string | null {
	const thinkingBlock = rawResponse.find((block) => block.type === "thinking");
	if (thinkingBlock === undefined) {
		return null;
	}
	return thinkingBlock.text;
}

function getThoughtsEndStatus(state: StepState): EndStatus | null {
	switch (state.type) {
		case "responding":
			return null;
		case "received_invalid_response":
			return {
				code: "error",
				endTime: state.end_time,
			};
		case "running_interact":
		case "errored_interact":
		case "completed_interact":
		case "running_fork":
		case "completed_fork":
			return {
				code: "ok",
				endTime: state.response_end_time,
			};
		case "end_session":
			return {
				code: "ok",
				endTime: state.end_time,
			};
		case "unexpected_error": {
			const lastEndStatus = getThoughtsEndStatus(state.last_step_state);
			if (lastEndStatus === null) {
				// If the error occurred while responding, we shouldn't return
				// null but instead return the end time of the step
				return {
					code: "error",
					endTime: state.end_time,
				};
			}
			return lastEndStatus;
		}
		default: {
			const _exhaustiveCheck: never = state;
			return _exhaustiveCheck;
		}
	}
}

const StepThoughts = observer(function StepThoughts({
	step,
}: {
	step: StepShape;
}) {
	const thoughtsRef = useRef<HTMLParagraphElement>(null);
	const stepState =
		step.state.type === "unexpected_error"
			? step.state.last_step_state
			: step.state;
	const thoughts =
		"raw_response" in stepState
			? getThoughtsFromRawResponse(stepState.raw_response)
			: "Thinking...";
	const endStatus: EndStatus | null = getThoughtsEndStatus(step.state);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (thoughtsRef.current) {
			thoughtsRef.current.scrollTop = thoughtsRef.current.scrollHeight;
		}
	}, [thoughts]);

	return (
		<Collapsible defaultOpen={false} className="flex flex-col gap-1">
			<CollapsibleTrigger className="group flex w-full items-center justify-between">
				<span className="inline-flex items-center gap-2">
					<span className="font-medium text-sm">Thoughts</span>
					<CaretDown
						size={16}
						className="group-data-[state=open]:-rotate-180"
					/>
				</span>
				<StatusBadge startTime={step.start_time} endStatus={endStatus} />
			</CollapsibleTrigger>
			<CollapsibleContent>
				<div className="relative bg-neutral-50">
					<div className="absolute inset-x-2 top-0 z-10 h-4 bg-gradient-to-b from-neutral-50 to-transparent" />
					<p
						ref={thoughtsRef}
						className="max-h-64 w-full max-w-full overflow-y-auto whitespace-pre-line p-2 font-mono text-xs"
					>
						{thoughts}
					</p>
					<div className="absolute inset-x-2 bottom-0 z-10 h-4 w-full bg-gradient-to-t from-neutral-50 to-transparent" />
				</div>
			</CollapsibleContent>
		</Collapsible>
	);
});

function getActionEndStatus(state: StepState): EndStatus | null {
	switch (state.type) {
		// Cases before the action starts
		case "responding":
		case "received_invalid_response":
			return null;

		// Cases while the action is running
		case "running_interact":
		case "running_fork":
			return null;

		case "errored_interact":
			return {
				endTime: state.end_time,
				code: "error",
			};

		case "completed_interact":
		case "completed_fork":
		case "end_session":
			return {
				endTime: state.end_time,
				code: "ok",
			};

		case "unexpected_error": {
			const lastEndStatus = getActionEndStatus(state.last_step_state);
			if (lastEndStatus === null) {
				return {
					endTime: state.end_time,
					code: "error",
				};
			}
			return lastEndStatus;
		}
		default: {
			const _exhaustiveCheck: never = state;
			return _exhaustiveCheck;
		}
	}
}

type StepEvent =
	| {
			type: "span";
			span: Span;
	  }
	| {
			type: "interaction";
			interactionEvent: InteractionEvent;
	  };

function getStepEventTime(event: StepEvent): string {
	switch (event.type) {
		case "span":
			return event.span.start_time;
		case "interaction":
			return event.interactionEvent.timestamp;
	}
}

const InteractStepActions = observer(function InteractStepActions({
	stepId,
	stepState,
}: {
	stepId: StepId;
	stepState:
		| RunningInteractStepState
		| ErroredInteractStepState
		| CompletedInteractStepState;
}) {
	const spanStore = useSpanStore();
	const spans = spanStore.getForStep(stepId);
	const events: StepEvent[] = [
		...stepState.completed_events.map((interactionEvent) => ({
			type: "interaction" as const,
			interactionEvent,
		})),
		...spans.map((span) => ({
			type: "span" as const,
			span,
		})),
	].sort((a, b) => {
		return (
			new Date(getStepEventTime(a)).getTime() -
			new Date(getStepEventTime(b)).getTime()
		);
	});

	return (
		<div className="flex flex-col">
			{events.map((event) => {
				switch (event.type) {
					case "interaction":
						return (
							<InteractionEventComponent
								key={event.interactionEvent.timestamp}
								interactionEvent={event.interactionEvent}
							/>
						);
					case "span":
						return <SpanComponent key={event.span.span_id} span={event.span} />;
				}
			})}
			{stepState.type === "errored_interact" && (
				<FailedInteractionComponent
					type={stepState.pending_interactions[0].type}
					errorMessage={stepState.error_message}
				/>
			)}
		</div>
	);
});

/**
 * A single forked session's step display.
 */
const ForkedSessionCollapsible = observer(function ForkedSessionCollapsible({
	assistantSessionId,
}: {
	assistantSessionId: AssistantSessionId;
}) {
	const assistantSessionStore = useAssistantSessionStore();
	const assistantSession = assistantSessionStore.getById(assistantSessionId);
	if (!assistantSession.isOk()) {
		return null;
	}
	const goal = assistantSession.value.goal;
	return (
		<Collapsible defaultOpen={false}>
			<CollapsibleTrigger className="group flex w-full min-w-0 items-center justify-between gap-2 px-2 py-1">
				<div className="flex min-w-0 gap-3">
					<div className="mt-1">
						<AssistantPresenceIcon assistantSessionId={assistantSessionId} />
					</div>
					<TextEditor
						className="mt-0.5 grow font-medium text-sm"
						options={{
							content: goal,
							editable: false,
						}}
					/>
				</div>
				<CaretDown
					size={16}
					className=" group-data-[state=open]:-rotate-180 flex-none text-neutral-500"
				/>
			</CollapsibleTrigger>
			<CollapsibleContent className="mx-2 mt-1 mb-4 bg-neutral-50 p-2">
				<StreamingEditor
					options={{
						content: "TODO(John): add status",
						editable: false,
					}}
					className="font-mono text-xs"
				/>
			</CollapsibleContent>
		</Collapsible>
	);
});

const ForkStepActions = ({
	stepState,
}: {
	stepState: RunningForkStepState | CompletedForkStepState;
}) => {
	return (
		<div>
			<div className="flex items-center gap-2 px-2 py-2 text-neutral-950 text-sm">
				<PersonSimpleRun weight="fill" className="flex-none" />
				<span>Run {stepState.forked_sessions.length} sessions</span>
			</div>
			<div className="flex flex-col gap-1">
				{stepState.forked_sessions.map((assistantSessionId) => (
					<ForkedSessionCollapsible
						key={assistantSessionId}
						assistantSessionId={assistantSessionId}
					/>
				))}
			</div>
		</div>
	);
};

const StepActions = observer(function StepActions({
	step,
}: {
	step: StepShape;
}) {
	const stepState =
		step.state.type === "unexpected_error"
			? step.state.last_step_state
			: step.state;
	if (!("status" in stepState)) {
		return null;
	}
	const status = stepState.status;
	const startTime =
		stepState.type === "end_session"
			? stepState.end_time
			: stepState.response_end_time;
	const endStatus = getActionEndStatus(step.state);

	let eventsComponent: React.ReactNode;
	switch (stepState.type) {
		case "running_interact":
		case "errored_interact":
		case "completed_interact": {
			eventsComponent = (
				<InteractStepActions stepId={step.step_id} stepState={stepState} />
			);
			break;
		}
		case "running_fork":
		case "completed_fork": {
			eventsComponent = <ForkStepActions stepState={stepState} />;
			break;
		}
		case "end_session": {
			eventsComponent = (
				<div className="flex items-center gap-1 py-1 text-neutral-400 text-xs hover:text-neutral-950 data-[state=open]:text-neutral-950">
					<Power />
					<span>End Session</span>
				</div>
			);
			break;
		}
		default: {
			const _exhaustiveCheck: never = stepState;
			return _exhaustiveCheck;
		}
	}

	return (
		<div className="flex flex-col gap-1">
			<div className="group flex w-full items-center justify-between">
				<span className="font-medium text-sm">Actions</span>
				<StatusBadge startTime={startTime} endStatus={endStatus} />
			</div>
			<TextEditor
				className="w-full max-w-full bg-neutral-50 p-2 font-mono text-xs"
				options={{
					content: status,
					editable: false,
				}}
			/>
			{eventsComponent}
		</div>
	);
});

export const StepComponent = observer(function StepComponent({
	step,
	stepIndex,
}: {
	step: StepShape;
	stepIndex: number;
}) {
	const endStatus = getStepEndStatus(step);

	return (
		<div className="flex w-full flex-col gap-2 px-2">
			{/* Header */}
			<span className="inline-flex w-full items-center justify-between text-xs">
				<span className="inline-flex items-center gap-2">
					<span className="font-medium text-neutral-950 text-sm">
						Step {stepIndex + 1}
					</span>
					<span className="text-neutral-500">
						{dayjs(step.start_time).format("MMM D, h:mm A")}
					</span>
				</span>
				<StatusBadge startTime={step.start_time} endStatus={endStatus} />
			</span>

			<StepThoughts step={step} />

			<StepActions step={step} />
		</div>
	);
});
