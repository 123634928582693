import { API_ENDPOINT_HTTP } from "@/config";
import type { AppState } from "@/contexts/app-context/app-context";
import openapiHashes from "@/generated/openapi-hashes.json";
import { resourceRefToLinkProps } from "@/lib/paths";
import { ElectricSyncedMap } from "@/lib/sync/electric";
import { sortByOrder } from "@/lib/utils";
import type {
	AssistantSession,
	AssistantSessionId,
	MessageId,
	ResourceRef,
	SessionStatus,
	Span,
	StepShape,
} from "@api/schemas";
import { makeAutoObservable } from "mobx";
import { computedFn } from "mobx-utils";
import { type Result, err, ok } from "neverthrow";

/**
 * Summary of an assistant session and what it did.
 */
interface SessionSummary {
	numSteps: number;
	linksVisited: Set<string>;
	messagesSent: number;
}

type ActiveAssistantSession = AssistantSession & {
	end_status: null;
};

type CompletedAssistantSession = AssistantSession & {
	end_status: SessionStatus;
};

/**
 * A result of a search for assistant sessions.
 *
 * session: The assistant session that matches the search.
 * spans: The spans that match the search. Returns all of the session's spans
 * if resourceRef and threadId are null.
 */
interface AssistantSessionFilterResult<T extends AssistantSession> {
	session: T;
	spans: Span[];
}

export interface SessionFilters {
	resourceRef: ResourceRef | null;
	threadId: MessageId | null;
	goal: string | null;
	path: string | null;
}

export class AssistantSessionStore {
	appState: AppState;
	map: ElectricSyncedMap<AssistantSession, ["assistant_session_id"]>;

	constructor(appState: AppState) {
		makeAutoObservable(this);
		this.appState = appState;
		this.map = new ElectricSyncedMap<
			AssistantSession,
			["assistant_session_id"]
		>({
			shapeUrl: `${API_ENDPOINT_HTTP}/shapes/assistant_sessions`,
			pKeyFields: ["assistant_session_id"],
			shapeHash: openapiHashes.AssistantSession,
			getBearerToken: () => this.appState.getTokenOrThrow(),
		});
	}

	getById(id: AssistantSessionId): Result<AssistantSession, Error> {
		const session = this.map.get(id);
		if (!session) {
			return err(new Error("Assistant session not found"));
		}
		return ok(session);
	}

	get liveSessions() {
		return Array.from(this.map.values()).filter(
			(session) => session.end_status === null,
		);
	}

	get rootLiveSessions() {
		return this.liveSessions.filter(
			(session) => session.parent_step_id === null,
		);
	}

	/**
	 * Get the steps for an assistant session.
	 */
	getSteps = computedFn(
		(assistantSessionId: AssistantSessionId): StepShape[] => {
			const steps = sortByOrder(
				Array.from(this.appState.stepStore.map.values()).filter(
					(step) => step.assistant_session_id === assistantSessionId,
				),
				(step) => step.start_time,
			);
			return steps;
		},
	);

	get rootSessions() {
		return sortByOrder(
			Array.from(this.map.values()).filter(
				(session) => session.parent_step_id === null,
			),
			(session) => session.start_time,
		);
	}

	getLinksVisited = computedFn(
		(assistantSessionId: AssistantSessionId): Set<string> => {
			const spans =
				this.appState.spanStore.getForAssistantSessionId(assistantSessionId);
			return new Set(
				spans.flatMap((span) => {
					if (span.attributes.type === "open_tab") {
						return [span.attributes.initial_path];
					}
					if (span.attributes.type === "navigate_tab") {
						return [span.attributes.new_path];
					}
					return [];
				}),
			);
		},
	);

	getNumMessagesSent = computedFn(
		(assistantSessionId: AssistantSessionId): number => {
			const messages = this.appState.messageStore.messages
				.values()
				.filter(
					(message) => message.assistant_session_id === assistantSessionId,
				);
			return messages.length;
		},
	);

	getSessionSummary = computedFn(
		(assistantSessionId: AssistantSessionId): SessionSummary => {
			const numSteps = this.getSteps(assistantSessionId).length;
			const linksVisited = this.getLinksVisited(assistantSessionId);
			const messagesSent = this.getNumMessagesSent(assistantSessionId);
			return { numSteps, linksVisited, messagesSent };
		},
	);

	getParentSession = computedFn(
		(
			assistantSessionId: AssistantSessionId,
		): Result<AssistantSession | null, Error> =>
			this.getById(assistantSessionId).andThen((session) => {
				if (session.parent_step_id === null) {
					return ok(null);
				}
				return this.appState.stepStore
					.getById(session.parent_step_id)
					.andThen((step) => this.getById(step.assistant_session_id));
			}),
	);

	/**
	 * Searches for assistant sessions that match the given filters. Also
	 * organizes sessions by status. Used in the top-level assistant activity
	 * viewer.
	 *
	 * TODO(John): I don't really understand why this is a useful feature...
	 *
	 * goal: A query to search for in the goal of the assistant session.
	 * resourceRef: Whether the assistant session has spans that accessed this resource.
	 * threadId: Whether the assistant session has spans that accessed this thread.
	 */
	filterAssistantSessions = computedFn(
		(
			filters: SessionFilters,
		): {
			active: AssistantSessionFilterResult<ActiveAssistantSession>[];
			completed: AssistantSessionFilterResult<CompletedAssistantSession>[];
		} => {
			// Goal query filtering
			let sessions: AssistantSession[] = Array.from(
				this.appState.assistantSessionStore.map.values(),
			);
			const goalQuery = filters.goal;
			if (goalQuery !== null && goalQuery !== "") {
				sessions = sessions.filter((session) => {
					if (session.goal?.includes(goalQuery)) {
						return true;
					}
					return false;
				});
			}

			// Thread ID filtering
			// For now, we'll use the open_thread_id, but later we'll use
			const threadId = filters.threadId;
			if (threadId !== null) {
				sessions = sessions.filter((session) => {
					return session.open_thread_id === threadId;
				});
			}

			// Add spans to each session
			let sessionSearchResults: AssistantSessionFilterResult<AssistantSession>[] =
				sessions.map((session) => {
					const spans = this.appState.spanStore.getForAssistantSessionId(
						session.assistant_session_id,
					);
					return {
						session,
						spans,
					};
				});

			// Resource filtering
			const resourceRef = filters.resourceRef;

			if (resourceRef) {
				const resourceLinkProps = resourceRefToLinkProps(resourceRef);
				const resourcePath =
					this.appState.tabStore.linkPropsToHref(resourceLinkProps);

				sessionSearchResults = sessionSearchResults
					.map((result) => {
						const filteredSpans = result.spans.filter((span) => {
							const attrs = span.attributes;
							if (attrs.type === "open_tab") {
								return attrs.initial_path.includes(resourcePath);
							}
							if (attrs.type === "navigate_tab") {
								return attrs.new_path.includes(resourcePath);
							}
							return false;
						});
						return { ...result, spans: filteredSpans };
					})
					.filter((result) => result.spans.length > 0);
			}

			// Path filtering
			if (filters.path) {
				// Provide a dummy origin because the path is relative
				const normalizedFilterPath = new URL(
					filters.path,
					"http://localhost:3000",
				).pathname;
				sessionSearchResults = sessionSearchResults.filter((result) => {
					return result.session.open_tabs.some((tab) => {
						const tabPath = new URL(tab.path, "http://localhost:3000").pathname;
						return tabPath === normalizedFilterPath;
					});
				});
			}

			return {
				active: sessionSearchResults.filter(
					(
						result,
					): result is AssistantSessionFilterResult<ActiveAssistantSession> =>
						result.session.end_status === null,
				),
				completed: sessionSearchResults.filter(
					(
						result,
					): result is AssistantSessionFilterResult<CompletedAssistantSession> =>
						result.session.end_status !== null,
				),
			};
		},
	);
}
