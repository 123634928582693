import {
	getRecordLinkColumn,
	getResourceIndexColumn,
	getResourceIndexSelectorColumn,
} from "@/components/table/columns";
import type { TablesStore } from "@/contexts/tables/stores/table-store";
import type { TabStore } from "@/contexts/tabs/tabs-context";
import type { ResourceLink, TableId, TableResource } from "@api/schemas";
import type { ColumnDef } from "@tanstack/react-table";
import { makeAutoObservable } from "mobx";

// Keep this in sync with the index view in the server
interface TablesIndexRecord {
	link: ResourceLink;
	name: string;
	table_id: TableId;
	created_at: string;
}

/**
 * This represents the table state for the tables index.
 */
export class TablesIndex {
	tablesStore: TablesStore;
	tabStore: TabStore;

	constructor(tablesStore: TablesStore, tabStore: TabStore) {
		makeAutoObservable(this);
		this.tablesStore = tablesStore;
		this.tabStore = tabStore;
	}

	get columns(): ColumnDef<TablesIndexRecord>[] {
		return [
			getResourceIndexSelectorColumn<TablesIndexRecord>(),
			getRecordLinkColumn({
				resetScroll: true,
				didInit: true,
			}),
			getResourceIndexColumn({
				key: "name",
				name: "Name",
				scalarType: "text",
				width: 200,
			}),
			getResourceIndexColumn({
				key: "created_at",
				name: "Created At",
				scalarType: "timestamptz",
				width: 200,
			}),
		];
	}

	tableResourceToRow = (table: TableResource): TablesIndexRecord => {
		return {
			link: this.tabStore.resourceRefToHref({
				resource_id: table.table_id,
				type: "table",
			}),
			name: table.name,
			table_id: table.table_id,
			created_at: table.created_at,
		};
	};

	get allRows() {
		return Array.from(this.tablesStore.map.values()).map(
			this.tableResourceToRow,
		);
	}

	get userRows() {
		return Array.from(this.tablesStore.map.values())
			.filter((table) => table.creator_id !== null)
			.map(this.tableResourceToRow);
	}

	get edgarRows() {
		return Array.from(this.tablesStore.map.values())
			.filter((table) => table.integration_type === "edgar")
			.map(this.tableResourceToRow);
	}
}
