import { StatusDot } from "@/components/layout/right-sidebar/assistant-presence-icon";
import { LiveSessionsViewer } from "@/components/layout/right-sidebar/live-sessions-viewer";
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { useAssistantSessionStore } from "@/contexts/app-context/db-store/db-store-hooks";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { cn } from "@/lib/utils";
import { ResourceLinkComponent } from "@/plugins/resource-link";
import { CaretRight, ChatTeardropDots, Desktop } from "@phosphor-icons/react";
import { RouterProvider } from "@tanstack/react-router";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

/**
 * The right sidebar, which shows messages and session activity.
 */
export const RightSidebar = observer(() => {
	const appContext = useAppContext();
	const assistantSessionStore = useAssistantSessionStore();
	const liveSessions = assistantSessionStore.liveSessions;
	const [isOpen, setIsOpen] = useState(liveSessions.length > 0);
	const [wasAutoOpened, setWasAutoOpened] = useState(false);

	// Auto-open the collapsible when sessions go from 0 to >0
	useEffect(() => {
		if (liveSessions.length > 0 && !wasAutoOpened) {
			setIsOpen(true);
			setWasAutoOpened(true);
		}
		if (liveSessions.length === 0 && wasAutoOpened) {
			setWasAutoOpened(false);
		}
	}, [liveSessions.length, wasAutoOpened]);

	return (
		appContext.rightSidebarState.showRightSidebar && (
			<div className="flex h-full w-full flex-col bg-white">
				<Collapsible open={isOpen} onOpenChange={setIsOpen} className="w-full">
					<CollapsibleTrigger className="group flex h-10 w-full flex-none items-center justify-between gap-2 border-b bg-neutral-50 px-4">
						<div className="flex items-center gap-2">
							<Desktop />
							<span className="font-semibold">Sessions</span>
							<ResourceLinkComponent
								linkProps={{ href: "/assistant-session" }}
								className="rounded px-1 text-xs opacity-0 transition-opacity hover:bg-neutral-100 hover:text-neutral-950 group-hover:text-neutral-500 group-hover:opacity-100"
							>
								See all
							</ResourceLinkComponent>
						</div>
						<div className="flex items-center gap-1">
							<div className="flex items-center gap-1">
								<StatusDot active={liveSessions.length > 0} />
								<span className="text-neutral-500 text-xs">
									{liveSessions.length}
								</span>
							</div>
							<CaretRight
								className={cn(
									"h-4 w-4 transition-transform",
									isOpen && "rotate-90",
								)}
							/>
						</div>
					</CollapsibleTrigger>
					<CollapsibleContent className="h-96 overflow-y-auto border-b">
						<LiveSessionsViewer isOpen={isOpen} />
					</CollapsibleContent>
				</Collapsible>

				<div className="flex min-h-0 w-full grow flex-col">
					<div className="flex h-10 w-full flex-none items-center gap-2 border-b bg-neutral-50 px-4">
						<ChatTeardropDots />
						<span className="font-semibold">Chat</span>
					</div>
					<div className="flex min-h-0 w-full grow flex-col overflow-y-auto pb-2">
						<RouterProvider
							router={appContext.rightSidebarState.messageTab.router}
						/>
					</div>
				</div>
			</div>
		)
	);
});
