import { StaticHtmlRenderer, StreamingEditor } from "@/components/editor";
import { TabLink } from "@/components/tab-link";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Skeleton } from "@/components/ui/skeleton";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { API_ENDPOINT_HTTP } from "@/config";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import type { PdfSearchParams } from "@/contexts/pdfviewer-context";
import {
	type EnrichedResourceResult,
	SearchResultsTabState,
} from "@/contexts/search/tab-state";
import { LinkTarget } from "@/contexts/tabs/router-types";
import { useTabStore } from "@/contexts/tabs/use-tab-store";
import { cn, formatAuthors, formatDate, formatUploadTitle } from "@/lib/utils";
import {
	getGetFeedItemExtractedImageRouteQueryKey,
	getGetFeedItemPageImageRouteQueryKey,
	getGetUploadExtractedImageRouteQueryKey,
	getGetUploadPageImageRouteQueryKey,
	getGetWebpageExtractedImageRouteQueryKey,
	getGetWebpagePageImageRouteQueryKey,
} from "@api/fastAPI";
import {
	type ImageSearchResult,
	PageResolution,
	type ResourceResult,
	type SearchId,
	type Snippet,
} from "@api/schemas";
import { ArrowDown, ArrowUp, Dot, FilePdf } from "@phosphor-icons/react";
import { createFileRoute } from "@tanstack/react-router";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useId, useMemo, useState } from "react";

interface SearchAnswerProps {
	synthesis: string;
}

const SearchAnswer = observer(function SearchAnswer({
	synthesis,
}: SearchAnswerProps) {
	const [showMore, setShowMore] = useState(false);

	const editorOptions = useMemo(() => {
		return {
			content: synthesis,
			editable: false,
		};
	}, [synthesis]);

	return (
		<div className="relative flex flex-col">
			<div
				className={cn(
					"flex min-h-48 w-full shrink-0 flex-col gap-3 overflow-y-hidden bg-neutral-50 p-4",
					showMore ? "h-auto" : "h-48",
				)}
			>
				<StreamingEditor
					className="w-full max-w-full"
					options={editorOptions}
				/>
			</div>
			<button
				className="h-5 w-full border-neutral-100 border-t bg-neutral-50 text-center text-neutral-500 text-xs"
				type="button"
				onClick={() => setShowMore(!showMore)}
			>
				{showMore ? "Show less" : "Show more"}
			</button>
		</div>
	);
});

const Title = observer(function Title({
	resource,
}: { resource: EnrichedResourceResult["resource"] }) {
	if ("upload_id" in resource) {
		return (
			<h1 className="text-blue-600">
				{formatUploadTitle({
					name: resource.name,
					subtitle: resource.subtitle,
					filename: resource.filename,
				})}
			</h1>
		);
	}

	if ("document_id" in resource) {
		return <h1 className="text-blue-600">{resource.filename}</h1>;
	}

	return <h1 className="text-blue-600">{resource.name}</h1>;
});

// TODO(John): fix - search should probably be more differentiated by resource
const Subtitle = observer(function Subtitle({
	resource,
}: { resource: EnrichedResourceResult["resource"] }) {
	if (
		"feed_item_id" in resource ||
		"upload_id" in resource ||
		"webpage_id" in resource
	) {
		return (
			<div className="text-neutral-500 text-xs">
				{formatAuthors({
					authors: resource.authors,
					truncate: true,
				})}
				{resource.date_published && `, ${formatDate(resource.date_published)}`}
			</div>
		);
	}

	// Maybe the name of the ID field isn't the best discriminator...
	if ("document_id" in resource) {
		return (
			<div className="flex items-center text-neutral-500 text-xs">
				{resource.filing.form}
				<Dot />
				{resource.filing.filing_date}
				<Dot />
				{resource.accession_number}
				<Dot />
				{resource.sequence}
			</div>
		);
	}
});

const ResourceImage = observer(function ResourceImage({
	resource,
}: {
	resource: EnrichedResourceResult["resource"];
}) {
	if ("upload_id" in resource) {
		return <FilePdf className="h-12 max-w-12 rounded-xs shadow-sm" />;
	}
	if ("feed_item_id" in resource) {
		if (resource.og_image) {
			return (
				<img
					src={resource.og_image}
					alt={resource.name ?? ""}
					className="flex h-12 max-w-12 shrink-0 object-cover"
				/>
			);
		}
		return null;
	}

	return null;
});

const getMarkedTextFromSnippet = (snippet: Snippet) => {
	const { fragment, extract_ranges } = snippet;

	// If no extract ranges, just return the full text unmarked.
	if (!extract_ranges || extract_ranges.length === 0) {
		return fragment;
	}

	let output = "";
	let lastIndex = 0;

	// Sort the ranges, in case they come in out of order
	const sortedRanges = [...extract_ranges].sort((a, b) => a[0] - b[0]);

	for (const [start, end] of sortedRanges) {
		// Append the unmarked text before this range
		output += fragment.slice(lastIndex, start);

		// Mark this extract
		output += `<mark data-extract>${fragment.slice(start, end)}</mark>`;

		// Move our pointer
		lastIndex = end;
	}

	// Append any final piece after the last range
	output += fragment.slice(lastIndex);

	return output;
};

const ImageResultItem = observer(function ImageResultItem({
	imageResult,
	result,
}: { imageResult: ImageSearchResult; result: ResourceResult }) {
	const appContext = useAppContext();

	let imageUrl: string;
	let pageUrl: string;
	let linkComponent: React.ReactNode;

	// Is there a better way to template image URLs besides using query keys?
	switch (result.resource_ref.type) {
		case "upload":
			imageUrl = getGetUploadExtractedImageRouteQueryKey(
				result.resource_ref.resource_id,
				imageResult.image.page_index,
				imageResult.image.index_in_page,
			)[0];
			pageUrl = getGetUploadPageImageRouteQueryKey(
				result.resource_ref.resource_id,
				imageResult.image.page_index,
				PageResolution.high,
			)[0];
			linkComponent = (
				<TabLink
					target={LinkTarget.NewTab}
					to={"/uploads/upload/$upload-id"}
					params={{
						"upload-id": result.resource_ref.resource_id,
					}}
					search={{
						page_index: imageResult.image.page_index,
					}}
				>
					View in PDF
				</TabLink>
			);
			break;
		case "webpage":
			imageUrl = getGetWebpageExtractedImageRouteQueryKey(
				result.resource_ref.resource_id,
				imageResult.image.page_index,
				imageResult.image.index_in_page,
			)[0];
			pageUrl = getGetWebpagePageImageRouteQueryKey(
				result.resource_ref.resource_id,
				imageResult.image.page_index,
				PageResolution.high,
			)[0];
			linkComponent = (
				<TabLink
					target={LinkTarget.NewTab}
					to={"/web/webpage/$webpage-id"}
					params={{
						"webpage-id": result.resource_ref.resource_id,
					}}
					search={{
						page_index: imageResult.image.page_index,
					}}
				>
					View in browser
				</TabLink>
			);
			break;
		case "feed-item":
			imageUrl = getGetFeedItemExtractedImageRouteQueryKey(
				result.resource_ref.resource_id,
				imageResult.image.page_index,
				imageResult.image.index_in_page,
			)[0];
			pageUrl = getGetFeedItemPageImageRouteQueryKey(
				result.resource_ref.resource_id,
				imageResult.image.page_index,
				PageResolution.high,
			)[0];
			linkComponent = (
				<TabLink
					target={LinkTarget.NewTab}
					to={"/feeds/feed-item/$feed-item-id"}
					params={{
						"feed-item-id": result.resource_ref.resource_id,
					}}
					search={{
						page_index: imageResult.image.page_index,
					}}
				>
					View in feed
				</TabLink>
			);
			break;
		case "edgar-document":
			throw new Error("Edgar documents are not supported yet");
		default: {
			const _exhaustiveCheck: never = result.resource_ref;
			throw new Error(`Unhandled resource type: ${_exhaustiveCheck}`);
		}
	}

	return (
		<Dialog>
			<DialogTrigger asChild>
				<button
					type="button"
					key={`${imageResult.image.page_index}-${imageResult.image.index_in_page}`}
					className="flex flex-col items-center justify-between gap-2 border p-2 hover:border-blue-200 hover:bg-blue-50"
				>
					<img
						src={`${API_ENDPOINT_HTTP}${imageUrl}`}
						alt="Search result"
						className="max-h-48 max-w-48"
					/>
					<div className="w-full max-w-max text-center text-neutral-500 text-xs">
						Page {imageResult.image.page_index + 1}
						{appContext.devSettings.showSearchResultScores && (
							<div className="mt-1 max-w-max border bg-neutral-50 px-1 py-0.5 text-neutral-500 text-xs">
								Score: {Math.round(imageResult.score * 100) / 100}
							</div>
						)}
					</div>
				</button>
			</DialogTrigger>
			<DialogContent className="min-h-48 max-w-(--breakpoint-md)">
				{linkComponent}
				<div className="flex flex-col items-center justify-center">
					<div className="relative max-h-max max-w-max">
						<img src={`${API_ENDPOINT_HTTP}${pageUrl}`} alt="Search result" />
						<div
							className="absolute border border-blue-300"
							style={{
								left: `${imageResult.image.xmin_frac * 100 - 1}%`,
								top: `${imageResult.image.ymin_frac * 100 - 1}%`,
								width: `${(imageResult.image.xmax_frac - imageResult.image.xmin_frac) * 100 + 2}%`,
								height: `${(imageResult.image.ymax_frac - imageResult.image.ymin_frac) * 100 + 2}%`,
							}}
						/>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
});

const TextResultItem = observer(function TextResultItem({
	result,
	snippet,
}: {
	result: ResourceResult;
	snippet: Snippet;
}) {
	const appContext = useAppContext();

	let linkComponent: React.ReactNode;

	const highlightSearchParams: PdfSearchParams = {
		page_index: snippet.chunk_result.chunk.start_page_index,
		highlight_start: snippet.fragment,
	};

	switch (result.resource_ref.type) {
		case "feed-item":
			linkComponent = (
				<TabLink
					target={LinkTarget.NewTab}
					to={"/feeds/feed-item/$feed-item-id"}
					params={{ "feed-item-id": result.resource_ref.resource_id }}
					search={highlightSearchParams}
					className="text-blue-600 text-xs hover:underline"
				>
					Page {snippet.chunk_result.chunk.start_page_index + 1}
				</TabLink>
			);
			break;
		case "upload":
			linkComponent = (
				<TabLink
					target={LinkTarget.NewTab}
					to={"/uploads/upload/$upload-id"}
					params={{ "upload-id": result.resource_ref.resource_id }}
					search={highlightSearchParams}
					className="text-blue-600 text-xs hover:underline"
				>
					Page {snippet.chunk_result.chunk.start_page_index + 1}
				</TabLink>
			);
			break;
		case "webpage":
			linkComponent = (
				<TabLink
					target={LinkTarget.NewTab}
					to={"/web/webpage/$webpage-id"}
					params={{ "webpage-id": result.resource_ref.resource_id }}
					search={highlightSearchParams}
					className="text-blue-600 text-xs hover:underline"
				>
					Page {snippet.chunk_result.chunk.start_page_index + 1}
				</TabLink>
			);
			break;
		case "edgar-document":
			linkComponent = (
				<TabLink
					target={LinkTarget.NewTab}
					to={"/edgar/edgar-document/$document-id"}
					params={{ "document-id": result.resource_ref.resource_id }}
					search={highlightSearchParams}
					className="text-blue-600 text-xs hover:underline"
				>
					Page {snippet.chunk_result.chunk.start_page_index + 1}
				</TabLink>
			);
			break;
		default: {
			const _exhaustiveCheck: never = result.resource_ref;
			throw new Error(`Unhandled resource type: ${_exhaustiveCheck}`);
		}
	}

	return (
		<div className="flex flex-col gap-2">
			<div className="flex items-center gap-1 ">
				{appContext.devSettings.showSearchResultScores ? (
					<>
						<div className="w-fit border border-neutral-200 bg-neutral-50 px-1 py-0.5 font-medium text-neutral-500 text-xs">
							{/* Truncate to 2 decimal places */}
							Snippet: {Math.round(snippet.score * 100) / 100}
						</div>
						<div className="w-fit border border-neutral-200 bg-neutral-50 px-1 py-0.5 font-medium text-neutral-500 text-xs">
							{/* Truncate to 2 decimal places */}
							Chunk reranker:{" "}
							{Math.round(snippet.chunk_result.reranker_score * 100) / 100}
						</div>
					</>
				) : null}
				{linkComponent}
			</div>
			<div className="border-neutral-200 border-l-2 pl-3">
				<StaticHtmlRenderer
					content={getMarkedTextFromSnippet(snippet)}
					className="prose-headings:font-medium prose-h1:text-base prose-h2:text-sm prose-h3:text-sm prose-h4:text-sm prose-headings:text-neutral-500 prose-p:text-neutral-500 prose-p:text-xs"
				/>
			</div>
		</div>
	);
});

const SearchResult = observer(function SearchResult({
	enrichedResult,
}: { enrichedResult: EnrichedResourceResult }) {
	const appContext = useAppContext();
	const { result, resource } = enrichedResult;
	const [sortingStrategy, setSortingStrategy] = useState<
		"order" | "order-desc" | "relevance" | "relevance-desc"
	>("relevance");

	const hasSnippets = result.snippets.length > 0;
	const hasImages = result.images.length > 0;

	const sortedSnippets: Snippet[] = useMemo(() => {
		const snippets = [...result.snippets];
		switch (sortingStrategy) {
			case "order":
				return snippets.sort(
					(a, b) =>
						b.chunk_result.chunk.start_page_index -
						a.chunk_result.chunk.start_page_index,
				);
			case "order-desc":
				return snippets.sort(
					(a, b) =>
						a.chunk_result.chunk.start_page_index -
						b.chunk_result.chunk.start_page_index,
				);
			case "relevance":
				return snippets.sort(
					(a, b) =>
						b.chunk_result.reranker_score - a.chunk_result.reranker_score,
				);
			case "relevance-desc":
				return snippets.sort(
					(a, b) =>
						a.chunk_result.reranker_score - b.chunk_result.reranker_score,
				);
			default: {
				const _exhaustiveCheck: never = sortingStrategy;
				throw new Error(`Unhandled sorting strategy: ${_exhaustiveCheck}`);
			}
		}
	}, [result.snippets, sortingStrategy]);

	const sortedImages = useMemo(() => {
		const images = [...result.images];
		switch (sortingStrategy) {
			case "order":
				return images.sort((a, b) => b.image.page_index - a.image.page_index);
			case "order-desc":
				return images.sort((a, b) => a.image.page_index - b.image.page_index);
			case "relevance":
				return images.sort((a, b) => b.score - a.score);
			case "relevance-desc":
				return images.sort((a, b) => a.score - b.score);
		}
	}, [result.images, sortingStrategy]);

	const tabStore = useTabStore();
	const head = tabStore.getResourceRefTabHead(result.resource_ref);

	let linkComponent: React.ReactNode;

	switch (result.resource_ref.type) {
		case "feed-item":
			linkComponent = (
				<TabLink
					target={LinkTarget.NewTab}
					to="/feeds/feed-item/$feed-item-id"
					params={{ "feed-item-id": result.resource_ref.resource_id }}
				>
					<Title resource={resource} />
				</TabLink>
			);
			break;
		case "upload":
			linkComponent = (
				<TabLink
					target={LinkTarget.NewTab}
					to="/uploads/upload/$upload-id"
					params={{ "upload-id": result.resource_ref.resource_id }}
				>
					<Title resource={resource} />
				</TabLink>
			);
			break;
		case "webpage":
			linkComponent = (
				<TabLink
					target={LinkTarget.NewTab}
					to="/web/webpage/$webpage-id"
					params={{ "webpage-id": result.resource_ref.resource_id }}
				>
					<Title resource={resource} />
				</TabLink>
			);
			break;
		case "edgar-document":
			linkComponent = (
				<TabLink
					target={LinkTarget.NewTab}
					to="/edgar/edgar-document/$document-id"
					params={{ "document-id": result.resource_ref.resource_id }}
				>
					<Title resource={resource} />
				</TabLink>
			);
			break;
		default: {
			const _exhaustiveCheck: never = result.resource_ref;
			throw new Error(`Unhandled resource type: ${_exhaustiveCheck}`);
		}
	}

	return (
		<div className="flex flex-col gap-2">
			{/* Header */}
			<div className="flex flex-col gap-2">
				{/* Resource path */}
				<div className="flex gap-2">
					<ResourceImage resource={resource} />
					<div className="flex flex-col gap-1">
						<div className="flex items-center gap-1">
							{appContext.devSettings.showSearchResultScores ? (
								<div className="w-fit border border-neutral-200 bg-neutral-50 px-1 py-0.5 font-medium text-neutral-500 text-xs">
									{/* Truncate to 2 decimal places */}
									Score: {Math.round(result.score * 100) / 100}
								</div>
							) : null}
							<head.icon className="h-4 w-4" />
							{linkComponent}
						</div>
						<Subtitle resource={resource} />
					</div>
				</div>
			</div>
			<div className="flex flex-col gap-2">
				{/* 
				For now, we render snippets and figures as separate tabs per resource.

				Alternatively, we could make the tab a top-level toggle similar to Google,
				or find a way to interleave figures and snippets in a single tab.
				*/}
				<Tabs defaultValue="snippets">
					<TabsList asChild>
						{/* Snippets */}
						<div className="flex w-full justify-between border-neutral-200 border-b pt-1">
							<div className="flex">
								{hasSnippets ? (
									<TabsTrigger
										value="snippets"
										className="flex items-center gap-1.5"
									>
										Snippets{" "}
										<span className="text-neutral-400">
											{result.snippets.length}
										</span>
									</TabsTrigger>
								) : null}
								{hasImages ? (
									<TabsTrigger
										value="figures"
										className="flex items-center gap-1.5"
									>
										Figures{" "}
										<span className="text-neutral-400">
											{result.images.length}
										</span>
									</TabsTrigger>
								) : null}
							</div>
							<div className="flex gap-1">
								<div className="text-neutral-500 text-xs">Sort by:</div>
								<button
									className={cn(
										"flex items-center text-neutral-500 text-xs",
										sortingStrategy === "order" ||
											sortingStrategy === "order-desc"
											? "text-neutral-950"
											: "text-neutral-500",
									)}
									type="button"
									onClick={() => {
										if (sortingStrategy === "order-desc") {
											setSortingStrategy("order");
										} else {
											setSortingStrategy("order-desc");
										}
									}}
								>
									{sortingStrategy === "order" ? (
										<ArrowDown className="h-3 w-3" />
									) : null}
									{sortingStrategy === "order-desc" ? (
										<ArrowUp className="h-3 w-3" />
									) : null}
									Order
								</button>
								<button
									className={cn(
										"flex items-center text-neutral-500 text-xs",
										sortingStrategy === "relevance" ||
											sortingStrategy === "relevance-desc"
											? "text-neutral-950"
											: "text-neutral-500",
									)}
									type="button"
									onClick={() => {
										if (sortingStrategy === "relevance") {
											setSortingStrategy("relevance-desc");
										} else {
											setSortingStrategy("relevance");
										}
									}}
								>
									{sortingStrategy === "relevance" ? (
										<ArrowDown className="h-3 w-3" />
									) : null}
									{sortingStrategy === "relevance-desc" ? (
										<ArrowUp className="h-3 w-3" />
									) : null}
									Relevance
								</button>
							</div>
						</div>
					</TabsList>
					<TabsContent value="snippets">
						<div className="flex flex-col gap-4 py-2">
							{sortedSnippets.map((snippet, index) => {
								return (
									<TextResultItem
										// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
										key={index}
										result={result}
										snippet={snippet}
									/>
								);
							})}
						</div>
					</TabsContent>
					<TabsContent value="figures">
						<div className="flex flex-wrap gap-2">
							{sortedImages.map((imageResult) => {
								return (
									<ImageResultItem
										key={`${imageResult.image.page_index}-${imageResult.image.index_in_page}`}
										imageResult={imageResult}
										result={result}
									/>
								);
							})}
						</div>
					</TabsContent>
				</Tabs>

				{/* <div className="flex justify-end">
						<button
							className="flex items-center gap-1 text-neutral-500 text-xs underline"
							type="button"
							onClick={() => {}}
						>
							<Plus className="h-4 w-4" />
							Find more snippets
						</button>
					</div> */}
			</div>
		</div>
	);
});

const SearchResults = observer(function SearchResults() {
	const containerId = useId();

	const { tabState } = Route.useLoaderData();
	const fullResult = tabState.fullResult;
	const enrichedSearchResult = tabState.enrichedResults;

	let SearchBody = null;
	if (fullResult === null || !fullResult.search.results_loaded_at) {
		SearchBody = (
			<div className="relative flex grow flex-col overflow-y-auto bg-white">
				<div className="flex w-full flex-col space-y-2 p-6">
					<div className="flex space-x-2">
						<Skeleton className="h-8 w-6" />
						<div className="flex min-w-0 grow flex-col space-y-2">
							<Skeleton className="h-3 w-full" />
							<Skeleton className="h-3 w-full max-w-48" />
						</div>
					</div>
					<div className="flex w-full flex-col">
						<div className="flex grow flex-col space-y-2">
							<Skeleton className="h-3 w-full" />
							<Skeleton className="h-3 w-full" />
							<Skeleton className="h-3 w-full" />
						</div>
					</div>
				</div>
			</div>
		);
	} else if (enrichedSearchResult.length === 0) {
		SearchBody = (
			<div className="flex h-full w-full items-center justify-center">
				<h1 className="text-neutral-500">No results found</h1>
			</div>
		);
	} else {
		SearchBody = (
			<>
				{fullResult.search.synthesis !== null && (
					<SearchAnswer synthesis={fullResult.search.synthesis} />
				)}
				<div className="flex flex-col gap-4 p-4">
					<div className="text-neutral-500 text-xs">
						searched {dayjs(fullResult.search.requested_at).fromNow()}
					</div>
					{enrichedSearchResult.map((enrichedResult, index) => {
						return (
							<SearchResult
								// biome-ignore lint/suspicious/noArrayIndexKey: the index represents the rank of the result
								key={index}
								enrichedResult={enrichedResult}
							/>
						);
					})}
				</div>
			</>
		);
	}

	return (
		<div
			// the key forces a rerender and a scroll reset when we switch between grouped and ungrouped results
			// or when the results change as indicated by result_id
			key={"search-results"}
			className="relative flex grow flex-col gap-2 overflow-y-auto bg-white"
			id={containerId}
		>
			{SearchBody}
		</div>
	);
});

export const Route = createFileRoute("/search/result/$search-id")({
	component: SearchResults,
	loader: ({ params, context: { tab } }) => {
		return {
			tabState: new SearchResultsTabState(tab, params["search-id"] as SearchId),
		};
	},
});
