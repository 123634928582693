import { CollaborativeEditor } from "@/components/editor";
import { usePageStore } from "@/contexts/app-context/db-store/db-store-hooks";
import { PageTabState } from "@/contexts/pages/tab-state";
import type { ElectricProvider } from "@/lib/y-electric";
import type { PageId } from "@api/schemas";
import { createFileRoute } from "@tanstack/react-router";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

const PagePage = observer(function PagePage() {
	const { tabState } = Route.useLoaderData();
	const pageStore = usePageStore();
	const page = tabState.page.unwrapOr(null);
	const [title, setTitle] = useState("");
	const [eProvider, setEProvider] = useState<ElectricProvider | null>(null);

	useEffect(() => {
		if (page?.name) {
			setTitle(page.name);
		}
	}, [page?.name]);

	useEffect(() => {
		if (page?.page_id) {
			const provider = pageStore.getProvider(page.page_id);
			setEProvider(provider);
			return () => {
				setEProvider(null);
				pageStore.destroyProvider(page.page_id);
			};
		}
	}, [page?.page_id, pageStore]);

	const titleUpdate = () => {
		if (page?.page_id && title !== page.name) {
			pageStore.updatePage({ pageId: page.page_id, name: title });
		}
	};

	if (page === null) {
		return (
			<div className="flex h-full w-full items-center justify-center">
				<div className="text-center">
					<div className="mb-4 font-bold text-3xl">Loading...</div>
					<div className="text-gray-500">
						Please wait while we load your page
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="flex h-full min-h-0 w-full flex-col px-16">
			{/* Header */}
			<input
				className="mt-10 mb-6 w-full bg-transparent px-4 font-bold text-4xl focus:outline-hidden"
				value={title}
				onChange={(e) => setTitle(e.target.value)}
				onBlur={titleUpdate}
			/>
			{/* Editor */}
			{eProvider && (
				<CollaborativeEditor
					className="min-w-full grow p-4"
					provider={eProvider}
					onEditorReady={(editor) => tabState.setEditor(editor)}
					options={{
						editorProps: {
							attributes: {
								class: "pb-48",
							},
						},
					}}
				/>
			)}
		</div>
	);
});

export const Route = createFileRoute("/pages/$page-id")({
	component: PagePage,
	loader: ({ params, context: { tab } }) => {
		const tabState = new PageTabState(tab, params["page-id"] as PageId);
		return { tabState };
	},
});
