import {
	getRecordLinkColumn,
	getResourceIndexColumn,
	getResourceIndexSelectorColumn,
} from "@/components/table/columns";
import type { PageStore } from "@/contexts/pages/page-store";
import type { TabStore } from "@/contexts/tabs/tabs-context";
import type { PageId, ResourceLink } from "@api/schemas";
import type { ColumnDef } from "@tanstack/react-table";

interface PagesIndexRecord {
	pageId: PageId;
	link: ResourceLink;
	name: string;
	createdAt: string;
	updatedAt: string;
}

export class PagesIndex {
	pageStore: PageStore;
	tabStore: TabStore;

	constructor(pageStore: PageStore, tabStore: TabStore) {
		this.pageStore = pageStore;
		this.tabStore = tabStore;
	}

	get rows(): PagesIndexRecord[] {
		return this.pageStore.map.values().map((page) => ({
			pageId: page.page_id,
			link: this.tabStore.resourceRefToHref({
				resource_id: page.page_id,
				type: "page",
			}),
			name: page.name,
			createdAt: page.created_at,
			updatedAt: page.updated_at,
		}));
	}

	get columns(): ColumnDef<PagesIndexRecord>[] {
		return [
			getResourceIndexSelectorColumn<PagesIndexRecord>(),
			getRecordLinkColumn({
				resetScroll: true,
				didInit: true,
			}),
			getResourceIndexColumn({
				key: "name",
				name: "Name",
				scalarType: "text",
				width: 200,
			}),
			getResourceIndexColumn({
				key: "createdAt",
				name: "Created At",
				scalarType: "timestamptz",
				width: 200,
			}),
			getResourceIndexColumn({
				key: "updatedAt",
				name: "Updated At",
				scalarType: "timestamptz",
				width: 200,
			}),
		];
	}
}
