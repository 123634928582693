import { API_ENDPOINT_HTTP } from "@/config";
import type { AppState } from "@/contexts/app-context/app-context";
import { isFieldPrimary } from "@/contexts/tables/stores/field-store";
import {
	AddFieldAction,
	AddRelationshipAction,
	AddSelectOptionAction,
	DeleteFieldAction,
	DeleteSelectOptionAction,
	MoveFieldAction,
	UpdateDatetimeTimeFormatAction,
	UpdateFieldAction,
	UpdateSelectOptionColorAction,
	UpdateSelectOptionLabelAction,
} from "@/contexts/tables/stores/handlers/fields";
import {
	AddRecordAction,
	DeleteRecordsAction,
	MoveRecordsAction,
	UpdateCellValueAction,
} from "@/contexts/tables/stores/handlers/records";
import {
	CreateComputedTableAction,
	CreateTableAction,
	DeleteTablesAction,
	RenameTableAction,
} from "@/contexts/tables/stores/handlers/tables";
import openapiHashes from "@/generated/openapi-hashes.json";
import { makeAutoObservableAbstract } from "@/lib/make-auto-observable-abstract";
import type { ActionResult } from "@/lib/sync/action-executor";
import { ElectricOptimisticMap } from "@/lib/sync/electric";
import { OptimisticMap } from "@/lib/sync/optimistic-map";
import { RecordSyncedMap } from "@/lib/sync/records";
import { sortByOrder } from "@/lib/utils";
import type {
	CellValue,
	Field,
	FieldId,
	Record,
	RelationshipField,
	ResourceLink,
	ResourceTableResource,
	ResourceType,
	SortField,
	TableId,
	TableResource,
	UserTableResource,
	UserTableResourceQuery,
	ViewField,
} from "@api/schemas";
import { makeAutoObservable } from "mobx";
import { type Result, err, ok } from "neverthrow";

function isUserTable(resource: TableResource): resource is UserTableResource {
	return resource.creator_id !== null;
}

type TablePaginationKey = string & {
	readonly __type: "TableKey";
};

export const createTablePaginationKey = (props: {
	tableId: TableId;
	pageIdx: number;
	pageSize: number;
	sortFields: SortField[];
}): TablePaginationKey => {
	const { tableId, pageIdx, pageSize, sortFields } = props;

	const sortFieldsString = sortFields
		.map((x) => `${x.field_id}:${x.direction}`)
		.join(",");

	return `${tableId}-${pageIdx}-${pageSize}-${sortFieldsString}` as TablePaginationKey;
};

export class TablesStore {
	readonly appState: AppState;
	readonly map: ElectricOptimisticMap<TableResource, ["table_id"], "write_id">;

	readonly tableStates: Map<
		TablePaginationKey,
		UserTableState | ResourceTableState
	> = new Map();

	constructor(appState: AppState) {
		this.appState = appState;
		this.map = new ElectricOptimisticMap({
			shapeUrl: `${API_ENDPOINT_HTTP}/shapes/tables`,
			pKeyFields: ["table_id"],
			writeIdField: "write_id",
			shapeHash: openapiHashes.TableResource,
			getBearerToken: this.appState.getTokenOrThrow,
		});
		makeAutoObservable(this);
	}

	getResourceById(tableId: TableId): Result<TableResource, Error> {
		return this.map.get(tableId);
	}

	get sortedResources(): TableResource[] {
		return this.map.keys
			.map((x) => this.getResourceById(x))
			.filter((x) => x.isOk())
			.map((x) => x.value)
			.filter((x) => x.deleted_at === null);
	}

	createTable = (props: CreateTableAction["args"]) => {
		this.appState.actionQueue.run(new CreateTableAction(props));
	};

	renameTable = (props: RenameTableAction["args"]) => {
		this.appState.actionQueue.run(new RenameTableAction(props));
	};

	deleteTables = (tableIds: Set<TableId>) => {
		this.appState.actionQueue.run(new DeleteTablesAction({ tableIds }));
	};

	createComputedTable = (
		props: CreateComputedTableAction["args"],
	): Promise<ActionResult<
		{
			newTableResource: UserTableResource;
		},
		void
	> | null> => {
		return this.appState.actionQueue.run(new CreateComputedTableAction(props));
	};

	getResourceTableId(
		integrationType: "tables" | "feeds",
		resourceType: ResourceType,
	): TableId {
		const table = this.sortedResources.find(
			(x) =>
				x.integration_type === integrationType &&
				x.resource_type === resourceType,
		);
		if (!table) {
			throw new Error(
				`Table resource for integration type ${integrationType} and resource type ${resourceType} not found`,
			);
		}
		return table.table_id;
	}

	getTableStateById(props: {
		tableId: TableId;
		pageIdx: number;
		pageSize: number;
		sortFields: SortField[];
	}): UserTableState | ResourceTableState {
		const tableKey = createTablePaginationKey(props);

		const tableState = this.tableStates.get(tableKey);

		// If the table state already exists, return it
		if (tableState) {
			return tableState;
		}

		// Otherwise, we need to fetch the table resource and create a new table state
		const tableResource = this.getResourceById(props.tableId);
		if (tableResource.isErr()) {
			throw new Error(
				`Table resource for table with ID ${props.tableId} not found`,
			);
		}

		let newTableState: UserTableState | ResourceTableState;
		if (isUserTable(tableResource.value)) {
			newTableState = new UserTableState({
				tablesStore: this,
				tableResource: tableResource.value,
				pageIdx: props.pageIdx,
				pageSize: props.pageSize,
				sortFields: props.sortFields,
			});
		} else {
			newTableState = new ResourceTableState({
				tablesStore: this,
				tableResource: tableResource.value,
				pageIdx: props.pageIdx,
				pageSize: props.pageSize,
				sortFields: props.sortFields,
			});
		}
		this.tableStates.set(tableKey, newTableState);
		return newTableState;
	}
}

abstract class TableState {
	readonly tablesStore: TablesStore;
	readonly tableResource: TableResource;

	/**
	 * The base synced map for the table, which accepts server-authoritative
	 * updates only.
	 */
	readonly syncedMap: RecordSyncedMap;
	/**
	 * The optimistic map for the table, which reconciles the server-authoritative
	 * map with pending client-side updates.
	 */
	readonly optimisticMap: OptimisticMap<Record, ["link"]>;

	constructor(props: {
		tablesStore: TablesStore;
		tableResource: TableResource;
		pageIdx: number;
		pageSize: number;
		sortFields: SortField[];
	}) {
		this.tablesStore = props.tablesStore;
		this.tableResource = props.tableResource;
		if (!this.tablesStore.appState.userSessionId) {
			throw new Error("User session not initialized");
		}
		// NOTE(John): remember to start/stop the stream
		this.syncedMap = new RecordSyncedMap({
			tableId: this.tableId,
			userSessionId: this.tablesStore.appState.userSessionId,
			getToken: this.tablesStore.appState.getToken,
			pageIdx: props.pageIdx,
			pageSize: props.pageSize,
			sortFields: props.sortFields,
		});
		this.optimisticMap = new OptimisticMap({
			itemMap: this.syncedMap,
			matchWrite: this.syncedMap.matchWrite,
		});
	}

	get type(): "user" | "resource" {
		return isUserTable(this.tableResource) ? "user" : "resource";
	}

	get tableId() {
		return this.tableResource.table_id;
	}

	// Pagination properties of the synced map
	get pageIdx() {
		return this.syncedMap.pageIdx;
	}
	get pageSize() {
		return this.syncedMap.pageSize;
	}
	get sortFields() {
		return this.syncedMap.sortFields;
	}

	get tableKey() {
		return createTablePaginationKey({
			tableId: this.tableId,
			pageIdx: this.pageIdx,
			pageSize: this.pageSize,
			sortFields: this.sortFields,
		});
	}

	get fields(): Map<FieldId, Field> {
		return this.tablesStore.appState.workspace.fields.getFieldsByTableId(
			this.tableId,
		);
	}

	getFieldById(fieldId: FieldId): Result<Field, Error> {
		const field = this.fields.get(fieldId);
		if (!field) {
			return err(new Error(`Field with ID ${fieldId} not found`));
		}
		return ok(field);
	}

	/**
	 * Returns the fields for this table, organized into sections.
	 *
	 * If the table is a computed table, then there are two groups of fields:
	 * the fields of the computed view, which come first and can't be
	 * reordered, and the primitive table's fields.
	 *
	 * If the table is a regular table, then the two groups are the primary
	 * fields, which come first and can be reordered among themselves, and the
	 * regular fields.
	 *
	 * TODO(John): fix the types... this feels weird
	 */
	get sortedFields(): Field[] {
		// Computed table: return view and regular fields
		if (this.tableResource.query !== null) {
			const viewFields: ViewField[] = [];
			const regularFields = [];
			for (const field of this.fields.values()) {
				if (field.type === "view") {
					viewFields.push(field);
				} else {
					regularFields.push(field);
				}
			}
			const sortedViewFields = sortByOrder(viewFields, (field) => field.order);
			const sortedRegularFields = sortByOrder(
				regularFields,
				(field) => field.order,
			);
			return [...sortedViewFields, ...sortedRegularFields];
		}

		const primaryFields = [];
		const regularFields = [];
		for (const field of this.fields.values()) {
			if (isFieldPrimary(field)) {
				primaryFields.push(field);
			} else {
				regularFields.push(field);
			}
		}
		const sortedPrimaryFields = sortByOrder(
			primaryFields,
			(field) => field.order,
		);
		const sortedRegularFields = sortByOrder(
			regularFields,
			(field) => field.order,
		);
		return [...sortedPrimaryFields, ...sortedRegularFields];
	}

	getRecordByLink(recordLink: ResourceLink): Record {
		const record = this.optimisticMap.get(recordLink);
		if (record.isErr()) {
			throw new Error(`Record with link ${recordLink} not found`);
		}
		return record.value;
	}

	getCellValue(recordLink: ResourceLink, field: Field): CellValue {
		return this.getRecordByLink(recordLink).cell_values[field.field_id];
	}

	// TODO: make this update properly with inserts and deletes. This is only
	// based off the insert order in the map right now.
	get sortedRecords(): Record[] {
		return [...this.optimisticMap.values()];
	}

	get recordIndexMap(): Map<ResourceLink, number> {
		return new Map(this.sortedRecords.map((record, i) => [record.link, i]));
	}

	get initState() {
		return this.syncedMap.initState;
	}

	subscribe() {
		this.syncedMap.subscribe();
	}

	unsubscribe() {
		this.syncedMap.unsubscribe();
	}

	refresh() {
		this.syncedMap.refresh();
	}
}

/**
 * The data for a user table.
 */
export class UserTableState extends TableState {
	constructor(props: {
		tablesStore: TablesStore;
		tableResource: UserTableResource;
		pageIdx: number;
		pageSize: number;
		sortFields: SortField[];
	}) {
		super(props);
		makeAutoObservableAbstract(this);
	}

	get isComputedTable() {
		return this.tableResource.query !== null;
	}

	get query(): UserTableResourceQuery {
		return this.tableResource.query;
	}

	getRecordBefore(recordLink: ResourceLink): Record | null {
		const index = this.recordIndexMap.get(recordLink);
		if (index === undefined || index === 0) {
			return null;
		}
		return this.sortedRecords.at(index - 1) ?? null;
	}

	getRecordAfter(recordLink: ResourceLink): Record | null {
		const index = this.recordIndexMap.get(recordLink);
		if (index === undefined || index === this.sortedRecords.length - 1) {
			return null;
		}
		return this.sortedRecords.at(index + 1) ?? null;
	}

	/**
	 * Used for reordering regular fields.
	 *
	 * Returns null if the field is at the beginning.
	 */
	getFieldBefore(fieldId: FieldId): Result<Field | null, Error> {
		const index = this.sortedFields.findIndex(
			(field) => field.field_id === fieldId,
		);
		if (index === -1) {
			return err(new Error(`Field with ID ${fieldId} not found`));
		}
		if (index === 0) {
			return ok(null);
		}
		return ok(this.sortedFields[index - 1]);
	}

	/**
	 * Action bindings from table-handlers
	 */
	addRecord = (props: Omit<AddRecordAction["args"], "tableState">) => {
		this.tablesStore.appState.actionQueue.run(
			new AddRecordAction({
				...props,
				tableState: this,
			}),
		);
	};
	moveRecords = (props: Omit<MoveRecordsAction["args"], "tableState">) => {
		this.tablesStore.appState.actionQueue.run(
			new MoveRecordsAction({
				...props,
				tableState: this,
			}),
		);
	};
	updateCellValue = (
		props: Omit<UpdateCellValueAction["args"], "tableState">,
	) => {
		this.tablesStore.appState.actionQueue.run(
			new UpdateCellValueAction({
				...props,
				tableState: this,
			}),
		);
	};
	deleteRecords = (props: Omit<DeleteRecordsAction["args"], "tableState">) => {
		this.tablesStore.appState.actionQueue.run(
			new DeleteRecordsAction({
				...props,
				tableState: this,
			}),
		);
	};
	addField<T extends Exclude<Field, RelationshipField | ViewField>>(
		props: Omit<AddFieldAction<T>["args"], "tableState">,
	) {
		this.tablesStore.appState.actionQueue.run(
			new AddFieldAction({
				...props,
				tableState: this,
			}),
		);
	}
	addRelationship = (
		props: Omit<AddRelationshipAction["args"], "tableState">,
	) => {
		this.tablesStore.appState.actionQueue.run(
			new AddRelationshipAction({
				...props,
				tableState: this,
			}),
		);
	};
	updateField = (props: Omit<UpdateFieldAction["args"], "tableState">) => {
		this.tablesStore.appState.actionQueue.run(
			new UpdateFieldAction({
				...props,
				tableState: this,
			}),
		);
	};
	moveField = (props: Omit<MoveFieldAction["args"], "tableState">) => {
		this.tablesStore.appState.actionQueue.run(
			new MoveFieldAction({
				...props,
				tableState: this,
			}),
		);
	};
	deleteField = (props: Omit<DeleteFieldAction["args"], "tableState">) => {
		this.tablesStore.appState.actionQueue.run(
			new DeleteFieldAction({
				...props,
				tableState: this,
			}),
		);
	};
	addSelectOption = (
		props: Omit<AddSelectOptionAction["args"], "tableState">,
	) => {
		this.tablesStore.appState.actionQueue.run(
			new AddSelectOptionAction({
				...props,
				tableState: this,
			}),
		);
	};
	deleteSelectOption = (
		props: Omit<DeleteSelectOptionAction["args"], "tableState">,
	) => {
		this.tablesStore.appState.actionQueue.run(
			new DeleteSelectOptionAction({
				...props,
				tableState: this,
			}),
		);
	};
	updateSelectOptionLabel = (
		props: Omit<UpdateSelectOptionLabelAction["args"], "tableState">,
	) => {
		this.tablesStore.appState.actionQueue.run(
			new UpdateSelectOptionLabelAction({
				...props,
				tableState: this,
			}),
		);
	};
	updateSelectOptionColor = (
		props: Omit<UpdateSelectOptionColorAction["args"], "tableState">,
	) => {
		this.tablesStore.appState.actionQueue.run(
			new UpdateSelectOptionColorAction({
				...props,
				tableState: this,
			}),
		);
	};
	updateDatetimeTimeFormat = (
		props: Omit<UpdateDatetimeTimeFormatAction["args"], "tableState">,
	) => {
		this.tablesStore.appState.actionQueue.run(
			new UpdateDatetimeTimeFormatAction({ ...props, tableState: this }),
		);
	};
}

export class ResourceTableState extends TableState {
	constructor(props: {
		tablesStore: TablesStore;
		tableResource: ResourceTableResource;
		pageIdx: number;
		pageSize: number;
		sortFields: SortField[];
	}) {
		super(props);
		makeAutoObservableAbstract(this);
	}
}
