import {
	getRecordLinkColumn,
	getResourceIndexColumn,
	getResourceIndexSelectorColumn,
} from "@/components/table/columns";
import type { TabStore } from "@/contexts/tabs/tabs-context";
import type { UploadsStore } from "@/contexts/uploads/stores/upload-store";
import type { ResourceLink, UploadId } from "@api/schemas";
import type { ColumnDef } from "@tanstack/react-table";

// Keep this in sync with the index view in the server
interface UploadsIndexRecord {
	uploadId: UploadId;
	link: ResourceLink;
	name: string;
	filename: string;
	subtitle: string | null;
	authors: string;
	publisher: string | null;
	url: string | null;
}

/**
 * This represents the table state for the uploads index.
 */
export class UploadsIndex {
	uploadsStore: UploadsStore;
	tabStore: TabStore;

	constructor(uploadsStore: UploadsStore, tabStore: TabStore) {
		this.uploadsStore = uploadsStore;
		this.tabStore = tabStore;
	}

	get rows(): UploadsIndexRecord[] {
		return this.uploadsStore.map.values().map((upload) => ({
			uploadId: upload.upload_id,
			link: this.tabStore.resourceRefToHref({
				resource_id: upload.upload_id,
				type: "upload",
			}),
			name: upload.name,
			filename: upload.name,
			subtitle: upload.subtitle,
			authors: upload.authors.join(", "),
			publisher: upload.publisher,
			url: upload.resolved_url,
		}));
	}

	get columns(): ColumnDef<UploadsIndexRecord>[] {
		return [
			getResourceIndexSelectorColumn<UploadsIndexRecord>(),
			getRecordLinkColumn({
				resetScroll: true,
				didInit: true,
			}),
			getResourceIndexColumn({
				key: "name",
				name: "Name",
				scalarType: "text",
				width: 200,
			}),
			getResourceIndexColumn({
				key: "filename",
				name: "Filename",
				scalarType: "text",
				width: 200,
			}),
			getResourceIndexColumn({
				key: "subtitle",
				name: "Subtitle",
				scalarType: "text",
				width: 200,
			}),
			getResourceIndexColumn({
				key: "authors",
				name: "Authors",
				scalarType: "text",
				width: 200,
			}),
			getResourceIndexColumn({
				key: "publisher",
				name: "Publisher",
				scalarType: "text",
				width: 200,
			}),
			getResourceIndexColumn({
				key: "url",
				name: "URL",
				scalarType: "text",
				width: 200,
			}),
		];
	}
}
