import type { ResourceRef } from "@api/schemas";
import type { LinkProps } from "@tanstack/react-router";

export type LinkableResourceRef = Extract<
	ResourceRef,
	{
		type:
			| "feed-channel"
			| "feed-item"
			| "table"
			| "upload"
			| "search-result"
			| "webpage"
			| "web-search-result"
			| "page"
			| "edgar-document"
			| "edgar-filing"
			| "edgar-entity";
	}
>;
/**
 * Converts a resource ref into a typesafe LinkProps object.
 */
export function resourceRefToLinkProps({
	resource_id,
	type,
}: LinkableResourceRef): LinkProps {
	switch (type) {
		case "feed-item":
			return {
				to: "/feeds/feed-item/$feed-item-id",
				params: {
					"feed-item-id": resource_id,
				},
			};
		case "feed-channel":
			return {
				to: "/feeds/feed-channel/$feed-channel-id",
				params: {
					"feed-channel-id": resource_id,
				},
			};
		case "table":
			return {
				to: "/tables/table/$table-id",
				params: {
					"table-id": resource_id,
				},
			};
		case "upload":
			return {
				to: "/uploads/upload/$upload-id",
				params: {
					"upload-id": resource_id,
				},
			};
		case "search-result":
			return {
				to: "/search/result/$search-id",
				params: {
					"search-id": resource_id,
				},
			};
		case "webpage":
			return {
				to: "/web/webpage/$webpage-id",
				params: {
					"webpage-id": resource_id,
				},
			};
		case "web-search-result":
			return {
				to: "/web-search/search/$web-search-id",
				params: {
					"web-search-id": resource_id,
				},
			};
		case "page":
			return {
				to: "/pages/$page-id",
				params: {
					"page-id": resource_id,
				},
			};
		case "edgar-document":
			return {
				to: "/edgar/edgar-document/$document-id",
				params: {
					"document-id": resource_id,
				},
			};
		case "edgar-entity":
			return {
				to: "/edgar/edgar-entity/$edgar-cik",
				params: {
					"edgar-cik": resource_id,
				},
			};
		case "edgar-filing":
			return {
				to: "/edgar/edgar-filing/$edgar-accession-number",
				params: {
					"edgar-accession-number": resource_id,
				},
			};
		default: {
			const _exhaustiveCheck: never = type;
			throw new Error(`Unknown resource type: ${_exhaustiveCheck}`);
		}
	}
}
