const CROCKFORD_BASE32_CHARS = "0123456789ABCDEFGHJKMNPQRSTVWXYZ";

const TAILWIND_COLORS = {
	blue: "blue",
	green: "green",
	purple: "purple",
	pink: "pink",
	indigo: "indigo",
	red: "red",
	orange: "orange",
	gray: "gray",
	teal: "teal",
	lime: "lime",
	cyan: "cyan",
	emerald: "emerald",
	fuchsia: "fuchsia",
} as const;

type ColorVariant = "bg" | "border" | "text";

/*
	Create a lookup table with every valid combination.
	(Includes shades: 50, 100, 400, and 500)
	
	We need to write the full classnames and not build them with string
	interpolation because Tailwind analyzes the code for class names to know
	which ones to include in the compiled CSS.
*/
const ASSISTANT_COLOR_MAPPING: Record<
	keyof typeof TAILWIND_COLORS | "neutral",
	Record<ColorVariant, Record<number, string>>
> = {
	neutral: {
		bg: {
			50: "bg-neutral-50",
			100: "bg-neutral-100",
			400: "bg-neutral-400",
			500: "bg-neutral-500",
		},
		border: {
			50: "border-neutral-50",
			100: "border-neutral-100",
			400: "border-neutral-400",
			500: "border-neutral-500",
		},
		text: {
			50: "text-neutral-50",
			100: "text-neutral-100",
			400: "text-neutral-400",
			500: "text-neutral-500",
		},
	},
	blue: {
		bg: {
			50: "bg-blue-50",
			100: "bg-blue-100",
			400: "bg-blue-400",
			500: "bg-blue-500",
		},
		border: {
			50: "border-blue-50",
			100: "border-blue-100",
			400: "border-blue-400",
			500: "border-blue-500",
		},
		text: {
			50: "text-blue-50",
			100: "text-blue-100",
			400: "text-blue-400",
			500: "text-blue-500",
		},
	},
	green: {
		bg: {
			50: "bg-green-50",
			100: "bg-green-100",
			400: "bg-green-400",
			500: "bg-green-500",
		},
		border: {
			50: "border-green-50",
			100: "border-green-100",
			400: "border-green-400",
			500: "border-green-500",
		},
		text: {
			50: "text-green-50",
			100: "text-green-100",
			400: "text-green-400",
			500: "text-green-500",
		},
	},
	purple: {
		bg: {
			50: "bg-purple-50",
			100: "bg-purple-100",
			400: "bg-purple-400",
			500: "bg-purple-500",
		},
		border: {
			50: "border-purple-50",
			100: "border-purple-100",
			400: "border-purple-400",
			500: "border-purple-500",
		},
		text: {
			50: "text-purple-50",
			100: "text-purple-100",
			400: "text-purple-400",
			500: "text-purple-500",
		},
	},
	pink: {
		bg: {
			50: "bg-pink-50",
			100: "bg-pink-100",
			400: "bg-pink-400",
			500: "bg-pink-500",
		},
		border: {
			50: "border-pink-50",
			100: "border-pink-100",
			400: "border-pink-400",
			500: "border-pink-500",
		},
		text: {
			50: "text-pink-50",
			100: "text-pink-100",
			400: "text-pink-400",
			500: "text-pink-500",
		},
	},
	indigo: {
		bg: {
			50: "bg-indigo-50",
			100: "bg-indigo-100",
			400: "bg-indigo-400",
			500: "bg-indigo-500",
		},
		border: {
			50: "border-indigo-50",
			100: "border-indigo-100",
			400: "border-indigo-400",
			500: "border-indigo-500",
		},
		text: {
			50: "text-indigo-50",
			100: "text-indigo-100",
			400: "text-indigo-400",
			500: "text-indigo-500",
		},
	},
	red: {
		bg: {
			50: "bg-red-50",
			100: "bg-red-100",
			400: "bg-red-400",
			500: "bg-red-500",
		},
		border: {
			50: "border-red-50",
			100: "border-red-100",
			400: "border-red-400",
			500: "border-red-500",
		},
		text: {
			50: "text-red-50",
			100: "text-red-100",
			400: "text-red-400",
			500: "text-red-500",
		},
	},
	orange: {
		bg: {
			50: "bg-orange-50",
			100: "bg-orange-100",
			400: "bg-orange-400",
			500: "bg-orange-500",
		},
		border: {
			50: "border-orange-50",
			100: "border-orange-100",
			400: "border-orange-400",
			500: "border-orange-500",
		},
		text: {
			50: "text-orange-50",
			100: "text-orange-100",
			400: "text-orange-400",
			500: "text-orange-500",
		},
	},
	gray: {
		bg: {
			50: "bg-gray-50",
			100: "bg-gray-100",
			400: "bg-gray-400",
			500: "bg-gray-500",
		},
		border: {
			50: "border-gray-50",
			100: "border-gray-100",
			400: "border-gray-400",
			500: "border-gray-500",
		},
		text: {
			50: "text-gray-50",
			100: "text-gray-100",
			400: "text-gray-400",
			500: "text-gray-500",
		},
	},
	teal: {
		bg: {
			50: "bg-teal-50",
			100: "bg-teal-100",
			400: "bg-teal-400",
			500: "bg-teal-500",
		},
		border: {
			50: "border-teal-50",
			100: "border-teal-100",
			400: "border-teal-400",
			500: "border-teal-500",
		},
		text: {
			50: "text-teal-50",
			100: "text-teal-100",
			400: "text-teal-400",
			500: "text-teal-500",
		},
	},
	lime: {
		bg: {
			50: "bg-lime-50",
			100: "bg-lime-100",
			400: "bg-lime-400",
			500: "bg-lime-500",
		},
		border: {
			50: "border-lime-50",
			100: "border-lime-100",
			400: "border-lime-400",
			500: "border-lime-500",
		},
		text: {
			50: "text-lime-50",
			100: "text-lime-100",
			400: "text-lime-400",
			500: "text-lime-500",
		},
	},
	cyan: {
		bg: {
			50: "bg-cyan-50",
			100: "bg-cyan-100",
			400: "bg-cyan-400",
			500: "bg-cyan-500",
		},
		border: {
			50: "border-cyan-50",
			100: "border-cyan-100",
			400: "border-cyan-400",
			500: "border-cyan-500",
		},
		text: {
			50: "text-cyan-50",
			100: "text-cyan-100",
			400: "text-cyan-400",
			500: "text-cyan-500",
		},
	},
	emerald: {
		bg: {
			50: "bg-emerald-50",
			100: "bg-emerald-100",
			400: "bg-emerald-400",
			500: "bg-emerald-500",
		},
		border: {
			50: "border-emerald-50",
			100: "border-emerald-100",
			400: "border-emerald-400",
			500: "border-emerald-500",
		},
		text: {
			50: "text-emerald-50",
			100: "text-emerald-100",
			400: "text-emerald-400",
			500: "text-emerald-500",
		},
	},
	fuchsia: {
		bg: {
			50: "bg-fuchsia-50",
			100: "bg-fuchsia-100",
			400: "bg-fuchsia-400",
			500: "bg-fuchsia-500",
		},
		border: {
			50: "border-fuchsia-50",
			100: "border-fuchsia-100",
			400: "border-fuchsia-400",
			500: "border-fuchsia-500",
		},
		text: {
			50: "text-fuchsia-50",
			100: "text-fuchsia-100",
			400: "text-fuchsia-400",
			500: "text-fuchsia-500",
		},
	},
};

/**
 * Deterministically maps a ULID to a Tailwind color class using a static lookup table.
 * By using a precomputed map of all class names and CSS variables,
 * we avoid dynamic string templating which can interfere with Tailwind's class detection.
 *
 * @param ulid The ULID string used to select a color
 * @param variant The type of color: "bg", "border", or "text"
 * @param shade The numeric shade (currently only 400 is supported in the mapping)
 * @returns The color classname
 */
export function getColorForSession(
	ulid: string,
	variant: ColorVariant,
	shade: number,
): string {
	if (!ulid || ulid === "") {
		return ASSISTANT_COLOR_MAPPING.neutral[variant][shade];
	}

	const lastChar = ulid.slice(-1).toUpperCase();
	const value = CROCKFORD_BASE32_CHARS.indexOf(lastChar);

	if (value === -1) {
		console.warn(`invalid crockford base32 char found in ulid: ${lastChar}`);
		return ASSISTANT_COLOR_MAPPING.neutral[variant][shade];
	}

	const colors = Object.values(TAILWIND_COLORS);
	const colorKey = colors[
		value % colors.length
	] as keyof typeof ASSISTANT_COLOR_MAPPING;

	return ASSISTANT_COLOR_MAPPING[colorKey][variant][shade];
}
