import { TextEditor } from "@/components/editor";
import { getColorForSession } from "@/components/layout/right-sidebar/assistant-colors";
import {
	HoverCard,
	HoverCardContent,
	HoverCardTrigger,
} from "@/components/ui/hover-card";
import { useAssistantSessionStore } from "@/contexts/app-context/db-store/db-store-hooks";
import { useTabStore } from "@/contexts/tabs/use-tab-store";
import { cn } from "@/lib/utils";
import type { AssistantSessionId } from "@api/schemas";
import { Desktop } from "@phosphor-icons/react";
import { observer } from "mobx-react-lite";

export const StatusDot = ({
	active,
}: {
	active: boolean;
}) => (
	<div
		className={cn(
			"h-2 w-2 shrink-0 rounded-full",
			active ? "bg-green-500" : "border border-neutral-300 bg-white",
		)}
	/>
);

export const AssistantPresenceIcon = observer(function AssistantPresenceIcon({
	assistantSessionId,
}: { assistantSessionId: AssistantSessionId }) {
	const assistantSessionStore = useAssistantSessionStore();
	const tabStore = useTabStore();
	const assistantSession = assistantSessionStore.getById(assistantSessionId);

	if (assistantSession.isErr()) {
		throw new Error("Assistant session not found");
	}

	const isActive = assistantSession.value.end_status === null;
	const bgColor = getColorForSession(assistantSessionId, "bg", 50);
	const textColor = getColorForSession(assistantSessionId, "text", 500);
	const isTracked = tabStore.trackedAssistantSessions.has(assistantSessionId);
	const trackedBorderColor = getColorForSession(
		assistantSessionId,
		"border",
		500,
	);

	return (
		<HoverCard openDelay={0}>
			<HoverCardTrigger asChild>
				<button
					className={cn(
						"relative flex h-6 w-6 shrink-0 cursor-pointer items-center justify-center rounded-full border",
						bgColor,
						isTracked ? trackedBorderColor : "border-neutral-200",
						!isActive && !isTracked && "saturate-10",
					)}
					onClick={(e) => {
						e.stopPropagation();
						if (isTracked) {
							tabStore.stopTrackingSession(assistantSessionId);
						} else {
							tabStore.startTrackingSession(assistantSessionId);
						}
					}}
					type="button"
				>
					<Desktop size={16} className={cn(textColor)} />
				</button>
			</HoverCardTrigger>
			<HoverCardContent className="flex h-fit w-fit flex-col gap-1 px-2 pt-1 pb-2">
				<div className="prose prose-sm max-h-[200px] overflow-y-auto">
					<TextEditor
						className="font-medium text-xs"
						options={{
							content: assistantSession.value.goal,
							editable: false,
						}}
					/>
				</div>
				<p className="text-center text-neutral-700 text-xs">
					{isTracked ? "Stop following" : "Click to follow"}
				</p>
			</HoverCardContent>
		</HoverCard>
	);
});

export const GenericAssistantPresenceIcon = observer(() => {
	return (
		<div className="relative flex h-6 w-6 shrink-0 items-center justify-center rounded-full border border-white bg-neutral-50">
			<Desktop size={16} weight="bold" className="text-neutral-500" />
		</div>
	);
});
