import { StepComponent } from "@/components/layout/right-sidebar/step-component";
import { useAssistantSessionStore } from "@/contexts/app-context/db-store/db-store-hooks";
import { cn } from "@/lib/utils";
import type { AssistantSessionId } from "@api/schemas";
import { Pulse } from "@phosphor-icons/react";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";

interface AssistantStepPopoverProps {
	assistantSessionId: AssistantSessionId;
}

export const AssistantStepPopover = observer(function AssistantStepPopover({
	assistantSessionId,
}: AssistantStepPopoverProps) {
	const [isOpen, setIsOpen] = useState(true);
	const assistantSessionStore = useAssistantSessionStore();
	const sessionStepsResult = assistantSessionStore.getSteps(assistantSessionId);
	const containerRef = useRef<HTMLDivElement>(null);

	const { latestStep, stepIndex } =
		sessionStepsResult.length > 0
			? {
					latestStep: sessionStepsResult.at(-1),
					stepIndex: sessionStepsResult.length - 1,
				}
			: {
					latestStep: null,
					stepIndex: 0,
				};

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (containerRef.current && isOpen) {
			containerRef.current.scrollTop = containerRef.current.scrollHeight;
		}
	}, [latestStep, isOpen]);

	if (!latestStep) {
		return null;
	}

	return (
		<>
			<button
				type="button"
				onClick={() => setIsOpen(!isOpen)}
				className={cn(
					"flex items-center p-1 text-neutral-700 hover:bg-black/5",
					isOpen && "bg-black/5",
				)}
			>
				<Pulse
					size={12}
					className={isOpen ? "text-neutral-950" : "text-neutral-500"}
				/>
			</button>
			{isOpen && (
				<div className="absolute top-20.5 right-4 bottom-4 z-50 w-80">
					<div
						ref={containerRef}
						className="max-h-full w-full overflow-y-auto border border-neutral-200 bg-white p-2 shadow-md"
					>
						<StepComponent step={latestStep} stepIndex={stepIndex} />
					</div>
				</div>
			)}
		</>
	);
});
