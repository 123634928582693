import { PageTreeContextMenuWrapper } from "@/components/tree/context-menu";
import { DragOverlayComponent } from "@/components/tree/drag-overlay";
import type {
	AnyNode,
	Node,
	PageTreeState,
} from "@/components/tree/tree-state";
import { useTabStore } from "@/contexts/tabs/use-tab-store";
import { resourceRefToLinkProps } from "@/lib/paths";
import { cn } from "@/lib/utils";
import {
	DndContext,
	MouseSensor,
	TouchSensor,
	pointerWithin,
	useDraggable,
	useDroppable,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import { CaretRight } from "@phosphor-icons/react";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { createPortal } from "react-dom";

/**
 * Indentation for each depth level
 */
const DepthIndent = (props: { depth: number }) => {
	const { depth } = props;
	return (
		<>
			{(new Array(depth).fill(0) as number[]).map((_, i) => (
				// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
				<div key={i} className="h-8 w-2 shrink-0" />
			))}
		</>
	);
};

/**
 * Computes the background color for a node, factoring in selection state and hover/droppable states
 */
function getNodeBgColor(
	isSelected: boolean,
	isMultiSelected: boolean,
	isHover: boolean,
	isOver?: boolean,
): string {
	// Priority: droppable "isOver" uses a different color
	if (isOver) {
		if (isSelected) {
			return "bg-blue-200";
		}
		return "bg-neutral-200";
	}

	if (isMultiSelected) {
		return isHover ? "bg-blue-100" : "bg-blue-100";
	}

	if (isSelected) {
		return "bg-neutral-200";
	}

	return isHover ? "bg-neutral-100" : "bg-white";
}

/**
 * Returns the selection status of a node.
 */
function getNodeSelectionStatus(node: AnyNode, treeState: PageTreeState) {
	const isSelected = treeState.selectedNodes.has(node.id);
	const isMultiSelected = isSelected && treeState.selectedNodes.size > 1;
	const isRightClicked = treeState.rightClickedNode?.id === node.id;
	return { isSelected, isMultiSelected, isRightClicked };
}

/**
 * Node for resources.
 */
const ResourceNode = observer(
	(props: {
		node: Exclude<AnyNode, Node<"page">>;
		treeState: PageTreeState;
		depth: number;
	}) => {
		const { isSelected, isMultiSelected, isRightClicked } =
			getNodeSelectionStatus(props.node, props.treeState);
		const [isHover, setIsHover] = useState(false);

		const {
			setNodeRef: setDraggableNodeRef,
			listeners,
			attributes,
		} = useDraggable({
			id: props.node.href,
			data: {
				node: props.node,
			},
			disabled: props.treeState.dndEnabled,
		});

		const bgColor = getNodeBgColor(isSelected, isMultiSelected, isHover);
		const tabStore = useTabStore();
		const head = tabStore.getTabHead(resourceRefToLinkProps(props.node.ref));

		return (
			<div
				className={cn(
					"flex h-8 items-center px-2",
					bgColor,
					props.treeState.draggingNodes.size > 0 && "cursor-default",
					isRightClicked && "-outline-offset-1 outline outline-blue-300",
				)}
				onClick={(e) => props.treeState.handleNodeClick(e, props.node)}
				onContextMenu={() => {
					props.treeState.setRightClickedNode(props.node);
				}}
				ref={setDraggableNodeRef}
				{...listeners}
				{...attributes}
				onMouseEnter={() => setIsHover(true)}
				onMouseLeave={() => setIsHover(false)}
			>
				<DepthIndent depth={props.depth} />
				<head.icon className="h-4 w-4 shrink-0 text-neutral-700" />
				<h2
					className={cn(
						"ml-2 w-full min-w-0 select-none truncate text-neutral-700 text-sm",
					)}
				>
					{head.label}
				</h2>
			</div>
		);
	},
);

/**
 * Node for pages.
 *
 * Pages are expandable and have children, so we handle them separately.
 */
const PageNode = observer(
	(props: {
		node: Node<"page">;
		treeState: PageTreeState;
		depth: number;
	}) => {
		const { isSelected, isMultiSelected, isRightClicked } =
			getNodeSelectionStatus(props.node, props.treeState);
		const tabStore = useTabStore();
		const head = tabStore.getTabHead(resourceRefToLinkProps(props.node.ref));

		const isExpanded = props.treeState.expandedNodes.has(props.node.id);

		// Only compute children if the node is expanded to prevent unnecessary
		// computation and avoid infinite recursion in cyclic trees
		const children = isExpanded ? props.treeState.getChildren(props.node) : [];

		// TODO(John): for some reason, hovering is flickering whenever it's a drop target
		// We'll get around this by combining it with isOver.
		const [isHover, setIsHover] = useState(false);

		const { setNodeRef: setDroppableNodeRef, isOver } = useDroppable({
			id: props.node.id,
			disabled: props.treeState.dndEnabled,
			data: {
				node: props.node,
			},
		});

		const {
			setNodeRef: setDraggableNodeRef,
			listeners,
			attributes,
		} = useDraggable({
			id: props.node.id,
			data: {
				node: props.node,
			},
			disabled: props.treeState.dndEnabled,
		});

		const bgColor = getNodeBgColor(
			isSelected,
			isMultiSelected,
			isHover,
			isOver,
		);

		return (
			<>
				<div
					className={cn(
						"flex h-8 items-center px-2",
						bgColor,
						props.treeState.draggingNodes.size > 0 && "cursor-default",
						isRightClicked && "-outline-offset-1 outline outline-blue-300",
					)}
					onClick={(e) => props.treeState.handleNodeClick(e, props.node)}
					onContextMenu={() => {
						props.treeState.setRightClickedNode(props.node);
					}}
					ref={(el) => {
						setDraggableNodeRef(el);
						setDroppableNodeRef(el);
					}}
					{...listeners}
					{...attributes}
					onMouseEnter={() => setIsHover(true)}
					onMouseLeave={() => setIsHover(false)}
				>
					<DepthIndent depth={props.depth} />
					{isHover || isOver ? (
						<CaretRight
							weight="regular"
							className={cn(
								"h-4 w-4 shrink-0 bg-white text-neutral-500 hover:bg-neutral-200 hover:text-neutral-950",
								isExpanded && "rotate-90",
							)}
							onClick={(e) => {
								props.treeState.toggleExpandedNode(props.node);
								e.stopPropagation();
							}}
						/>
					) : (
						<head.icon className="h-4 w-4 shrink-0 text-neutral-500" />
					)}
					<h2
						className={cn(
							"ml-2 w-full min-w-0 select-none truncate text-neutral-800 text-sm",
						)}
					>
						{head.label}
					</h2>
				</div>
				{isExpanded &&
					(children.length > 0 ? (
						children.map((child) => {
							return (
								<NodeComponent
									key={child.id}
									node={child}
									treeState={props.treeState}
									depth={props.depth + 1}
								/>
							);
						})
					) : (
						<div className="flex h-8 items-center px-2">
							<DepthIndent depth={props.depth + 1} />
							<span className="text-neutral-500 text-sm">No links</span>
						</div>
					))}
			</>
		);
	},
);

const NodeComponent = (props: {
	node: AnyNode;
	treeState: PageTreeState;
	depth: number;
}) => {
	if (props.node.ref?.type === "page") {
		return (
			<PageNode
				node={props.node as Node<"page">}
				treeState={props.treeState}
				depth={props.depth}
			/>
		);
	}
	return (
		<ResourceNode
			node={props.node as Exclude<AnyNode, Node<"page">>}
			treeState={props.treeState}
			depth={props.depth}
		/>
	);
};

export const PageTree = observer(
	(props: {
		treeState: PageTreeState;
	}) => {
		const mouseSensor = useSensor(MouseSensor, {
			// Require the mouse to move by 10 pixels before activating
			activationConstraint: {
				distance: 10,
			},
		});
		const touchSensor = useSensor(TouchSensor, {
			// Press delay of 250ms, with tolerance of 5px of movement
			activationConstraint: {
				delay: 250,
				tolerance: 5,
			},
		});
		const sensors = useSensors(mouseSensor, touchSensor);
		return (
			<DndContext
				collisionDetection={pointerWithin}
				sensors={sensors}
				onDragStart={props.treeState.handleDragStart}
				onDragOver={props.treeState.handleDragOver}
				onDragEnd={props.treeState.handleDragEnd}
			>
				<PageTreeContextMenuWrapper treeState={props.treeState}>
					<NodeComponent
						node={props.treeState.rootNode}
						treeState={props.treeState}
						depth={0}
					/>
					{createPortal(
						<DragOverlayComponent treeState={props.treeState} />,
						document.body,
					)}
				</PageTreeContextMenuWrapper>
			</DndContext>
		);
	},
);
