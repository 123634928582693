import {
	getRecordLinkColumn,
	getResourceIndexColumn,
} from "@/components/table/columns";
import { getResourceIndexSelectorColumn } from "@/components/table/columns";
import { TableComponent } from "@/components/table/table-component";
import { EdgarEntityTabState } from "@/contexts/edgar/tab-state";
import type { TabStore } from "@/contexts/tabs/tabs-context";
import { useTabStore } from "@/contexts/tabs/use-tab-store";
import { useGetEdgarEntityRoute } from "@api/fastAPI";
import type { EdgarEntityWithFilings, ResourceLink } from "@api/schemas";
import { createFileRoute } from "@tanstack/react-router";
import type { ColumnDef } from "@tanstack/react-table";
import { observer } from "mobx-react-lite";

interface FilingsIndexRecord {
	link: ResourceLink;
	accession_number: string;
	form: string;
	filing_date: string;
}

class FilingsIndex {
	edgarEntity: EdgarEntityWithFilings;
	tabStore: TabStore;

	constructor(edgarEntity: EdgarEntityWithFilings, tabStore: TabStore) {
		this.edgarEntity = edgarEntity;
		this.tabStore = tabStore;
	}

	get rows(): FilingsIndexRecord[] {
		return this.edgarEntity.filings.map((filing) => ({
			link: this.tabStore.resourceRefToHref({
				resource_id: filing.accession_number,
				type: "edgar-filing" as const,
			}),
			accession_number: filing.accession_number,
			form: filing.form,
			filing_date: filing.filing_date,
		}));
	}

	get columns(): ColumnDef<FilingsIndexRecord>[] {
		return [
			getResourceIndexSelectorColumn<FilingsIndexRecord>(),
			getRecordLinkColumn({
				resetScroll: true,
				didInit: true,
			}),
			getResourceIndexColumn({
				key: "accession_number",
				name: "Accession Number",
				scalarType: "text",
				width: 200,
			}),
			getResourceIndexColumn({
				key: "form",
				name: "Form",
				scalarType: "text",
				width: 200,
			}),
			getResourceIndexColumn({
				key: "filing_date",
				name: "Filing Date",
				scalarType: "timestamptz",
				width: 200,
			}),
		];
	}
}

const EdgarEntityPage = observer(function EdgarEntityPage() {
	const { tabState } = Route.useLoaderData();
	const tabStore = useTabStore();
	const {
		data: edgarEntityResponse,
		isLoading,
		isError,
	} = useGetEdgarEntityRoute(tabState.edgarEntityCik);

	if (isError) {
		return <div>Error loading entity</div>;
	}

	if (isLoading || !edgarEntityResponse) {
		return <div>Loading...</div>;
	}

	const edgarEntity = edgarEntityResponse.data;

	const edgarEntityLink = `https://www.sec.gov/edgar/browse/?CIK=${edgarEntity.cik}&owner=exclude`;

	const filingsIndex = new FilingsIndex(edgarEntity, tabStore);

	return (
		<div className="flex h-full max-h-full min-h-0 w-full flex-col px-16 pt-4">
			<h1 className="px-2 font-bold text-2xl">{edgarEntity.name}</h1>
			<h2 className="px-2 text-neutral-500 text-sm">NASDAQ: DNE</h2>

			<div className="my-2 flex flex-col gap-1 bg-neutral-100 p-3 text-neutral-700 text-sm">
				<div className="flex flex-col items-start">
					<span className="font-semibold">CIK</span>
					<span>{edgarEntity.cik}</span>
				</div>
				<div className="flex flex-col items-start">
					<span className="font-semibold">State of Incorporation</span>
					<span>{edgarEntity.state_of_incorporation}</span>
				</div>
				<div className="flex flex-col items-start">
					<span className="font-semibold">Business Address</span>
					{/** TODO(Tae): Format this */}
					<span>{edgarEntity.business_address?.city}</span>
				</div>
				<div className="flex flex-col items-start">
					<span className="font-semibold">Phone Number</span>
					<span>{edgarEntity.phone_number}</span>
				</div>
				<div className="flex flex-col items-start">
					<span className="font-semibold">Link to EDGAR</span>
					<span>
						<a className="text-blue-500" href={edgarEntityLink}>
							{edgarEntityLink}
						</a>
					</span>
				</div>
			</div>
			<div className="flex w-full border-b px-4 pt-4 pb-4">
				<h2 className="font-semibold">Filings</h2>
			</div>
			<div className="relative flex grow flex-col">
				<TableComponent
					getRowId={(row) => row.accession_number}
					getRowLink={(row) => row.link}
					data={filingsIndex.rows}
					columns={filingsIndex.columns}
					maxHeight={500}
					rowSelectionPopoverTop={0}
				/>
			</div>
		</div>
	);
});

type EdgarCik = string;

export const Route = createFileRoute("/edgar/edgar-entity/$edgar-cik")({
	component: EdgarEntityPage,
	loader: ({ params, context: { tab } }) => {
		const tabState = new EdgarEntityTabState(
			tab,
			params["edgar-cik"] as EdgarCik,
		);
		return { tabState };
	},
});
