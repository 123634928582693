import { StreamingEditor } from "@/components/editor";
import { Skeleton } from "@/components/ui/skeleton";
import {
	RESULTS_PER_PAGE,
	WebSearchResultsTabState,
	webSearchSearchSchema,
} from "@/contexts/web/tab-state";
import { cn } from "@/lib/utils";
import { zodValidator } from "@/lib/zod-validator";
import type { WebSearchId, WebsiteResult } from "@api/schemas";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import dayjs from "dayjs";
import DOMPurify from "dompurify";
import { observer } from "mobx-react-lite";
import { useId, useMemo, useState } from "react";

const SafeHtmlContent = ({ htmlString }: { htmlString: string }) => {
	const sanitizedHtml = DOMPurify.sanitize(htmlString);
	// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
	return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
};

const WebsiteResultComponent = observer(
	({ result }: { result: WebsiteResult }) => {
		return (
			<div className="flex w-full gap-4">
				<div className="flex min-w-0 grow flex-col gap-1">
					<div className="flex w-full items-center gap-2">
						<div className="shrink-0 overflow-clip rounded-full border border-neutral-200 bg-neutral-100 p-1">
							<img
								className="text-neutral-500 text-xs"
								src={`https://www.google.com/s2/favicons?domain=${result.url}`}
								alt="favicon"
								width={12}
								height={12}
							/>
						</div>
						<div className="flex min-w-0 grow flex-col">
							<a
								className="text-neutral-500 text-sm"
								href={result.url}
								target="_blank"
								rel="noreferrer"
							>
								{result.site_name}
							</a>
							<a
								className="truncate text-wrap break-words text-neutral-500 text-xs"
								href={result.url}
								target="_blank"
								rel="noreferrer"
							>
								<SafeHtmlContent htmlString={result.display_url} />
							</a>
						</div>
					</div>

					<a
						className="text-base text-blue-600 visited:text-purple-600"
						href={result.url}
						target="_blank"
						rel="noreferrer"
					>
						<SafeHtmlContent htmlString={result.title} />
					</a>
					<div className="text-neutral-500 text-sm">
						{/* {result.snippet} */}
						<SafeHtmlContent htmlString={result.snippet} />
					</div>
				</div>
				{result.primary_image_url && (
					<div
						className="shrink-0 overflow-clip rounded-md"
						style={{
							width: 80,
							height: 80,
						}}
					>
						<img
							src={result.primary_image_url}
							alt="Bing thumbnail"
							width={80}
							height={80}
						/>
					</div>
				)}
			</div>
		);
	},
);

interface WebSearchAnswerProps {
	synthesis: string;
}

const WebSearchAnswer = observer(({ synthesis }: WebSearchAnswerProps) => {
	const [showMore, setShowMore] = useState(false);

	const editorOptions = useMemo(() => {
		return {
			content: synthesis,
			editable: false,
		};
	}, [synthesis]);

	return (
		<div className="relative flex flex-col">
			<div
				className={cn(
					"flex min-h-48 w-full shrink-0 flex-col gap-3 overflow-y-hidden bg-neutral-50 p-4",
					showMore ? "h-auto" : "h-48",
				)}
			>
				<StreamingEditor
					className="w-full max-w-full"
					options={editorOptions}
				/>
			</div>
			<button
				className="h-5 w-full border-neutral-100 border-t bg-neutral-50 text-center text-neutral-500 text-xs"
				type="button"
				onClick={() => setShowMore(!showMore)}
			>
				{showMore ? "Show less" : "Show more"}
			</button>
		</div>
	);
});

interface PageNavigatorProps {
	currentPage: number;
	totalPages: number;
	onPageChange: (page: number) => void;
}

const PageNavigator = ({
	currentPage,
	totalPages,
	onPageChange,
}: PageNavigatorProps) => {
	const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

	return (
		<div className="flex items-center gap-1">
			{pageNumbers.map((pageNum) => (
				<button
					key={pageNum}
					onClick={() => onPageChange(pageNum)}
					className={`flex h-6 w-6 items-center justify-center rounded-full text-sm ${
						pageNum === currentPage
							? "bg-blue-500 text-white"
							: "text-blue-500 hover:bg-gray-100"
					}`}
					aria-current={pageNum === currentPage ? "page" : undefined}
					type="button"
					disabled={pageNum === currentPage}
				>
					{pageNum}
				</button>
			))}
		</div>
	);
};

const WebSearchResults = observer(function WebSearchResults() {
	const containerId = useId();
	const { tabState } = Route.useLoaderData();

	const webSearchResult = tabState.fullResult;
	const navigate = useNavigate();

	let WebSearchBody = null;
	if (!webSearchResult || !webSearchResult.results_loaded_at) {
		WebSearchBody = (
			<div className="relative flex grow flex-col overflow-y-auto bg-white">
				{[...Array(1)].map((_, i) => (
					<div
						// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
						key={i}
						className="flex w-full flex-col space-y-2 p-6"
					>
						<div className="flex space-x-2">
							<Skeleton className="h-8 w-6" />
							<div className="flex min-w-0 grow flex-col space-y-2">
								<Skeleton className="h-3 w-full" />
								<Skeleton className="h-3 w-full max-w-48" />
							</div>
						</div>
						<div className="flex w-full flex-col">
							<div className="flex grow flex-col space-y-2">
								<Skeleton className="h-3 w-full" />
								<Skeleton className="h-3 w-full" />
								<Skeleton className="h-3 w-full" />
							</div>
						</div>
					</div>
				))}
			</div>
		);
	} else if (webSearchResult.search_results.length === 0) {
		WebSearchBody = (
			<div className="flex h-full w-full items-center justify-center">
				<h1 className="text-neutral-500">No results found</h1>
			</div>
		);
	} else {
		const currentPage = tabState.urlParams.pageNum;
		const startIndex = (currentPage - 1) * RESULTS_PER_PAGE;
		const endIndex = startIndex + RESULTS_PER_PAGE;
		const totalPages = Math.ceil(
			webSearchResult.search_results.length / RESULTS_PER_PAGE,
		);
		WebSearchBody = (
			<>
				{webSearchResult.synthesis !== null && (
					<WebSearchAnswer synthesis={webSearchResult.synthesis} />
				)}
				<div className="flex grow flex-col gap-4 p-4">
					<div className=" text-neutral-500 text-xs">
						searched {dayjs(webSearchResult.requested_at).fromNow()}
					</div>
					{webSearchResult.search_results
						.slice(startIndex, endIndex)
						.map((result) => {
							return (
								<WebsiteResultComponent key={result.url} result={result} />
							);
						})}
				</div>
				{totalPages > 1 && (
					<PageNavigator
						currentPage={currentPage}
						totalPages={totalPages}
						onPageChange={(page) => {
							navigate({
								to: "/web-search/search/$web-search-id",
								params: {
									"web-search-id": webSearchResult.web_search_id,
								},
								search: {
									page,
								},
							});
						}}
					/>
				)}
			</>
		);
	}
	return (
		<div
			// the key forces a rerender and a scroll reset when we switch between grouped and ungrouped results
			// or when the results change as indicated by result_id
			key={"web-search-results"}
			className="relative flex grow flex-col items-center overflow-y-auto bg-white pb-4"
			id={containerId}
		>
			{WebSearchBody}
		</div>
	);
});

export const Route = createFileRoute("/web-search/search/$web-search-id")({
	component: WebSearchResults,
	validateSearch: zodValidator(webSearchSearchSchema),
	loaderDeps: ({ search }) => ({ search }),
	loader: ({ params, context: { tab }, deps }) => {
		return {
			tabState: new WebSearchResultsTabState(tab, {
				webSearchId: params["web-search-id"] as WebSearchId,
				pageNum: deps.search.page,
			}),
		};
	},
});
