import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import * as React from "react";

import { cn } from "@/lib/utils";
import { Check, Minus } from "@phosphor-icons/react";

const Checkbox = React.forwardRef<
	React.ElementRef<typeof CheckboxPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
	<CheckboxPrimitive.Root
		ref={ref}
		className={cn(
			"peer group h-4 w-4 shrink-0 rounded-sm border border-neutral-300 hover:border-neutral-400 hover:bg-neutral-100 focus-visible:outline-hidden focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:border-blue-500 data-[state=indeterminate]:border-blue-500 data-[state=checked]:bg-blue-500 data-[state=indeterminate]:bg-blue-500 data-[state=checked]:text-neutral-50 data-[state=indeterminate]:text-neutral-50",
			className,
		)}
		{...props}
	>
		<CheckboxPrimitive.Indicator
			className={cn("flex items-center justify-center text-current text-sm")}
		>
			<Check
				weight="bold"
				className="hidden group-data-[state=checked]:block"
			/>
			<Minus
				weight="bold"
				className="hidden group-data-[state=indeterminate]:block"
			/>
		</CheckboxPrimitive.Indicator>
	</CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
