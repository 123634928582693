import { AssistantPresenceIcon } from "@/components/layout/right-sidebar/assistant-presence-icon";
import { useAssistantSessionStore } from "@/contexts/app-context/db-store/db-store-hooks";
import { LinkTarget } from "@/contexts/tabs/router-types";
import { useTabStore } from "@/contexts/tabs/use-tab-store";
import { cleanXmlText } from "@/lib/formatting";
import {
	ResourceLinkComponent,
	ResourceLinkContent,
} from "@/plugins/resource-link";
import type { AssistantSession } from "@api/schemas";
import { Chat, CheckSquare, Link, Play } from "@phosphor-icons/react";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";

export const SessionPreview = observer(function SessionPreview({
	assistantSession,
}: { assistantSession: AssistantSession }) {
	const tabStore = useTabStore();
	const assistantSessionStore = useAssistantSessionStore();
	const goalText = useMemo(() => {
		return cleanXmlText(assistantSession.goal);
	}, [assistantSession.goal]);

	const sessionSummary = assistantSessionStore.getSessionSummary(
		assistantSession.assistant_session_id,
	);
	return (
		// biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
		<div
			className="flex w-full cursor-pointer gap-2 px-2 py-4 hover:bg-neutral-50"
			onClick={() => {
				tabStore.openLink(
					{
						to: "/assistant-session/$session-id",
						params: {
							"session-id": assistantSession.assistant_session_id,
						},
					},
					LinkTarget.Self,
				);
			}}
		>
			<div className="mt-0.5">
				<AssistantPresenceIcon
					assistantSessionId={assistantSession.assistant_session_id}
				/>
			</div>
			<div className="flex min-w-0 grow flex-col gap-1">
				<p className="font-medium">{goalText}</p>
				{/* Summary statistics */}
				<div className="mt-1 flex w-full flex-col gap-1 text-neutral-800 text-xs">
					<span className="inline-flex items-center gap-2">
						<Play weight="bold" />
						<span>
							{new Date(assistantSession.start_time).toLocaleString("en-US", {
								month: "numeric",
								day: "numeric",
								hour: "numeric",
								minute: "numeric",
							})}
						</span>
					</span>
					<span className="inline-flex items-center gap-2">
						<CheckSquare weight="bold" />
						<span>
							{sessionSummary.numSteps} step
							{sessionSummary.numSteps === 1 ? "" : "s"}
						</span>
					</span>
					<div className="flex w-full flex-col gap-1">
						<span className="inline-flex items-center gap-2">
							<Link weight="bold" className="flex-none" />
							<span>
								{sessionSummary.linksVisited.size} link
								{sessionSummary.linksVisited.size === 1 ? "" : "s"} visited
							</span>
						</span>
						{sessionSummary.linksVisited.size > 0 && (
							<div className="flex w-full min-w-0 flex-col gap-1 pl-4">
								{Array.from(sessionSummary.linksVisited).map((href) => (
									<ResourceLinkComponent
										key={href}
										linkProps={{ href }}
										className="truncate"
									>
										<ResourceLinkContent
											linkProps={{ href }}
											className="text-neutral-500 hover:text-neutral-950"
										/>
									</ResourceLinkComponent>
								))}
							</div>
						)}
					</div>
					<span className="inline-flex items-center gap-2">
						<Chat weight="bold" />
						<span>
							{sessionSummary.messagesSent} message
							{sessionSummary.messagesSent === 1 ? "" : "s"} sent
						</span>
					</span>
				</div>
			</div>
		</div>
	);
});
