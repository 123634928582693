import { getEditorExtensions } from "@/components/editor";
import { BaseTabState } from "@/contexts/tabs/base-tab-state";
import type { Tab } from "@/contexts/tabs/tabs-context";
import { makeAutoObservableAbstract } from "@/lib/make-auto-observable-abstract";
import type { Message, MessageId } from "@api/schemas";
import { Chat } from "@phosphor-icons/react";
import { Editor } from "@tiptap/core";
import { runInAction } from "mobx";
import type { Result } from "neverthrow";
import { ok } from "neverthrow";

export class MessageTabState extends BaseTabState {
	// Instead of eagerly loading the message into a property,
	// store the message id and load via a getter.
	messageId: MessageId | null = null;
	editor: Editor;
	attachments: string[] = [];
	editorContent: { text: string; html: string } = { text: "", html: "" };

	attachmentsQuery = "";
	showAttachmentsMenu = false;

	constructor(tab: Tab, messageId: MessageId | null) {
		super(tab);
		makeAutoObservableAbstract(this);
		this.messageId = messageId;
		this.editor = new Editor({
			extensions: getEditorExtensions(tab.tabStore.appState),
			content: "",
			editorProps: {
				attributes: {
					class:
						"text-sm min-h-32 max-h-96 w-full p-2 overflow-y-auto outline-hidden bg-white",
				},
			},
			onUpdate: ({ editor }) => {
				runInAction(() => {
					this.editorContent = {
						text: editor.getText(),
						html: editor.getHTML(),
					};
				});
			},
		});
	}

	// TODO(John): fix how this works
	get parentMessage(): Result<Message | null, Error> {
		if (!this.messageId) {
			return ok(null);
		}
		return this.tab.tabStore.appState.messageStore.messages.get(this.messageId);
	}

	/**
	 * The header is based on the current state of the message. If the message
	 * loads successfully, we use its subject (or fallback to "Message"). If there's
	 * an error, we fallback to "Unknown Message".
	 */
	get head() {
		return this.parentMessage.match(
			() => ({
				icon: Chat,
				label: "Message",
			}),
			() => ({
				icon: Chat,
				label: "Unknown Message",
			}),
		);
	}

	get attachmentResults() {
		return this.tab.tabStore.appState.localSearchStore
			.searchResources(this.attachmentsQuery)
			.map((result) => this.tab.tabStore.resourceRefToHref(result));
	}
}
