import { API_ENDPOINT_HTTP } from "@/config";
import type { AppState } from "@/contexts/app-context/app-context";
import openapiHashes from "@/generated/openapi-hashes.json";
import { createFeedChannelId, createFeedItemId } from "@/lib/id-generators";
import { ElectricOptimisticMap } from "@/lib/sync/electric";
import { indexFeedChannelRoute } from "@api/fastAPI";
import type {
	FeedChannelId,
	FeedChannelResource,
	FeedItemId,
	FeedItemResource,
	ParsedFeed,
	ParsedFeedItem,
} from "@api/schemas";
import { makeAutoObservable } from "mobx";
import type { Result } from "neverthrow";

export class FeedChannelsStore {
	appState: AppState;
	map: ElectricOptimisticMap<
		FeedChannelResource,
		["feed_channel_id"],
		"write_id"
	>;

	constructor(appState: AppState) {
		makeAutoObservable(this);
		this.appState = appState;
		this.map = new ElectricOptimisticMap({
			shapeUrl: `${API_ENDPOINT_HTTP}/shapes/feed_channels`,
			pKeyFields: ["feed_channel_id"],
			writeIdField: "write_id",
			shapeHash: openapiHashes.FeedChannelResource,
			getBearerToken: this.appState.getTokenOrThrow,
		});
	}

	getResourceById(id: FeedChannelId): Result<FeedChannelResource, Error> {
		return this.map.get(id);
	}

	get sortedResources(): FeedChannelResource[] {
		return this.map.keys
			.map((x) => this.getResourceById(x))
			.filter((x) => x.isOk())
			.map((x) => x.value)
			.sort((a, b) => a.name.localeCompare(b.name));
	}

	addFeedChannel = async ({
		feedChannelPreview,
	}: { feedChannelPreview: ParsedFeed }) => {
		const channelId = createFeedChannelId();
		const itemsById = new Map<FeedItemId, ParsedFeedItem>();
		for (const item of feedChannelPreview.feed_items) {
			itemsById.set(createFeedItemId(), item);
		}
		await indexFeedChannelRoute({
			feed_channel: feedChannelPreview.feed_channel,
			channel_id: channelId,
			items_by_id: Object.fromEntries(itemsById),
		});
	};
}

export class FeedItemsStore {
	appState: AppState;
	map: ElectricOptimisticMap<FeedItemResource, ["feed_item_id"], "write_id">;

	constructor(appState: AppState) {
		makeAutoObservable(this);
		this.appState = appState;
		this.map = new ElectricOptimisticMap({
			shapeUrl: `${API_ENDPOINT_HTTP}/shapes/feed_items`,
			pKeyFields: ["feed_item_id"],
			writeIdField: "write_id",
			shapeHash: openapiHashes.FeedItemResource,
			getBearerToken: this.appState.getTokenOrThrow,
		});
	}

	getResourceById(id: FeedItemId): Result<FeedItemResource, Error> {
		return this.map.get(id);
	}

	feedItemsForChannel(feedChannelId: FeedChannelId): FeedItemResource[] {
		const items = this.map.keys
			.map((x) => this.getResourceById(x))
			.filter((x) => x.isOk())
			.map((x) => x.value)
			.filter((x) => x.feed_channel_id === feedChannelId);
		return items;
	}
}
