import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import type {
	ClickInteractionEvent,
	EditTextInteractionEvent,
	InteractionEvent,
} from "@api/schemas";
import { CaretRight, CursorClick, Keyboard } from "@phosphor-icons/react";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { TextEditor } from "../../editor";

function isClickEvent(event: InteractionEvent): event is ClickInteractionEvent {
	return event.interaction.type === "click";
}

function isEditTextEvent(
	event: InteractionEvent,
): event is EditTextInteractionEvent {
	return event.interaction.type === "edit_text";
}

const InteractionEventHeader = observer(function InteractionEventHeader({
	type,
	label,
	className,
}: {
	type: InteractionEvent["interaction"]["type"];
	label: string;
	className?: string;
}) {
	const Icon = type === "click" ? CursorClick : Keyboard;
	return (
		<div
			className={cn(
				"mr-2 flex items-center gap-3 py-1 text-neutral-400 text-xs",
				className,
			)}
		>
			<Icon className="z-10 flex-none bg-white" />
			<span className="truncate">{label}</span>
		</div>
	);
});

export const InteractionEventComponent = observer(
	function InteractionEventComponent({
		interactionEvent,
	}: {
		interactionEvent: InteractionEvent;
	}) {
		const [open, setOpen] = useState(false);

		if (isClickEvent(interactionEvent)) {
			const label = interactionEvent.element_label;
			return <InteractionEventHeader type="click" label={`Click ${label}`} />;
		}

		if (isEditTextEvent(interactionEvent)) {
			return (
				<Collapsible open={open} onOpenChange={setOpen}>
					<CollapsibleTrigger className="group flex h-7 items-center gap-1">
						<InteractionEventHeader
							type="edit_text"
							label={`Type in ${interactionEvent.element_label}`}
							className="group-hover:text-neutral-950 group-data-[state=open]:text-neutral-950"
						/>
						<CaretRight className="text-neutral-400 text-sm group-hover:text-neutral-950 group-data-[state=open]:rotate-90 group-data-[state=open]:text-neutral-950" />
					</CollapsibleTrigger>
					<CollapsibleContent className="my-2 flex flex-col gap-1">
						<TextEditor
							className="bg-red-50 p-1 text-xs"
							options={{
								content: interactionEvent.before || "<i>Empty</i>",
								editable: false,
								editorProps: {
									attributes: {
										class: "text-red-600",
									},
								},
							}}
						/>
						{interactionEvent.after && (
							<TextEditor
								className="bg-green-50 p-1 text-green-600 text-xs"
								options={{
									content: interactionEvent.after,
									editable: false,
									editorProps: {
										attributes: {
											class: "text-green-600",
										},
									},
								}}
							/>
						)}
					</CollapsibleContent>
				</Collapsible>
			);
		}

		return null;
	},
);

export const FailedInteractionComponent = observer(
	function FailedInteractionComponent({
		type,
		errorMessage,
	}: {
		type: InteractionEvent["interaction"]["type"];
		errorMessage: string;
	}) {
		return (
			<TooltipProvider>
				<Tooltip>
					<TooltipTrigger asChild>
						<div>
							<InteractionEventHeader type={type} label={`${type} failed`} />
						</div>
					</TooltipTrigger>
					<TooltipContent>{errorMessage}</TooltipContent>
				</Tooltip>
			</TooltipProvider>
		);
	},
);
