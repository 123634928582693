/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */

export type DatetimeFormat = typeof DatetimeFormat[keyof typeof DatetimeFormat];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DatetimeFormat = {
  twelve_hour: 'twelve_hour',
  twenty_four_hour: 'twenty_four_hour',
  hidden: 'hidden',
} as const;
