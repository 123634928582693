import { SortPopover } from "@/components/table/sort-popover";
import { TableComponent } from "@/components/table/table-component";
import { TablePaginator } from "@/components/table/table-paginator";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { Switch } from "@/components/ui/switch";
import { IS_DEV } from "@/config";
import { useTablesStore } from "@/contexts/app-context/db-store/db-store-hooks";
import { UserTableState } from "@/contexts/tables/stores/table-store";
import { TableTabState, TableViewProvider } from "@/contexts/tables/tab-state";
import { useTableViewContext } from "@/contexts/tables/use-table-context";
import { zodValidator } from "@/lib/zod-validator";
import type {
	FieldId,
	ResourceLink,
	TableId,
	TableResource,
} from "@api/schemas";
import { Spinner } from "@phosphor-icons/react";
import {
	createFileRoute,
	stripSearchParams,
	useNavigate,
} from "@tanstack/react-router";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import AutosizeInput from "react-input-autosize";
import { useResizeDetector } from "react-resize-detector";
import { toast } from "sonner";
import { z } from "zod";

// Separate headers for loading and loaded states because the loading state
// uses appContext to show the table name, but the loaded state needs
// the tableContext to show additional info and toggles
export const LoadingTableHeader = observer(
	({ tableMetadata }: { tableMetadata: TableResource }) => {
		return (
			<div className="flex w-full shrink-0 items-center px-2">
				<div className="rounded-md border border-transparent bg-white px-2 font-semibold text-lg ring-blue-100 hover:border-blue-200 focus:border-blue-200 focus:outline-hidden focus:ring-1">
					{tableMetadata.name}
				</div>
			</div>
		);
	},
);

export const TableHeader = observer(() => {
	const tableViewState = useTableViewContext();
	const tablesStore = useTablesStore();
	const tableState = tableViewState.tableState;

	const [newName, setNewName] = useState(tableState.tableResource.name);

	useEffect(() => {
		setNewName(tableState.tableResource.name);
	}, [tableState.tableResource.name]);

	const onSubmit = () => {
		if (newName === tableState.tableResource.name) {
			return;
		}
		if (newName.trim() === "") {
			toast.error("Table name cannot be empty");
			setNewName(tableState.tableResource.name);
			return;
		}
		tablesStore.renameTable({
			tableId: tableState.tableResource.table_id,
			newName,
		});
	};

	return (
		<>
			<div className="flex w-full shrink-0 items-center px-4">
				{tableState instanceof UserTableState ? (
					<AutosizeInput
						value={newName}
						className="rounded-md border border-transparent bg-white font-semibold text-lg"
						placeholder="Untitled table"
						onChange={(e) => {
							setNewName(e.target.value);
						}}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								onSubmit();
							}
						}}
						onBlur={onSubmit}
					/>
				) : (
					<div className="rounded-md bg-white font-semibold text-lg">
						{tableViewState.tableState.tableResource.name}
					</div>
				)}
			</div>

			<div className="flex shrink-0 items-end justify-end text-sm">
				<div className="flex items-center px-3 py-2">
					<SortPopover sortState={tableViewState.sortState} />

					{IS_DEV && (
						<div className="ml-2 flex max-w-max items-center gap-2 rounded-md border bg-neutral-50 p-1">
							<Switch
								checked={tableViewState.devMode}
								onCheckedChange={(checked) => {
									runInAction(() => {
										tableViewState.devMode = checked;
									});
								}}
							/>{" "}
							<Label>Dev mode</Label>
						</div>
					)}

					{tableState instanceof UserTableState && (
						<Button
							className="ml-2 h-7"
							onClick={() => {
								tableState.addRecord({ precedingRecordLink: null });
							}}
							variant="outline"
						>
							Add row
						</Button>
					)}
				</div>
			</div>
		</>
	);
});

export const TablePage = observer(function TablePage() {
	const { tabState } = Route.useLoaderData();
	const tableViewState = tabState.tableViewState;
	const tableState = tableViewState.tableState;
	const navigate = useNavigate();

	useEffect(
		function registerSubscription() {
			tabState.tableViewState.tableState.subscribe();
			return () => {
				tabState.tableViewState.tableState.unsubscribe();
			};
		},
		[tabState.tableViewState.tableState],
	);

	/**
	 * In the table page, we render a virtualized table with the following
	 * sizing strategy:
	 *	1.	When the table fits inside the viewport (i.e. scrolling is not needed),
	 *		the footer should immediately follow the end of the table instead
	 *		of being spaced at the bottom of the screen.
	 *	2.	When the table requires scrolling, the footer should be placed as a
	 *		sticky element at the bottom of the screen.
	 *
	 * Because the table is virtualized, we need to provide a max height to its
	 * container, which is passed as prop to the TableComponent. To calculate the
	 * max height, we wrap both the table and the footer in a container with
	 * flex-grow, and measure the height of the container and the footer. The
	 * max height for the table is the height of the container minus the height of
	 * the footer.
	 *
	 * Note that the container must have overflow-y-hidden to prevent brief
	 * flickering when the table's content pushes the container to exceed its
	 * flex-grow height.
	 */
	const { height: tableContainerHeight, ref: tableContainerRef } =
		useResizeDetector();
	const { height: tableFooterHeight, ref: tableFooterRef } =
		useResizeDetector();

	const initState = tableViewState.tableState.initState;
	const tableMaxHeight =
		tableContainerHeight !== undefined && tableFooterHeight !== undefined
			? tableContainerHeight - tableFooterHeight
			: null;

	return (
		<div className="relative flex h-full min-h-0 w-full grow flex-col pt-4">
			<TableViewProvider tableViewState={tableViewState}>
				<TableHeader />

				<section
					// Container for the table and the footer.
					className="flex grow flex-col overflow-y-hidden"
					ref={tableContainerRef}
				>
					{tableMaxHeight ? (
						<TableComponent
							columns={tableViewState.columns}
							data={initState.ready ? tableViewState.rows : Array(5).fill({})}
							getRowId={tableViewState.getRowLink}
							getRowLink={tableViewState.getRowLink}
							editableProps={
								tableState instanceof UserTableState
									? {
											addRow: (precedingRowId) => {
												tableState.addRecord({
													precedingRecordLink: precedingRowId,
												});
											},
											moveRows: (rowIds, precedingRowId) => {
												tableState.moveRecords({
													recordLinks: rowIds,
													followingRecordLink: precedingRowId,
												});
											},
											deleteRows: (rowIds) => {
												tableState.deleteRecords({
													recordLinks: rowIds,
												});
											},
											moveColumn: (columnId, overColumnId) => {
												tableState.moveField({
													fieldId: columnId as FieldId,
													followingFieldId: overColumnId as FieldId | null,
												});
											},
											resizeColumn: (columnId, newWidth) => {
												tableState.updateField({
													fieldId: columnId as FieldId,
													newWidth,
												});
											},
										}
									: undefined
							}
							maxHeight={tableMaxHeight}
							rowSelectionPopoverTop={"3rem"}
						/>
					) : null}

					<div
						className="flex w-full items-center justify-between border-t px-2 py-1"
						ref={tableFooterRef}
					>
						<Popover>
							<PopoverTrigger className="cursor-pointer rounded px-2 py-1 text-center text-sm hover:bg-neutral-100">
								View
							</PopoverTrigger>
							<PopoverContent>
								<div className="flex items-center gap-2">
									<Label>Rows per page</Label>
									<Input
										type="number"
										className="w-18"
										defaultValue={tableViewState.tableState.pageSize}
										onBlur={(e) => {
											const newPageSize = Math.min(
												Math.max(1, Number.parseInt(e.target.value)),
												1000,
											);
											navigate({
												to: "/tables/table/$table-id",
												params: {
													"table-id": tableState.tableResource.table_id,
												},
												search: (prev) => ({
													...prev,
													page_size: newPageSize,
												}),
											});
										}}
									/>
								</div>
							</PopoverContent>
						</Popover>

						{initState.ready && (
							<TablePaginator
								totalPages={Math.ceil(
									initState.totalRows / tableState.pageSize,
								)}
								pageIdx={tableState.pageIdx}
								getPageLinkProps={(pageIdx) => ({
									to: "/tables/table/$table-id",
									params: {
										"table-id": tableState.tableResource.table_id,
									},
									search: (prev) => ({ ...prev, page_idx: pageIdx }),
								})}
							/>
						)}

						<span className="text-neutral-500 text-sm">
							{initState.ready ? (
								`${initState.totalRows.toLocaleString()} row${
									initState.totalRows === 1 ? "" : "s"
								}`
							) : (
								<span className="flex items-center gap-1">
									<Spinner className="animate shrink-0 animate-spin" />
									Loading
								</span>
							)}
						</span>
					</div>
				</section>
			</TableViewProvider>
		</div>
	);
});

const defaultValues = {
	record_id: null,
	pageIdx: 0,
	pageSize: 100,
};

const tableSearchSchema = z.object({
	record_id: z.string().nullable().default(defaultValues.record_id),
	// These are snake case for consistency with the server
	page_idx: z.number().default(defaultValues.pageIdx),
	page_size: z.number().default(defaultValues.pageSize),
});

export type TableSearchParams = z.infer<typeof tableSearchSchema>;

export const Route = createFileRoute("/tables/table/$table-id")({
	component: TablePage,
	validateSearch: zodValidator(tableSearchSchema),
	search: {
		// strip default values
		middlewares: [stripSearchParams(defaultValues)],
	},
	loaderDeps: ({ search }) => ({ search }),
	loader: ({ params, context: { tab }, deps }) => {
		// If we're already in a table state, check if the table id matches
		// Otherwise, we'll reinitialize the same table whenever the URL changes.
		if (
			tab.state instanceof TableTabState &&
			tab.state.tableResource.table_id === params["table-id"]
		) {
			tab.state.tableViewState.setVisibleRecordLink(
				(deps.search.record_id ?? null) as ResourceLink | null,
			);

			// If the cursor or limit has changed, update the pagination props
			// for the table state.
			if (
				tab.state.tableViewState.tableState.pageIdx !== deps.search.page_idx ||
				tab.state.tableViewState.tableState.pageSize !== deps.search.page_size
			) {
				tab.state.tableViewState.updatePaginationProps({
					pageIdx: deps.search.page_idx,
					pageSize: deps.search.page_size,
				});
			}

			return {
				tabState: tab.state,
			};
		}

		const tabState = new TableTabState({
			tab,
			tableId: params["table-id"] as TableId,
			pageIdx: deps.search.page_idx,
			pageSize: deps.search.page_size,
		});

		return {
			tabState,
		};
	},
});
