import type { PageTreeState } from "@/components/tree/tree-state";
import {
	ContextMenu,
	ContextMenuContent,
	ContextMenuItem,
} from "@/components/ui/context-menu";
import { Dialog } from "@/components/ui/dialog";
import { useUploadsStore } from "@/contexts/app-context/db-store/db-store-hooks";
import { MetadataEditDialogContent } from "@/pages/tabs/uploads/-components/metadata-edit-dialog";
import type { UploadResourceRef } from "@api/schemas";
import { DownloadSimple, PencilSimple } from "@phosphor-icons/react";
import { ContextMenuTrigger } from "@radix-ui/react-context-menu";
import { observer } from "mobx-react-lite";
import { useState } from "react";

const EditUploadMetaDialog = observer(
	({
		onOpenChange,
		upload,
	}: {
		onOpenChange: (open: boolean) => void;
		upload: UploadResourceRef;
	}) => {
		return (
			<Dialog open onOpenChange={onOpenChange}>
				<MetadataEditDialogContent uploadId={upload.resource_id} />
			</Dialog>
		);
	},
);

const UploadContextMenuActions = observer(function UploadContextMenuActions({
	uploadRef,
	setOpenDialog,
}: {
	uploadRef: UploadResourceRef;
	setOpenDialog: (
		openDialog: {
			type: "editUploadMeta";
			props: { upload: UploadResourceRef };
		} | null,
	) => void;
}) {
	const uploadsStore = useUploadsStore();
	return (
		<>
			<ContextMenuItem
				onClick={() => {
					setOpenDialog({
						type: "editUploadMeta",
						props: {
							upload: uploadRef,
						},
					});
				}}
				className="flex items-center gap-2"
			>
				<PencilSimple weight="bold" /> Edit upload metadata
			</ContextMenuItem>
			<ContextMenuItem
				className="flex items-center gap-2"
				onClick={() => {
					uploadsStore.downloadUploadPdf(uploadRef.resource_id);
				}}
			>
				<DownloadSimple weight="bold" /> Processed PDF
			</ContextMenuItem>
			<ContextMenuItem
				className="flex items-center gap-2"
				onClick={() => {
					uploadsStore.downloadOriginalUploadResource(uploadRef.resource_id);
				}}
			>
				<DownloadSimple weight="bold" /> Original{" "}
				{uploadsStore.getResourceById(uploadRef.resource_id).match(
					(upload) => upload.mimetype.toUpperCase(),
					() => {
						throw new Error("Upload not found");
					},
				)}
			</ContextMenuItem>
		</>
	);
});

export const ContextMenuActions = observer(function ContextMenuActions({
	treeState,
}: {
	treeState: PageTreeState;
}) {
	const rightClickedNode = treeState.rightClickedNode;
	const [openDialog, setOpenDialog] = useState<{
		type: "editUploadMeta";
		props: { upload: UploadResourceRef };
	} | null>(null);

	if (!rightClickedNode) {
		return null;
	}

	const onOpenChangeHandler = (open: boolean) => {
		if (!open) {
			setOpenDialog(null);
			treeState.setRightClickedNode(null);
		}
	};

	let openDialogComponent: React.ReactNode | null = null;
	if (!openDialog) {
		openDialogComponent = null;
	} else {
		switch (openDialog.type) {
			case "editUploadMeta":
				openDialogComponent = (
					<EditUploadMetaDialog
						upload={openDialog.props.upload}
						onOpenChange={onOpenChangeHandler}
					/>
				);
				break;

			default: {
				const _exhaustiveCheck: never = openDialog.type;
				openDialogComponent = _exhaustiveCheck;
			}
		}
	}

	const ref = rightClickedNode.ref;

	let resourceActions: React.ReactNode | null = null;
	switch (ref.type) {
		case "upload":
			resourceActions = (
				<UploadContextMenuActions
					uploadRef={ref}
					setOpenDialog={setOpenDialog}
				/>
			);
			break;
		case "table":
			resourceActions = null;
			break;
		case "feed-channel":
			resourceActions = null;
			break;
		case "feed-item":
			resourceActions = null;
			break;
		case "search-result":
			resourceActions = null;
			break;
		case "webpage":
			resourceActions = null;
			break;
		case "web-search-result":
			resourceActions = null;
			break;
		case "page":
			resourceActions = null;
			break;
		case "edgar-document":
			resourceActions = null;
			break;
		case "edgar-filing":
			resourceActions = null;
			break;
		case "edgar-entity":
			resourceActions = null;
			break;
		default: {
			const _exhaustiveCheck: never = ref;
			throw new Error(`Unknown resource type: ${_exhaustiveCheck}`);
		}
	}

	// We don't want to render an empty context menu because that looks weird
	if (!resourceActions) {
		return null;
	}

	return (
		<ContextMenuContent>
			{openDialogComponent}
			{resourceActions}
		</ContextMenuContent>
	);
});

export const PageTreeContextMenuWrapper = observer(
	(props: { children: React.ReactNode; treeState: PageTreeState }) => {
		return (
			<ContextMenu>
				<ContextMenuTrigger>{props.children}</ContextMenuTrigger>
				<ContextMenuActions treeState={props.treeState} />
			</ContextMenu>
		);
	},
);
