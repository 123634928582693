import { Favicon } from "@/components/favicon";
import { TabLink } from "@/components/tab-link";
import { TableComponent } from "@/components/table/table-component";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import {
	ContextMenu,
	ContextMenuContent,
	ContextMenuItem,
	ContextMenuTrigger,
} from "@/components/ui/context-menu";
import { Dialog } from "@/components/ui/dialog";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { FeedsHomeTabState } from "@/contexts/feeds/tab-state";
import { useFeedsIndex } from "@/contexts/resource-indexes/hooks";
import { LinkTarget } from "@/contexts/tabs/router-types";
import { cn } from "@/lib/utils";
import type { FeedChannelResource } from "@api/schemas";
import { Plus, Trash } from "@phosphor-icons/react";
import { createFileRoute } from "@tanstack/react-router";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useState } from "react";

const FeedsEmptyState = observer(() => {
	const appContext = useAppContext();
	return (
		<div className="flex h-full flex-col items-center justify-center gap-4">
			<h1 className="px-4 text-center text-neutral-500">
				Add a feed to get started!
			</h1>
			<Button
				onClick={() => {
					runInAction(() => {
						appContext.showAddFeedDialog = true;
					});
				}}
			>
				Add feed
			</Button>
		</div>
	);
});

export const FeedChannelComponent = observer(
	({ feedChannel }: { feedChannel: FeedChannelResource }) => {
		const [contextOpen, setContextOpen] = useState(false);
		return (
			<Dialog>
				<AlertDialog>
					<ContextMenu onOpenChange={setContextOpen}>
						<ContextMenuTrigger
							asChild
							className={cn(
								"group flex w-full cursor-pointer items-center rounded-md border px-2 py-1 hover:bg-neutral-100",
								contextOpen ? "border-blue-300" : "border-transparent",
							)}
						>
							<tr>
								<td>
									<TabLink
										target={LinkTarget.Self}
										to={"/feeds/feed-channel/$feed-channel-id"}
										params={{
											"feed-channel-id": feedChannel.feed_channel_id,
										}}
										className="flex min-w-0 grow items-center gap-2 truncate pr-2 text-left"
									>
										{feedChannel.feed_link ? (
											<Favicon
												url={feedChannel.feed_link}
												alt={feedChannel.name}
												className="h-8 w-8 shrink-0 rounded-md bg-neutral-100"
											/>
										) : (
											<div className="h-8 w-8 shrink-0 rounded-md bg-neutral-200" />
										)}

										<div className="flex min-w-0 flex-col gap-1.5 truncate">
											<h2 className="min-w-0 truncate text-sm leading-4">
												{feedChannel.name}
											</h2>
										</div>
									</TabLink>
								</td>
								<td>test</td>
							</tr>
						</ContextMenuTrigger>
						<ContextMenuContent>
							<ContextMenuItem className="flex items-center gap-2">
								<AlertDialogTrigger className="flex items-center gap-2">
									<Trash weight="bold" />
									Delete feed
								</AlertDialogTrigger>
							</ContextMenuItem>
						</ContextMenuContent>
					</ContextMenu>
					<AlertDialogContent>
						<AlertDialogHeader>
							<AlertDialogTitle>Delete feed?</AlertDialogTitle>
							<AlertDialogDescription>
								This will remove the feed.
							</AlertDialogDescription>
						</AlertDialogHeader>
						<AlertDialogFooter>
							<AlertDialogCancel>Cancel</AlertDialogCancel>
							<AlertDialogAction
								onClick={() => {
									// TODO(John): how do we think about the ability to remove feeds?
									// I think it should really just be "remove feed" from "my feeds"
									// So there's like, my feeds and all feeds
									// resourceStore.deleteResources({
									// 	resourceIds: [feedChannel.resource_id],
									// });
								}}
							>
								Continue
							</AlertDialogAction>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialog>
			</Dialog>
		);
	},
);
const FeedsPage = observer(() => {
	const feedsIndex = useFeedsIndex();
	const appContext = useAppContext();

	return (
		<div className="flex h-full max-h-full min-h-0 w-full flex-col px-16 pt-4">
			<h1 className="px-2 font-bold text-2xl">Feeds</h1>
			<div className="flex w-full px-2 pt-4 pb-4">
				<Button
					onClick={() => {
						runInAction(() => {
							appContext.showAddFeedDialog = true;
						});
					}}
					className="gap-2"
					size="sm"
					variant="outline"
				>
					<Plus weight="bold" />
					<span>Add</span>
				</Button>
			</div>
			{feedsIndex.rows.length > 0 ? (
				<div className="relative flex h-96 flex-col">
					<TableComponent
						getRowId={(row) => row.feed_channel_id}
						getRowLink={(row) => row.link}
						columns={feedsIndex.columns}
						data={feedsIndex.rows}
						maxHeight={500}
						rowSelectionPopoverTop={0}
					/>
				</div>
			) : (
				<FeedsEmptyState />
			)}
		</div>
	);
});

export const Route = createFileRoute("/feeds/")({
	component: FeedsPage,
	loader: ({ context: { tab } }) => {
		const tabState = new FeedsHomeTabState(tab);
		return {
			tabState,
		};
	},
});
