import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { LinkTarget } from "@/contexts/tabs/router-types";
import { useTabStore } from "@/contexts/tabs/use-tab-store";
import { resourceRefToLinkProps } from "@/lib/paths";
import type { PendingResource } from "@api/schemas";
import {
	CaretRight,
	FilePdf,
	Globe,
	Rss,
	Spinner,
	X,
} from "@phosphor-icons/react";
import { observer } from "mobx-react-lite";
import { AnimatePresence, motion } from "motion/react";
import { useState } from "react";

const ResourceStatusIcon = ({
	status,
	resourceType,
}: {
	status: "pending" | "ok" | "error";
	resourceType: PendingResource["resource_type"];
}) => {
	switch (status) {
		case "pending":
			return <Spinner className="animate shrink-0 animate-spin" />;
		case "ok":
			switch (resourceType) {
				case "webpage":
					return <Globe />;
				case "upload":
					return <FilePdf />;
				case "feed-channel":
					return <Rss />;
				case "feed-item":
					return <Rss />;
				default: {
					throw new Error("Invalid resource type");
				}
			}
		case "error":
			return <X className="shrink-0" />;
	}
};

/**
 * Displays a single pending resource item in the resources drawer.
 * Shows status icon, resource name, and an "Open" button when the resource is ready.
 * The Open button supports different navigation targets based on keyboard modifiers:
 * - Click: Open in current tab
 * - Cmd/Ctrl+Click: Open in new tab
 * - Shift+Click: Open in new pane
 */
const PendingResourceRow = observer(function PendingResourceRow({
	resource,
}: {
	resource: PendingResource;
}) {
	const tabsContext = useTabStore();
	return (
		<motion.div
			key={resource.pending_resource_id}
			initial={{ opacity: 0, x: -20 }}
			animate={{ opacity: 1, x: 0 }}
			exit={{ opacity: 0, x: -20 }}
			transition={{ duration: 0.2 }}
			className="flex items-center gap-2.5 px-0.5 py-1 text-sm"
		>
			<div className="shrink-0">
				<ResourceStatusIcon
					status={
						resource.end_status === null ? "pending" : resource.end_status.code
					}
					resourceType={resource.resource_type}
				/>
			</div>
			<div className="flex w-full grow items-center truncate text-neutral-600">
				<span className="grow truncate">{resource.name}</span>
				{resource.end_status?.code === "ok" && (
					<button
						type="button"
						className="max-w-max shrink-0 cursor-pointer rounded-sm border px-2 py-0.5 hover:bg-white"
						onClick={(e) => {
							if (resource.end_status?.code !== "ok") {
								return;
							}
							const resourceLinkProps = resourceRefToLinkProps(
								resource.end_status.resource_ref,
							);

							const isShift = e.shiftKey;
							const isCmdOrCtrl = e.metaKey || e.ctrlKey;

							if (isCmdOrCtrl) {
								tabsContext.openLink(resourceLinkProps, LinkTarget.NewTab);
							} else if (isShift) {
								tabsContext.openLink(resourceLinkProps, LinkTarget.NewPane);
							} else {
								tabsContext.openLink(resourceLinkProps, LinkTarget.Self);
							}
						}}
					>
						Open
					</button>
				)}
			</div>
		</motion.div>
	);
});

/**
 * Drawer that shows pending resources.
 */
export const PendingResourcesDrawer = observer(
	function PendingResourcesDrawer() {
		const appState = useAppContext();
		const [isOpen, setIsOpen] = useState(true);

		const numVisible = appState.pendingResources.visibleResources.length;
		if (numVisible === 0) {
			return null;
		}
		const numPending = appState.pendingResources.pendingResources.length;

		return (
			<motion.div
				initial={{ opacity: 0, y: 50 }}
				animate={{ opacity: 1, y: 0 }}
				exit={{ opacity: 0, y: 50 }}
				transition={{ duration: 0.3 }}
				className="fixed right-4 bottom-4 z-50 w-[32rem] overflow-hidden border shadow-sm"
			>
				<Collapsible open={isOpen} onOpenChange={setIsOpen}>
					<CollapsibleTrigger className="group flex w-full items-center gap-2 bg-white px-4 py-3">
						<motion.button
							type="button"
							animate={{ rotate: isOpen ? 90 : 0 }}
							transition={{ duration: 0.2 }}
						>
							<CaretRight
								className="text-lg text-neutral-500 group-hover:text-neutral-800"
								weight="bold"
							/>
						</motion.button>
						<span className="grow text-left text-neutral-600 text-sm">
							{numPending === 0 ? (
								<>All resources processed.</>
							) : (
								<>
									Processing {numPending} resource{numPending > 1 ? "s" : ""}
								</>
							)}
						</span>
						{numPending === 0 && (
							<button
								type="button"
								onClick={(e) => {
									e.stopPropagation();
									appState.pendingResources.dismissDrawer();
								}}
								className="p-1 text-lg text-neutral-500 hover:bg-neutral-100 hover:text-neutral-800"
							>
								<X />
							</button>
						)}
					</CollapsibleTrigger>
					<AnimatePresence>
						{isOpen ? (
							<CollapsibleContent forceMount asChild>
								<motion.div
									initial={{ opacity: 0, height: 0 }}
									animate={{ opacity: 1, height: "auto" }}
									exit={{ opacity: 0, height: 0 }}
									transition={{ duration: 0.15 }}
								>
									<div className="flex max-h-[50vh] flex-col gap-0.5 overflow-y-scroll border-t bg-neutral-100 px-4 py-2">
										{appState.pendingResources.visibleResources.map(
											(resource) => (
												<PendingResourceRow
													key={resource.pending_resource_id}
													resource={resource}
												/>
											),
										)}
									</div>
								</motion.div>
							</CollapsibleContent>
						) : null}
					</AnimatePresence>
				</Collapsible>
			</motion.div>
		);
	},
);
