import {
	TableHeader,
	type TableHeaderProps,
} from "@/components/table/table-header";
import {
	ADD_COLUMN_ID,
	LINK_COLUMN_ID,
	SELECT_COLUMN_ID,
	useTableSensors,
} from "@/components/table/utils";
import { isFieldPrimary } from "@/contexts/tables/stores/field-store";
import { cn } from "@/lib/utils";
import {
	DndContext,
	type DragEndEvent,
	type DragOverEvent,
	DragOverlay,
	type DragStartEvent,
	pointerWithin,
	useDraggable,
	useDroppable,
} from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import type { Header } from "@tanstack/react-table";
import React, { useEffect, useState } from "react";

type HeaderProps<TData, TColumnId extends string> = {
	header: Header<TData, unknown>;
	dropColumnId: TColumnId | null;
	draggedColumnId: TColumnId | null;
	children: React.ReactElement<TableHeaderProps<TData>>;
};

const DropIndicator = ({ columnId }: { columnId: string }) => {
	return (
		<div
			key={`drop-indicator-${columnId}`}
			className="relative z-50 h-8 w-0 p-0 ring-1 ring-blue-500"
		/>
	);
};

const ReorderableColumn = <TData, TColumnId extends string>(
	props: HeaderProps<TData, TColumnId>,
) => {
	const { setNodeRef: setDropNodeRef } = useDroppable({
		id: props.header.column.id,
	});

	const {
		attributes,
		listeners,
		setNodeRef: setDragNodeRef,
	} = useDraggable({
		id: props.header.column.id,
	});

	const isDragging = props.draggedColumnId === props.header.column.id;

	return (
		<>
			{/* Drop Indicator */}
			{props.dropColumnId === props.header.column.id ? (
				<DropIndicator columnId={props.header.column.id} />
			) : null}
			{/* Draggable/Droppable Wrapper */}
			<div
				className={cn(isDragging && "opacity-50")}
				ref={(ref) => {
					setDragNodeRef(ref);
					setDropNodeRef(ref);
				}}
				{...attributes}
				{...listeners}
			>
				{props.children}
			</div>
		</>
	);
};

/**
 * Wrapper for columns that can be dropped onto but not dragged.
 *
 * Only used for the add column header, so we can drop columns to the end of
 * the table.
 */
const DroppableColumn = <TData, TColumnId extends string>(
	props: HeaderProps<TData, TColumnId>,
) => {
	const { setNodeRef: setDropNodeRef } = useDroppable({
		id: props.header.column.id,
	});

	return (
		<>
			{props.dropColumnId === props.header.column.id ? (
				<DropIndicator columnId={props.header.column.id} />
			) : null}
			<div ref={setDropNodeRef}>{props.children}</div>
		</>
	);
};

/**
 * Column wrapper that calculates if a column is reorderable.
 *
 * If it is, it wraps the column in a reorderable component.
 * Otherwise, it just returns the column.
 */
const ColumnWrapper = <TData, TColumnId extends string>(
	props: HeaderProps<TData, TColumnId>,
) => {
	// Not pinning any columns right now, but we might want to pin the select + pkey columns later
	const isPinned = props.header.column.getIsPinned();

	const field = props.header.column.columnDef.meta?.tableMeta?.field;
	const isPrimary = field && isFieldPrimary(field);

	const isAddColumn = props.header.column.id === ADD_COLUMN_ID;

	// Disallow dragging the select, link, and add column headers
	const isReorderable =
		!isPinned &&
		!isPrimary &&
		props.header.column.id !== SELECT_COLUMN_ID &&
		props.header.column.id !== LINK_COLUMN_ID &&
		!isAddColumn;

	if (isReorderable) {
		return <ReorderableColumn {...props} />;
	}

	if (isAddColumn) {
		return <DroppableColumn {...props} />;
	}

	return props.children;
};

export type HeaderDndContextProps<TData, TColumnId extends string> = {
	children: React.ReactElement<TableHeaderProps<TData>>[][];
	moveColumn: (columnId: TColumnId, overColumnId: TColumnId | null) => void;
};

export const HeaderDndContext = <TData, TColumnId extends string>(
	props: HeaderDndContextProps<TData, TColumnId>,
) => {
	const sensors = useTableSensors();
	const [draggedColumnId, setDraggedColumnId] = useState<TColumnId | null>(
		null,
	);
	const [overColumnId, setOverColumnId] = useState<TColumnId | null>(null);

	function handleColumnDragStart(event: DragStartEvent) {
		const activeDroppableId = event.active.id;
		setDraggedColumnId(activeDroppableId as TColumnId);
	}

	function handleColumnDragOver(event: DragOverEvent) {
		const over = event.over;
		if (!over) return;
		const newOverColumnId = over.id as TColumnId;
		setOverColumnId(newOverColumnId);
	}

	function handleColumnDragEnd(event: DragEndEvent) {
		const { active } = event;

		setDraggedColumnId(null);
		setOverColumnId(null);

		const draggedColumnId = active.id as TColumnId;
		if (draggedColumnId === overColumnId) return;

		// The add column header isn't a real column, and it's equivalent to
		// moving the column to the end of the table.
		if (overColumnId === ADD_COLUMN_ID) {
			props.moveColumn(draggedColumnId, null);
		} else {
			props.moveColumn(draggedColumnId, overColumnId);
		}
	}

	useEffect(
		function activateDragIndicators() {
			const dragIndicatorElements = document.querySelectorAll(
				`[data-drag-indicator-column-id="${overColumnId}"]`,
			);
			for (const el of dragIndicatorElements) {
				(el as HTMLElement).style.setProperty("opacity", "1");
			}

			return () => {
				for (const el of dragIndicatorElements) {
					(el as HTMLElement).style.setProperty("opacity", "0");
				}
			};
		},
		[overColumnId],
	);

	return (
		<DndContext
			collisionDetection={pointerWithin}
			modifiers={[restrictToHorizontalAxis]}
			onDragStart={handleColumnDragStart}
			onDragOver={handleColumnDragOver}
			onDragEnd={handleColumnDragEnd}
			sensors={sensors}
		>
			{React.Children.map(props.children.flat(), (child) => {
				if (!React.isValidElement(child) || child.type !== TableHeader)
					return null;

				const header = (child.props as TableHeaderProps<TData>).header;

				return (
					<ColumnWrapper
						key={header.id}
						header={header}
						dropColumnId={overColumnId}
						draggedColumnId={draggedColumnId}
					>
						{child}
					</ColumnWrapper>
				);
			})}
			{/* Drag previews for columns */}
			<DragOverlay>
				{/* Tanstack doesn't have a function to get headers by ID,
								 so we have to do this the hard way */}
				{draggedColumnId &&
					React.Children.map(props.children.flat(), (child) => {
						if (!React.isValidElement(child) || child.type !== TableHeader)
							return child;
						const header = (child.props as TableHeaderProps<TData>).header;
						return header.id === draggedColumnId ? (
							<TableHeader
								key={`drag-preview-${header.id}`}
								header={header}
								isDragPreview
							/>
						) : null;
					})}
			</DragOverlay>
		</DndContext>
	);
};
