import { TextEditor } from "@/components/editor";
import { AssistantPresenceIcon } from "@/components/layout/right-sidebar/assistant-presence-icon";
import {
	useUserSessionStore,
	useUsersStore,
} from "@/contexts/app-context/db-store/db-store-hooks";
import { getDisplayName } from "@/contexts/app-context/db-store/user-store";
import type { Message, UserSessionId } from "@api/schemas";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";

const MessageContent = observer(function MessageContent({
	content,
}: {
	content: string;
}) {
	return (
		<TextEditor
			className="w-full text-sm"
			options={{ content, editable: false }}
		/>
	);
});

const UserMessageLabel = observer(function UserMessageLabel({
	user_session_id,
}: {
	user_session_id: UserSessionId;
}) {
	const userSessionStore = useUserSessionStore();
	const userStore = useUsersStore();
	const userSession = userSessionStore.getUserSessionById(user_session_id);
	if (userSession.isErr()) {
		return "Unknown User";
	}
	const user = userStore.items.get(userSession.value.user_id);
	if (user.isErr()) {
		return "Unknown User";
	}
	const displayName = getDisplayName(user.value);
	return <>{displayName}</>;
});

/**
 * Shows the message with metadata.
 */
export const MessageComponent = observer(function MessageComponent({
	message,
}: {
	message: Message;
}) {
	return (
		<div className="flex w-full min-w-0 grow flex-col gap-1 px-5 py-3 hover:bg-neutral-50">
			{/* Message Header */}
			<div className="flex items-center gap-2 text-sm">
				{/* Name */}
				<span className="flex items-center gap-2">
					<span className="font-semibold text-neutral-950">
						{message.user_session_id !== null ? (
							<UserMessageLabel user_session_id={message.user_session_id} />
						) : (
							"Assistant"
						)}
					</span>
					{message.assistant_session_id !== null && (
						<AssistantPresenceIcon
							assistantSessionId={message.assistant_session_id}
						/>
					)}
				</span>
				{/* Timestamp */}
				<span className="text-neutral-500 text-xs">
					{dayjs(message.created_at).format("h:mm A")}
				</span>
			</div>
			<MessageContent content={message.content} />
		</div>
	);
});
