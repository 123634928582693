import type { AppState } from "@/contexts/app-context/app-context";
import type { BaseTabState } from "@/contexts/tabs/base-tab-state";
import { LinkTarget } from "@/contexts/tabs/router-types";
import type { LinkableResourceRef } from "@/lib/paths";
import type { PageId, ResourceLink } from "@api/schemas";
import type {
	DragEndEvent,
	DragOverEvent,
	DragStartEvent,
} from "@dnd-kit/core";
import type { LinkProps } from "@tanstack/react-router";
import { makeAutoObservable } from "mobx";
import { computedFn } from "mobx-utils";
import type { MouseEvent as ReactMouseEvent } from "react";

type NodeId = string & { __brand: "NodeId" };

/**
 * A node in the tree.
 *
 * path: the path from the root node to the current node, where each number is
 * the index of the child node in the parent node.
 */
export class Node<T extends LinkableResourceRef["type"]> {
	path: number[];
	href: ResourceLink;
	head: BaseTabState["head"];
	ref: Extract<LinkableResourceRef, { type: T }>;

	constructor(props: {
		path: number[];
		href: ResourceLink;
		head: BaseTabState["head"];
		ref: Extract<LinkableResourceRef, { type: T }>;
	}) {
		this.path = props.path;
		this.href = props.href;
		this.head = props.head;
		this.ref = props.ref;
	}

	get id() {
		return this.path.join("-") as NodeId;
	}
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
type NodeUnion<T extends LinkableResourceRef["type"]> = T extends any
	? Node<T>
	: never;

export type AnyNode = NodeUnion<LinkableResourceRef["type"]>;

export class PageTreeState {
	appState: AppState;
	dndEnabled: boolean;
	multiSelectEnabled: boolean;

	rightClickedNode: AnyNode | null = null;
	selectedNodes: Map<NodeId, AnyNode> = new Map();
	expandedNodes: Map<NodeId, AnyNode> = new Map();
	draggingNodes: Map<NodeId, AnyNode> = new Map();
	dropOverNode: AnyNode | null = null;

	searchQuery = "";

	constructor(props: {
		appState: AppState;
		isDndEnabled: boolean;
		isMultiSelect: boolean;
	}) {
		makeAutoObservable(this);

		this.appState = props.appState;
		this.dndEnabled = props.isDndEnabled;
		this.multiSelectEnabled = props.isMultiSelect;

		this.expandedNodes.set(this.rootNode.id, this.rootNode);
	}

	get rootNode(): Node<"page"> {
		const homeLinkProps: LinkProps = {
			to: "/pages/home",
		};
		const tabStore = this.appState.tabStore;
		const homePageLink = tabStore.linkPropsToHref(
			homeLinkProps,
		) as ResourceLink;
		const homePageId = this.appState.workspace.pages.homePageId.unwrapOr(
			"dummy-home-page-id" as PageId,
		);
		const homePageRef = {
			type: "page" as const,
			resource_id: homePageId,
		};
		const head = tabStore.getTabHead(homeLinkProps);
		return new Node({
			path: [],
			href: homePageLink,
			ref: homePageRef,
			head,
		});
	}

	getChildren = computedFn((node: AnyNode) => {
		if (node.ref?.type !== "page") {
			return [];
		}
		const tabStore = this.appState.tabStore;
		const childLinks = this.appState.workspace.pageLinks.getLinksForPage(
			node.ref.resource_id,
		);
		const childNodes: AnyNode[] = [];
		for (const [index, link] of childLinks.entries()) {
			const head = tabStore.getTabHead({ href: link });
			if (head.resourceRef === undefined) {
				continue;
			}
			childNodes.push(
				new Node({
					path: [...node.path, index],
					href: link as ResourceLink,
					ref: head.resourceRef,
					head,
				}) as AnyNode,
			);
		}
		return childNodes;
	});

	setSelectedNodes = (nodes: Map<NodeId, AnyNode>) => {
		this.selectedNodes = nodes;
	};

	setRightClickedNode = (node: AnyNode | null) => {
		this.rightClickedNode = node;
	};

	/**
	 * Returns all nodes in the tree in depth-first traversal order.
	 *
	 * Note that the tree could be cyclic, so we want to stop traversing
	 * when we see a node that we've already seen.
	 */
	get flattenedNodes() {
		const nodes: AnyNode[] = [];
		const seenNodes: Set<AnyNode["href"]> = new Set();
		const stack: AnyNode[] = [this.rootNode];
		while (stack.length > 0) {
			const node = stack.pop();
			if (!node || seenNodes.has(node.href)) {
				continue;
			}
			nodes.push(node);
			seenNodes.add(node.href);
			stack.push(...this.getChildren(node));
		}
		return nodes;
	}

	/**
	 * Returns all nodes between node1 and node2, inclusive.
	 */
	nodesBetween = (node1: AnyNode, node2: AnyNode) => {
		const index1 = this.flattenedNodes.findIndex(
			(node) => node.href === node1.href,
		);
		const index2 = this.flattenedNodes.findIndex(
			(node) => node.href === node2.href,
		);
		return this.flattenedNodes.slice(
			Math.min(index1, index2),
			Math.max(index1, index2) + 1,
		);
	};

	/**
	 * When a node is clicked, set it as selected, and navigate to the file.
	 *
	 * When a node is shift-clicked, select all nodes from the last selected
	 * node to that node, and don't navigate to the file.
	 */
	handleNodeClick = (
		e: ReactMouseEvent<HTMLDivElement, MouseEvent>,
		node: AnyNode,
	) => {
		// If we are not in multi-select mode, just select the node and trigger the navigation handler
		if (this.multiSelectEnabled && e.shiftKey) {
			// When a node is shift-clicked, if there is already a selection, select
			// all nodes from the last selected node to that node, and don't
			// navigate to the file. If there is no selection, just select the node.
			const lastSelectedNode = Array.from(this.selectedNodes.values()).at(-1);
			if (!lastSelectedNode) {
				this.selectedNodes = new Map([[node.id, node]]);
			} else {
				this.selectedNodes = new Map(
					this.nodesBetween(lastSelectedNode, node).map((n) => [n.id, n]),
				);
			}
		} else if (this.multiSelectEnabled && (e.metaKey || e.ctrlKey)) {
			if (this.selectedNodes.has(node.id)) {
				this.selectedNodes.delete(node.id);
			} else {
				this.selectedNodes.set(node.id, node);
			}
		} else {
			// Otherwise, just select the node, and navigate to the file.
			this.selectedNodes = new Map([[node.id, node]]);
			this.appState.tabStore.openLink({ href: node.href }, LinkTarget.Self);
		}
		this.setRightClickedNode(null);
	};

	handleDragStart = (event: DragStartEvent) => {
		const node = event.active.data.current?.node as AnyNode | undefined;
		if (!node) {
			return;
		}
		// If the node we are dragging is also selected, we should drag the
		// entire selection
		if (this.selectedNodes.has(node.id)) {
			this.draggingNodes = new Map(this.selectedNodes);
		} else {
			this.draggingNodes = new Map([[node.id, node]]);
		}
	};

	handleDragOver = (event: DragOverEvent) => {
		const over = event.over;
		if (!over) {
			this.dropOverNode = null;
			return;
		}
		const node = over.data.current?.node as AnyNode | undefined;
		if (!node) {
			this.dropOverNode = null;
			return;
		}
		this.dropOverNode = node;
	};

	/**
	 * Returns all descendants of nodes being dragged.
	 *
	 * We can't drag nodes into their descendants, so this is used to disable
	 * droppables.
	 */
	get descendantsOfDraggingNodes(): Set<string> {
		const allDescendants: Set<string> = new Set();
		for (const draggingNode of this.draggingNodes.values()) {
			const descendants = this.getChildren(draggingNode);
			for (const descendant of descendants) {
				allDescendants.add(descendant.href);
			}
		}
		return allDescendants;
	}

	handleDragEnd = (event: DragEndEvent) => {
		const overNode = event.over?.data.current?.node as AnyNode | undefined;
		if (overNode) {
			if (this.descendantsOfDraggingNodes.has(overNode.href)) {
				console.warn("Tried to move files into one of their descendants");
			}
			this.expandedNodes.set(overNode.id, overNode);
		}
		// If we were dragging multiple nodes, clear the multi-select
		if (this.draggingNodes.size > 1) {
			this.selectedNodes.clear();
		}
		this.draggingNodes.clear();
		this.dropOverNode = null;
	};

	toggleExpandedNode = (node: AnyNode) => {
		if (this.expandedNodes.has(node.id)) {
			this.expandedNodes.delete(node.id);
		} else {
			this.expandedNodes.set(node.id, node);
		}
	};

	setSearchQuery = (query: string) => {
		this.searchQuery = query;
	};
}
