import type { AnyNode, PageTreeState } from "@/components/tree/tree-state";
import { useTabStore } from "@/contexts/tabs/use-tab-store";
import { resourceRefToLinkProps } from "@/lib/paths";
import { DragOverlay, type Modifier } from "@dnd-kit/core";
import { getEventCoordinates } from "@dnd-kit/utilities";
import { Files } from "@phosphor-icons/react";
import { observer } from "mobx-react-lite";
import type { ReactNode } from "react";

const snapTopLeftToCursor: Modifier = ({
	activatorEvent,
	draggingNodeRect,
	transform,
}) => {
	if (draggingNodeRect && activatorEvent) {
		const activatorCoordinates = getEventCoordinates(activatorEvent);

		if (!activatorCoordinates) {
			return transform;
		}

		const offsetX = activatorCoordinates.x - draggingNodeRect.left;
		const offsetY = activatorCoordinates.y - draggingNodeRect.top;

		return {
			...transform,
			x: transform.x + offsetX,
			y: transform.y + offsetY,
		};
	}

	return transform;
};

const DropTargetLabel = observer((props: { dropTarget: AnyNode }) => {
	const tabStore = useTabStore();
	const head = tabStore.getTabHead(
		resourceRefToLinkProps(props.dropTarget.ref),
	);
	return (
		<span className="inline-flex items-center gap-2 text-sm">
			<span>Move into</span>
			<span className="inline-flex items-center gap-1">
				<head.icon className=" text-neutral-500" />
				<span>{head.label}</span>
			</span>
		</span>
	);
});

const SingleDragTargetLabel = observer((props: { dragTarget: AnyNode }) => {
	const tabStore = useTabStore();
	const head = tabStore.getTabHead(
		resourceRefToLinkProps(props.dragTarget.ref),
	);
	return (
		<>
			<head.icon className="h-4 w-4 shrink-0 text-neutral-500" />
			<span className="text-sm">{head.label}</span>
		</>
	);
});

interface DragOverlayProps {
	treeState: PageTreeState;
}

export const DragOverlayComponent = observer((props: DragOverlayProps) => {
	const { dropOverNode, draggingNodes, descendantsOfDraggingNodes } =
		props.treeState;

	let DraggingNodesInfo: ReactNode = null;
	if (draggingNodes.size === 1) {
		const dragTarget = Array.from(draggingNodes.values())[0];
		DraggingNodesInfo = <SingleDragTargetLabel dragTarget={dragTarget} />;
	} else if (draggingNodes.size > 1) {
		DraggingNodesInfo = (
			<>
				<Files weight="bold" className="h-4 w-4 shrink-0 text-neutral-500" />
				<span className="text-sm">{`${draggingNodes.size} files`}</span>
			</>
		);
	}

	let DropTargetInfo: ReactNode;
	if (
		dropOverNode === null ||
		descendantsOfDraggingNodes.has(dropOverNode.href)
	) {
		DropTargetInfo = null;
	} else {
		DropTargetInfo = <DropTargetLabel dropTarget={dropOverNode} />;
	}

	return (
		<DragOverlay
			className="flex flex-col gap-2 border border-neutral-300 bg-neutral-50 p-1"
			style={{
				height: "fit-content",
				width: "fit-content",
			}}
			modifiers={[snapTopLeftToCursor]}
			dropAnimation={null}
		>
			<div className="flex items-center gap-2">{DraggingNodesInfo}</div>
			{DropTargetInfo}
		</DragOverlay>
	);
});
