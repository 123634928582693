import { Button } from "@/components/ui/button";
import {
	CommandEmpty,
	CommandGroup,
	CommandItem,
	CommandList,
} from "@/components/ui/command";
import { X } from "@phosphor-icons/react";
import { getRouteApi } from "@tanstack/react-router";
import dayjs from "dayjs";
import { matchSorter } from "match-sorter";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";

interface SearchComboboxCommandListProps {
	setShowCommandList?: (show: boolean) => void;
}

export const SearchComboboxCommandList = observer(
	({ setShowCommandList }: SearchComboboxCommandListProps) => {
		const routeApi = getRouteApi("/search");
		const { tabState } = routeApi.useLoaderData();
		const { query } = tabState.form.config;
		const tabStore = tabState.tab.tabStore;

		const filteredSearchHistory = useMemo(
			() =>
				query.trim().length > 0
					? // note that this will sort by match order, not by creation date — is this what we want?
						matchSorter(tabStore.appState.searchStore.recentSearches, query, {
							keys: ["query"],
						})
					: tabStore.appState.searchStore.recentSearches,
			[tabStore.appState.searchStore.recentSearches, query],
		);

		const hasFilteredSearches = filteredSearchHistory.length > 0;

		return (
			<CommandList className="max-h-full">
				{query && (
					<CommandGroup className="p-2">
						<CommandItem
							key={"current_search"}
							value={query}
							className="flex w-full items-center gap-2 rounded-md"
							onSelect={() => {
								tabState.form.handleSearch();
								setShowCommandList?.(false);
							}}
						>
							<div className="w-full min-w-0">
								<h3 className="w-full min-w-0 truncate text-neutral-700 text-sm">
									Search for "{query}"
								</h3>
							</div>
						</CommandItem>
					</CommandGroup>
				)}

				{hasFilteredSearches ? (
					<CommandGroup
						className="p-2"
						heading={
							<span className="flex items-baseline gap-2">
								<h2 className="flex min-w-0 grow items-center gap-1">
									<span className="text-xs">Recent searches</span>
								</h2>{" "}
								<Button
									className="h-max p-1 text-xs"
									variant="ghost"
									onClick={() => {
										runInAction(() => {
											tabStore.appState.searchStore.recentSearchIds.clear();
										});
									}}
								>
									Clear all
								</Button>
							</span>
						}
					>
						<CommandEmpty>No recent searches</CommandEmpty>
						{filteredSearchHistory.map((search, idx) => {
							const { search_id, query } = search;
							return (
								<CommandItem
									key={search_id}
									// we use the index to ensure that the value is unique,
									// otherwise hovering on one item will highlight all items with the same query
									// (but possibly different search modes)
									value={`${idx}`}
									className="group flex w-full items-center gap-2"
									onSelect={() => {
										// TODO(John): make this open a new tab again
										tabState.tab.router.navigate({
											to: "/search/result/$search-id",
											params: {
												"search-id": search_id,
											},
										});
										setShowCommandList?.(false);
									}}
								>
									<div className="w-full min-w-0">
										<h3 className="w-full min-w-0 truncate text-neutral-700 text-sm">
											{query}
										</h3>
										<p className="flex items-center gap-1 text-neutral-500 text-xs">
											{/** Timestamp */}
											<span className="text-xs">
												{dayjs(search.requested_at).fromNow()} ago
											</span>
										</p>
									</div>
									<button
										type="button"
										className="p-2 opacity-0 hover:bg-neutral-200 group-hover:opacity-100"
										onClick={(e) => {
											e.stopPropagation();
											runInAction(() => {
												tabStore.appState.searchStore.recentSearchIds.delete(
													search_id,
												);
											});
										}}
									>
										<X size={16} />
									</button>
								</CommandItem>
							);
						})}
					</CommandGroup>
				) : null}
			</CommandList>
		);
	},
);
