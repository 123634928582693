import { API_ENDPOINT_HTTP } from "@/config";
import type { AppState } from "@/contexts/app-context/app-context";
import openapiHashes from "@/generated/openapi-hashes.json";
import { ElectricOptimisticMap } from "@/lib/sync/electric";
import type { UserSession, UserSessionId } from "@api/schemas";
import { makeAutoObservable } from "mobx";
import type { Result } from "neverthrow";

export class UserSessionStore {
	appState: AppState;
	items: ElectricOptimisticMap<UserSession, ["user_session_id"], "write_id">;

	constructor(appState: AppState) {
		makeAutoObservable(this);
		this.appState = appState;

		this.items = new ElectricOptimisticMap<
			UserSession,
			["user_session_id"],
			"write_id"
		>({
			shapeUrl: `${API_ENDPOINT_HTTP}/shapes/user_sessions`,
			pKeyFields: ["user_session_id"],
			writeIdField: "write_id",
			shapeHash: openapiHashes.UserSession,
			getBearerToken: appState.getTokenOrThrow,
		});
	}

	getUserSessionById(id: UserSessionId): Result<UserSession, Error> {
		return this.items.get(id);
	}
}
