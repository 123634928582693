import type { PdfSearchParams } from "@/contexts/pdfviewer-context";
import { BaseTabState } from "@/contexts/tabs/base-tab-state";
import type { Tab } from "@/contexts/tabs/tabs-context";
import { makeAutoObservableAbstract } from "@/lib/make-auto-observable-abstract";
import { FileMagnifyingGlass } from "@phosphor-icons/react";
import { FilePdf } from "@phosphor-icons/react";

// TODO(Tae): Figure out why this doesn't get generated as a type
type EdgarAccessionNumber = string;

export class EdgarFilingTabState extends BaseTabState {
	edgarFilingAccessionNumber: EdgarAccessionNumber;

	constructor(tab: Tab, edgarFilingAccessionNumber: EdgarAccessionNumber) {
		super(tab);
		makeAutoObservableAbstract(this);

		this.edgarFilingAccessionNumber = edgarFilingAccessionNumber;
	}

	get head() {
		return {
			icon: FileMagnifyingGlass,
			label: this.edgarFilingAccessionNumber,
			resourceRef: {
				type: "edgar-filing" as const,
				resource_id: this.edgarFilingAccessionNumber,
			},
		};
	}
}

type EdgarCik = string;

export class EdgarEntityTabState extends BaseTabState {
	edgarEntityCik: EdgarCik;

	constructor(tab: Tab, edgarEntityCik: EdgarCik) {
		super(tab);
		makeAutoObservableAbstract(this);

		this.edgarEntityCik = edgarEntityCik;
	}

	get head() {
		return {
			icon: FileMagnifyingGlass,
			label: this.edgarEntityCik,
			resourceRef: {
				type: "edgar-entity" as const,
				resource_id: this.edgarEntityCik,
			},
		};
	}
}

export class EdgarDocumentTabState extends BaseTabState {
	accession: string;
	sequence: number;
	searchParams: PdfSearchParams;

	constructor(tab: Tab, documentId: string, searchParams: PdfSearchParams) {
		super(tab);
		const [accession, sequence] = documentId.split(".");
		this.accession = accession;
		this.sequence = Number.parseInt(sequence);
		this.searchParams = searchParams;
	}

	get head() {
		let label = `EDGAR: ${this.accession}.${this.sequence}`;
		if (this.searchParams.page_index !== undefined) {
			label += ` p.${this.searchParams.page_index + 1}`;
		}
		return {
			icon: FilePdf,
			label,
		};
	}
}

export class EdgarHomeTabState extends BaseTabState {
	constructor(tab: Tab) {
		super(tab);
		makeAutoObservableAbstract(this);
	}

	get head() {
		return {
			icon: FileMagnifyingGlass,
			label: "EDGAR",
		};
	}
}
