import { TableComponent } from "@/components/table/table-component";
import { Button } from "@/components/ui/button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { useTablesStore } from "@/contexts/app-context/db-store/db-store-hooks";
import { useTablesIndex } from "@/contexts/resource-indexes/hooks";
import { TablesHomeTabState } from "@/contexts/tables/tab-state";
import { ArrowsMerge, File, Plus, Table } from "@phosphor-icons/react";
import { createFileRoute } from "@tanstack/react-router";
import { observer } from "mobx-react-lite";

const TablesEmptyState = observer(() => {
	const tablesStore = useTablesStore();
	const { tabState } = Route.useLoaderData();

	return (
		<div className="flex h-full flex-col items-center justify-center gap-4">
			<h1 className="px-4 text-center text-neutral-500">
				Add a table to get started!
			</h1>
			<Button
				onClick={async () => {
					await tablesStore.createTable({
						name: "New table",
						afterLocalCallback: (newTableResource) => {
							tabState.tab.router.navigate({
								to: "/tables/table/$table-id",
								params: {
									"table-id": newTableResource.table_id,
								},
							});
						},
					});
				}}
			>
				Add table
			</Button>
		</div>
	);
});

const TableListings = observer(function TableList() {
	const tablesIndex = useTablesIndex();
	const tablesStore = useTablesStore();
	return (
		<div className="flex grow flex-col gap-4">
			<h3 className="font-semibold text-lg">User</h3>
			<div className="relative flex max-h-96 flex-col">
				<TableComponent
					getRowId={(row) => row.table_id}
					getRowLink={(row) => row.link}
					columns={tablesIndex.columns}
					data={tablesIndex.userRows}
					editableProps={{
						deleteRows: (rowIds) => {
							tablesStore.deleteTables(rowIds);
						},
					}}
					maxHeight={500}
					rowSelectionPopoverTop={"-3rem"}
				/>
			</div>
			<h3 className="font-semibold text-lg">EDGAR</h3>
			<div className="relative flex max-h-96 flex-col">
				<TableComponent
					getRowId={(row) => row.table_id}
					getRowLink={(row) => row.link}
					columns={tablesIndex.columns}
					data={tablesIndex.edgarRows}
					maxHeight={500}
					rowSelectionPopoverTop={"-3rem"}
				/>
			</div>
		</div>
	);
});

export const TablesHomePage = observer(() => {
	const tablesStore = useTablesStore();

	return (
		<div className="relative flex h-full min-h-0 w-full grow flex-col px-16">
			<div className="flex w-full shrink-0 flex-col gap-4 py-8 text-2xl">
				<div className="flex w-fit items-center gap-2 rounded-md bg-neutral-100 p-2">
					<Table size={24} weight="regular" />
				</div>
				<div className="flex w-full justify-between">
					<h1 className="font-semibold">Tables</h1>
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button className="flex shrink-0 items-center gap-2 rounded-md">
								<Plus className="text-lg" weight="regular" />
								<span>Create new</span>
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent>
							<Tooltip>
								<TooltipTrigger asChild>
									<DropdownMenuItem
										onClick={async () => {
											await tablesStore.createTable({
												name: "New table",
											});
										}}
										className="flex items-center gap-2"
									>
										<File />
										New Table
									</DropdownMenuItem>
								</TooltipTrigger>
								<TooltipContent align="start" side="left">
									Analyze multiple documents in parallel.
								</TooltipContent>
							</Tooltip>
							<Tooltip>
								<TooltipTrigger asChild>
									<DropdownMenuItem
										onSelect={() => {
											console.log("TODO: create computed table");
										}}
										className="flex items-center gap-2"
									>
										<ArrowsMerge />
										Computed table
									</DropdownMenuItem>
								</TooltipTrigger>
								<TooltipContent align="start" side="left">
									Combine or transform existing tables to build a new one.
								</TooltipContent>
							</Tooltip>
						</DropdownMenuContent>
					</DropdownMenu>
				</div>
			</div>
			{tablesStore.sortedResources.length === 0 ? (
				<TablesEmptyState />
			) : (
				<TableListings />
			)}
		</div>
	);
});

export const Route = createFileRoute("/tables/")({
	component: TablesHomePage,
	loader: ({ context: { tab } }) => {
		const tabState = new TablesHomeTabState(tab);
		return {
			tabState,
		};
	},
});
