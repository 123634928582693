import { HeaderDndContext } from "@/components/table/dnd/header-dnd";
import { RowDndContext } from "@/components/table/dnd/row-dnd";
import {
	TableHeader,
	type TableHeaderProps,
} from "@/components/table/table-header";
import { TableRow } from "@/components/table/table-row";
import { sanitizeForCSSVariableName } from "@/components/table/utils";
import { IS_DEV } from "@/config";
import type { ResourceLink } from "@api/schemas";
import { Copy, Trash } from "@phosphor-icons/react";
import {
	type ColumnDef,
	type RowSelectionState,
	type Table,
	type TableState,
	getCoreRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { observer } from "mobx-react-lite";
import { motion } from "motion/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "sonner";

// from https://github.com/TanStack/table/discussions/2498#discussioncomment-8649218
export const useResizeObserver = <TColumnId extends string>(
	state: TableState,
	callback: (columnId: TColumnId, columnSize: number) => void,
) => {
	// This Ref will contain the id of the column being resized or undefined
	const columnResizeRef = useRef<string | false>();
	useEffect(() => {
		// We are interested in calling the resize event only when "state.columnResizingInfo?.isResizingColumn" changes from
		// a string to false, because it indicates that it WAS resizing but it no longer is.
		if (
			state.columnSizingInfo &&
			!state.columnSizingInfo?.isResizingColumn &&
			columnResizeRef.current
		) {
			// Trigger resize event
			callback(
				columnResizeRef.current as TColumnId,
				state.columnSizing[columnResizeRef.current],
			);
		}
		columnResizeRef.current = state.columnSizingInfo?.isResizingColumn;
	}, [callback, state.columnSizingInfo, state.columnSizing]);
};

/**
 * Props to define if a table is editable. Editable means that we allow rows
 * and columns to be dragged and rows to be added (adding columns is handled by
 * the column definition).
 *
 * These are optional because resource indexes allow for row deletion but none
 * of the other features.
 */
interface TableEditableProps<TRowId extends string, TColumnId extends string> {
	addRow?: (precedingRowId: TRowId | null) => void;
	moveRows?: (rowIds: Set<TRowId>, precedingRowId: TRowId | null) => void;
	deleteRows?: (rowIds: Set<TRowId>) => void;
	moveColumn?: (columnId: TColumnId, overColumnId: TColumnId | null) => void;
	resizeColumn?: (columnId: TColumnId, newWidth: number) => void;
}

const HeaderWrapper = <TData, TColumnId extends string>(props: {
	children: React.ReactElement<TableHeaderProps<TData>>[][];
	moveColumn?: (columnId: TColumnId, overColumnId: TColumnId | null) => void;
}) => {
	if (props.moveColumn) {
		return (
			<HeaderDndContext moveColumn={props.moveColumn}>
				{props.children}
			</HeaderDndContext>
		);
	}
	return props.children;
};

/**
 * Table body component.
 *
 * Explicitly not an observer. See comment in TableRow.tsx.
 */
const TableBody = <TData, TRowId extends string>(props: {
	table: Table<TData>;
	moveRows?: (rowIds: Set<TRowId>, precedingRowId: TRowId | null) => void;
	addRow?: (precedingRowId: TRowId | null) => void;
	selectedRowIds: Set<TRowId>;
	scrollElement: HTMLDivElement | null;
}) => {
	const rowData = props.table.getRowModel().rows;
	const rowVirtualizer = useVirtualizer<HTMLDivElement, HTMLDivElement>({
		count: rowData.length,
		estimateSize: () => 64, // Estimate row height for accurate scrollbar dragging
		getScrollElement: () => props.scrollElement,
		// Measure dynamic row height, except in firefox because it measures table border height incorrectly
		measureElement:
			typeof window !== "undefined" &&
			navigator.userAgent.indexOf("Firefox") === -1
				? (element) => element?.getBoundingClientRect().height
				: undefined,
		overscan: 16,
		debug: IS_DEV,
	});

	// Virtualized rows to render
	const virtualRows = rowVirtualizer.getVirtualItems();

	// If a moveRows function is provided, we'll enable drag and drop for rows
	// via a separate component
	if (props.moveRows) {
		return (
			<div
				aria-label="tbody"
				style={{
					display: "grid",
					height: `${rowVirtualizer.getTotalSize()}px`,
					position: "relative",
				}}
			>
				<RowDndContext
					table={props.table}
					selectedRowIds={props.selectedRowIds}
					moveRows={props.moveRows ?? (() => {})}
					addRow={props.addRow}
					virtualRows={virtualRows}
					rowVirtualizer={rowVirtualizer}
				/>
			</div>
		);
	}

	// Otherwise, render the rows directly
	return (
		<div
			aria-label="tbody"
			style={{
				display: "grid",
				height: `${rowVirtualizer.getTotalSize()}px`,
				position: "relative",
			}}
		>
			{virtualRows.map((virtualRow) => {
				const row = rowData[virtualRow.index];
				return (
					<TableRow
						key={row.id}
						row={row}
						virtualRow={virtualRow}
						rowVirtualizer={rowVirtualizer}
					/>
				);
			})}
		</div>
	);
};

type TableComponentProps<
	TData,
	TRowId extends string,
	TColumnId extends string,
> = {
	columns: ColumnDef<TData>[];
	data: TData[];
	// Should we stick with only IDs/links?
	getRowId?: (row: TData) => TRowId;
	getRowLink?: (row: TData) => ResourceLink;
	editableProps?: TableEditableProps<TRowId, TColumnId>;
	maxHeight: string | number;
	rowSelectionPopoverTop: string | number;
};

/**
 * General table component.
 *
 * @template TData - The type of data being displayed in the table
 * @template TRowId - The type for row IDs, must extend string
 * @template TColumnId - The type for column IDs, must extend string
 *
 * @param {Object} props - Component props
 * @param {ColumnDef<TData>[]} props.columns - Column definitions for the table
 * @param {TData[]} props.data - The data to display in the table
 * @param {function(row: TData): TRowId} [props.getRowId] - Optional function to derive row IDs from row data
 * @param {TableEditableProps<TRowId, TColumnId>} [props.editableProps] - Optional props for editable functionality
 *   like adding, moving, or deleting rows and columns
 * @param {string | number} props.maxHeight - The maximum height of the table.
 * @param {string | number} props.rowSelectionPopoverTop - The top offset of the row selection popover.
 * @returns {JSX.Element} The rendered table component
 */
export const TableComponent = observer(function TableComponentInner<
	TData,
	TRowId extends string,
	TColumnId extends string,
>(props: TableComponentProps<TData, TRowId, TColumnId>) {
	const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

	const table = useReactTable({
		data: props.data,
		columns: props.columns,
		getCoreRowModel: getCoreRowModel(),
		enableRowSelection: true,
		onRowSelectionChange: setRowSelection,
		state: {
			rowSelection,
		},
		getRowId: props.getRowId,
		enableColumnResizing: true,
		columnResizeMode: "onChange",
		defaultColumn: {
			minSize: 60,
			maxSize: 800,
		},
		debugTable: IS_DEV,
	});

	useResizeObserver<TColumnId>(table.getState(), (columnId, columnSize) => {
		props.editableProps?.resizeColumn?.(columnId, columnSize);
	});

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const selectedRowIds: Set<TRowId> = useMemo(() => {
		return new Set(
			table.getSelectedRowModel().rows.map((row) => row.id as TRowId),
		);
	}, [rowSelection]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const selectedRowLinks: Set<ResourceLink> | null = useMemo(() => {
		const getRowLink = props.getRowLink;
		if (getRowLink === undefined) return null;
		return new Set(
			table.getSelectedRowModel().rows.map((row) => getRowLink(row.original)),
		);
	}, [rowSelection]);

	// From https://tanstack.com/table/v8/docs/framework/react/examples/column-resizing-performant
	/**
	 * Instead of calling `column.getSize()` on every render for every header
	 * and especially every data cell (very expensive),
	 * we will calculate all column sizes at once at the root table level in a useMemo
	 * and pass the column sizes down as CSS variables to the <table> element.
	 *
	 * Note that this means that column IDs should not have spaces.
	 */
	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const columnSizeVars = useMemo(() => {
		const headers = table.getFlatHeaders();
		const colSizes: { [key: string]: number } = {};
		for (let i = 0; i < headers.length; i++) {
			const header = headers[i];
			colSizes[`--header-${sanitizeForCSSVariableName(header.id)}-size`] =
				header.getSize();
			colSizes[`--col-${sanitizeForCSSVariableName(header.column.id)}-size`] =
				header.column.getSize();
		}
		return colSizes;
	}, [
		table.getState().columnSizingInfo,
		table.getState().columnSizing,
		table.getAllColumns(),
	]);

	// Used to measure scrollbar height for virtualizer
	// The Tanstack examples use a ref, but that causes an issue with our example
	// because the ref does not trigger a rerender of the virtualized container
	// after it is set. To trigger the rerender, we use a state variable.
	const [scrollElement, setScrollElement] = useState<HTMLDivElement | null>(
		null,
	);

	function deleteRowsHandler() {
		table.resetRowSelection();
		props.editableProps?.deleteRows?.(selectedRowIds);
	}
	return (
		<>
			{/* This component is absolute-positioned, so make sure to wrap it in a
				relative container. */}

			{selectedRowIds.size > 0 ? (
				<motion.div
					initial={{ opacity: 0, y: 8 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: 8 }}
					transition={{ duration: 0.15 }}
					className="absolute z-50"
					style={{ top: props.rowSelectionPopoverTop }}
				>
					<div className="flex items-stretch gap-1 rounded-lg border bg-white p-1 shadow-md">
						<div className="whitespace-nowrap rounded-md border-2 border-blue-200 border-dotted bg-blue-50 px-4 py-1 text-blue-500 text-sm">
							{selectedRowIds.size} row
							{selectedRowIds.size > 1 ? "s" : ""} selected
						</div>
						{selectedRowLinks ? (
							<button
								onClick={() => {
									toast.success(
										`${selectedRowLinks.size} link${
											selectedRowLinks.size === 1 ? "" : "s"
										} copied to clipboard`,
									);
									navigator.clipboard.writeText(
										Array.from(
											[...selectedRowLinks].map((path) => {
												const url = new URL(path, window.location.origin).href;
												return url;
											}),
										).join("\n"),
									);
								}}
								className="flex cursor-pointer items-center gap-2 whitespace-nowrap rounded-md px-3 py-1 text-neutral-600 text-sm hover:bg-neutral-100 hover:text-neutral-900"
								type="button"
							>
								<Copy weight="bold" className="shrink-0" />
								Copy links
							</button>
						) : null}
						{props.editableProps?.deleteRows ? (
							<button
								type="button"
								onClick={deleteRowsHandler}
								className="flex cursor-pointer items-center gap-2 rounded-md px-3 py-1 text-neutral-600 text-sm hover:bg-neutral-100 hover:text-neutral-900"
							>
								<Trash weight="bold" className="shrink-0" /> Delete
							</button>
						) : null}
					</div>
				</motion.div>
			) : null}

			<div
				aria-label="table container"
				ref={setScrollElement}
				style={{
					overflow: "auto", //our scrollable table container
					position: "relative", //needed for sticky header
					maxHeight: props.maxHeight ?? undefined,
				}}
			>
				<div
					aria-label="table"
					className="grid w-full"
					style={{
						...columnSizeVars,
					}}
				>
					<div
						aria-label="thead"
						className="sticky top-0 z-10 flex border-y bg-neutral-50 text-neutral-700"
					>
						<HeaderWrapper moveColumn={props.editableProps?.moveColumn}>
							{table
								.getHeaderGroups()
								.map((headerGroup) =>
									headerGroup.headers.map((header) => (
										<TableHeader
											key={header.id}
											header={header}
											isDragPreview={false}
										/>
									)),
								)}
						</HeaderWrapper>
					</div>
					<TableBody
						table={table}
						selectedRowIds={selectedRowIds}
						addRow={props.editableProps?.addRow}
						moveRows={props.editableProps?.moveRows}
						scrollElement={scrollElement}
						// The props for this component are insensitive to column order,
						// so we use a concatenation of all column IDs as the key to
						// force a rerender when the column order changes.
						key={table
							.getAllFlatColumns()
							.map((column) => column.id)
							.join(",")}
					/>
				</div>
			</div>
		</>
	);
});
