import type { AppState } from "@/contexts/app-context/app-context";
import { MinisearchIndex } from "@/lib/minisearch-index";
import type {
	FeedChannelResource,
	FeedItemResource,
	PageResource,
	ResourceRef,
	TableResource,
	UploadResource,
	WebpageResource,
} from "@api/schemas";

type LocalSearchableResourceType =
	| "feed-channel"
	| "feed-item"
	| "page"
	| "upload"
	| "webpage"
	| "table";

export type LocalSearchableResourceRef = Extract<
	ResourceRef,
	{
		type: LocalSearchableResourceType;
	}
>;

export class LocalSearchStore {
	appState: AppState;

	pages: MinisearchIndex<PageResource, "page_id">;
	uploads: MinisearchIndex<UploadResource, "upload_id">;
	feedChannels: MinisearchIndex<FeedChannelResource, "feed_channel_id">;
	feedItems: MinisearchIndex<FeedItemResource, "feed_item_id">;
	webpages: MinisearchIndex<WebpageResource, "webpage_id">;
	tables: MinisearchIndex<TableResource, "table_id">;

	constructor(appState: AppState) {
		this.appState = appState;
		this.pages = new MinisearchIndex({
			sourceMap: this.appState.workspace.pages.map.syncedMap.items,
			idKey: "page_id",
			indexedFields: ["name", "content"],
		});
		this.uploads = new MinisearchIndex({
			sourceMap: this.appState.workspace.uploads.map.syncedMap.items,
			idKey: "upload_id",
			indexedFields: ["filename", "name", "subtitle", "authors"],
		});
		this.feedChannels = new MinisearchIndex({
			sourceMap: this.appState.workspace.feedChannels.map.syncedMap.items,
			idKey: "feed_channel_id",
			indexedFields: ["name", "feed_subtitle", "feed_description"],
		});
		this.feedItems = new MinisearchIndex({
			sourceMap: this.appState.workspace.feedItems.map.syncedMap.items,
			idKey: "feed_item_id",
			indexedFields: ["name", "description", "original_url", "authors"],
		});
		this.webpages = new MinisearchIndex({
			sourceMap: this.appState.workspace.webpages.map.syncedMap.items,
			idKey: "webpage_id",
			indexedFields: ["name", "description", "authors"],
		});
		this.tables = new MinisearchIndex({
			sourceMap: this.appState.workspace.tables.map.syncedMap.items,
			idKey: "table_id",
			indexedFields: ["name"],
		});
	}

	searchResources(
		query: string,
		options: {
			resourceTypes?: LocalSearchableResourceType[];
		} = {},
	): (LocalSearchableResourceRef & { score: number })[] {
		const { resourceTypes } = options;
		const results: (LocalSearchableResourceRef & { score: number })[] = [];

		if (!resourceTypes || resourceTypes.includes("page")) {
			results.push(
				...this.pages.search({ query }).map(({ id, score }) => ({
					resource_id: id,
					type: "page" as const,
					score,
				})),
			);
		}

		if (!resourceTypes || resourceTypes.includes("upload")) {
			results.push(
				...this.uploads.search({ query }).map(({ id, score }) => ({
					resource_id: id,
					type: "upload" as const,
					score,
				})),
			);
		}

		if (!resourceTypes || resourceTypes.includes("feed-channel")) {
			results.push(
				...this.feedChannels.search({ query }).map(({ id, score }) => ({
					resource_id: id,
					type: "feed-channel" as const,
					score,
				})),
			);
		}

		if (!resourceTypes || resourceTypes.includes("feed-item")) {
			results.push(
				...this.feedItems.search({ query }).map(({ id, score }) => ({
					resource_id: id,
					type: "feed-item" as const,
					score,
				})),
			);
		}

		if (!resourceTypes || resourceTypes.includes("webpage")) {
			results.push(
				...this.webpages.search({ query }).map(({ id, score }) => ({
					resource_id: id,
					type: "webpage" as const,
					score,
				})),
			);
		}

		if (!resourceTypes || resourceTypes.includes("table")) {
			results.push(
				...this.tables.search({ query }).map(({ id, score }) => ({
					resource_id: id,
					type: "table" as const,
					score,
				})),
			);
		}

		return results.sort((a, b) => b.score - a.score);
	}
}
