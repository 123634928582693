import { API_ENDPOINT_HTTP } from "@/config";
import { createTableSubscriptionId } from "@/lib/id-generators";
import { makeAutoObservableAbstract } from "@/lib/make-auto-observable-abstract";
import type { LocalWrite } from "@/lib/sync/local-write";
import { ItemMap, MATCH_WRITE_TIMEOUT_MS } from "@/lib/sync/optimistic-map";
import { getTableRecordsRoute } from "@api/fastAPI";
import type {
	Record as ApiRecord,
	DiffEvent,
	RecordId,
	SortField,
	TableId,
	TableUpdateEvent,
	UserSessionId,
} from "@api/schemas";
import type { WatchTableRequest } from "@api/schemas";
import type { useAuth } from "@clerk/clerk-react";
import { EventSource } from "eventsource";
import { runInAction } from "mobx";

type InitState =
	| {
			ready: false;
	  }
	| {
			ready: true;
			totalRows: number;
			cursor: RecordId | null;
	  };

/**
 * RecordSyncedMap
 *
 * A sync stream for our table records. We don't use ElectricSQL for this
 * because we can't use it to sync views to the client.
 */
export class RecordSyncedMap extends ItemMap<ApiRecord, ["link"]> {
	readonly tableId: TableId;
	readonly userSessionId: UserSessionId;

	readonly pageIdx: number;
	readonly pageSize: number;
	readonly sortFields: SortField[];

	/**
	 * Tracks the number of tabs reading from this map. We use a reference
	 * counting mechanism so that when no tabs are reading from this map, we
	 * can stop watching the table.
	 */
	watchers = 0;

	/**
	 * List of recent updates, which can be used to trigger animations and show
	 * a log of changes.
	 *
	 * Consider truncating + persisting this.
	 */
	recentUpdates: TableUpdateEvent[] = [];

	/**
	 * Pending callbacks for transactions, keyed by the transaction ID. When we
	 * receive confirmation of a transaction, we call the corresponding callback
	 * and remove it from the map.
	 *
	 * Note that this setup, as well as the associated `matchWrite` and
	 * `diffHandler` functions, is quite similar to that of `ElectricSyncedMap`.
	 * and `ElectricOptimisticMap`. However, the key difference is that we
	 * sync on the basis of transactions rather than individual writes to rows.
	 * In our tables, it is harder to sync on a per-row basis because on the
	 * backend, each table is really an aggregate view over several underlying
	 * components.
	 */
	private matchingWrites: Map<number, (message: DiffEvent) => void> = new Map();
	eventSource: EventSource | null = null;
	// Can't just check eventSource because getToken is async, causing a
	// possible race condition
	isWatching = false;
	readonly getToken: ReturnType<typeof useAuth>["getToken"];

	// Set once the server has sent an initial reset event
	initState: InitState = {
		ready: false,
	};

	// Retry policy configuration
	retryCount = 0;
	maxRetries = 5;
	retryTimeout: ReturnType<typeof setTimeout> | null = null;
	isReconnecting = false;

	constructor(props: {
		tableId: TableId;
		userSessionId: UserSessionId;
		getToken: ReturnType<typeof useAuth>["getToken"];
		pageIdx: number;
		pageSize: number;
		sortFields: SortField[];
	}) {
		super({ pKeyFields: ["link"] });
		makeAutoObservableAbstract(this);
		this.tableId = props.tableId;
		this.userSessionId = props.userSessionId;
		this.getToken = props.getToken;
		this.pageIdx = props.pageIdx;
		this.pageSize = props.pageSize;
		this.sortFields = props.sortFields;
	}

	/**
	 * Callback for registering a watch function for confirmation of server-side
	 * persistence.
	 */
	matchWrite = (
		write: LocalWrite<ApiRecord, ["link"]>,
		removeWrite: () => void,
	) => {
		const matchFn = (event: DiffEvent) => {
			return event.write_id === write.transactionId;
		};

		const timeout = setTimeout(() => {
			removeWrite();
			this.matchingWrites.delete(write.index);
			console.error("Write matching timed out");
		}, MATCH_WRITE_TIMEOUT_MS);

		this.matchingWrites.set(write.index, (message) => {
			if (matchFn(message)) {
				clearTimeout(timeout);
				this.matchingWrites.delete(write.index);
				removeWrite();
			}
		});
	};

	/**
	 * Refresh the table's records from the server once.
	 *
	 * Useful when you need the table's latest data but don't need to
	 * live-update it.
	 *
	 * For example, when modifying a relationship cell, we need to display the
	 * foreign table's records, but it's okay to not have live updates.
	 *
	 * We don't want the refresh to succeed if we're already watching the
	 * table, because then the watch should manage the records.
	 */
	async refresh() {
		if (this.eventSource !== null) {
			return;
		}
		const response = await getTableRecordsRoute({
			table_id: this.tableId,
			page_idx: this.pageIdx,
			page_size: this.pageSize,
		});
		runInAction(() => {
			if (this.eventSource !== null) {
				return;
			}
			this.items = new Map(
				response.data.records.map((record) => [
					this.extractPkeyStr(record),
					record,
				]),
			);
		});
	}

	/**
	 * Set up the EventSource to watch table changes
	 */
	private setupEventSource() {
		this.getToken().then((token) => {
			// Clean up existing event source if any
			if (this.eventSource !== null) {
				this.eventSource.close();
				this.eventSource = null;
			}

			const request: WatchTableRequest = {
				table_id: this.tableId,
				table_subscription_id: createTableSubscriptionId(),
				page_idx: this.pageIdx,
				page_size: this.pageSize,
				sort_fields: this.sortFields,
			};

			this.eventSource = new EventSource(`${API_ENDPOINT_HTTP}/tables/watch`, {
				fetch: (url, init) =>
					fetch(url, {
						...init,
						// By default, EventSource only supports GET requests,
						// but we use POST so we can use a request body for
						// convenience.
						method: "POST",
						headers: {
							...init?.headers,
							Authorization: `Bearer ${token}`,
							"Content-Type": "application/json",
						},
						body: JSON.stringify(request),
					}),
			});

			this.eventSource.addEventListener("diff", this.diffHandler);

			// Reset retry count on successful connection
			this.eventSource.addEventListener("open", () => {
				this.retryCount = 0;
				this.isReconnecting = false;
			});

			// Enhanced error handling with retry logic
			this.eventSource.addEventListener("error", this.handleEventSourceError);
		});
	}

	/**
	 * Handle event source errors and implement retry logic with exponential backoff
	 */
	private handleEventSourceError = (event: Event) => {
		console.error("Error watching table", event);

		// Only attempt to reconnect if we're still watching and not already reconnecting
		if (!this.isWatching || this.isReconnecting) {
			return;
		}

		this.isReconnecting = true;

		// Clean up existing event source
		if (this.eventSource !== null) {
			this.eventSource.close();
			this.eventSource = null;
		}

		if (this.retryCount < this.maxRetries) {
			const backoffTime = Math.min(1000 * 2 ** this.retryCount, 30000); // Exponential backoff with max of 30 seconds

			// Clear any existing timeout
			if (this.retryTimeout !== null) {
				clearTimeout(this.retryTimeout);
			}

			// Set up retry timeout
			this.retryTimeout = setTimeout(() => {
				this.retryCount++;
				this.setupEventSource();
			}, backoffTime);
		} else {
			console.error(`Failed to reconnect after ${this.maxRetries} attempts`);
			this.isReconnecting = false;
			// At this point we could emit an event that consumers can listen to
			// or implement additional fallback strategies
		}
	};

	/**
	 * Handles raw events received from the EventSource.
	 */
	diffHandler = (rawEvent: MessageEvent) => {
		// TOOD(John): validate?
		const event: TableUpdateEvent = JSON.parse(rawEvent.data);

		runInAction(() => {
			if (event.table_id !== this.tableId) {
				console.error("Received diff event for table that we're not watching");
				return;
			}

			switch (event.event_type) {
				case "reset": {
					this.items.clear();
					for (const record of event.new_records) {
						this.set(record.link, record);
					}
					if (!this.initState.ready) {
						this.initState = {
							ready: true,
							totalRows: event.total_rows,
							cursor: event.cursor,
						};
					}
					break;
				}
				case "diff": {
					for (const op of event.ops) {
						switch (op.op) {
							case "insert": {
								this.set(op.record.link, op.record);
								break;
							}

							case "update": {
								const current = this.get(op.record_link);
								if (current === undefined) {
									console.error("Tried to update item that doesn't exist");
									return;
								}
								this.set(op.record_link, {
									...current,
									cell_values: {
										...current.cell_values,
										...op.updated_cell_values,
									},
									...(op.updated_record_order && {
										order: op.updated_record_order,
									}),
								});
								break;
							}

							case "delete": {
								this.delete(op.record_link);
								break;
							}
						}
					}

					// Once the server-authoritative map has been updated, check for
					// pending writes with a transaction ID matching that of the change
					// event. If there are any, clear them.

					// Reset events don't contain write IDs because they're not
					// generated in response to a particular transaction.
					for (const tryMatchFn of this.matchingWrites.values()) {
						tryMatchFn(event);
					}
				}
			}
		});

		// Once all changes have been processed, add the event to the recent
		// updates list.
		this.recentUpdates.push(event);
	};

	/**
	 * Start watching the table. Called on table tab mount.
	 */
	subscribe() {
		this.watchers++;
		if (this.isWatching) {
			return;
		}
		this.isWatching = true;
		this.retryCount = 0; // Reset retry count on fresh start
		this.setupEventSource();
	}

	/**
	 * Stop watching the table. Called on table tab unmount.
	 */
	unsubscribe() {
		this.watchers--;
		if (this.watchers > 0) {
			// Do nothing if there are still watchers
			return;
		}
		// Otherwise, close the event source and reset the reconnect state

		this.isWatching = false;
		this.isReconnecting = false;

		// Clear any pending retry timeout
		if (this.retryTimeout !== null) {
			clearTimeout(this.retryTimeout);
			this.retryTimeout = null;
		}

		if (this.eventSource === null) {
			return;
		}

		this.eventSource.close();
		this.eventSource = null;

		// Since the table isn't receiving updates, we'll request a reset event
		// on next load.
		this.initState = {
			ready: false,
		};
	}
}
