import { useFormattedDuration } from "@/components/layout/right-sidebar/use-elapsed-time";
import { Tooltip, TooltipContent } from "@/components/ui/tooltip";
import { TooltipTrigger } from "@/components/ui/tooltip";
import { TooltipProvider } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import dayjs from "dayjs";

type EndStatusCode = "ok" | "error";

export interface EndStatus {
	endTime: string | null;
	code: EndStatusCode;
	message?: string;
}

export const StatusBadge = ({
	startTime,
	endStatus,
}: {
	startTime: string;
	endStatus: EndStatus | null;
}) => {
	const startTimeDayjs = dayjs(startTime);
	const endTimeDayjs = endStatus?.endTime ? dayjs(endStatus.endTime) : null;
	const formattedDuration = useFormattedDuration(startTimeDayjs, endTimeDayjs);

	let colors = "bg-yellow-50 text-yellow-700";
	if (endStatus) {
		switch (endStatus.code) {
			case "ok":
				colors = "bg-green-50 text-green-700";
				break;
			case "error":
				colors = "bg-red-50 text-red-700";
				break;
		}
	}

	const badge = (
		<span
			className={cn(
				"h-fit flex-none rounded-full px-1 py-0.5 font-mono text-xs",
				colors,
			)}
		>
			{formattedDuration}
		</span>
	);

	if (endStatus?.message) {
		return (
			<TooltipProvider>
				<Tooltip>
					<TooltipTrigger asChild>{badge}</TooltipTrigger>
					<TooltipContent>{endStatus.message}</TooltipContent>
				</Tooltip>
			</TooltipProvider>
		);
	}

	return badge;
};
