/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */

/**
 * The mimetype (pdf, epub) of the uploaded file.
 */
export type UploadMimetype = typeof UploadMimetype[keyof typeof UploadMimetype];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UploadMimetype = {
  pdf: 'pdf',
  epub: 'epub',
} as const;
