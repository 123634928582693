import { API_ENDPOINT_HTTP } from "@/config";
import type { AppState } from "@/contexts/app-context/app-context";
import { getStepEndStatus } from "@/contexts/assistant/stores/step-store";
import openapiHashes from "@/generated/openapi-hashes.json";
import { ElectricOptimisticMap } from "@/lib/sync/electric";
import { sortByOrder } from "@/lib/utils";
import type { AssistantSessionId, Span, SpanId, StepId } from "@api/schemas";
import { makeAutoObservable } from "mobx";
import { computedFn } from "mobx-utils";
import type { Result } from "neverthrow";

export class SpanStore {
	appState: AppState;
	items: ElectricOptimisticMap<Span, ["span_id"], "write_id">;

	constructor(appState: AppState) {
		makeAutoObservable(this);
		this.appState = appState;
		this.items = new ElectricOptimisticMap<Span, ["span_id"], "write_id">({
			shapeUrl: `${API_ENDPOINT_HTTP}/shapes/spans`,
			pKeyFields: ["span_id"],
			writeIdField: "write_id",
			shapeHash: openapiHashes.Span,
			getBearerToken: this.appState.getTokenOrThrow,
		});
	}

	getSpanById(id: SpanId): Result<Span, Error> {
		return this.items.get(id);
	}

	getForAssistantSessionId = computedFn(
		(assistantSessionId: AssistantSessionId): Span[] => {
			const spans = Array.from(this.items.values()).flatMap((span) => {
				if (span.assistant_session_id === assistantSessionId) {
					return span;
				}
				return [];
			});
			return sortByOrder(spans, (span) => span.start_time);
		},
	);

	/**
	 * Gets any spans that occurred within the step's session and time range.
	 */
	getForStep = computedFn((stepId: StepId): Span[] => {
		const stepResult = this.appState.stepStore.getById(stepId);
		if (stepResult.isErr()) {
			return [];
		}
		const step = stepResult.value;
		const stepEndTime = getStepEndStatus(step)?.endTime ?? null;
		const spans = Array.from(this.items.values()).filter(
			(span) =>
				span.assistant_session_id === step.assistant_session_id &&
				span.start_time >= step.start_time &&
				(stepEndTime === null ||
					span.end_status?.end_time === undefined ||
					span.end_status.end_time <= stepEndTime),
		);
		return sortByOrder(spans, (span) => span.start_time);
	});
}
