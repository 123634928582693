import {
	ADD_COLUMN_ID,
	LINK_COLUMN_ID,
	SELECT_COLUMN_ID,
	sanitizeForCSSVariableName,
} from "@/components/table/utils";
import { cn } from "@/lib/utils";
import { type Header, flexRender } from "@tanstack/react-table";

// TODO(John): think about what role this has
export type TableHeaderProps<TData> = {
	header: Header<TData, unknown>;
};

export const TableHeader = <TData,>(
	props: TableHeaderProps<TData> & { isDragPreview: boolean },
) => {
	const isPinned = props.header.column.getIsPinned();
	const isResizing = props.header.column.getIsResizing();

	const canResize =
		props.header.column.id !== ADD_COLUMN_ID &&
		props.header.column.id !== LINK_COLUMN_ID &&
		props.header.column.id !== SELECT_COLUMN_ID;

	return (
		<div
			aria-label="th"
			className={cn(
				"relative z-10 h-8 shrink-0 whitespace-nowrap p-0 text-left font-semibold text-sm",
				props.isDragPreview && "opacity-75",
			)}
			style={{
				width: `calc(var(--header-${sanitizeForCSSVariableName(
					props.header.id,
				)}-size) * 1px)`,
				position: isPinned ? "sticky" : "relative",
				zIndex: isPinned ? 10 : 1,
				left: isPinned ? `${props.header.column.getStart("left")}px` : 0,
			}}
		>
			{props.header.isPlaceholder
				? null
				: flexRender(
						props.header.column.columnDef.header,
						props.header.getContext(),
					)}
			{/* Resize handle */}
			{canResize ? (
				<button
					type="button"
					{...{
						onMouseDown: (e) => {
							// Prevent the header from also being dragged
							e.stopPropagation();
							props.header.getResizeHandler()(e);
						},
						onTouchStart: (e) => {
							// Prevent the header from also being dragged
							e.stopPropagation();
							props.header.getResizeHandler()(e);
						},
						className: cn(
							"h-full cursor-ew-resize absolute transition-opacity duration-100 ease-in-out right-0 z-20 w-1 top-0 bg-neutral-500 opacity-0 hover:opacity-100",
							isResizing && "opacity-100",
						),
					}}
				/>
			) : null}
		</div>
	);
};
