import { BaseSortManager } from "@/components/table/sort-popover";
import { isFieldPrimary } from "@/contexts/tables/stores/field-store";
import { BaseTabState } from "@/contexts/tabs/base-tab-state";
import type { Tab } from "@/contexts/tabs/tabs-context";
import { createQueryId } from "@/lib/id-generators";
import { makeAutoObservableAbstract } from "@/lib/make-auto-observable-abstract";
import type { ActionResult } from "@/lib/sync/action-executor";
import { runQueryRoute } from "@api/fastAPI";
import type {
	AggregateField,
	AndFilterGroup,
	DataType,
	Field,
	FieldId,
	Filter,
	FilterExpression,
	GroupByField,
	GroupByQuery,
	OrFilterGroup,
	Query,
	QueryId,
	QueryResult,
	SelectQuery,
	SelectQueryField,
	SortField,
	TableId,
	UserTableResource,
} from "@api/schemas";
import { ChartBar } from "@phosphor-icons/react";
import { produce } from "immer";
import { toJS } from "mobx";
import { z } from "zod";

export const DEFAULT_WHERE_EXPRESSION: AndFilterGroup = {
	type: "and",
	expressions: [],
};

// 1) The top-level schema that sets defaults for missing fields
//    That way, if you have no "sql_query" param, it defaults to ""
export const querySearchSchemaRaw = z
	.object({
		view_mode: z.enum(["sql_query", "query_builder"]).default("query_builder"),
		query_builder_view_type: z.enum(["select", "group_by"]).default("select"),

		// For query_builder mode
		from_table_id: z.string().optional().nullable(),
		where: z
			.string()
			.optional()
			.default(JSON.stringify(DEFAULT_WHERE_EXPRESSION)),

		selected_fields: z.string().optional().default("[]"), // for "select" mode
		grouping_fields: z.string().optional().default("[]"), // for "group_by"
		aggregate_fields: z.string().optional().default("[]"),
		sort_fields: z.string().optional().default("[]"), // for sorting fields

		// For sql_query mode
		sql_query: z.string().optional().default(""), // no error if missing

		page_size: z.number().optional().default(100),
		page_idx: z.number().optional().default(0),
	})
	.default({});

export type QuerySearchParams = z.infer<typeof querySearchSchemaRaw>;

export interface ParsedQueryParams {
	viewMode: "sql_query" | "query_builder";
	queryBuilderViewType: "select" | "group_by";

	fromTableId: TableId | null;
	where: AndFilterGroup | OrFilterGroup;

	selectedFields: SelectQueryField[];
	groupingFields: GroupByField[];
	aggregateFields: AggregateField[];

	sqlQuery: string;

	sortFields: SortField[];
	pageSize: number;
	pageIdx: number;
}

// TODO(John): fix!
export const getDataTypeOperators = (dataType: DataType): Filter["type"][] => {
	// For now, I won't handle arrays.
	if (dataType.array_depth > 0) {
		return [];
	}
	switch (dataType.scalar_type) {
		case "text":
			return ["text_equals", "text_not_equals", "text_contains"];
		case "real":
			return ["number_equals", "number_not_equals"];
		case "timestamptz":
			return ["datetime_equals", "datetime_not_equals"];
		case "boolean":
			return ["boolean_equals", "boolean_not_equals"];
		case "record_link":
			return [];
		// TODO(John): replace with the correct filters
		case "select_option":
			return ["text_equals", "text_not_equals"];
	}
};

// Copied from server/server/postgres/orm/integrations/tables/computed_tables.py
export const SELECT_VIEW_PRIMARY_COLUMN = "Original Record";

export class QuerySortManager extends BaseSortManager {
	queryState: QueryTabState;

	constructor(queryState: QueryTabState) {
		super();
		this.queryState = queryState;
	}

	get sortFields() {
		return this.queryState.parsedQueryParams.sortFields;
	}

	/**
	 * Adds a sort field to the query.
	 */
	public addSortField(fieldId: FieldId) {
		if (!this.queryState.isQueryBuilderMode) return;

		const newSortFields = [...this.sortFields];
		// Don't add duplicates
		if (!newSortFields.some((sf) => sf.field_id === fieldId)) {
			newSortFields.push({
				field_id: fieldId,
				direction: "asc", // Default to ascending
			});

			this.queryState.parsedQueryParams.sortFields = newSortFields;
		}
	}

	/**
	 * Removes a sort field by its target_field_name.
	 */
	public removeSortField(fieldId: string) {
		if (!this.queryState.isQueryBuilderMode) return;

		this.queryState.parsedQueryParams.sortFields = this.sortFields.filter(
			(sf) => sf.field_id !== fieldId,
		);
	}

	/**
	 * Toggle the direction (asc/desc) of a sort field.
	 */
	public updateSortDirection(index: number, direction: SortField["direction"]) {
		if (!this.queryState.isQueryBuilderMode) return;

		this.queryState.parsedQueryParams.sortFields[index].direction = direction;
	}

	/**
	 * Updates the field_id of a sort field.
	 */
	public updateSortField(index: number, fieldId: FieldId) {
		if (!this.queryState.isQueryBuilderMode) return;

		this.queryState.parsedQueryParams.sortFields[index].field_id = fieldId;
	}

	/**
	 * Moves a sort field from one index to another.
	 */
	public moveSortField(fromIndex: number, toIndex: number) {
		if (!this.queryState.isQueryBuilderMode) return;

		if (
			fromIndex >= 0 &&
			fromIndex < this.sortFields.length &&
			toIndex >= 0 &&
			toIndex < this.sortFields.length &&
			fromIndex !== toIndex
		) {
			// Remove item from current position
			const [removed] = this.sortFields.splice(fromIndex, 1);
			// Insert at new position
			this.sortFields.splice(toIndex, 0, removed);
		}
	}

	/**
	 * Clears all sort fields.
	 */
	public clearSortFields() {
		if (!this.queryState.isQueryBuilderMode) return;

		// Set to empty array
		this.queryState.parsedQueryParams.sortFields = [];
	}

	/**
	 * Lists all the available fields that can be added as another sort.
	 *
	 * We only include fields that are included in the query, and that are not
	 * already used as sort fields.
	 */
	public get availableSortFieldIds(): string[] {
		const selectFieldTargetNames = this.queryState.selectedFieldsByTargetName;
		const groupingFieldTargetNames = this.queryState.groupingFieldsByTargetName;
		const aggregateFieldTargetNames =
			this.queryState.aggregateFieldsByTargetName;

		const availableFieldTargetNames = [
			...selectFieldTargetNames.values(),
			...groupingFieldTargetNames.values(),
			...aggregateFieldTargetNames.values(),
		].filter(
			(field) =>
				!this.sortFields.some(
					(sortField) => sortField.field_id === field.target_field_name,
				),
		);

		return availableFieldTargetNames.map((field) => field.target_field_name);
	}
}

export class QueryTabState extends BaseTabState {
	parsedQueryParams: ParsedQueryParams;
	draftSqlQuery = "";

	result:
		| {
				state: "loading";
				query_id: QueryId;
		  }
		| {
				state: "success";
				query_id: QueryId;
				result: QueryResult;
		  }
		| {
				state: "error";
				error: Error;
		  }
		| {
				state: "empty";
				message: string;
		  } = {
		state: "empty",
		message: "Select a source table to begin",
	};

	sortState: QuerySortManager;

	constructor(tab: Tab, parsed: ParsedQueryParams) {
		super(tab);
		makeAutoObservableAbstract(this);

		this.parsedQueryParams = parsed;
		this.draftSqlQuery = parsed.sqlQuery;
		this.sortState = new QuerySortManager(this);
	}

	public updateQueryState(newQueryState: ParsedQueryParams) {
		this.parsedQueryParams = newQueryState;
		this.draftSqlQuery = newQueryState.sqlQuery;
	}

	/**
	 * Replaces the entire "where" group with a new group.
	 */
	public updateWhere(newWhere: AndFilterGroup | OrFilterGroup) {
		if (this.parsedQueryParams.viewMode !== "query_builder") return;
		this.parsedQueryParams.where = newWhere;
	}

	/**
	 * Adds a new filter expression into the existing "where" group.
	 */
	public addFilter(newExpression: FilterExpression) {
		if (this.parsedQueryParams.viewMode !== "query_builder") return;

		// e.g. cast or narrow:
		const currentWhere = this.parsedQueryParams.where;
		const updatedWhere = {
			...currentWhere,
			expressions: [...currentWhere.expressions, newExpression],
		};
		this.updateWhere(updatedWhere);
	}

	/**
	 * Removes a filter expression at a particular index (for a top-level group).
	 * If you need recursion for nested groups, you'd do that more carefully.
	 */
	public removeFilterAtIndex(index: number) {
		if (this.parsedQueryParams.viewMode !== "query_builder") return;
		const currentWhere = this.parsedQueryParams.where;
		const updatedWhere = {
			...currentWhere,
			expressions: currentWhere.expressions.filter((_, i) => i !== index),
		};
		this.updateWhere(updatedWhere);
	}

	/**
	 * Change the group type from "and" to "or" (or vice versa).
	 * For a top-level group only. For nested groups, do recursion or separate methods.
	 */
	public setGroupType(newType: "and" | "or") {
		if (this.parsedQueryParams.viewMode !== "query_builder") return;
		const currentWhere = this.parsedQueryParams.where;
		const updatedWhere = {
			...currentWhere,
			type: newType,
		};
		this.updateWhere(updatedWhere);
	}

	public updateFilterField(
		filterIndexPath: number[], // if nested, might be more than one index
		newFieldId: FieldId,
		newOperator: Filter["type"],
	) {
		if (this.parsedQueryParams.viewMode !== "query_builder") return;
		// 1) read current 'where'
		const currentWhere = this.parsedQueryParams.where as
			| AndFilterGroup
			| OrFilterGroup;

		// 2) create a deep copy with that filter's field updated
		const updatedWhere = produce(currentWhere, (draft) => {
			// find the filter at filterIndexPath, e.g. for a top-level expression:
			const index = filterIndexPath[0];
			const filter = draft.expressions[index] as Filter; // or do a check first
			filter.field_id = newFieldId;
			filter.type = newOperator;
			filter.value = null; // perhaps we reset the value
		});

		// 3) navigate with the new 'where'
		this.parsedQueryParams.where = updatedWhere;
	}

	public updateFilterOperator(
		filterIndexPath: number[],
		newOperator: Filter["type"],
	) {
		if (this.parsedQueryParams.viewMode !== "query_builder") return;
		const currentWhere = this.parsedQueryParams.where as
			| AndFilterGroup
			| OrFilterGroup;

		const updatedWhere = produce(currentWhere, (draft) => {
			const index = filterIndexPath[0];
			const filter = draft.expressions[index] as Filter;
			filter.type = newOperator;
		});

		this.parsedQueryParams.where = updatedWhere;
	}

	public updateFilterValue(
		filterIndexPath: number[],
		newValue: Filter["value"],
	) {
		if (this.parsedQueryParams.viewMode !== "query_builder") return;
		const currentWhere = this.parsedQueryParams.where as
			| AndFilterGroup
			| OrFilterGroup;

		const updatedWhere = produce(currentWhere, (draft) => {
			const index = filterIndexPath[0];
			const filter = draft.expressions[index] as Filter;
			filter.value = newValue;
		});

		this.parsedQueryParams.where = updatedWhere;
	}

	public toggleSelectedField(fieldId: FieldId) {
		// Only do something if in query_builder + select mode
		if (
			this.parsedQueryParams.viewMode !== "query_builder" ||
			this.parsedQueryParams.queryBuilderViewType !== "select"
		) {
			return;
		}

		const selectedField = this.selectedFieldsBySourceId.get(fieldId);

		if (selectedField) {
			this.parsedQueryParams.selectedFields =
				this.parsedQueryParams.selectedFields.filter(
					(field) => field.source_field_id !== fieldId,
				);
			// Remove the field from the sort, if it is being used
			this.sortState.removeSortField(selectedField.target_field_name);
		} else {
			let newName =
				this.fromTableFields.get(fieldId)?.field.name ?? "Selected field";
			let i = 1;
			while (this.allTargetFieldNames.has(newName)) {
				newName = `${newName} (${i})`;
				i++;
			}

			this.parsedQueryParams.selectedFields.push({
				source_field_id: fieldId,
				target_field_name: newName,
			});
		}
	}

	public toggleAllFields() {
		// Only do something if in query_builder + select mode
		if (
			this.parsedQueryParams.viewMode !== "query_builder" ||
			this.parsedQueryParams.queryBuilderViewType !== "select"
		) {
			return;
		}

		// Get all field IDs from the fromTable
		const allFieldIds = Array.from(this.fromTableFields.keys());

		// If all fields are already selected, deselect all
		if (
			allFieldIds.length === this.parsedQueryParams.selectedFields.length &&
			allFieldIds.every((fieldId) => this.selectedFieldsBySourceId.has(fieldId))
		) {
			this.parsedQueryParams.selectedFields = [];
			// Also clear any sort fields as they might reference now-removed fields
			this.sortState.clearSortFields();
			return;
		}

		// Otherwise, select all fields that aren't already selected
		const newSelectedFields = [...this.parsedQueryParams.selectedFields];
		const usedNames = new Set<string>();

		for (const fieldId of allFieldIds) {
			// Skip if already selected
			if (this.selectedFieldsBySourceId.has(fieldId)) {
				continue;
			}

			// Generate a unique target field name
			let newName =
				this.fromTableFields.get(fieldId)?.field.name ?? "Selected field";
			let i = 1;
			while (usedNames.has(newName)) {
				newName = `${newName} (${i})`;
				i++;
			}

			// Add the field to the selected fields
			newSelectedFields.push({
				source_field_id: fieldId,
				target_field_name: newName,
			});
			usedNames.add(newName);
		}

		this.parsedQueryParams.selectedFields = newSelectedFields;
	}

	/**
	 * Called whenever the user types in the SQL textarea.
	 * We store it ephemeral for now, so we don't spam the URL.
	 */
	public setDraftSqlQuery(newText: string) {
		this.draftSqlQuery = newText;
	}

	/**
	 * When user clicks "Run query," we push the ephemeral text into the URL,
	 */
	public applySqlQuery() {
		this.parsedQueryParams.sqlQuery = this.draftSqlQuery;
	}

	get isQueryBuilderMode() {
		return this.parsedQueryParams.viewMode === "query_builder";
	}

	get isSelectMode() {
		return (
			this.isQueryBuilderMode &&
			this.parsedQueryParams.queryBuilderViewType === "select"
		);
	}

	get isGroupByMode() {
		return (
			this.isQueryBuilderMode &&
			this.parsedQueryParams.queryBuilderViewType === "group_by"
		);
	}

	get selectedFieldsBySourceId(): Map<FieldId, SelectQueryField> {
		return new Map(
			this.parsedQueryParams.selectedFields.map((field) => [
				field.source_field_id,
				field,
			]),
		);
	}

	get selectedFieldsByTargetName(): Map<string, SelectQueryField> {
		return new Map(
			this.parsedQueryParams.selectedFields.map((field) => [
				field.target_field_name,
				field,
			]),
		);
	}

	get groupingFields(): GroupByField[] {
		if (this.isGroupByMode) {
			return this.parsedQueryParams.groupingFields;
		}
		return [];
	}

	get groupingFieldsBySourceId(): Map<FieldId, GroupByField> {
		return new Map(
			this.groupingFields.map((field) => [field.source_field_id, field]),
		);
	}

	get groupingFieldsByTargetName(): Map<string, GroupByField> {
		return new Map(
			this.groupingFields.map((field) => [field.target_field_name, field]),
		);
	}

	get aggregateFields(): AggregateField[] {
		if (this.isGroupByMode) {
			return this.parsedQueryParams.aggregateFields;
		}
		return [];
	}

	get aggregateFieldsByTargetName(): Map<string, AggregateField> {
		return new Map(
			this.aggregateFields.map((field) => [field.target_field_name, field]),
		);
	}

	get allTargetFieldNames(): Set<string> {
		return new Set([
			...this.aggregateFieldsByTargetName.keys(),
			...this.groupingFieldsByTargetName.keys(),
			...this.selectedFieldsByTargetName.keys(),
		]);
	}

	// This is a helper function to get the fields from the "from table"
	get fromTableFields(): Map<FieldId, { field: Field; dataType: DataType }> {
		if (!this.parsedQueryParams.fromTableId) {
			throw new Error("fromTableFields: No from table id");
		}

		const fields =
			this.tab.tabStore.appState.workspace.fields.getFieldsByTableId(
				this.parsedQueryParams.fromTableId,
			);
		const fieldsWithDataType = new Map<
			FieldId,
			{ field: Field; dataType: DataType }
		>();
		for (const [fieldId, field] of fields) {
			this.tab.tabStore.appState.workspace.fields.getDataType(fieldId).match(
				(dataType) => {
					fieldsWithDataType.set(fieldId, { field, dataType });
				},
				(error) => {
					console.error(error);
				},
			);
		}
		return fieldsWithDataType;
	}

	/**
	 * Build a "query" object suitable for runQueryRoute, based on the internal union state.
	 */
	private getComputedQuery(): Query {
		// If we're in sql_query mode, we only need to pass the raw query string
		if (this.parsedQueryParams.viewMode === "sql_query") {
			return {
				sql_query: this.parsedQueryParams.sqlQuery,
			};
		}

		// Otherwise, we are in query_builder mode => from_table_id is required
		if (!this.parsedQueryParams.fromTableId) {
			throw new Error("No 'fromTableId' found for query_builder mode");
		}

		// handle select vs group_by
		if (this.parsedQueryParams.queryBuilderViewType === "select") {
			// The "where" property is an AndFilterGroup or OrFilterGroup
			// The "selectedFields" is a Set<FieldId>
			const selectDef: SelectQuery = {
				from_table_id: this.parsedQueryParams.fromTableId,
				where: toJS(this.parsedQueryParams.where) as
					| AndFilterGroup
					| OrFilterGroup,
				selected_fields: Array.from(this.parsedQueryParams.selectedFields),
			};
			return selectDef;
		}
		// group_by mode
		const groupByDef: GroupByQuery = {
			from_table_id: this.parsedQueryParams.fromTableId,
			where: toJS(this.parsedQueryParams.where) as
				| AndFilterGroup
				| OrFilterGroup,
			grouping_fields: toJS(this.parsedQueryParams.groupingFields),
			aggregate_fields: toJS(this.parsedQueryParams.aggregateFields),
		};
		return groupByDef;
	}

	get defaultNewFilter(): FilterExpression {
		const firstPrimaryField = Array.from(this.fromTableFields.values()).find(
			({ field }) => isFieldPrimary(field),
		);

		if (!firstPrimaryField) {
			throw new Error("No primary fields found");
		}
		const dataType = this.tab.tabStore.appState.workspace.fields.getDataType(
			firstPrimaryField.field.field_id,
		);

		if (dataType.isErr()) {
			throw new Error("Could not get data type for primary field");
		}

		const operators = getDataTypeOperators(dataType.value);

		return {
			type: operators[0],
			field_id: firstPrimaryField.field.field_id,
			value: null,
			array_op: null,
		};
	}

	public setQueryBuilderSubMode(mode: "select" | "group_by") {
		// Only relevant if we're in query_builder mode at all
		if (this.parsedQueryParams.viewMode !== "query_builder") return;

		this.parsedQueryParams.queryBuilderViewType = mode;
		this.parsedQueryParams.sortFields = [];
	}

	public toggleGroupByField(fieldId: FieldId) {
		if (
			this.parsedQueryParams.viewMode !== "query_builder" ||
			this.parsedQueryParams.queryBuilderViewType !== "group_by"
		) {
			return;
		}

		const groupingField = this.groupingFieldsBySourceId.get(fieldId);
		let newGroupingFields: GroupByField[];

		if (groupingField) {
			// Remove the field if it already exists
			newGroupingFields = [...this.parsedQueryParams.groupingFields].filter(
				(field) => field.source_field_id !== fieldId,
			);
			// Remove the field from the sort, if it is being used
			this.sortState.removeSortField(groupingField.target_field_name);
		} else {
			// Add the field if it doesn't exist
			newGroupingFields = [...this.parsedQueryParams.groupingFields];
			let newName =
				this.fromTableFields.get(fieldId)?.field.name ?? "Grouped field";
			let i = 1;
			while (this.allTargetFieldNames.has(newName)) {
				newName = `${newName} (${i})`;
				i++;
			}
			newGroupingFields.push({
				source_field_id: fieldId,
				target_field_name: newName,
			});
		}
		this.parsedQueryParams.groupingFields = newGroupingFields;
	}

	public addAggregateField(field: AggregateField) {
		if (
			this.parsedQueryParams.viewMode !== "query_builder" ||
			this.parsedQueryParams.queryBuilderViewType !== "group_by"
		) {
			return;
		}

		let newName = field.target_field_name;
		let i = 1;
		while (this.allTargetFieldNames.has(newName)) {
			newName = `${field.target_field_name} (${i})`;
			i++;
		}

		this.parsedQueryParams.aggregateFields.push({
			...field,
			target_field_name: newName,
		});
	}

	public removeAggregateField(index: number) {
		if (
			this.parsedQueryParams.viewMode !== "query_builder" ||
			this.parsedQueryParams.queryBuilderViewType !== "group_by"
		) {
			return;
		}

		if (index >= this.aggregateFields.length) {
			return;
		}

		// Remove the field from the sort, if it is being used
		this.sortState.removeSortField(
			this.aggregateFields[index].target_field_name,
		);

		const newAggregates = [...this.parsedQueryParams.aggregateFields];
		newAggregates.splice(index, 1);
		this.parsedQueryParams.aggregateFields = newAggregates;
	}

	public updateAggregateField(index: number, field: AggregateField) {
		if (
			this.parsedQueryParams.viewMode !== "query_builder" ||
			this.parsedQueryParams.queryBuilderViewType !== "group_by"
		) {
			return;
		}

		if (index >= this.aggregateFields.length) {
			return;
		}

		const oldName = this.aggregateFields[index].target_field_name;
		let newName = field.target_field_name;

		if (oldName !== newName) {
			// In case the field's name is already taken, we'll add a number to the end
			let i = 1;
			while (this.allTargetFieldNames.has(newName)) {
				newName = `${newName} (${i})`;
				i++;
			}

			// If the field is used in sorting, update its field_id in the sort fields
			const sortFieldIndex = this.sortState.sortFields.findIndex(
				(sortField) => sortField.field_id === oldName,
			);

			if (sortFieldIndex !== -1) {
				// Update the sort field to use the new field name
				this.sortState.updateSortField(sortFieldIndex, newName as FieldId);
			}
		}

		const newAggregates = [...this.parsedQueryParams.aggregateFields];
		newAggregates[index] = {
			...field,
			target_field_name: newName,
		};

		this.parsedQueryParams.aggregateFields = newAggregates;
	}

	async createComputedTable(): Promise<ActionResult<
		{
			newTableResource: UserTableResource;
		},
		void
	> | null> {
		if (this.result.state !== "success") {
			throw new Error("You must select a table!");
		}
		return await this.tab.tabStore.appState.workspace.tables.createComputedTable(
			{
				query: this.getComputedQuery(),
			},
		);
	}

	get head() {
		return {
			icon: ChartBar,
			label: "Query",
		};
	}

	runQuery() {
		if (this.parsedQueryParams.viewMode === "sql_query") {
			// If there's a non-empty sql query, let's run it
			if (this.parsedQueryParams.sqlQuery.trim() === "") {
				this.result = {
					state: "empty",
					message: "No SQL query provided",
				};
				return;
			}
		}
		if (this.parsedQueryParams.viewMode === "query_builder") {
			if (!this.parsedQueryParams.fromTableId) {
				this.result = {
					state: "empty",
					message: "No table selected",
				};
				return;
			}

			if (this.parsedQueryParams.queryBuilderViewType === "group_by") {
				if (this.parsedQueryParams.groupingFields.length === 0) {
					this.result = {
						state: "empty",
						message: "No grouping fields selected",
					};
					return;
				}
			}
		}

		const newQueryId = createQueryId();

		this.result = {
			state: "loading",
			query_id: newQueryId,
		};

		runQueryRoute({
			query: this.getComputedQuery(),
			page_size: this.parsedQueryParams.pageSize,
			page_idx: this.parsedQueryParams.pageIdx,
			sort_fields: this.sortState.sortFields,
			query_id: newQueryId,
		})
			.then((response) => {
				// Avoid race condition where we run several queries in a row,
				// but earlier ones might overwrite later ones
				if (response.data.query_id !== newQueryId) {
					return;
				}
				this.result = {
					state: "success",
					query_id: response.data.query_id,
					result: response.data.result,
				};
			})
			// The surface area of creating a computed table is huge,
			// so there's a good chance invalid states will be created.
			// In this case, we want to render an error message instead
			// of crashing / showing a continued loading state.
			.catch((error) => {
				if (
					this.result.state === "loading" &&
					this.result.query_id === newQueryId
				) {
					this.result = {
						state: "error",
						error,
					};
				}
			});
	}
}
