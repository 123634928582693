import {
	type HighlightResult,
	computeGappedHighlight,
	computePaginatedHighlight,
} from "@/lib/highlight";
import { type HighlightId, createHighlightId } from "@/lib/id-generators";
import type { TocSection } from "@api/schemas";
import range from "lodash.range";
import { flow, makeAutoObservable, runInAction } from "mobx";
import type { PDFDocumentProxy } from "pdfjs-dist";
import type { TextContent } from "pdfjs-dist/types/src/display/api";
import { createContext, useContext, useState } from "react";
import type { VariableSizeList } from "react-window";
import { z } from "zod";

// Pixels to pad each page with
export const PAGE_PADDING = 4;

/**
 * A highlight object that contains the text to highlight and the page indices to search for it.
 *
 * @param textToHighlight - The text to highlight. If `textEnd` is not provided, the highlight is a single span.
 * @param startPageIndex - The page index to start highlighting from.
 */
export interface HighlightRequest {
	highlightId: HighlightId;
	textToHighlight: {
		textStart: string;
		textEnd?: string;
	};
	startPageIndex: number;
}

export type PdfHeaderMetadata = {
	title: string;
	authors: string[];
	description: string | null;
	url: string | null;
};

export const pdfSearchSchema = z
	.object({
		page_index: z
			.number()
			.optional()
			.describe("Page index, when a single page is specified."),
		highlight_start: z
			.string()
			.min(1)
			.optional()
			.describe("Start text, when highlights are specified."),
		highlight_end: z
			.string()
			.min(1)
			.optional()
			.describe("End text, when highlights are specified."),
	})
	.refine((data) => !(data.highlight_start && data.page_index === undefined), {
		message: "highlight_start cannot be defined without page_index",
	})
	.refine(
		(data) => !(data.highlight_end && data.highlight_start === undefined),
		{
			message: "highlight_end cannot be defined without highlight_start",
		},
	);

export type PdfSearchParams = z.infer<typeof pdfSearchSchema>;

/**
 * The state of the PDF viewer
 */
export class PDFViewerState {
	currentPageIndex = 0;
	pdfScale = 1;

	highlightRequests: Map<HighlightId, HighlightRequest> = new Map();
	highlightResults: Map<HighlightId, HighlightResult> = new Map();
	activeHighlightId: HighlightId | null = null;

	/**
	 * Raw PDF bytes to render
	 */
	pdfBytes: { data: Uint8Array } | null = null;

	/**
	 * Table of contents for the PDF
	 */
	toc: TocSection[] | null = null;

	/**
	 * Current PDF document and page dimensions
	 */
	pdf: {
		document: PDFDocumentProxy;
		// page index -> [width, height]
		pageDimensions: Map<number, [number, number]>;
		averagePageHeight: number;
	} | null = null;

	/**
	 * Ref to the virtualized list that renders the PDF pages
	 */
	listRef: VariableSizeList | null = null;

	/**
	 * Whether the sidebar is currently visible
	 */
	showSidebar = false;

	headerMetadata: PdfHeaderMetadata;
	searchParams: PdfSearchParams;
	constructor({
		loader,
		searchParams,
		tocLoader,
		headerMetadata,
	}: {
		loader: () => Promise<Uint8Array>;
		searchParams: PdfSearchParams;
		tocLoader: () => Promise<TocSection[]>;
		headerMetadata: PdfHeaderMetadata;
	}) {
		makeAutoObservable(this);

		this.headerMetadata = headerMetadata;
		this.searchParams = searchParams;
		loader().then((data) => {
			this.pdfBytes = { data };
		});

		tocLoader().then((toc) => {
			this.toc = toc;
		});

		// The viewer is capable of rendering multiple highlights at once,
		// but for now we only render a single highlight if the viewer is
		// being opened from a search result.
		if (searchParams.highlight_start !== undefined) {
			const highlightId = createHighlightId();
			this.setHighlightRequests([
				{
					highlightId,
					textToHighlight: {
						textStart: searchParams.highlight_start,
						textEnd: searchParams.highlight_end,
					},
					// biome-ignore lint/style/noNonNullAssertion: should be validated for by Zod
					startPageIndex: searchParams.page_index!,
				},
			]);
			this.activeHighlightId = highlightId;
		}
	}

	toggleSidebar() {
		this.showSidebar = !this.showSidebar;
	}

	/**
	 * Set the highlights to search for
	 */
	setHighlightRequests(highlights: HighlightRequest[]) {
		this.highlightRequests.clear();
		for (const highlight of highlights) {
			this.highlightRequests.set(highlight.highlightId, highlight);
		}
	}

	setCurrentPageIndex(index: number) {
		this.currentPageIndex = index;
	}

	/**
	 * Initialize the PDF document and extract page dimensions for the virtualized list
	 */
	async initPdf(document: PDFDocumentProxy) {
		const pages = await Promise.all(
			[...Array(document.numPages)].map((_, i) => document.getPage(i + 1)),
		);

		// Assuming all pages may have different heights. Otherwise we can just
		// load the first page and use its height for determining all the row
		// heights.
		const pageDimensions = new Map(
			pages.map((page) => [
				page._pageIndex,
				[
					page.view[2] - page.view[0] + PAGE_PADDING * 2,
					page.view[3] - page.view[1] + PAGE_PADDING * 2,
				] as [number, number],
			]),
		);

		const totalHeight = [...pageDimensions.values()].reduce(
			(sum, [_, h]) => sum + h,
			0,
		);

		runInAction(() => {
			this.pdf = {
				document,
				pageDimensions,
				averagePageHeight: totalHeight / document.numPages,
			};
		});
	}

	async scanPdfForHighlight(
		startPageIndex: number,
		textToHighlight: {
			textStart: string;
			textEnd?: string;
		},
		cachedPageTexts: Map<number, TextContent>,
	): Promise<HighlightResult> {
		let scannedUpToIndex = startPageIndex;
		const windowIncrement = 1;
		// biome-ignore lint/style/noNonNullAssertion: only run after we have a pdf
		const pdf = this.pdf!;
		while (scannedUpToIndex < pdf.document.numPages) {
			scannedUpToIndex = Math.min(
				scannedUpToIndex + windowIncrement,
				pdf.document.numPages,
			);
			const pageTextsPromises = range(startPageIndex, scannedUpToIndex).map(
				async (
					pageIndex,
				): Promise<{ pageIndex: number; pageText: TextContent }> => {
					const cachedPageText = cachedPageTexts.get(pageIndex);
					if (cachedPageText !== undefined) {
						return { pageIndex, pageText: cachedPageText };
					}

					// getPage is 1-indexed
					const page = await pdf.document.getPage(pageIndex + 1);
					const pageText = await page.getTextContent();
					cachedPageTexts.set(pageIndex, pageText);
					return { pageIndex, pageText };
				},
			);

			const pageTexts = await Promise.all(pageTextsPromises);

			const { textStart, textEnd } = textToHighlight;
			try {
				// If we find the highlight, we break out of the loop and
				// process the next one
				const highlightResult = textEnd
					? computeGappedHighlight(textStart, textEnd, pageTexts)
					: computePaginatedHighlight(textStart, pageTexts);

				return highlightResult;
			} catch (e) {
				// If we don't find the highlight, we try again with the next 5
				// pages
			}
		}
		throw new Error("Highlight not found");
	}

	/**
	 * Compute the highlight results for all the highlights
	 */
	calculateHighlightResults = flow(function* (this: PDFViewerState) {
		if (!this.pdf) {
			return;
		}
		const cachedPageTexts = new Map<number, TextContent>();

		for (const [id, rawHighlight] of this.highlightRequests.entries()) {
			const { startPageIndex, textToHighlight } = rawHighlight;
			const highlightResult = yield this.scanPdfForHighlight(
				startPageIndex,
				textToHighlight,
				cachedPageTexts,
			);
			runInAction(() => {
				this.highlightResults.set(id, highlightResult);
			});
		}
	});

	get activeHighlightResult() {
		if (!this.activeHighlightId) return null;
		return this.highlightResults.get(this.activeHighlightId) ?? null;
	}

	get numPages() {
		return this.pdf?.document.numPages ?? null;
	}
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const PDFViewerContext = createContext<PDFViewerState>(null as any);

export const usePDFViewerContext = () => {
	const context = useContext(PDFViewerContext);
	if (!context) {
		throw new Error("useViewerContext must be used within a ViewerProvider");
	}
	return context;
};

export const PDFViewerProvider: React.FC<{
	children: React.ReactNode;
	searchParams: PdfSearchParams;
	loader: () => Promise<Uint8Array>;
	tocLoader: () => Promise<TocSection[]>;
	headerMetadata: PdfHeaderMetadata;
}> = function PDFViewerProvider({
	children,
	searchParams,
	loader,
	tocLoader,
	headerMetadata,
}) {
	const [viewerState] = useState(
		() =>
			new PDFViewerState({
				searchParams,
				loader,
				tocLoader,
				headerMetadata,
			}),
	);

	return (
		<PDFViewerContext.Provider value={viewerState}>
			{children}
		</PDFViewerContext.Provider>
	);
};
