import type { EndStatus } from "@/components/layout/right-sidebar/running-status";
import { API_ENDPOINT_HTTP } from "@/config";
import type { AppState } from "@/contexts/app-context/app-context";
import openapiHashes from "@/generated/openapi-hashes.json";
import { ElectricSyncedMap } from "@/lib/sync/electric";
import type {
	CompletedForkStepShape,
	CompletedInteractStepShape,
	EndSessionStepShape,
	ErroredInteractStepShape,
	ReceivedInvalidResponseStepShape,
	RespondingStepShape,
	RunningForkStepShape,
	RunningInteractStepShape,
	StepId,
	StepShape,
	UnexpectedErrorStepShape,
} from "@api/schemas";
import { makeAutoObservable } from "mobx";
import { type Result, err, ok } from "neverthrow";

export function getStepEndStatus(step: StepShape): EndStatus | null {
	switch (step.state.type) {
		case "responding":
		case "running_interact":
		case "running_fork":
			return null;
		case "received_invalid_response":
		case "errored_interact":
		case "completed_interact":
		case "completed_fork":
		case "end_session":
			return {
				endTime: step.state.end_time,
				code: "ok",
			};
		case "unexpected_error":
			return {
				endTime: step.state.end_time,
				code: "error",
			};
	}
}

export type HistoryStep =
	| ReceivedInvalidResponseStepShape
	| ErroredInteractStepShape
	| CompletedInteractStepShape
	| CompletedForkStepShape;

export function isHistoryStep(step: StepShape): step is HistoryStep {
	return (
		step.state.type === "received_invalid_response" ||
		step.state.type === "errored_interact" ||
		step.state.type === "completed_interact" ||
		step.state.type === "completed_fork"
	);
}
export type InProgressStep =
	| RespondingStepShape
	| RunningInteractStepShape
	| RunningForkStepShape;

export function isInProgressStep(step: StepShape): step is InProgressStep {
	return (
		step.state.type === "responding" ||
		step.state.type === "running_interact" ||
		step.state.type === "running_fork"
	);
}

export type TerminalStep = EndSessionStepShape | UnexpectedErrorStepShape;

export function isTerminalStep(step: StepShape): step is TerminalStep {
	return (
		step.state.type === "end_session" || step.state.type === "unexpected_error"
	);
}

export class StepStore {
	appState: AppState;
	map: ElectricSyncedMap<StepShape, ["step_id"]>;

	constructor(appState: AppState) {
		makeAutoObservable(this);
		this.appState = appState;
		this.map = new ElectricSyncedMap<StepShape, ["step_id"]>({
			shapeUrl: `${API_ENDPOINT_HTTP}/shapes/steps`,
			pKeyFields: ["step_id"],
			shapeHash: openapiHashes.StepShape,
			getBearerToken: () => this.appState.getTokenOrThrow(),
		});
	}

	getById(id: StepId): Result<StepShape, Error> {
		const step = this.map.get(id);
		if (!step) {
			return err(new Error("Step not found"));
		}
		return ok(step);
	}
}
