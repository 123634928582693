import { TextEditor } from "@/components/editor";
import { StatusDot } from "@/components/layout/right-sidebar/assistant-presence-icon";
import { SessionPreview } from "@/components/layout/right-sidebar/session-preview";
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuPortal,
	DropdownMenuSub,
	DropdownMenuSubTrigger,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
	DropdownMenuItem,
	DropdownMenuSubContent,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { AssistantSessionIndexTabState } from "@/contexts/assistant-session/tab-state";
import type { LocalSearchableResourceRef } from "@/contexts/local-search-store";
import { useTabStore } from "@/contexts/tabs/use-tab-store";
import { resourceRefToLinkProps } from "@/lib/paths";
import { cn } from "@/lib/utils";
import {
	ResourceLinkComponent,
	ResourceLinkContent,
} from "@/plugins/resource-link";
import { CaretDown, Chat, File, FunnelSimple, X } from "@phosphor-icons/react";
import { createFileRoute } from "@tanstack/react-router";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useState } from "react";

const ResourceFilterItem = observer(function ResourceFilterItem({
	resourceRef,
}: {
	resourceRef: LocalSearchableResourceRef;
}) {
	const tabStore = useTabStore();
	const head = tabStore.getTabHead(resourceRefToLinkProps(resourceRef));

	return (
		<DropdownMenuItem className="flex h-8 w-full items-center px-2">
			<head.icon className="h-4 w-4 shrink-0 text-neutral-700" />
			<h2
				className={cn(
					"ml-2 w-full min-w-0 select-none truncate text-neutral-700 text-sm",
				)}
			>
				{head.label}
			</h2>
		</DropdownMenuItem>
	);
});

const ResourceFilterSubmenu = observer(() => {
	const appContext = useAppContext();
	const [resourceSearchValue, setResourceSearchValue] = useState("");

	const filteredResources =
		appContext.localSearchStore.searchResources(resourceSearchValue);

	return (
		<DropdownMenuSubContent className="flex w-48 flex-col p-0">
			<div className="flex w-full items-center gap-2 p-1">
				<Input
					// AutoFocus doesn't work
					autoFocus
					type="text"
					placeholder="Search resources"
					className="w-full border-none"
					value={resourceSearchValue}
					onChange={(e) => {
						setResourceSearchValue(e.target.value);
					}}
				/>
			</div>
			<div className="flex w-full flex-col gap-1 border-neutral-100 border-t p-1">
				<div className="flex w-full flex-col items-center p-1">
					{filteredResources.map((result) => {
						return (
							<ResourceFilterItem
								key={result.resource_id}
								resourceRef={result}
							/>
						);
					})}
					{filteredResources.length === 0 && (
						<span className="text-neutral-500 text-xs">No resources found</span>
					)}
				</div>
			</div>
		</DropdownMenuSubContent>
	);
});

const ThreadFilterSubmenu = observer(() => {
	const appContext = useAppContext();
	const { tabState } = Route.useLoaderData();
	const [threadSearchValue, setThreadSearchValue] = useState("");

	const threads = appContext.messageStore.threads;
	const filteredThreads = threads.filter((thread) =>
		thread.content.toLowerCase().includes(threadSearchValue.toLowerCase()),
	);
	return (
		<DropdownMenuSubContent className="flex w-48 flex-col p-0">
			<div className="flex w-full items-center gap-2 p-1">
				<Input
					// AutoFocus doesn't work
					autoFocus
					type="text"
					placeholder="Search threads"
					className="w-full border-none"
					value={threadSearchValue}
					onChange={(e) => {
						setThreadSearchValue(e.target.value);
					}}
				/>
			</div>
			<div className="flex w-full flex-col gap-1 border-neutral-100 border-t p-1">
				<div className="flex max-h-80 w-full flex-col gap-1 overflow-y-auto p-1">
					{filteredThreads.map((thread) => (
						<DropdownMenuItem
							key={thread.message_id}
							onSelect={() => {
								tabState.setFilterToThread(thread.message_id);
							}}
							className="flex w-full flex-col items-start px-2"
						>
							<span className="text-neutral-500 text-xs">
								{new Date(thread.created_at).toLocaleString("en-US", {
									month: "numeric",
									day: "numeric",
									hour: "numeric",
									minute: "numeric",
								})}
							</span>
							<TextEditor
								className="w-full text-sm"
								options={{ content: thread.content, editable: false }}
							/>
						</DropdownMenuItem>
					))}
					{filteredThreads.length === 0 && (
						<span className="text-neutral-500 text-xs">No threads found</span>
					)}
				</div>
			</div>
		</DropdownMenuSubContent>
	);
});

const AssistantSessionIndex = observer(function AssistantSessionIndex() {
	const { tabState } = Route.useLoaderData();
	const appContext = useAppContext();
	const filters = tabState.filters;
	const activeTabHead = appContext.tabStore.activeTab?.state.head;
	const activeTabResourceRef = activeTabHead?.resourceRef ?? null;

	const activeResourcePath = activeTabResourceRef
		? resourceRefToLinkProps(activeTabResourceRef)
		: null;

	const filteredResourcePath = filters.resourceRef
		? resourceRefToLinkProps(filters.resourceRef)
		: null;

	const sessions =
		appContext.assistantSessionStore.filterAssistantSessions(filters);

	const thread = filters.threadId
		? appContext.messageStore.messages.get(filters.threadId).unwrapOr(null)
		: null;

	return (
		<div className="flex h-full w-full flex-col">
			{/* Session Filters Header */}
			<div className="flex flex-col gap-2 border-neutral-100 border-b bg-white p-3">
				<Input
					type="text"
					placeholder="Search sessions"
					className="w-full"
					value={filters.goal ?? ""}
					onChange={(e) => {
						runInAction(() => {
							tabState.filters.goal = e.target.value;
						});
					}}
				/>

				{filteredResourcePath && (
					<div className="flex h-6 w-fit max-w-full items-center divide-x overflow-hidden rounded-sm border border-neutral-200">
						<div className="whitespace-nowrap p-1 text-neutral-500 text-xs">
							Filtered by:
						</div>
						<ResourceLinkComponent linkProps={filteredResourcePath}>
							<ResourceLinkContent
								linkProps={filteredResourcePath}
								className="rounded-xs p-0.5 text-neutral-800 text-xs"
							/>
						</ResourceLinkComponent>
						<button
							type="button"
							onClick={() => {
								runInAction(() => {
									tabState.filters.resourceRef = null;
								});
							}}
							className="p-1 text-neutral-500 text-xs hover:bg-neutral-100"
						>
							<X size={14} />
						</button>
					</div>
				)}

				{thread && (
					<div className="flex h-6 w-fit max-w-full items-center divide-x overflow-hidden rounded-sm border border-neutral-200">
						<div className="whitespace-nowrap p-1 text-neutral-500 text-xs">
							Filtered by:
						</div>
						<div className="flex min-w-0 shrink items-center gap-1 overflow-hidden p-1 text-neutral-500 text-xs">
							<Chat size={14} className="shrink-0 text-neutral-800" />
							<TextEditor
								// Weird way to limit to one line but it will do
								className="h-4 text-xs"
								options={{ content: thread.content, editable: false }}
							/>
						</div>
						<button
							type="button"
							onClick={() => {
								runInAction(() => {
									tabState.filters.threadId = null;
									tabState.filters.resourceRef = null;
								});
							}}
							className="p-1 text-neutral-500 text-xs hover:bg-neutral-100"
						>
							<X size={14} />
						</button>
					</div>
				)}

				{!filteredResourcePath && !thread && (
					<div className="flex w-full items-center gap-2">
						<DropdownMenu>
							<DropdownMenuTrigger className="flex h-min w-min items-center gap-1 p-1 text-neutral-500 text-xs">
								<FunnelSimple size={16} className="text-neutral-500" />
								<span>Filter</span>
							</DropdownMenuTrigger>
							<DropdownMenuContent align="start" className="w-fit p-0">
								<div className="flex w-42 flex-col p-2">
									<DropdownMenuSub>
										<DropdownMenuSubTrigger className="flex h-fit min-h-0 w-full items-center justify-start gap-2 px-2 py-1 text-neutral-800 text-sm">
											<File size={14} className="text-neutral-500" />
											<span className="truncate text-neutral-800">
												By Resource
											</span>
										</DropdownMenuSubTrigger>
										<DropdownMenuPortal>
											<ResourceFilterSubmenu />
										</DropdownMenuPortal>
									</DropdownMenuSub>
									<DropdownMenuSub>
										<DropdownMenuSubTrigger className="flex h-fit min-h-0 w-full items-center justify-start gap-2 px-2 py-1 text-neutral-800 text-sm">
											<Chat size={14} className="text-neutral-500" />
											<span className="truncate text-neutral-800">
												By Thread
											</span>
										</DropdownMenuSubTrigger>
										<DropdownMenuPortal>
											<ThreadFilterSubmenu />
										</DropdownMenuPortal>
									</DropdownMenuSub>
								</div>
							</DropdownMenuContent>
						</DropdownMenu>
						{activeResourcePath && (
							<button
								type="button"
								className=""
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									runInAction(() => {
										tabState.filters.threadId = null;
										tabState.filters.resourceRef = activeTabResourceRef;
									});
								}}
							>
								<ResourceLinkContent
									linkProps={activeResourcePath}
									className="rounded-xs border border-neutral-300 border-dashed text-neutral-500 text-xs"
								/>
							</button>
						)}
					</div>
				)}
			</div>

			{/* Session List */}
			<div className="flex min-h-0 w-full grow flex-col gap-2 overflow-y-auto">
				{/* Active Sessions */}
				<Collapsible className="flex flex-col" defaultOpen={true}>
					<CollapsibleTrigger className="m-1 flex items-center gap-1 px-2 py-1 text-left font-semibold text-neutral-800 text-xs hover:bg-neutral-100">
						<StatusDot active={true} />
						<span className="font-semibold text-neutral-800 text-xs">
							Active
						</span>
						<span className="text-neutral-500 text-xs">
							{sessions.active.length}
						</span>
						<CaretDown size={14} className="in-data-[state=open]:rotate-180" />
					</CollapsibleTrigger>
					<CollapsibleContent>
						<div className="flex w-full flex-col-reverse">
							{sessions.active.length > 0 ? (
								sessions.active.map((result) => (
									<SessionPreview
										key={result.session.assistant_session_id}
										assistantSession={result.session}
									/>
								))
							) : (
								<div className="border-neutral-100 px-2 py-1 text-neutral-500">
									No active assistants.
								</div>
							)}
						</div>
					</CollapsibleContent>
				</Collapsible>

				{/* Past Sessions */}
				<Collapsible defaultOpen={true}>
					<CollapsibleTrigger className="m-1 flex items-center gap-1 px-2 py-1 text-left font-semibold text-neutral-800 text-xs hover:bg-neutral-100">
						<StatusDot active={false} />
						<span>Completed</span>
						<CaretDown size={14} className="in-data-[state=open]:rotate-180" />
					</CollapsibleTrigger>
					<CollapsibleContent>
						<div className="flex w-full flex-col-reverse">
							{sessions.completed.length > 0 ? (
								sessions.completed.map((result) => (
									<SessionPreview
										key={result.session.assistant_session_id}
										assistantSession={result.session}
									/>
								))
							) : (
								<div className="border-neutral-100 px-2 py-1 text-neutral-500">
									No completed assistants.
								</div>
							)}
						</div>
					</CollapsibleContent>
				</Collapsible>
			</div>
		</div>
	);
});

export const Route = createFileRoute("/assistant-session/")({
	component: AssistantSessionIndex,
	loader: ({ context: { tab } }) => {
		const tabState = new AssistantSessionIndexTabState(tab);
		return {
			tabState,
		};
	},
});
