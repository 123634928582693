import {
	getRecordLinkColumn,
	getResourceIndexColumn,
	getResourceIndexSelectorColumn,
} from "@/components/table/columns";
import type { EdgarEntitiesStore } from "@/contexts/edgar/stores/edgar-stores";
import type { TabStore } from "@/contexts/tabs/tabs-context";
import type { ResourceLink } from "@api/schemas";
import type { ColumnDef } from "@tanstack/react-table";

// Keep this in sync with the index view in the server
// TODO(Tae): Revisit these columns.
// There may be a less manual way to define the columns.
interface EdgarEntityRecord {
	link: ResourceLink;
	name: string;
	cik: string;
}

export class EdgarEntitiesIndex {
	edgarEntitiesStore: EdgarEntitiesStore;
	tabStore: TabStore;

	constructor(edgarEntitiesStore: EdgarEntitiesStore, tabStore: TabStore) {
		this.edgarEntitiesStore = edgarEntitiesStore;
		this.tabStore = tabStore;
	}

	get rows(): EdgarEntityRecord[] {
		return [];
	}

	get columns(): ColumnDef<EdgarEntityRecord>[] {
		return [
			getResourceIndexSelectorColumn<EdgarEntityRecord>(),
			getRecordLinkColumn({
				resetScroll: true,
				didInit: true,
			}),
			getResourceIndexColumn({
				key: "name",
				name: "Name",
				scalarType: "text",
				width: 200,
			}),
			getResourceIndexColumn({
				key: "cik",
				name: "CIK",
				scalarType: "text",
				width: 200,
			}),
		];
	}
}
