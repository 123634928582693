import type { SessionFilters } from "@/contexts/assistant/stores/assistant-session-store";
import { BaseTabState } from "@/contexts/tabs/base-tab-state";
import type { Tab } from "@/contexts/tabs/tabs-context";
import { makeAutoObservableAbstract } from "@/lib/make-auto-observable-abstract";
import type { AssistantSessionId, MessageId, ResourceRef } from "@api/schemas";
import type { AssistantSession } from "@api/schemas";
import { Desktop } from "@phosphor-icons/react";
import type { Result } from "neverthrow";

export class AssistantSessionIndexTabState extends BaseTabState {
	// Filters for the top-level assistant activity viewer
	filters: SessionFilters = {
		resourceRef: null,
		threadId: null,
		goal: null,
		path: null,
	};

	constructor(tab: Tab) {
		super(tab);
		makeAutoObservableAbstract(this);
	}

	get head() {
		return {
			icon: Desktop,
			label: "Assistant Sessions",
		};
	}

	setFilterToResource = (resourceRef: ResourceRef) => {
		this.filters.resourceRef = resourceRef;
		this.filters.threadId = null;
		this.filters.goal = null;
	};

	setFilterToThread = (threadId: MessageId) => {
		this.filters.threadId = threadId;
		this.filters.resourceRef = null;
		this.filters.goal = null;
	};
}

export class AssistantSessionTabState extends BaseTabState {
	assistantSessionId: AssistantSessionId;

	constructor(tab: Tab, assistantSessionId: AssistantSessionId) {
		super(tab);
		makeAutoObservableAbstract(this);
		this.assistantSessionId = assistantSessionId;
	}

	get assistantSession(): Result<AssistantSession, Error> {
		return this.tab.tabStore.appState.assistantSessionStore.getById(
			this.assistantSessionId,
		);
	}

	get head() {
		const goal =
			this.assistantSession.unwrapOr(null)?.goal ?? "Unknown session";
		return {
			icon: Desktop,
			label: goal,
		};
	}
}
