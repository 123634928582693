import { SidebarNavigationButton } from "@/components/layout/left-sidebar/sidebar-navigation-button";
import { ToggleLeftSidebarButton } from "@/components/layout/toggle-left-sidebar-button";
import { PageTree } from "@/components/tree/tree";
import { Button } from "@/components/ui/button";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { Switch } from "@/components/ui/switch";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { IS_DEV } from "@/config";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { LinkTarget } from "@/contexts/tabs/router-types";
import { useTabStore } from "@/contexts/tabs/use-tab-store";
import { cn } from "@/lib/utils";
import { SignedIn, UserButton } from "@clerk/clerk-react";
import {
	Bug,
	ChartLine,
	FileArrowUp,
	FileMagnifyingGlass,
	FileText,
	Globe,
	type Icon,
	MagnifyingGlass,
	Plug,
	Rss,
	Table,
} from "@phosphor-icons/react";
import type { LinkProps } from "@tanstack/react-router";
import { useMediaQuery } from "@uidotdev/usehooks";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";

const Overlay = ({
	isOpen,
	onClose,
}: {
	isOpen: boolean;
	onClose: () => void;
}) => {
	if (!isOpen) {
		return null;
	}

	return (
		<div
			role="button"
			aria-label="Close sidebar"
			tabIndex={0}
			className="absolute top-0 right-0 bottom-0 left-0 z-40 bg-black/10 opacity-80"
			onClick={onClose}
			onKeyDown={(e) => {
				if (e.key === "Enter" || e.key === " ") {
					onClose();
				}
			}}
		/>
	);
};

type SidebarItem = {
	icon: Icon;
	label: string;
	to: LinkProps["to"];
};

function SidebarSection({
	title,
	items,
}: {
	title: string;
	items: SidebarItem[];
}) {
	const tabsContext = useTabStore();

	return (
		<div className="flex min-h-0 w-full shrink-0 flex-col">
			<div className="flex items-center gap-2 px-2 py-1.5">
				<span className="font-semibold text-neutral-500 text-xs">{title}</span>
			</div>
			{items.map((item) => (
				<SidebarNavigationButton
					key={item.to}
					ButtonIcon={item.icon}
					label={item.label}
					location={{ to: item.to }}
					navigate={(
						location: LinkProps,
						e: React.MouseEvent<HTMLButtonElement>,
					) => {
						const isShift = e.shiftKey;
						const isCmdOrCtrl = e.metaKey || e.ctrlKey;

						if (isCmdOrCtrl) {
							tabsContext.openLink(location, LinkTarget.NewTab);
						} else if (isShift) {
							tabsContext.openLink(location, LinkTarget.NewPane);
						} else {
							tabsContext.openLink(location, LinkTarget.Self);
						}
					}}
				/>
			))}
		</div>
	);
}

export const Sidebar = observer(() => {
	const appContext = useAppContext();
	const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

	const fileItems: SidebarItem[] = [
		{ icon: FileText, label: "Pages", to: "/pages" },
		{ icon: FileArrowUp, label: "Uploads", to: "/uploads" },
		{ icon: Globe, label: "Webpages", to: "/web" },
		{ icon: Table, label: "Tables", to: "/tables" },
	];

	const toolItems: SidebarItem[] = [
		{ icon: ChartLine, label: "Query", to: "/query" },
		{ icon: FileMagnifyingGlass, label: "Search", to: "/search" },
		{ icon: MagnifyingGlass, label: "Web Search", to: "/web-search" },
	];

	const integrationItems: SidebarItem[] = [
		{ icon: Rss, label: "RSS Feeds", to: "/feeds" },
		{ icon: Plug, label: "SEC EDGAR", to: "/edgar" },
	];

	return (
		<>
			{isSmallDevice && (
				<Overlay
					isOpen={appContext.leftSidebarState.showSidebar}
					onClose={() => {
						appContext.leftSidebarState.setShowSidebar(false);
					}}
				/>
			)}
			<div
				className={cn(
					"z-50 h-full w-full min-w-0 shrink-0 overflow-hidden",
					isSmallDevice
						? "absolute top-0 bottom-0 border-r shadow-xl"
						: "relative",
				)}
			>
				<nav
					className={cn(
						"group/sidebar flex h-full w-full flex-col gap-3 bg-white p-1 backdrop-blur-sm",
					)}
				>
					<div className="flex p-1">
						<ToggleLeftSidebarButton />
						{IS_DEV && (
							<Popover>
								<Tooltip>
									<TooltipTrigger asChild>
										<PopoverTrigger asChild>
											<Button variant="ghost" className="h-fit">
												<Bug />
											</Button>
										</PopoverTrigger>
									</TooltipTrigger>
									<TooltipContent>Dev tools</TooltipContent>
								</Tooltip>
								<PopoverContent
									align="start"
									className="flex w-96 flex-col gap-2"
								>
									<div className="flex items-center justify-between gap-2 font-mono">
										<div className="text-neutral-500 text-sm">
											Show search result scores
										</div>
										<Switch
											checked={appContext.devSettings.showSearchResultScores}
											onCheckedChange={(checked) => {
												runInAction(() => {
													appContext.devSettings.showSearchResultScores =
														checked;
												});
											}}
										/>
									</div>
								</PopoverContent>
							</Popover>
						)}
					</div>

					<PageTree treeState={appContext.leftSidebarState.treeState} />

					<SidebarSection title="Files" items={fileItems} />

					<SidebarSection title="Tools" items={toolItems} />

					<SidebarSection title="Integrations" items={integrationItems} />

					<div className="grow" />

					<SignedIn>
						<div className="flex items-center border-neutral-100 border-t bg-white p-2">
							<UserButton />
						</div>
					</SignedIn>
				</nav>
			</div>
		</>
	);
});
