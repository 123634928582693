import { TableComponent } from "@/components/table/table-component";
import { useWebpagesIndex } from "@/contexts/resource-indexes/hooks";
import { WebIndexTabState } from "@/contexts/web/tab-state";
import { AddWebpageDialog } from "@/pages/tabs/web/-components/add-webpage-dialog";
import { createFileRoute } from "@tanstack/react-router";
import { observer } from "mobx-react-lite";
import { toast } from "sonner";

const WebpagesHomePage = observer(function WebpagesHomePage() {
	const webpagesIndex = useWebpagesIndex();
	return (
		<div className="flex h-full min-h-0 w-full grow flex-col px-16 pt-4">
			<h1 className="px-2 font-bold text-2xl">Webpages</h1>
			<div className="flex w-full px-2 pt-4 pb-4">
				<AddWebpageDialog />
			</div>
			<div className="relative flex grow flex-col">
				<TableComponent
					getRowId={(row) => row.link}
					getRowLink={(row) => row.link}
					columns={webpagesIndex.columns}
					data={webpagesIndex.rows}
					editableProps={{
						deleteRows: () => {
							toast.error("Not implemented");
						},
					}}
					maxHeight={500}
					rowSelectionPopoverTop={"-3rem"}
				/>
			</div>
		</div>
	);
});

export const Route = createFileRoute("/web/")({
	component: WebpagesHomePage,
	loader: ({ context: { tab } }) => {
		const tabState = new WebIndexTabState(tab);
		return { tabState };
	},
});
