import { PDFViewer } from "@/components/pdf/pdfviewer";
import { EdgarDocumentTabState } from "@/contexts/edgar/tab-state";
import { pdfSearchSchema } from "@/contexts/pdfviewer-context";
import { zodValidator } from "@/lib/zod-validator";
import { useGetEdgarDocument, useGetEdgarDocumentPdfRoute } from "@api/fastAPI";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/edgar/edgar-document/$document-id")({
	component: RouteComponent,
	validateSearch: zodValidator(pdfSearchSchema),
	loaderDeps: ({ search }) => ({ search }),
	loader: ({ params, context: { tab }, deps }) => {
		const tabState = new EdgarDocumentTabState(
			tab,
			params["document-id"] as string,
			deps.search,
		);
		return { tabState };
	},
});

function RouteComponent() {
	const { tabState } = Route.useLoaderData();
	const document = useGetEdgarDocument(tabState.accession, tabState.sequence);
	const pdf = useGetEdgarDocumentPdfRoute(
		tabState.accession,
		tabState.sequence,
		{
			axios: {
				responseType: "arraybuffer",
			},
		},
	);

	if (document.isError || pdf.isError) {
		return <div>Error loading document</div>;
	}

	if (document.isLoading || !document.data || pdf.isLoading || !pdf.data) {
		return <div>Loading...</div>;
	}

	return (
		<div className="flex h-full max-h-full min-h-0 w-full flex-col">
			<PDFViewer
				documentId={tabState.accession}
				searchParams={tabState.searchParams}
				loader={async () => {
					// Make a copy to avoid consuming the original, which gets
					// cached and reused
					return (pdf.data.data as Uint8Array<ArrayBufferLike>).slice(0);
				}}
				tocLoader={async () => {
					return document.data.data.toc;
				}}
				headerMetadata={{
					title: document.data.data.filename ?? "",
					authors: [],
					description: document.data.data.description ?? null,
					url: `https://www.sec.gov/Archives/edgar/data/0/${tabState.accession}-index.htm`,
				}}
			/>
		</div>
	);
}
