import {
	API_ENDPOINT_HTTP,
	CLERK_PUBLISHABLE_KEY,
	IS_DEV,
	VITE_APP_ENV,
} from "@/config";
import "@/index.css";
import { Root } from "@/pages/root";
import {
	ClerkProvider,
	RedirectToSignIn,
	SignedIn,
	SignedOut,
} from "@clerk/clerk-react";
import * as Sentry from "@sentry/react";
import axios from "axios";
import React from "react";
import ReactDOM from "react-dom/client";

if (!IS_DEV) {
	Sentry.init({
		dsn: "https://50cd796e3f9bffb42b53324657fe45f8@o4504119928291328.ingest.us.sentry.io/4507584710180864",
		integrations: [
			Sentry.browserTracingIntegration(),
			// Sentry.replayIntegration(),
		],
		environment: VITE_APP_ENV,
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [
			"localhost",
			/^https:\/\/greenmantle-api.village\.dev/,
			/^https:\/\/greenmantle-dev.village\.dev/,
		],
		// Session Replay
		// replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		// replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}
axios.defaults.baseURL = API_ENDPOINT_HTTP;

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
	<React.StrictMode>
		<ClerkProvider publishableKey={CLERK_PUBLISHABLE_KEY}>
			<SignedOut>
				<RedirectToSignIn signInForceRedirectUrl={window.location.href} />
			</SignedOut>
			<SignedIn>
				<Root />
			</SignedIn>
		</ClerkProvider>
	</React.StrictMode>,
);
