import {
	getRecordLinkColumn,
	getResourceIndexColumn,
} from "@/components/table/columns";
import { getResourceIndexSelectorColumn } from "@/components/table/columns";
import { TableComponent } from "@/components/table/table-component";
import { EdgarFilingTabState } from "@/contexts/edgar/tab-state";
import type { TabStore } from "@/contexts/tabs/tabs-context";
import { useTabStore } from "@/contexts/tabs/use-tab-store";
import { useGetEdgarFilingRoute } from "@api/fastAPI";
import type { EdgarFilingFull, ResourceLink } from "@api/schemas";
import { createFileRoute } from "@tanstack/react-router";
import type { ColumnDef } from "@tanstack/react-table";
import { observer } from "mobx-react-lite";

interface EntitiesIndexRecord {
	link: ResourceLink;
	cik: string;
	name: string;
}

class EntitiesIndex {
	filing: EdgarFilingFull;
	tabStore: TabStore;
	constructor(filing: EdgarFilingFull, tabStore: TabStore) {
		this.filing = filing;
		this.tabStore = tabStore;
	}

	get rows(): EntitiesIndexRecord[] {
		return this.filing.entities.map((entity) => ({
			link: this.tabStore.resourceRefToHref({
				resource_id: entity.cik,
				type: "edgar-entity" as const,
			}),
			cik: entity.cik,
			name: entity.name,
		}));
	}

	get columns(): ColumnDef<EntitiesIndexRecord>[] {
		return [
			getResourceIndexSelectorColumn<EntitiesIndexRecord>(),
			getRecordLinkColumn({
				resetScroll: true,
				didInit: true,
			}),
			getResourceIndexColumn({
				key: "cik",
				name: "CIK",
				scalarType: "text",
				width: 200,
			}),
			getResourceIndexColumn({
				key: "name",
				name: "Name",
				scalarType: "text",
				width: 200,
			}),
		];
	}
}

interface DocumentsIndexRecord {
	link: ResourceLink;
	document_id: string;
	filename: string;
}

class DocumentsIndex {
	filing: EdgarFilingFull;
	tabStore: TabStore;
	constructor(filing: EdgarFilingFull, tabStore: TabStore) {
		this.filing = filing;
		this.tabStore = tabStore;
	}

	get rows(): DocumentsIndexRecord[] {
		return this.filing.documents.map((document) => ({
			link: this.tabStore.resourceRefToHref({
				resource_id: document.document_id,
				type: "edgar-document" as const,
			}),
			document_id: document.document_id,
			filename: document.filename,
		}));
	}

	get columns(): ColumnDef<DocumentsIndexRecord>[] {
		return [
			getResourceIndexSelectorColumn<DocumentsIndexRecord>(),
			getRecordLinkColumn({
				resetScroll: true,
				didInit: true,
			}),
			getResourceIndexColumn({
				key: "document_id",
				name: "Document ID",
				scalarType: "text",
				width: 200,
			}),
			getResourceIndexColumn({
				key: "filename",
				name: "Filename",
				scalarType: "text",
				width: 200,
			}),
		];
	}
}

const EdgarFilingPage = observer(function EdgarFilingPage() {
	const { tabState } = Route.useLoaderData();
	const tabStore = useTabStore();
	const {
		data: edgarFiling,
		isLoading,
		isError,
	} = useGetEdgarFilingRoute(tabState.edgarFilingAccessionNumber);

	if (isError) {
		return <div>Error loading filing</div>;
	}

	if (isLoading || !edgarFiling) {
		return <div>Loading...</div>;
	}

	const filing = edgarFiling.data;

	const joinedAccessionNumber = filing.accession_number.replace("-", "");
	const edgarFilingLink = `https://www.sec.gov/Archives/edgar/data/filer-cik-leading-zeros-removed/${joinedAccessionNumber}/${edgarFiling.data.accession_number}-index.htm`;

	const documentsIndex = new DocumentsIndex(filing, tabStore);
	const entitiesIndex = new EntitiesIndex(filing, tabStore);

	return (
		<div className="flex h-full max-h-full min-h-0 w-full flex-col px-16 pt-4">
			<h1 className="px-2 font-bold text-2xl">{filing.form}</h1>
			<h2 className="px-2 text-neutral-500 text-sm">
				Description of form: ex. quarterly report
			</h2>

			<div className="my-2 flex flex-col gap-1 bg-neutral-100 p-3 text-neutral-700 text-sm">
				<div className="flex flex-col items-start">
					<span className="font-semibold">Accession Number</span>
					<span>{filing.accession_number}</span>
				</div>
				<div className="flex flex-col items-start">
					<span className="font-semibold">Filing date</span>
					<span>{filing.filing_date}</span>
				</div>
				<div className="flex flex-col items-start">
					<span className="font-semibold">Link to EDGAR</span>
					<span>
						<a
							className="text-blue-500 hover:underline"
							href={edgarFilingLink}
							target="_blank"
							rel="noreferrer"
						>
							{edgarFilingLink}
						</a>
					</span>
				</div>
			</div>

			<div className="flex w-full border-b px-4 pt-4 pb-4">
				<h2 className="font-semibold">Documents</h2>
			</div>
			<div className="relative">
				<TableComponent
					getRowId={(row) => row.document_id}
					getRowLink={(row) => row.link}
					data={documentsIndex.rows}
					columns={documentsIndex.columns}
					maxHeight={500}
					rowSelectionPopoverTop={0}
				/>
			</div>

			<div className="flex w-full border-b px-4 pt-4 pb-4">
				<h2 className="font-semibold">Entities</h2>
			</div>
			<div className="relative">
				<TableComponent
					getRowId={(row) => row.cik}
					getRowLink={(row) => row.link}
					data={entitiesIndex.rows}
					columns={entitiesIndex.columns}
					maxHeight={500}
					rowSelectionPopoverTop={0}
				/>
			</div>
		</div>
	);
});

type EdgarAccessionNumber = string;

export const Route = createFileRoute(
	"/edgar/edgar-filing/$edgar-accession-number",
)({
	component: EdgarFilingPage,
	loader: ({ params, context: { tab } }) => {
		const tabState = new EdgarFilingTabState(
			tab,
			params["edgar-accession-number"] as EdgarAccessionNumber,
		);
		return { tabState };
	},
});
