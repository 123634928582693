import { TableComponent } from "@/components/table/table-component";
import { Button } from "@/components/ui/button";
import { usePageStore } from "@/contexts/app-context/db-store/db-store-hooks";
import { PagesIndexTabState } from "@/contexts/pages/tab-state";
import { usePagesIndex } from "@/contexts/resource-indexes/hooks";
import { Plus } from "@phosphor-icons/react";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { observer } from "mobx-react-lite";

const PagesHomePage = observer(function PagesHomePage() {
	const pagesIndex = usePagesIndex();
	const pagesStore = usePageStore();
	const navigate = useNavigate();

	const createPageHandler = () => {
		pagesStore.createPage({
			onLocalSuccess: (newPage) => {
				navigate({
					to: "/pages/$page-id",
					params: {
						"page-id": newPage.page_id,
					},
				});
			},
		});
	};

	return (
		<div className="flex h-full min-h-0 w-full grow flex-col px-16 pt-4">
			<h1 className="px-2 font-bold text-2xl">Pages</h1>
			<div className="flex w-full px-2 pt-4 pb-4">
				<Button
					variant="outline"
					onClick={createPageHandler}
					className="gap-2"
					size="sm"
				>
					<Plus weight="bold" />
					<span>Create</span>
				</Button>
			</div>
			<div className="relative flex grow flex-col">
				<TableComponent
					getRowId={(row) => row.pageId}
					getRowLink={(row) => row.link}
					columns={pagesIndex.columns}
					data={pagesIndex.rows}
					editableProps={{
						deleteRows: (rowIds) => {
							pagesStore.deletePages({
								pageIds: rowIds,
							});
						},
					}}
					maxHeight={500}
					rowSelectionPopoverTop={"-3rem"}
				/>
			</div>
		</div>
	);
});

export const Route = createFileRoute("/pages/")({
	component: PagesHomePage,
	loader: ({ context: { tab } }) => {
		const tabState = new PagesIndexTabState(tab);
		return { tabState };
	},
});
