import type { AppState } from "@/contexts/app-context/app-context";
import { Tab } from "@/contexts/tabs/tabs-context";
import { makeAutoObservable } from "mobx";

export class RightSidebarState {
	appState: AppState;

	showRightSidebar = true;

	messageTab: Tab;

	constructor(appState: AppState) {
		makeAutoObservable(this);
		this.appState = appState;
		this.messageTab = new Tab({
			tabsState: appState.tabStore,
			initialLocation: {
				to: "/message",
			},
		});
	}

	toggleRightSidebar = () => {
		this.showRightSidebar = !this.showRightSidebar;
	};
}
