import * as SwitchPrimitives from "@radix-ui/react-switch";
import * as React from "react";

import { cn } from "@/lib/utils";

type SwitchProps = React.ComponentPropsWithoutRef<
	typeof SwitchPrimitives.Root
> & {
	size?: "sm" | "default";
};

const Switch = React.forwardRef<
	React.ElementRef<typeof SwitchPrimitives.Root>,
	SwitchProps
>(({ className, size = "default", ...props }, ref) => (
	<SwitchPrimitives.Root
		className={cn(
			"peer inline-flex shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent shadow-xs transition-colors focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 focus-visible:ring-offset-white disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-emerald-500 data-[state=unchecked]:bg-neutral-200 dark:data-[state=checked]:bg-neutral-50 dark:data-[state=unchecked]:bg-neutral-800 dark:focus-visible:ring-neutral-300 dark:focus-visible:ring-offset-neutral-950",
			size === "default" && "h-5 w-9",
			size === "sm" && "h-4 w-7",
			className,
		)}
		{...props}
		ref={ref}
	>
		<SwitchPrimitives.Thumb
			className={cn(
				"pointer-events-none block rounded-full bg-white shadow-lg ring-0 transition-transform dark:bg-neutral-950",
				size === "default" && "h-4 w-4 data-[state=checked]:translate-x-4",
				size === "sm" && "h-3 w-3 data-[state=checked]:translate-x-3",
			)}
		/>
	</SwitchPrimitives.Root>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
