import { PageTreeState } from "@/components/tree/tree-state";
import type { AppState } from "@/contexts/app-context/app-context";
import { makeAutoObservable } from "mobx";

export class LeftSidebarState {
	appState: AppState;
	treeState: PageTreeState;
	showSidebar = true;

	constructor(appState: AppState) {
		makeAutoObservable(this);
		this.appState = appState;
		this.treeState = new PageTreeState({
			appState,
			isDndEnabled: false,
			isMultiSelect: false,
		});
	}

	setShowSidebar = (show: boolean) => {
		this.showSidebar = show;
	};
}
