import { Button } from "@/components/ui/button";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import type { Field, FieldId, SortField } from "@api/schemas";
import {
	DndContext,
	type DragEndEvent,
	KeyboardSensor,
	PointerSensor,
	closestCenter,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { ArrowsDownUp, DotsSixVertical, X } from "@phosphor-icons/react";
import { Plus, Trash } from "@phosphor-icons/react";
import { SelectTrigger as SelectTriggerRadix } from "@radix-ui/react-select";
import { observer } from "mobx-react-lite";

/**
 * Base class for managing sort state.
 */
export abstract class BaseSortManager {
	abstract get sortFields(): SortField[];

	abstract addSortField(fieldId: FieldId): void;

	abstract removeSortField(fieldId: string): void;

	abstract updateSortDirection(
		index: number,
		direction: SortField["direction"],
	): void;

	abstract updateSortField(index: number, fieldId: FieldId): void;

	abstract moveSortField(fromIndex: number, toIndex: number): void;

	abstract clearSortFields(): void;

	abstract get availableSortFieldIds(): string[];
}

interface SortableItemProps {
	id: string;
	field?: Field;
	sortField: SortField;
	index: number;
	sortState: BaseSortManager;
}

const SortableItem = observer(function SortableItem({
	id,
	sortField,
	index,
	sortState,
}: SortableItemProps) {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({ id });

	const style = {
		transform: transform
			? `translate3d(${transform.x}px, ${transform.y}px, 0)`
			: undefined,
		transition,
		zIndex: isDragging ? 10 : undefined,
		opacity: isDragging ? 0.5 : 1,
	};

	return (
		<div
			ref={setNodeRef}
			style={style}
			className="mb-2 flex items-center gap-2"
		>
			<div
				className="flex h-8 w-8 shrink-0 cursor-grab items-center justify-center rounded hover:bg-neutral-100"
				{...attributes}
				{...listeners}
			>
				<DotsSixVertical weight="bold" className="text-neutral-500" />
			</div>
			<Select
				value={sortField.field_id}
				onValueChange={(value) => {
					sortState.updateSortField(index, value as FieldId);
				}}
			>
				<SelectTrigger className="w-[180px]">
					<SelectValue placeholder="Select field...">
						<span className="flex items-center gap-2">
							{sortField.field_id}
						</span>
					</SelectValue>
				</SelectTrigger>
				<SelectContent>
					{sortState.availableSortFieldIds.map((fieldId) => (
						<SelectItem key={fieldId} value={fieldId}>
							<span className="flex items-center gap-2">{fieldId}</span>
						</SelectItem>
					))}
				</SelectContent>
			</Select>

			<Select
				value={sortField.direction}
				onValueChange={(value) => {
					sortState.updateSortDirection(index, value as "asc" | "desc");
				}}
			>
				<SelectTrigger className="w-[120px]">
					<SelectValue placeholder="Direction...">
						{sortField.direction === "asc" ? "Ascending" : "Descending"}
					</SelectValue>
				</SelectTrigger>
				<SelectContent>
					<SelectItem value="asc">Ascending</SelectItem>
					<SelectItem value="desc">Descending</SelectItem>
				</SelectContent>
			</Select>

			<Button
				variant="ghost"
				size="icon"
				className="h-8 w-8 shrink-0 rounded p-1"
				onClick={() => sortState.removeSortField(sortField.field_id)}
			>
				<X weight="bold" className="text-neutral-500" />
			</Button>
		</div>
	);
});

interface SortPopoverProps {
	sortState: BaseSortManager;
}

export const SortPopover = observer(function SortFields({
	sortState,
}: SortPopoverProps) {
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor),
	);

	// Generate unique IDs for sort fields
	const sortableItems = sortState.sortFields.map(
		(_, index) => `sort-field-${index}`,
	);

	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button
					type="button"
					variant="ghost"
					size="sm"
					className="flex max-w-max items-center gap-1 text-neutral-600"
				>
					<ArrowsDownUp weight="bold" /> Sorting{" "}
					{sortState.sortFields.length > 0
						? `(${sortState.sortFields.length})`
						: ""}
				</Button>
			</PopoverTrigger>
			<PopoverContent align="start" className="w-96">
				<h3 className="font-medium text-sm">Sort By</h3>
				<div className="mt-2">
					<DndContext
						sensors={sensors}
						collisionDetection={closestCenter}
						modifiers={[restrictToVerticalAxis]}
						onDragEnd={(event: DragEndEvent) => {
							const { active, over } = event;

							if (!over) return;

							const activeId = active.id.toString();
							const overId = over.id.toString();

							if (activeId !== overId) {
								const activeIndex = sortState.sortFields.findIndex(
									(_, i) => `sort-field-${i}` === activeId,
								);
								const overIndex = sortState.sortFields.findIndex(
									(_, i) => `sort-field-${i}` === overId,
								);

								if (activeIndex !== -1 && overIndex !== -1) {
									sortState.moveSortField(activeIndex, overIndex);
								}
							}
						}}
					>
						<SortableContext
							items={sortableItems}
							strategy={verticalListSortingStrategy}
						>
							{sortState.sortFields.map((sortField, index) => {
								return (
									<SortableItem
										key={sortField.field_id || `sort-field-${index}`}
										id={`sort-field-${index}`}
										index={index}
										sortField={sortField}
										sortState={sortState}
									/>
								);
							})}
						</SortableContext>
					</DndContext>
				</div>

				<Select
					onValueChange={(value) => {
						sortState.addSortField(value as FieldId);
					}}
				>
					{/* 
						Use the headless version here because the styled version
						adds an extra border and an icon 
					*/}
					<SelectTriggerRadix asChild>
						<Button
							variant="ghost"
							className="w-full justify-start gap-1 px-2 text-neutral-600"
							disabled={sortState.availableSortFieldIds.length === 0}
						>
							<Plus weight="bold" />
							Add Sort
						</Button>
					</SelectTriggerRadix>
					<SelectContent>
						{sortState.availableSortFieldIds.length > 0 ? (
							sortState.availableSortFieldIds.map((fieldId) => (
								<SelectItem key={fieldId} value={fieldId}>
									<span className="flex items-center gap-2">{fieldId}</span>
								</SelectItem>
							))
						) : (
							<div className="p-2 text-neutral-500 text-sm">
								No fields available for sorting
							</div>
						)}
					</SelectContent>
				</Select>

				{sortState.sortFields.length > 0 ? (
					<Button
						variant="ghost"
						className="flex w-full items-center justify-start gap-1 px-2 text-neutral-600"
						disabled={sortState.sortFields.length === 0}
						onClick={() => {
							sortState.clearSortFields();
						}}
					>
						<Trash /> Clear All
					</Button>
				) : null}
			</PopoverContent>
		</Popover>
	);
});
