import { IS_DEV } from "@/config";
import { createWriteId } from "@/lib/id-generators";
import type { WriteId } from "@api/schemas";
import * as Sentry from "@sentry/react";
import { toast } from "sonner";

const QUEUE_TIMEOUT_MS = 30_000;
/**
 * Transaction class for tracking and rolling back optimistic updates.
 *
 * Provides atomic semantics for optimistic operations by:
 * - tracking rollback functions in order of execution
 * - executing rollbacks in reverse order on failure
 * - uniquely identifying transaction scope with symbol id
 */
export class Transaction {
	private rollbacks: (() => void)[] = [];
	/**
	 * The transaction-level write ID.
	 *
	 * The tables sync engine uses these write IDs to perform tracing and
	 * confirmation of writes in the following way:
	 *  - A client-side transaction is created for a series of edits to a table.
	 *  - The edits are applied to the client optimistically, and a request
	 *    containing the transaction's write ID is sent to the server.
	 *  - The server executes the corresponding writes using a Postgres transaction
	 *    with the write ID set as a session variable.
	 *  - Triggers are fired for this table, which pass the write ID to backend
	 *    sync handler.
	 *  - The sync handler issues a `DiffEvent` containing the write ID and a set
	 *    of processed changes.
	 *  - The client-side sync engine updates it's server-authoritative map
	 *    with the processed changes.
	 *  - The client-side sync engine uses the write ID to match pending writes
	 *    and remove them, since they've now been confirmed on the server and
	 *    the changes are now reflected in the server-authoritative map.
	 *
	 * The Electric sync setup does not use transaction-level write IDs, instead
	 * matching on per-item write IDs.
	 */
	readonly id: WriteId = createWriteId();

	addRollback = (fn: () => void) => this.rollbacks.push(fn);
	rollback = () => {
		for (const fn of this.rollbacks.reverse()) {
			fn();
		}
		this.rollbacks = [];
	};

	get isEmpty(): boolean {
		return this.rollbacks.length === 0;
	}
}

/**
 * Error class for user-facing action failures that should trigger a toast notification.
 * Distinguishes between errors that should be displayed to users vs those that should
 * only be logged.
 */
export class DisplayedActionError extends Error {
	constructor(message: string) {
		super(message);
		this.name = "DisplayedActionError";

		// This line is necessary for proper prototype chain setup in some environments
		Object.setPrototypeOf(this, DisplayedActionError.prototype);
	}
}

/**
 * Base class for implementing optimistic updates with automatic rollback on failure.
 *
 * Provides a structured way to handle optimistic updates by separating local and remote operations:
 * - local changes are applied immediately and tracked in a transaction
 * - remote changes are executed asynchronously
 * - automatic rollback of local changes if remote operation fails
 * - standardized error handling with optional user feedback
 *
 * @template State - application state type that actions operate on
 * @template Args - arguments passed to execute the action
 * @template LocalResult - result type returned by local execution
 * @template RemoteResult - result type returned by remote execution
 */
export abstract class OptimisticAction<State, Args, LocalResult, RemoteResult> {
	constructor(public args: Args) {}

	/**
	 * Executes the local portion of an optimistic action, applying changes immediately to client state.
	 *
	 * The local method is responsible for:
	 * - Making optimistic updates to the application state
	 * - Recording rollback operations in the transaction
	 * - Validating inputs and throwing DisplayedActionError for user-facing failures
	 * - Returning any data needed by the remote operation
	 *
	 * @param tx - Transaction object for tracking rollback operations
	 * @param state - Current application state to modify
	 * @returns Promise resolving to data needed by remote operation
	 * @throws DisplayedActionError for user-facing validation/execution failures
	 * @throws Error for unexpected internal failures
	 */
	abstract local(tx: Transaction, state: State): Promise<LocalResult>;

	/**
	 * Executes the remote portion of an optimistic action, syncing local changes with the server.
	 *
	 * In most cases, this will simply call a FastAPI route. Does not modify client state directly (changes should come through streams).
	 *
	 * @param state - Current application state, used for read-only operations
	 * @param context - Object containing:
	 *   - localResult: Data returned from local execution
	 * @returns Promise that resolves when server sync is complete
	 * @throws Error if server operations fail, triggering automatic rollback
	 */
	abstract remote(
		context: {
			localResult: LocalResult;
			tx: Transaction;
		},
		state: State,
	): Promise<RemoteResult>;
}

/**
 * Result type returned by running an OptimisticAction through ActionExecutor.
 * Contains both the local and remote execution results if successful.
 *
 * @template LocalResult - Type returned by action's local execution
 * @template RemoteResult - Type returned by action's remote execution
 *
 * @property localResult - Result from optimistic local state changes
 * @property remoteResult - Result from server-side operation completion
 */
export type ActionResult<LocalResult, RemoteResult> = {
	localResult: LocalResult;
	remoteResult: RemoteResult;
};

/**
 * Error thrown when an action in the ActionExecutor exceeds the QUEUE_TIMEOUT_MS duration.
 * Used to prevent actions from hanging indefinitely and blocking the queue.
 *
 * @extends Error
 * @example
 * throw new TaskTimeoutError(); // "Task timed out"
 * throw new TaskTimeoutError("Custom timeout message");
 */
export class TaskTimeoutError extends Error {
	constructor(message = "Task timed out") {
		super(message);
		this.name = "TaskTimeoutError";
		Object.setPrototypeOf(this, TaskTimeoutError.prototype);
	}
}

/**
 * Manages sequential execution of OptimisticActions with timeout and error handling.
 *
 * Provides:
 * - Sequential action processing with FIFO queue
 * - Automatic timeout after QUEUE_TIMEOUT_MS
 * - Standardized error handling with user feedback
 *
 * @template State - Application state type actions operate on
 *
 * @remarks
 * - This queue doesn't track dependencies between tasks. It will move on to the next task
 *   even if the previous task fails.
 * - It also doesn't allow you to group tasks in batches. Batched tasks should be implemented
 *   by creating an action that uses the transaction primitive to group local operations, though
 *   there may be a need to implement a higher-level API for action batching in the future.
 */
export class ActionExecutor<State> {
	private queue: Array<() => Promise<void>> = [];
	private running = false;

	constructor(public state: State) {}

	private handleLocalError(error: unknown) {
		Sentry.captureException(error);
		if (error instanceof DisplayedActionError) {
			toast.error(error.message);
		} else {
			if (IS_DEV) {
				toast.error(`Failed to perform action locally: ${error}`);
				console.error(error);
			} else {
				toast.error("Failed to perform action locally.");
			}
		}
	}

	private handleRemoteError(error: unknown) {
		Sentry.captureException(error);
		if (error instanceof TaskTimeoutError) {
			toast.error("Operation timed out. Please try again.");
		} else {
			IS_DEV
				? toast.error(`Failed to sync with server: ${error}`)
				: toast.error("Failed to sync with server. Reverting changes.");
		}
	}

	/**
	 * Creates a task function that executes an optimistic action with error handling and timeout.
	 *
	 * The task:
	 * - Executes local changes immediately with rollback tracking
	 * - Syncs changes with server asynchronously
	 * - Handles errors by rolling back and showing user feedback
	 * - Resolves with results or null if failed
	 *
	 * @param action - OptimisticAction to execute
	 * @param resolve - Promise resolution callback
	 * @returns Async task function that executes the action
	 */
	private createTask<Args, LocalResult, RemoteResult>(
		action: OptimisticAction<State, Args, LocalResult, RemoteResult>,
		resolve: (value: ActionResult<LocalResult, RemoteResult> | null) => void,
	): () => Promise<void> {
		const task = async () => {
			const tx = new Transaction();
			let localResult: LocalResult;
			let remoteResult: RemoteResult;

			try {
				localResult = await action.local(tx, this.state);
			} catch (error) {
				this.handleLocalError(error);
				tx.rollback();
				resolve(null);
				return;
			}

			// if the transaction is empty, we don't need to sync with the server
			// example: moving a resource into a folder that already contains it
			if (tx.isEmpty) {
				resolve(null);
				return;
			}

			try {
				remoteResult = await action.remote({ localResult, tx }, this.state);
				resolve({ localResult, remoteResult });
			} catch (error) {
				this.handleRemoteError(error);
				tx.rollback();
				resolve(null);
			}
		};
		return task;
	}

	/**
	 * Executes an optimistic action through a sequential queue with timeout and error handling.
	 *
	 * Runs local changes immediately, then syncs with server. Automatically rolls back on failure.
	 *
	 * @template Args - Arguments for the action
	 * @template LocalResult - Result from local execution
	 * @template RemoteResult - Result from remote execution
	 * @param action - Optimistic action to execute
	 * @param args - Arguments for the action
	 * @returns Promise<ActionResult<LocalResult, RemoteResult> | null> - Results if successful, null if failed
	 * @throws {TaskTimeoutError} If action exceeds QUEUE_TIMEOUT_MS (30s)
	 */
	run = async <Args, LocalResult, RemoteResult>(
		action: OptimisticAction<State, Args, LocalResult, RemoteResult>,
	): Promise<ActionResult<LocalResult, RemoteResult> | null> => {
		return new Promise((resolve) => {
			this.queue.push(this.createTask(action, resolve));
			this.processQueue();
		});
	};

	private async runTaskWithTimeout(
		task: () => Promise<void>,
		timeoutMs: number,
	): Promise<void> {
		let timeoutId: ReturnType<typeof setTimeout> | undefined = undefined;

		const timeoutPromise = new Promise<void>((_, reject) => {
			timeoutId = setTimeout(() => {
				reject(new TaskTimeoutError());
			}, timeoutMs);
		});

		try {
			await Promise.race([task(), timeoutPromise]);
		} finally {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
		}
	}

	/**
	 * Processes queued tasks sequentially with timeout and error handling.
	 *
	 * @remarks
	 * Core queue processing logic that:
	 * - Executes one task at a time in FIFO order
	 * - Enforces timeout of QUEUE_TIMEOUT_MS (30s) per task
	 * - Handles errors and timeouts gracefully
	 * - Maintains queue processing state
	 */
	private async processQueue() {
		if (this.running) return;
		this.running = true;

		while (this.queue.length) {
			const task = this.queue.shift();
			if (!task) continue;

			try {
				await this.runTaskWithTimeout(task, QUEUE_TIMEOUT_MS);
			} catch (error) {
				Sentry.captureException(error);
				if (error instanceof TaskTimeoutError) {
					toast.error("Operation timed out. Please try again.");
				} else {
					toast.error("An error occurred while processing your request.");
				}
			}
		}

		this.running = false;
	}
}
