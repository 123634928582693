import { type DataType, FieldType, ScalarType } from "@api/schemas";
import {
	CaretCircleDown,
	CheckSquare,
	Clock,
	Eye,
	Hash,
	type IconProps,
	Link,
	MagnifyingGlass,
	TextAa,
} from "@phosphor-icons/react";

export const DataTypeIcon = ({
	dataType,
	...props
}: { dataType: DataType } & IconProps) => {
	switch (dataType.scalar_type) {
		case ScalarType.text:
			return <TextAa {...props} />;
		case ScalarType.select_option:
			return <CaretCircleDown {...props} />;
		case ScalarType.real:
			return <Hash {...props} />;
		case ScalarType.timestamptz:
			return <Clock {...props} />;
		case ScalarType.boolean:
			return <CheckSquare {...props} />;
		case ScalarType.record_link:
			return <Link {...props} />;
	}
};

/**
 * We differentiate between data type icons and field type icons because we need
 * field icons when creating fields, but in reality a field may have multiple
 * data types (e.g. a datetime might be a timestamptz or a date).
 */
export const FieldTypeIcon = ({
	fieldType,
	...props
}: {
	fieldType: FieldType;
} & IconProps) => {
	switch (fieldType) {
		case FieldType.text:
			return (
				<DataTypeIcon
					dataType={{ scalar_type: ScalarType.text, array_depth: 0 }}
					{...props}
				/>
			);

		case FieldType.boolean:
			return (
				<DataTypeIcon
					dataType={{ scalar_type: ScalarType.boolean, array_depth: 0 }}
					{...props}
				/>
			);

		case FieldType.select:
			return (
				<DataTypeIcon
					dataType={{ scalar_type: ScalarType.select_option, array_depth: 0 }}
					{...props}
				/>
			);

		case FieldType.datetime:
			return (
				<DataTypeIcon
					dataType={{ scalar_type: ScalarType.timestamptz, array_depth: 0 }}
					{...props}
				/>
			);

		case FieldType.number:
			return (
				<DataTypeIcon
					dataType={{ scalar_type: ScalarType.real, array_depth: 0 }}
					{...props}
				/>
			);

		case FieldType.relationship:
			return (
				<DataTypeIcon
					dataType={{ scalar_type: ScalarType.record_link, array_depth: 0 }}
					{...props}
				/>
			);

		case FieldType.lookup:
			return <MagnifyingGlass {...props} />;

		case "view":
			// Should never be used tbh
			return <Eye {...props} />;

		default: {
			const _exhaustiveCheck: never = fieldType;
			return _exhaustiveCheck;
		}
	}
};
