import { TableComponent } from "@/components/table/table-component";
import { useUploadsIndex } from "@/contexts/resource-indexes/hooks";
import { UploadsHomeTabState } from "@/contexts/uploads/tab-state";
import { AddUploadDropdown } from "@/pages/tabs/uploads/-components/add-upload-dropdown";
import { createFileRoute } from "@tanstack/react-router";
import { observer } from "mobx-react-lite";
import { toast } from "sonner";

const UploadsHomePage = observer(function UploadsHomePage() {
	const uploadsIndex = useUploadsIndex();
	return (
		<div className="flex h-full min-h-0 w-full grow flex-col gap-2 px-16 pt-4">
			<h1 className="px-2 font-bold text-2xl">Uploads</h1>

			<div className="px-2 py-2">
				<AddUploadDropdown />
			</div>
			<div className="relative flex grow flex-col">
				<TableComponent
					getRowId={(row) => row.uploadId}
					getRowLink={(row) => row.link}
					columns={uploadsIndex.columns}
					data={uploadsIndex.rows}
					editableProps={{
						deleteRows: () => {
							toast.error("Not implemented");
						},
					}}
					maxHeight={500}
					rowSelectionPopoverTop={"-3rem"}
				/>
			</div>
		</div>
	);
});

export const Route = createFileRoute("/uploads/")({
	component: UploadsHomePage,
	loader: ({ context: { tab } }) => {
		const tabState = new UploadsHomeTabState(tab);
		return { tabState };
	},
});
