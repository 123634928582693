import { getColorForSession } from "@/components/layout/right-sidebar/assistant-colors";
import { AssistantPresenceIndicator } from "@/components/layout/right-sidebar/assistant-presence";
import { ToggleLeftSidebarButton } from "@/components/layout/toggle-left-sidebar-button";
import { ToggleRightSidebarButton } from "@/components/layout/toggle-right-sidebar-button";
import { API_ENDPOINT_HTTP, IS_DEV } from "@/config";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import { AssistantStepPopover } from "@/contexts/tabs/assistant-tabset-tracker";
import { LinkTarget } from "@/contexts/tabs/router-types";
import type { Tab } from "@/contexts/tabs/tabs-context";
import { useTabStore } from "@/contexts/tabs/use-tab-store";
import { cn } from "@/lib/utils";
import type { TabId } from "@api/schemas";
import {
	ArrowLeft,
	ArrowRight,
	Copy,
	type Icon,
	Plus,
	Robot,
	X,
} from "@phosphor-icons/react";
import { RouterProvider } from "@tanstack/react-router";
import {
	DockviewReact,
	type DockviewReadyEvent,
	type IDockviewHeaderActionsProps,
	type IDockviewPanelProps,
	type IDockviewReactProps,
} from "dockview-react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { toast } from "sonner";

const ControlButton = ({
	Icon,
	onClick,
	className,
}: { Icon: Icon; onClick: () => void; className?: string }) => {
	return (
		<button
			type="button"
			className={cn(
				"flex items-center rounded-xs p-1 text-neutral-700 hover:bg-black/5",
				className,
			)}
			onClick={onClick}
		>
			<Icon size={12} />
		</button>
	);
};

const TabLeading = observer(
	({ panelProps }: { panelProps: IDockviewPanelProps }) => {
		const tabStore = useTabStore();

		const tabId = panelProps.api.id as TabId;
		let tab: Tab | undefined;
		const maybeTrackedSession = tabStore.getTrackedSession(
			panelProps.api.group,
		);
		if (maybeTrackedSession) {
			tab = maybeTrackedSession.tabs.get(tabId);
		} else {
			tab = tabStore.tabs.get(tabId);
		}
		if (tab === undefined) {
			console.error("Tab not found", tabId);
			return null;
		}
		const head = tab.state.head;

		const onClose = () => {
			tabStore.closeTab(tabId);
		};

		const isActive = tabStore.activeTab?.id === tabId;

		return (
			<div className="group flex h-full w-36 items-center border-r pr-1 pl-2 text-sm hover:bg-neutral-50">
				<div
					className="relative mr-1 flex min-w-0 grow items-center gap-2 overflow-hidden"
					style={{
						maskImage:
							"linear-gradient(to right, black calc(100% - 6px), transparent 100%)",
					}}
				>
					<head.icon size={16} className="shrink-0" />
					<span className="whitespace-nowrap">{head.label}</span>
				</div>
				{!maybeTrackedSession && (
					<ControlButton
						Icon={X}
						onClick={onClose}
						className={cn(
							"shrink-0 group-hover:block",
							isActive ? "block" : "hidden",
						)}
					/>
				)}
			</div>
		);
	},
);

const TabContent = observer(function TabContent({
	panelProps,
}: { panelProps: IDockviewPanelProps }) {
	const appContext = useAppContext();
	const tabStore = useTabStore();

	const tabId = panelProps.api.id as TabId;

	let tab: Tab | undefined;
	const maybeTrackedSession = tabStore.getTrackedSession(panelProps.api.group);

	if (maybeTrackedSession) {
		tab = maybeTrackedSession.tabs.get(tabId);
	} else {
		tab = tabStore.tabs.get(tabId);
	}
	if (tab === undefined) {
		console.error("Tab not found", tabId);
		return null;
	}

	return (
		<div key={tabId} className={cn("flex h-full flex-col bg-white")}>
			<div className="flex h-10 w-full shrink-0 items-center gap-2 border-b px-2">
				{/* Assistant URL copy button */}
				{IS_DEV && (
					<button
						type="button"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							// Copy href to clipboard
							navigator.clipboard.writeText(
								`${API_ENDPOINT_HTTP}/dev/assistant_ui${tab.parsedLocation?.href}`,
							);
							toast.success("Copied link to assistant view to clipboard");
						}}
						className="flex h-6 cursor-pointer items-center px-1.5 hover:bg-neutral-100"
					>
						<Robot size={12} />
					</button>
				)}
				{/* URL copy button */}
				<button
					type="button"
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						const url = new URL(
							tab.parsedLocation?.href,
							window.location.origin,
						).href;
						navigator.clipboard.writeText(url);
						toast.success("Copied URL to clipboard");
					}}
					className="flex h-6 cursor-pointer items-center px-1.5 hover:bg-neutral-100"
				>
					<Copy size={12} />
				</button>
				<button
					type="button"
					onClick={() => {
						runInAction(() => {
							if (appContext.commandKState.open) {
								appContext.commandKState.open = false;
							} else {
								appContext.commandKState.open = true;
							}
						});
					}}
					className="flex h-6 w-full cursor-pointer items-center border bg-neutral-50 px-2 hover:bg-neutral-100"
				>
					<span className="truncate font-mono text-neutral-500 text-xs">
						{tab.parsedLocation?.href}
					</span>
				</button>
				<AssistantPresenceIndicator
					filters={{
						resourceRef: null,
						threadId: null,
						goal: null,
						path: tab.parsedLocation?.href ?? null,
					}}
				/>
			</div>
			<div className="relative grow overflow-y-auto">
				{maybeTrackedSession && (
					<div className="absolute inset-0 z-20 bg-neutral-950/3" />
				)}
				<RouterProvider router={tab.router} />
			</div>
		</div>
	);
});

/**
 * Controls rendered on the left side of the "header", which is the area to the
 * right of the last tab until the end of the tab bar.
 */
const LeftControls = (props: IDockviewHeaderActionsProps) => {
	const tabStore = useTabStore();
	const onClick = () => {
		tabStore.addTab({
			initialLocation: {
				to: "/search",
			},
			position: {
				referenceGroup: props.group,
			},
		});
	};

	// Don't allow new tab creation if the group is tracked
	if (tabStore.getTrackedSession(props.group) !== undefined) {
		return null;
	}

	return (
		<div className="flex h-full items-center justify-center px-1">
			<ControlButton Icon={Plus} onClick={onClick} />
		</div>
	);
};

/**
 * Hook to check if a container element is at a specific position in the dock layout
 */
const isDockPosition = (
	groupElement: HTMLElement,
	position: "topLeft" | "topRight",
): boolean => {
	const dockviewRect = groupElement
		.closest(".dv-dockview")
		?.getBoundingClientRect();
	if (!dockviewRect) {
		throw new Error("Dockview not found");
	}
	const groupRect = groupElement.getBoundingClientRect();

	if (position === "topLeft") {
		return (
			Math.abs(dockviewRect.left - groupRect.left) <= 1 &&
			Math.abs(dockviewRect.top - groupRect.top) <= 1
		);
	}

	if (position === "topRight") {
		return (
			Math.abs(dockviewRect.right - groupRect.right) <= 1 &&
			Math.abs(dockviewRect.top - groupRect.top) <= 1
		);
	}

	return false;
};

/**
 * Controls rendered on the right side of the header.
 *
 * Since these controls are rendered for any group, we apply the assistant
 * group styles here (adding a border).
 */
const RightControls = (props: IDockviewHeaderActionsProps) => {
	const tabStore = useTabStore();
	const maybeTrackedSession = tabStore.getTrackedSession(props.group);
	const headerRef = useRef<HTMLDivElement>(null);
	const [isTopRightmost, setIsTopRightmost] = useState(false);

	useEffect(() => {
		// using this instead of onDidLayoutChange because the dimensions seem
		// to change on initial load without triggering a layout change
		// but layout changes also trigger dimensions changes
		setIsTopRightmost(isDockPosition(props.group.element, "topRight"));
		const disposable = props.api.onDidDimensionsChange(() => {
			setIsTopRightmost(isDockPosition(props.group.element, "topRight"));
		});

		return () => {
			disposable.dispose();
		};
	}, [props.api, props.group.element]);

	const onClick = () => {
		if (maybeTrackedSession) {
			tabStore.stopTrackingSession(maybeTrackedSession.assistantSessionId);
		} else {
			tabStore.closeGroup(props.group);
		}
	};

	// Apply a glow to the group view if it's a tracked session
	// We create a separate element for it because applying a box shadow
	// directly on the dv-groupview was annoying, the shadow would be clipped
	// by separators and such. Adding an overlay is cleaner.
	useEffect(() => {
		let glowElement: HTMLDivElement | null = null;

		if (headerRef.current && maybeTrackedSession) {
			const groupViewEl = headerRef.current.closest(
				".dv-groupview",
			) as HTMLDivElement;
			if (!groupViewEl) {
				throw new Error("Group view not found to apply ring");
			}

			const colorVar = getColorForSession(
				maybeTrackedSession.assistantSessionId,
				"border",
				500,
			).replace("border-", "color-");

			// Make the group view relative
			groupViewEl.style.position = "relative";

			// Create a glow element
			glowElement = document.createElement("div");
			glowElement.className =
				"absolute tab-glow-overlay inset-0 z-50 pointer-events-none opacity-60";
			glowElement.style.setProperty(
				"box-shadow",
				`inset 0px 0px 2px 2px var(--${colorVar})`,
			);
			groupViewEl.appendChild(glowElement);
		}
		return () => {
			glowElement?.remove();
		};
	}, [maybeTrackedSession]);

	return (
		<div
			ref={headerRef}
			className="flex h-full items-center justify-center px-1.5"
			data-tracked={maybeTrackedSession !== undefined}
		>
			{maybeTrackedSession && (
				<AssistantStepPopover
					assistantSessionId={maybeTrackedSession.assistantSessionId}
				/>
			)}
			<ControlButton Icon={X} onClick={onClick} />
			{isTopRightmost && <ToggleRightSidebarButton />}
		</div>
	);
};

const PrefixControls = observer((props: IDockviewHeaderActionsProps) => {
	const appContext = useAppContext();
	const tabStore = useTabStore();
	const [isTopLeftmost, setIsTopLeftmost] = useState(false);

	useEffect(() => {
		setIsTopLeftmost(isDockPosition(props.group.element, "topLeft"));
		const disposable = props.api.onDidDimensionsChange(() => {
			setIsTopLeftmost(isDockPosition(props.group.element, "topLeft"));
		});

		return () => {
			disposable.dispose();
		};
	}, [props.api, props.group.element]);

	const tab = tabStore.tabs.get(props.group.activePanel?.id as TabId);
	if (!tab) {
		throw new Error("Tab not found");
	}
	const isVisibleInGroup = props.api.isVisible;
	const historyIndex = tab.parsedLocation.state.__TSR_index;
	const canGoBack = historyIndex > 0;
	const canGoForward = historyIndex < tab.router.history.length - 1;

	const handleBack = () => {
		if (isVisibleInGroup && canGoBack) {
			tab.router.history.back();
		}
	};

	const handleForward = () => {
		if (isVisibleInGroup && canGoForward) {
			tab.router.history.forward();
		}
	};

	return (
		<div className="flex h-full items-center justify-center gap-1 px-1">
			{!appContext.leftSidebarState.showSidebar && isTopLeftmost && (
				<ToggleLeftSidebarButton />
			)}
			<ControlButton
				Icon={ArrowLeft}
				onClick={handleBack}
				className={cn(!canGoBack && "cursor-not-allowed opacity-30")}
			/>
			<ControlButton
				Icon={ArrowRight}
				onClick={handleForward}
				className={cn(!canGoForward && "cursor-not-allowed opacity-30")}
			/>
		</div>
	);
});

/**
 * The panels that we recognize for Dockview. We only have one kind of panel,
 * which uses the router to render its content.
 *
 * The ID of a panel is the same as the tabId it renders.
 */
const components: IDockviewReactProps["components"] = {
	main: (props) => {
		return <TabContent panelProps={props} />;
	},
};

/**
 * The "tab" is the handle that appears in the tab bar.
 */
const DefaultTabComponent: IDockviewReactProps["defaultTabComponent"] = (
	props,
) => {
	return <TabLeading panelProps={props} />;
};

export interface TabsProps {
	handleInitialUrl?: boolean;
	onInitialUrlHandled?: () => void;
}

export const Tabs = observer(function Tabs() {
	const tabStore = useTabStore();
	const initialHref = useRef(window.location.pathname + window.location.search);

	const onReady = (event: DockviewReadyEvent) => {
		tabStore.initLayout(event.api);

		// If we're not at the root path, open that path in a tab
		if (initialHref.current && initialHref.current !== "/") {
			tabStore.openLink(
				{
					href: initialHref.current,
				},
				LinkTarget.Self,
			);

			// Reset URL to root to avoid confusion - the tab system manages paths internally
			window.history.replaceState(null, "", "/");
		}
	};

	return (
		<div className="flex h-full w-full flex-col bg-neutral-50">
			<div className="relative min-h-0 grow ">
				<DockviewReact
					className="dockview-theme-custom"
					onReady={onReady}
					components={components}
					defaultTabComponent={DefaultTabComponent}
					leftHeaderActionsComponent={LeftControls}
					rightHeaderActionsComponent={RightControls}
					prefixHeaderActionsComponent={PrefixControls}
					defaultRenderer="always"
					// Disable edge drop targets
					// The UX is bad, and also it destroys and recreates groups
					// which messes with our assistantSessionId tracking, which
					// saves params on the group
					rootOverlayModel={{
						activationSize: {
							value: 0,
							type: "percentage",
						},
						size: {
							value: 0,
							type: "percentage",
						},
					}}
				/>
			</div>
		</div>
	);
});
