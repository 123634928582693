import type { BaseRendererProps } from "@/components/table/scalar-renderers/renderer-props";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
} from "@/components/ui/command";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { useFieldsStore } from "@/contexts/app-context/db-store/db-store-hooks";
import { useTablesStore } from "@/contexts/app-context/db-store/db-store-hooks";
import { isFieldPrimary } from "@/contexts/tables/stores/field-store";
import { cn } from "@/lib/utils";
import { ResourceLinkContent } from "@/plugins/resource-link";
import type {
	DataType,
	Field,
	FieldId,
	ResourceLink,
	TableId,
} from "@api/schemas";
import { Check } from "@phosphor-icons/react";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";

type RecordSelectorProps = {
	selectedRecordLinks: ResourceLink[];
	onSelectedRecordLinksChange: (links: ResourceLink[]) => void;
	foreignTableId: TableId;
};

const RecordSelector = observer(
	({
		selectedRecordLinks,
		onSelectedRecordLinksChange,
		foreignTableId,
	}: RecordSelectorProps) => {
		const tablesStore = useTablesStore();
		const fieldsStore = useFieldsStore();
		const [query, setQuery] = useState("");

		// TODO: maybe this should be a separate route?
		const foreignTable = tablesStore.getTableStateById({
			tableId: foreignTableId,
			pageIdx: 0,
			pageSize: 100,
			sortFields: [],
		});
		// Refresh foreign table records
		useEffect(() => {
			foreignTable.refresh();
		}, [foreignTable]);
		const foreignTableRecords = foreignTable.sortedRecords ?? [];
		const selectedRecords = foreignTableRecords.filter((record) =>
			selectedRecordLinks.includes(record.link),
		);
		const unselectedRecords = foreignTableRecords.filter(
			(record) => !selectedRecordLinks.includes(record.link),
		);

		const toggleRecord = (recordLink: ResourceLink) => {
			if (selectedRecordLinks.includes(recordLink)) {
				onSelectedRecordLinksChange(
					selectedRecordLinks.filter((link) => link !== recordLink),
				);
			} else {
				onSelectedRecordLinksChange([...selectedRecordLinks, recordLink]);
			}
		};

		const sortedFields = foreignTable.sortedFields;
		// TODO: this needs to take all the primary fields and concatenate them
		let labelField: [Field, DataType] | undefined;
		// Find the first primary field
		for (const field of sortedFields) {
			if (isFieldPrimary(field)) {
				const dataType = fieldsStore.getDataType(field.field_id);
				if (dataType.isErr()) {
					throw new Error(
						`Failed to get data type for field ${field.field_id}`,
					);
				}
				labelField = [field, dataType.value];
				break;
			}
		}
		if (labelField === undefined) {
			throw new Error("No primary field found! Implement primary fields");
		}

		return (
			<Command>
				<CommandInput
					value={query}
					onValueChange={setQuery}
					placeholder="Search records..."
				/>
				<CommandList>
					<CommandEmpty>No records found.</CommandEmpty>
					{selectedRecords.length > 0 && (
						<>
							<CommandGroup heading="Selected">
								{selectedRecords.map((record) => (
									<CommandItem
										key={record.order}
										value={record.link}
										onSelect={() => toggleRecord(record.link)}
									>
										<Check className="mr-2 h-4 w-4" />
										{/* TODO: use renderers or resource links */}
										<span className="truncate">
											{JSON.stringify(
												record.cell_values[labelField[0].field_id],
											)}
										</span>
									</CommandItem>
								))}
							</CommandGroup>
							<CommandSeparator />
						</>
					)}
					<CommandGroup heading="Available">
						{unselectedRecords.map((record) => (
							<CommandItem
								key={record.order}
								value={record.link}
								onSelect={() => toggleRecord(record.link)}
							>
								<div className="mr-2 w-4" />
								<span className="truncate">
									{JSON.stringify(record.cell_values[labelField[0].field_id])}
								</span>
							</CommandItem>
						))}
					</CommandGroup>
				</CommandList>
			</Command>
		);
	},
);

type RelationshipRendererProps = BaseRendererProps<"record_link"> & {
	foreignTableId?: TableId;
	foreignFieldId?: FieldId;
};

export const RelationshipRenderer = observer(
	(props: RelationshipRendererProps) => {
		// TODO(John): improve this logic
		const initialSelectedRecordLinks: ResourceLink[] = useMemo(() => {
			if (Array.isArray(props.value)) {
				return props.value;
			}
			if (props.value === null) {
				return [];
			}
			return [props.value];
		}, [props.value]);

		const [popoverOpen, setPopoverOpen] = useState(false);
		const [selectedRecordLinks, setSelectedRecordLinks] = useState(
			initialSelectedRecordLinks,
		);

		// Reset local state when cell value changes externally
		useEffect(() => {
			setSelectedRecordLinks(initialSelectedRecordLinks);
		}, [initialSelectedRecordLinks]);

		const handlePopoverClose = (open: boolean) => {
			if (popoverOpen && !open) {
				// Only update if there were actual changes
				if (
					JSON.stringify(selectedRecordLinks) !==
					JSON.stringify(initialSelectedRecordLinks)
				) {
					props.onUpdate?.(selectedRecordLinks);
				}
			}
			setPopoverOpen(open);
		};

		const cellStyles = cn(
			"flex h-full w-full min-w-0 items-start gap-1 truncate p-1 text-left text-neutral-800 text-sm",
			popoverOpen && "bg-blue-50 ring-2 ring-blue-300",
		);

		const displayValue =
			selectedRecordLinks.length > 0 ? (
				<div className="flex flex-wrap gap-1">
					{selectedRecordLinks.map((link, idx) => (
						<ResourceLinkContent
							key={`${link}-${
								// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
								idx
							}`}
							linkProps={{ href: link }}
							className="rounded-xs hover:bg-neutral-100 hover:underline"
						/>
					))}
				</div>
			) : (
				<span className="text-neutral-400">
					{props.editable && props.foreignTableId
						? "Select records..."
						: "No records selected"}
				</span>
			);

		if (!props.foreignTableId || !props.editable) {
			return <div className={cellStyles}>{displayValue}</div>;
		}

		return (
			<Popover open={popoverOpen} onOpenChange={handlePopoverClose}>
				<PopoverTrigger className={cellStyles}>{displayValue}</PopoverTrigger>
				<PopoverContent className="w-64 p-0" align="start">
					<RecordSelector
						selectedRecordLinks={selectedRecordLinks}
						onSelectedRecordLinksChange={setSelectedRecordLinks}
						foreignTableId={props.foreignTableId}
					/>
				</PopoverContent>
			</Popover>
		);
	},
);
