import type { PdfSearchParams } from "@/contexts/pdfviewer-context";
import { BaseTabState } from "@/contexts/tabs/base-tab-state";
import type { Tab } from "@/contexts/tabs/tabs-context";
import { makeAutoObservableAbstract } from "@/lib/make-auto-observable-abstract";
import type { UploadId } from "@api/schemas";
import { FileArrowUp } from "@phosphor-icons/react";

export class UploadTabState extends BaseTabState {
	searchParams: PdfSearchParams;
	uploadId: UploadId;

	constructor(tab: Tab, uploadId: UploadId, searchParams: PdfSearchParams) {
		super(tab);

		this.uploadId = uploadId;
		this.searchParams = searchParams;

		makeAutoObservableAbstract(this);
	}

	// We load the upload this way instead of in the constructor because
	// the store may sync after we initialize the tab.
	get upload() {
		return this.tab.tabStore.appState.workspace.uploads.getResourceById(
			this.uploadId,
		);
	}

	get head() {
		if (this.upload.isErr()) {
			return {
				icon: FileArrowUp,
				label: "Unknown Upload",
			};
		}
		let label = this.upload.value.name;
		if (this.searchParams.page_index !== undefined) {
			label += ` p.${this.searchParams.page_index + 1}`;
		}
		return {
			icon: FileArrowUp,
			label,
			resourceRef: {
				type: "upload" as const,
				resource_id: this.uploadId,
			},
		};
	}
}

export class UploadsHomeTabState extends BaseTabState {
	constructor(tab: Tab) {
		super(tab);
		makeAutoObservableAbstract(this);
	}

	get head() {
		return {
			icon: FileArrowUp,
			label: "Uploads",
		};
	}
}
