import { API_ENDPOINT_HTTP } from "@/config";
import type { AppState } from "@/contexts/app-context/app-context";
import openapiHashes from "@/generated/openapi-hashes.json";
import { createWebpageId } from "@/lib/id-generators";
import { ElectricOptimisticMap } from "@/lib/sync/electric";
import { indexWebpageRoute } from "@api/fastAPI";
import type { WebpageId, WebpageResource } from "@api/schemas";
import { makeAutoObservable } from "mobx";
import type { Result } from "neverthrow";

export class WebpageStore {
	appState: AppState;
	map: ElectricOptimisticMap<WebpageResource, ["webpage_id"], "write_id">;
	constructor(appState: AppState) {
		this.appState = appState;
		this.map = new ElectricOptimisticMap({
			shapeUrl: `${API_ENDPOINT_HTTP}/shapes/webpages`,
			pKeyFields: ["webpage_id"],
			writeIdField: "write_id",
			shapeHash: openapiHashes.WebpageResource,
			getBearerToken: this.appState.getTokenOrThrow,
		});
		makeAutoObservable(this);
	}

	// TODO(https://linear.app/village/issue/VIL-5421/return-map-loading-state-when-resource-not-found)
	// this should reflect the map's loading state if not found, so we can render error/loading states correctly
	getResourceById(webpageId: WebpageId): Result<WebpageResource, Error> {
		return this.map.get(webpageId);
	}

	indexWebpage = async ({ url }: { url: string }) => {
		const webpageId = createWebpageId();
		await indexWebpageRoute({ url, webpage_id: webpageId });
	};
}
