import { Switch } from "@/components/ui/switch";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { FileUploaderBox } from "@/pages/tabs/uploads/-components/file-uploader-box";
import { File, Link, Question } from "@phosphor-icons/react";

import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { useUploadsStore } from "@/contexts/app-context/db-store/db-store-hooks";
import { Plus } from "@phosphor-icons/react";
import { observer } from "mobx-react-lite";
import { useState } from "react";

const UrlUploadDialogContent = observer(function UrlUploadDialog() {
	const [url, setUrl] = useState("");
	const uploadsStore = useUploadsStore();

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (!url) return;
		uploadsStore.createUploadFromUrl({
			url,
		});
	};

	return (
		<DialogContent>
			<DialogHeader>
				<DialogTitle>Add upload</DialogTitle>
			</DialogHeader>

			<form onSubmit={handleSubmit} className="flex flex-col gap-4">
				<Input
					placeholder="URL"
					value={url}
					onChange={(e) => setUrl(e.target.value)}
					type="url"
					required
				/>
				<Button type="submit">Add upload</Button>
			</form>
		</DialogContent>
	);
});

const FileUploadDialogContent = observer(function FileUploadDialog() {
	const uploadsStore = useUploadsStore();
	const [inferMetadata, setInferMetadata] = useState(true);

	const onDrop = (files?: File[]) => {
		const promises = files?.map((file) => {
			return uploadsStore.createUpload({
				file,
				inferMetadata,
			});
		});
		Promise.all(promises || []);
	};

	return (
		<DialogContent className="max-h-[80vh] overflow-y-scroll">
			<DialogHeader>
				<DialogTitle>Select files</DialogTitle>
			</DialogHeader>
			<div className="flex items-center gap-2 text-neutral-700">
				<Switch checked={inferMetadata} onCheckedChange={setInferMetadata} />
				<span>Automatically infer metadata</span>
				<Tooltip>
					<TooltipTrigger>
						<Question weight="duotone" className="text-neutral-500 text-xl" />
					</TooltipTrigger>
					<TooltipContent>
						Processes the uploaded document with AI to infer its title, author,
						and other metadata.
					</TooltipContent>
				</Tooltip>
			</div>
			<FileUploaderBox description="PDFs and EPUBs supported" onDrop={onDrop} />
		</DialogContent>
	);
});

export const AddUploadDropdown = () => {
	const [openDialog, setOpenDialog] = useState<"file" | "url" | null>(null);

	return (
		<>
			<DropdownMenu>
				<DropdownMenuTrigger asChild>
					<Button className="max-w-max gap-2" size="sm" variant="outline">
						<Plus weight="bold" /> Add upload
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent align="start">
					<DropdownMenuItem
						onSelect={() => setOpenDialog("file")}
						className="flex items-center gap-1.5"
					>
						<File />
						From file
					</DropdownMenuItem>
					<DropdownMenuItem
						onSelect={() => setOpenDialog("url")}
						className="flex items-center gap-1.5"
					>
						<Link />
						From URL
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenu>
			<Dialog
				open={openDialog === "file"}
				onOpenChange={(open) => {
					if (!open) setOpenDialog(null);
				}}
			>
				<FileUploadDialogContent />
			</Dialog>
			<Dialog
				open={openDialog === "url"}
				onOpenChange={(open) => {
					if (!open) setOpenDialog(null);
				}}
			>
				<UrlUploadDialogContent />
			</Dialog>
		</>
	);
};
