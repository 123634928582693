import { API_ENDPOINT_HTTP } from "@/config";
import type { AppState } from "@/contexts/app-context/app-context";
import openapiHashes from "@/generated/openapi-hashes.json";
import { createPageId, createWriteId } from "@/lib/id-generators";
import { OptimisticAction, type Transaction } from "@/lib/sync/action-executor";
import { ElectricOptimisticMap, ElectricSyncedMap } from "@/lib/sync/electric";
import { ElectricProvider } from "@/lib/y-electric";
import {
	createPageRoute,
	deletePagesRoute,
	savePageAwarenessRoute,
	savePageOperationRoute,
	updatePageRoute,
} from "@api/fastAPI";
import type {
	PageId,
	PageLink,
	PageResource,
	PageResourceDelete,
	PageResourceUpdate,
	UserId,
} from "@api/schemas";
import { makeAutoObservable } from "mobx";
import { computedFn } from "mobx-utils";
import { type Result, err, ok } from "neverthrow";
import { Awareness } from "y-protocols/awareness.js";
import * as Y from "yjs";

function createPageResource(name: string, creatorId: UserId): PageResource {
	const pageId = createPageId();
	const writeId = createWriteId();
	const createdAt = new Date().toISOString();
	return {
		page_id: pageId,
		name,
		creator_id: creatorId,
		write_id: writeId,
		created_at: createdAt,
		updated_at: createdAt,
		deleted_at: null,
		content: "",
		is_home: false,
	};
}

/**
 * Necessary when you open the same page in multiple tabs. Without a ref
 * counter, calling .destroy anywhere else will destroy the provider for all
 * tabs.
 */
interface ProviderRef {
	provider: ElectricProvider;
	refCount: number;
}

export class PageStore {
	appState: AppState;
	map: ElectricOptimisticMap<PageResource, ["page_id"], "write_id">;

	// YDocs for collaborative editing
	private providerRefs = new Map<PageId, ProviderRef>();

	constructor(appState: AppState) {
		makeAutoObservable(this);
		this.appState = appState;
		this.map = new ElectricOptimisticMap({
			shapeUrl: `${API_ENDPOINT_HTTP}/shapes/pages`,
			pKeyFields: ["page_id"],
			writeIdField: "write_id",
			shapeHash: openapiHashes.PageResource,
			getBearerToken: this.appState.getTokenOrThrow,
		});
	}

	get homePageId(): Result<PageId, Error> {
		for (const page of this.map.values()) {
			if (page.is_home) {
				return ok(page.page_id);
			}
		}
		return err(new Error("Home page not found"));
	}

	get homePage(): Result<PageResource, Error> {
		return this.homePageId.andThen((pageId) =>
			this.map.get(pageId).map((page) => page),
		);
	}

	getProvider(pageId: PageId) {
		let providerRef = this.providerRefs.get(pageId);
		if (!providerRef) {
			const ydoc = new Y.Doc();
			const awareness = new Awareness(ydoc);
			const provider = new ElectricProvider({
				doc: ydoc,
				operationsStreamUrl: `${API_ENDPOINT_HTTP}/shapes/pages_operations/${pageId}`,
				awarenessStreamUrl: `${API_ENDPOINT_HTTP}/shapes/pages_awareness/${pageId}`,
				getBearerToken: this.appState.getTokenOrThrow,
				postOperation: (op) =>
					savePageOperationRoute({
						page_id: pageId,
						op,
					}),
				postAwareness: (clientId, op) =>
					savePageAwarenessRoute({
						page_id: pageId,
						client_id: clientId,
						op,
					}),
				awareness,
			});
			providerRef = {
				provider,
				refCount: 1,
			};
			this.providerRefs.set(pageId, providerRef);
		} else {
			providerRef.refCount++;
		}

		return providerRef.provider;
	}

	destroyProvider(pageId: PageId) {
		const providerRef = this.providerRefs.get(pageId);
		if (providerRef) {
			providerRef.refCount--;
			if (providerRef.refCount <= 0) {
				providerRef.provider.destroy();
				this.providerRefs.delete(pageId);
			}
		}
	}

	createPage = (props: CreatePageAction["args"]) => {
		this.appState.actionQueue.run(
			new CreatePageAction({
				...props,
			}),
		);
	};

	updatePage = (props: UpdatePageAction["args"]) => {
		this.appState.actionQueue.run(
			new UpdatePageAction({
				...props,
			}),
		);
	};

	deletePages = (props: DeletePagesAction["args"]) => {
		this.appState.actionQueue.run(
			new DeletePagesAction({
				...props,
			}),
		);
	};
}

export class CreatePageAction extends OptimisticAction<
	AppState,
	{
		onLocalSuccess: (newPage: PageResource) => void;
	},
	{
		insert: PageResource;
	},
	void
> {
	async local(
		tx: Transaction,
		state: AppState,
	): Promise<{
		insert: PageResource;
	}> {
		const newPageResource = createPageResource("New Page", state.userId);
		state.workspace.pages.map.insert(tx, newPageResource);
		this.args.onLocalSuccess(newPageResource);
		return { insert: newPageResource };
	}

	async remote(context: {
		localResult: { insert: PageResource };
	}): Promise<void> {
		await createPageRoute({
			insert: context.localResult.insert,
		});
	}
}

export class UpdatePageAction extends OptimisticAction<
	AppState,
	{
		pageId: PageId;
		name?: string;
		content?: string;
	},
	{
		update: PageResourceUpdate;
	},
	void
> {
	async local(
		tx: Transaction,
		state: AppState,
	): Promise<{ update: PageResourceUpdate }> {
		const update: PageResourceUpdate = {
			page_id: this.args.pageId,
			write_id: createWriteId(),
			updated_at: new Date().toISOString(),
			values: {
				name: this.args.name,
				content: this.args.content,
			},
		};

		state.workspace.pages.map.update(tx, this.args.pageId, {
			write_id: update.write_id,
			updated_at: update.updated_at,
			name: update.values.name,
			content: update.values.content,
		});
		return { update };
	}

	async remote(context: {
		localResult: { update: PageResourceUpdate };
	}): Promise<void> {
		await updatePageRoute({
			update: context.localResult.update,
		});
	}
}

export class DeletePagesAction extends OptimisticAction<
	AppState,
	{
		pageIds: Set<PageId>;
	},
	{
		page_delete: PageResourceDelete;
	},
	void
> {
	async local(
		tx: Transaction,
		state: AppState,
	): Promise<{ page_delete: PageResourceDelete }> {
		const page_delete: PageResourceDelete = {
			page_ids: Array.from(this.args.pageIds),
			deleted_at: new Date().toISOString(),
		};

		for (const pageId of this.args.pageIds) {
			state.workspace.pages.map.delete(tx, pageId);
		}
		return { page_delete };
	}

	async remote(context: {
		localResult: { page_delete: PageResourceDelete };
	}): Promise<void> {
		await deletePagesRoute({
			delete: context.localResult.page_delete,
		});
	}
}

export class PageLinkStore {
	appState: AppState;
	map: ElectricSyncedMap<PageLink, ["page_id", "link"]>;

	constructor(appState: AppState) {
		this.appState = appState;
		this.map = new ElectricSyncedMap({
			shapeUrl: `${API_ENDPOINT_HTTP}/shapes/pages_links`,
			pKeyFields: ["page_id", "link"],
			shapeHash: openapiHashes.PageLink,
			getBearerToken: () => this.appState.getTokenOrThrow(),
		});
	}

	getLinksForPage = computedFn((pageId: PageId): string[] => {
		return Array.from(this.map.values())
			.filter((pageLink) => pageLink.page_id === pageId)
			.sort((a, b) => a.position - b.position)
			.map((pageLink) => pageLink.link);
	});
}
