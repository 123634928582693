import type { Transaction } from "@/lib/sync/action-executor";
import type {
	LocalDelete,
	LocalInsert,
	LocalUpdate,
	LocalWrite,
	PKey,
} from "@/lib/sync/local-write";
import { makeAutoObservable, runInAction } from "mobx";
import { type Result, err, ok } from "neverthrow";

type PKeyStr<
	TItem extends object,
	TPKeyFields extends (keyof TItem)[],
> = TPKeyFields["length"] extends 1
	? TItem[TPKeyFields[0]] extends string
		? TItem[TPKeyFields[0]]
		: never
	: string;

export abstract class ItemMap<
	TItem extends object,
	TPKeyFields extends (keyof TItem)[],
> {
	pKeyFields: TPKeyFields;
	items: Map<PKeyStr<TItem, TPKeyFields>, TItem> = new Map();

	constructor(props: {
		pKeyFields: TPKeyFields;
	}) {
		this.pKeyFields = props.pKeyFields;
	}

	/**
	 * In case there are multiple primary key fields, we need to create a
	 * string key from them.
	 */
	pKeyToStr(pKey: PKey<TItem, TPKeyFields>): PKeyStr<TItem, TPKeyFields> {
		if (this.pKeyFields.length === 1) {
			return pKey as PKeyStr<TItem, TPKeyFields>;
		}
		return JSON.stringify(pKey) as PKeyStr<TItem, TPKeyFields>;
	}

	strToPKey(pKeyStr: PKeyStr<TItem, TPKeyFields>): PKey<TItem, TPKeyFields> {
		if (this.pKeyFields.length === 1) {
			return pKeyStr as PKey<TItem, TPKeyFields>;
		}
		return JSON.parse(pKeyStr) as PKey<TItem, TPKeyFields>;
	}

	/**
	 * Extract pkey from an item.
	 */
	extractPkey(item: TItem): PKey<TItem, TPKeyFields> {
		if (this.pKeyFields.length === 1) {
			return item[this.pKeyFields[0]] as PKey<TItem, TPKeyFields>;
		}
		const keyObject: Record<string, unknown> = {};
		for (const key of this.pKeyFields) {
			keyObject[key as string] = item[key];
		}
		return keyObject as PKey<TItem, TPKeyFields>;
	}

	extractPkeyStr(item: TItem): PKeyStr<TItem, TPKeyFields> {
		return this.pKeyToStr(this.extractPkey(item));
	}

	get(pKey: PKey<TItem, TPKeyFields>): TItem | undefined {
		return this.items.get(this.pKeyToStr(pKey));
	}

	has(pKey: PKey<TItem, TPKeyFields>): boolean {
		return this.items.has(this.pKeyToStr(pKey));
	}

	set(pKey: PKey<TItem, TPKeyFields>, item: TItem): void {
		this.items.set(this.pKeyToStr(pKey), item);
	}

	delete(pKey: PKey<TItem, TPKeyFields>): void {
		this.items.delete(this.pKeyToStr(pKey));
	}

	values() {
		return this.items.values();
	}
}

/**
 * The OptimisticMap maintains a Map of LocalWrite objects, which we create for
 * optimistic updates. When reading from the OptimisticMap, we process the
 * LocalWrites such that the query results are as if those writes were already
 * committed.
 *
 * Expects an implementation of matchWrite that runs the removeWrite callback
 * once the write has been confirmed to the syncedMap.
 *
 * We pass in removeWrite so it can run synchronously. For the
 * ElectricOptimisticMap, this means that writes can be cleared in the same
 * transaction as the map is updated by ChangeMessages.
 *
 */
export class OptimisticMap<
	TItem extends object,
	TPKeyFields extends (keyof TItem)[],
> {
	/**
	 * Server-authoritative map of items. Not managed by this class; passed in
	 * via the constructor.
	 */
	private readonly syncedMap: ItemMap<TItem, TPKeyFields>;
	/**
	 * Callback for registering a watch function for confirmation of server-side
	 * persistence. When a write is made locally, we call this function, which
	 * should register a pending operation that clears the write once we know
	 * the following has happened:
	 * 	1. The write has executed successfully on the server.
	 * 	2. The corresponding change has been received and made in the `itemMap`.
	 *
	 * Since the write is made in `itemMap`, we can clear the now-redundant
	 * optimistic write.
	 */
	private matchWrite: (
		write: LocalWrite<TItem, TPKeyFields>,
		removeWrite: () => void,
	) => void;

	/**
	 * Map of item keys to pending local writes for that item.
	 */
	private writeMap = new Map<
		PKeyStr<TItem, TPKeyFields>,
		Array<LocalWrite<TItem, TPKeyFields>>
	>();
	/**
	 * Internal write counter used for identifying writes.
	 */
	private writeIndex = 0;

	constructor(props: {
		itemMap: ItemMap<TItem, TPKeyFields>;
		matchWrite: (
			write: LocalWrite<TItem, TPKeyFields>,
			removeWrite: () => void,
		) => void;
	}) {
		makeAutoObservable(this);
		this.syncedMap = props.itemMap;
		this.matchWrite = props.matchWrite;
	}

	#registerWrite(tx: Transaction, write: LocalWrite<TItem, TPKeyFields>): void {
		let pKeyStr: PKeyStr<TItem, TPKeyFields>;
		if (write.operation === "insert") {
			pKeyStr = this.syncedMap.extractPkeyStr(write.value);
		} else {
			pKeyStr = this.syncedMap.pKeyToStr(write.pKey);
		}
		if (!this.writeMap.has(pKeyStr)) {
			this.writeMap.set(pKeyStr, []);
		}
		// biome-ignore lint/style/noNonNullAssertion: <explanation>
		const itemWrites = this.writeMap.get(pKeyStr)!;
		itemWrites.push(write);

		const removeWrite = () => {
			runInAction(() => {
				const index = itemWrites.findIndex((w) => w.index === write.index);
				if (index === -1) {
					throw new Error("Write not found");
				}
				itemWrites.splice(index, 1);
			});
		};

		tx.addRollback(() => {
			removeWrite();
		});

		// TODO(John): what does it make sense to do here for transactions?
		// should failing to sync trigger a rollback? should matches be
		// buffered until all the writes in a transaction have been matched?
		this.matchWrite(write, removeWrite);
	}

	/**
	 * This base implementation is only used for syncing tables, where we don't
	 * have a strong invalidation mechanism for write IDs. So right now, we
	 * generate them inside the call and don't worry about checking them
	 * elsewhere.
	 */
	insert(tx: Transaction, item: TItem): void {
		const write: LocalInsert<TItem> = {
			index: this.writeIndex++,
			operation: "insert",
			value: item,
			transactionId: tx.id,
		};
		this.#registerWrite(tx, write);
	}

	update(
		tx: Transaction,
		pKey: LocalUpdate<TItem, TPKeyFields>["pKey"],
		value: LocalUpdate<TItem, TPKeyFields>["value"],
	): void {
		const write: LocalUpdate<TItem, TPKeyFields> = {
			index: this.writeIndex++,
			operation: "update",
			pKey,
			value,
			transactionId: tx.id,
		};
		this.#registerWrite(tx, write);
	}

	delete(tx: Transaction, pKey: LocalDelete<TItem, TPKeyFields>["pKey"]): void {
		const write: LocalDelete<TItem, TPKeyFields> = {
			index: this.writeIndex++,
			operation: "delete",
			pKey,
			transactionId: tx.id,
		};
		this.#registerWrite(tx, write);
	}

	/**
	 * Get the value for a given pKey, coalescing any local writes over the
	 * base value.
	 */
	get(pKey: PKey<TItem, TPKeyFields>): Result<TItem, Error> {
		// Get existing item and pending writes
		const currentItem = this.syncedMap.get(pKey);
		const pendingWrites = [
			...(this.writeMap.get(this.syncedMap.pKeyToStr(pKey)) ?? []),
		];

		let result: TItem | undefined = undefined;

		if (currentItem === undefined) {
			const firstWrite = pendingWrites.at(0);
			if (firstWrite?.operation === "insert") {
				pendingWrites.shift();
				result = { ...firstWrite.value };
			} else {
				return err(new Error("No value found"));
			}
		} else {
			result = { ...currentItem };
		}

		// Apply writes
		for (const write of pendingWrites) {
			switch (write.operation) {
				case "update": {
					// For optional properties, Typescript accepts both missing
					// values and undefined values, but these don't behave the
					// same when spread; undefined values will override the
					// existing value. So, we filter out undefined values here.
					const updates = Object.fromEntries(
						Object.entries(write.value).filter(
							([_, value]) => value !== undefined,
						),
					);
					result = {
						...result,
						...updates,
					};
					break;
				}
				case "delete":
					return err(
						new Error("Tried to get item that has been locally deleted"),
					);
				case "insert": {
					return err(
						new Error(
							"Got an insert for an object that has already been inserted",
						),
					);
				}
			}
		}
		return ok(result);
	}

	/**
	 * Get all valid keys in the map, coalescing pending local writes.
	 * TODO(John): this can definitely be more efficient.
	 */
	get keys(): PKey<TItem, TPKeyFields>[] {
		// Get all base keys from shape map
		const pKeyStrs = new Set(this.syncedMap.items.keys());

		for (const [pKeyStr, writes] of this.writeMap.entries()) {
			for (const write of writes) {
				// Add keys from pending inserts
				if (write.operation === "insert") {
					pKeyStrs.add(pKeyStr);
				}
				// Remove keys from pending deletes
				else if (write.operation === "delete") {
					pKeyStrs.delete(pKeyStr);
				}
			}
		}
		return Array.from(pKeyStrs).map((pKeyStr) =>
			this.syncedMap.strToPKey(pKeyStr),
		);
	}

	/**
	 * Get all values in the map, coalescing pending local writes.
	 *
	 * TODO(John): can this be more efficient?
	 */
	values(): TItem[] {
		const values: TItem[] = [];
		for (const key of this.keys) {
			const value = this.get(key);
			if (value.isOk()) {
				values.push(value.value);
			}
		}
		return values;
	}

	has(id: PKey<TItem, TPKeyFields>): boolean {
		return this.get(id).isOk();
	}
}

export const MATCH_WRITE_TIMEOUT_MS = 60_000;
