import { Command } from "@/components/ui/command";
import { WebSearchIndexTabState } from "@/contexts/web/tab-state";
import { cn } from "@/lib/utils";
import { WebSearchComboboxCommandList } from "@/pages/tabs/web-search/-components/web-search-combobox-command-list";
import { Outlet, createFileRoute } from "@tanstack/react-router";
import { Command as CommandPrimitive } from "cmdk";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect } from "react";
import { useRef } from "react";

const WebSearchHeader = observer(() => {
	const { tabState } = Route.useLoaderData();
	const inputRef = useRef<HTMLInputElement>(null);
	const dropdownRef = useRef<HTMLDivElement>(null);

	const handleClickOutside = useCallback(
		(event: MouseEvent) => {
			if (
				inputRef.current &&
				!inputRef.current.contains(event.target as Node) &&
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target as Node)
			) {
				tabState.form.setShowCommandList(false);
			}
		},
		[tabState],
	);

	const handleEscapeKey = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === "Escape") {
				tabState.form.setShowCommandList(false);
				if (inputRef.current) {
					inputRef.current.blur();
				}
			}
		},
		[tabState],
	);

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		document.addEventListener("keydown", handleEscapeKey);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
			document.removeEventListener("keydown", handleEscapeKey);
		};
	}, [handleClickOutside, handleEscapeKey]);

	useEffect(() => {
		if (inputRef.current) {
			tabState.form.searchInputElement = inputRef.current;
		}
	}, [tabState]);

	return (
		<div className="bg-white">
			<div className="relative w-full min-w-0 px-2">
				<div className="flex w-full min-w-0 gap-1 py-2">
					<div className="relative w-full min-w-0">
						<Command shouldFilter={false}>
							<CommandPrimitive.Input
								placeholder="Search"
								value={tabState.form.config.query}
								className={cn(
									"flex w-full grow items-center border py-1 pr-1 pl-2 outline-hidden ring-1",
									tabState.form.showCommandList
										? "border-blue-300 ring-blue-100"
										: "ring-transparent",
								)}
								onValueChange={(value) => {
									tabState.form.setQuery(value);
								}}
								onFocus={() => tabState.form.setShowCommandList(true)}
								onKeyDown={(event) => {
									// After the user executes a search, we hide the command list.
									// If they continue to type another query, we want to show it via this
									// onKeyDown handler, because the onFocus will not be triggered again.
									tabState.form.setShowCommandList(true);
									if (event.key === "Escape") {
										inputRef.current?.blur();
									}
								}}
								ref={inputRef}
							/>
							{tabState.form.showCommandList ? (
								<div
									ref={dropdownRef}
									className={cn(
										"absolute top-full right-0 left-0 z-20 mt-2 max-h-[300px] overflow-y-auto border border-neutral-200 bg-white shadow-lg",
									)}
								>
									<WebSearchComboboxCommandList
										setShowCommandList={(show) => {
											tabState.form.setShowCommandList(show);
										}}
									/>
								</div>
							) : null}
						</Command>
					</div>
				</div>
			</div>
		</div>
	);
});

export const WebSearchPage = observer(() => {
	return (
		<div className="flex h-full min-h-0 w-full min-w-0 flex-col px-16 pt-4">
			<WebSearchHeader />
			<Outlet />
		</div>
	);
});

export const Route = createFileRoute("/web-search")({
	component: WebSearchPage,
	loader: ({ context: { tab } }) => {
		const tabState = new WebSearchIndexTabState(tab);
		return {
			tabState,
		};
	},
});
