import { BaseTabState } from "@/contexts/tabs/base-tab-state";
import type { Tab } from "@/contexts/tabs/tabs-context";
import type { WebSearchParams } from "@/contexts/web/stores/web-search-store";
import { makeAutoObservableAbstract } from "@/lib/make-auto-observable-abstract";
import type { WebSearchFull, WebSearchId } from "@api/schemas";
import { Globe, MagnifyingGlass } from "@phosphor-icons/react";
import { makeAutoObservable } from "mobx";
import { z } from "zod";

export class WebIndexTabState extends BaseTabState {
	constructor(tab: Tab) {
		super(tab);
		makeAutoObservableAbstract(this);
	}

	get head() {
		return {
			icon: Globe,
			label: "Webpages",
		};
	}
}

class WebSearchForm {
	tab: Tab;
	config: WebSearchParams;
	// UI components
	showCommandList = false;
	searchInputElement: HTMLInputElement | null = null;

	constructor(tab: Tab, config: WebSearchParams) {
		makeAutoObservable(this);
		this.tab = tab;
		this.config = config;
	}

	setQuery(query: string) {
		this.config.query = query;
	}

	setShowCommandList(show: boolean) {
		this.showCommandList = show;
	}

	handleSearch() {
		this.tab.tabStore.appState.webSearchStore.initiateWebSearch({
			config: this.config,
			onLocalSuccess: (resource) => {
				this.tab.router.navigate({
					to: "/web-search/search/$web-search-id",
					params: {
						"web-search-id": resource.web_search_id,
					},
					search: {
						page: 1,
					},
				});
			},
		});
	}
}

/**
 * Tab state for the web search index page. This is the state for the page that
 * shows the web search form with no loaded results.
 */
export class WebSearchIndexTabState extends BaseTabState {
	form: WebSearchForm;

	constructor(tab: Tab) {
		super(tab);
		makeAutoObservableAbstract(this);
		this.form = new WebSearchForm(tab, {
			query: "",
		});
	}

	get head() {
		return {
			icon: MagnifyingGlass,
			label: "Web Search",
		};
	}
}

export const RESULTS_PER_PAGE = 10;

export const webSearchSearchSchema = z.object({
	page: z.number().min(1),
});

export type WebSearchSearchParams = z.infer<typeof webSearchSearchSchema>;

interface WebSearchResultParams {
	webSearchId: WebSearchId;
	pageNum: number;
}

export class WebSearchResultsTabState extends BaseTabState {
	private resultsLoaded = false;
	form: WebSearchForm;
	urlParams: WebSearchResultParams;

	constructor(tab: Tab, urlParams: WebSearchResultParams) {
		super(tab);
		makeAutoObservableAbstract(this);
		this.urlParams = urlParams;
		this.form = new WebSearchForm(tab, {
			query: "",
		});
	}

	get fullResult(): WebSearchFull | null {
		const fullResult = this.tab.tabStore.appState.webSearchStore.getFullResult(
			this.urlParams.webSearchId,
		);
		if (fullResult === undefined) {
			return null;
		}
		if (!this.resultsLoaded) {
			this.resultsLoaded = true;
			this.form.setQuery(fullResult.query);
		}
		return fullResult;
	}

	get head() {
		if (this.fullResult === null) {
			return {
				icon: MagnifyingGlass,
				label: "Web Search",
				resourceRef: undefined,
			};
		}
		return {
			icon: MagnifyingGlass,
			label: `${this.fullResult.query} - Page ${this.urlParams.pageNum}`,
			resourceRef: {
				type: "web-search-result" as const,
				resource_id: this.fullResult.web_search_id,
			},
		};
	}
}
