/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';

import * as axios from 'axios';
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios';

import type {
  AddFieldRequest,
  AddRelationshipRequest,
  BodyCreateUploadRoute,
  CreateComputedTableRequest,
  CreateMessageRequest,
  CreatePageRequest,
  CreateTableRequest,
  CreateUploadFromUrlRequest,
  CrossrefResponse,
  DeleteFieldRequest,
  DeletePagesRequest,
  DeleteRecordsRequest,
  DeleteRelationshipRequest,
  DeleteTablesRequest,
  DummyShapes200,
  EdgarDocumentWithFiling,
  EdgarEntityFiling,
  EdgarEntityResource,
  EdgarEntityWithFilings,
  EdgarFilingFull,
  FeedItemId,
  Field,
  GetTableRecordsResponse,
  GetTableRecordsRouteParams,
  GetTableSizeResponse,
  GetTableSizeRouteParams,
  GoogleBooksResponse,
  GuessRssUrlParams,
  HTTPValidationError,
  IndexFeedRequest,
  IndexWebpageRequest,
  InferUploadMetadataRequest,
  InferUploadMetadataResponse,
  InsertEmptyRecordsRequest,
  PageAwarenessRequest,
  PageId,
  PageOperationRequest,
  PageResolution,
  PreviewRssFeedParams,
  PreviewRssFeedResponse,
  QueryCrossrefRequest,
  QueryGoogleBooksRequest,
  RefetchFeedChannelParams,
  RenameTableRequest,
  Resource,
  ResourceRef,
  RunQueryRequest,
  RunQueryResponse,
  SearchFull,
  SearchId,
  SearchRequest,
  StepState,
  TableUpdateEvent,
  TocSection,
  UpdateDatetimeTimeFormatRequest,
  UpdateFieldRequest,
  UpdatePageRequest,
  UpdateRecordsRequest,
  UpdateSelectFieldPropertiesRequest,
  UpdateUploadMetadataRequest,
  UploadId,
  UserSessionId,
  WatchTableRequest,
  WebSearchId,
  WebSearchResource,
  WebSearchResponse,
  WebpageId
} from './schemas';

/**
 * @summary Search Route
 */
export const searchRoute = (
    searchRequest: SearchRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SearchFull>> => {
    
    
    return axios.default.post(
      `/search`,
      searchRequest,options
    );
  }



export const getSearchRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchRoute>>, TError,{data: SearchRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof searchRoute>>, TError,{data: SearchRequest}, TContext> => {
    
const mutationKey = ['searchRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof searchRoute>>, {data: SearchRequest}> = (props) => {
          const {data} = props ?? {};

          return  searchRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SearchRouteMutationResult = NonNullable<Awaited<ReturnType<typeof searchRoute>>>
    export type SearchRouteMutationBody = SearchRequest
    export type SearchRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Search Route
 */
export const useSearchRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchRoute>>, TError,{data: SearchRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof searchRoute>>,
        TError,
        {data: SearchRequest},
        TContext
      > => {

      const mutationOptions = getSearchRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Search Route
 */
export const getSearchRoute = (
    searchId: SearchId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SearchFull>> => {
    
    
    return axios.default.get(
      `/search/${searchId}`,options
    );
  }


export const getGetSearchRouteQueryKey = (searchId: SearchId,) => {
    return [`/search/${searchId}`] as const;
    }

    
export const getGetSearchRouteQueryOptions = <TData = Awaited<ReturnType<typeof getSearchRoute>>, TError = AxiosError<HTTPValidationError>>(searchId: SearchId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSearchRouteQueryKey(searchId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSearchRoute>>> = ({ signal }) => getSearchRoute(searchId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(searchId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSearchRoute>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetSearchRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getSearchRoute>>>
export type GetSearchRouteQueryError = AxiosError<HTTPValidationError>


export function useGetSearchRoute<TData = Awaited<ReturnType<typeof getSearchRoute>>, TError = AxiosError<HTTPValidationError>>(
 searchId: SearchId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getSearchRoute>>,
          TError,
          Awaited<ReturnType<typeof getSearchRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetSearchRoute<TData = Awaited<ReturnType<typeof getSearchRoute>>, TError = AxiosError<HTTPValidationError>>(
 searchId: SearchId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getSearchRoute>>,
          TError,
          Awaited<ReturnType<typeof getSearchRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetSearchRoute<TData = Awaited<ReturnType<typeof getSearchRoute>>, TError = AxiosError<HTTPValidationError>>(
 searchId: SearchId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Search Route
 */

export function useGetSearchRoute<TData = Awaited<ReturnType<typeof getSearchRoute>>, TError = AxiosError<HTTPValidationError>>(
 searchId: SearchId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetSearchRouteQueryOptions(searchId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Upload Pdf
 */
export const getUploadPdf = (
    uploadId: UploadId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/uploads/pdf/${uploadId}`,options
    );
  }


export const getGetUploadPdfQueryKey = (uploadId: UploadId,) => {
    return [`/uploads/pdf/${uploadId}`] as const;
    }

    
export const getGetUploadPdfQueryOptions = <TData = Awaited<ReturnType<typeof getUploadPdf>>, TError = AxiosError<HTTPValidationError>>(uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadPdf>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUploadPdfQueryKey(uploadId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUploadPdf>>> = ({ signal }) => getUploadPdf(uploadId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(uploadId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUploadPdf>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetUploadPdfQueryResult = NonNullable<Awaited<ReturnType<typeof getUploadPdf>>>
export type GetUploadPdfQueryError = AxiosError<HTTPValidationError>


export function useGetUploadPdf<TData = Awaited<ReturnType<typeof getUploadPdf>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadPdf>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUploadPdf>>,
          TError,
          Awaited<ReturnType<typeof getUploadPdf>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetUploadPdf<TData = Awaited<ReturnType<typeof getUploadPdf>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadPdf>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUploadPdf>>,
          TError,
          Awaited<ReturnType<typeof getUploadPdf>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetUploadPdf<TData = Awaited<ReturnType<typeof getUploadPdf>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadPdf>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Upload Pdf
 */

export function useGetUploadPdf<TData = Awaited<ReturnType<typeof getUploadPdf>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadPdf>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetUploadPdfQueryOptions(uploadId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Download Original
 */
export const downloadOriginal = (
    uploadId: UploadId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/uploads/original/${uploadId}`,options
    );
  }


export const getDownloadOriginalQueryKey = (uploadId: UploadId,) => {
    return [`/uploads/original/${uploadId}`] as const;
    }

    
export const getDownloadOriginalQueryOptions = <TData = Awaited<ReturnType<typeof downloadOriginal>>, TError = AxiosError<HTTPValidationError>>(uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDownloadOriginalQueryKey(uploadId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadOriginal>>> = ({ signal }) => downloadOriginal(uploadId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(uploadId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type DownloadOriginalQueryResult = NonNullable<Awaited<ReturnType<typeof downloadOriginal>>>
export type DownloadOriginalQueryError = AxiosError<HTTPValidationError>


export function useDownloadOriginal<TData = Awaited<ReturnType<typeof downloadOriginal>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof downloadOriginal>>,
          TError,
          Awaited<ReturnType<typeof downloadOriginal>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useDownloadOriginal<TData = Awaited<ReturnType<typeof downloadOriginal>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof downloadOriginal>>,
          TError,
          Awaited<ReturnType<typeof downloadOriginal>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useDownloadOriginal<TData = Awaited<ReturnType<typeof downloadOriginal>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Download Original
 */

export function useDownloadOriginal<TData = Awaited<ReturnType<typeof downloadOriginal>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadOriginal>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getDownloadOriginalQueryOptions(uploadId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Upload Page Image Route
 */
export const getUploadPageImageRoute = (
    uploadId: UploadId,
    pageIndex: number,
    resolution: PageResolution, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/uploads/page_image/${uploadId}/${pageIndex}/${resolution}.jpg`,options
    );
  }


export const getGetUploadPageImageRouteQueryKey = (uploadId: UploadId,
    pageIndex: number,
    resolution: PageResolution,) => {
    return [`/uploads/page_image/${uploadId}/${pageIndex}/${resolution}.jpg`] as const;
    }

    
export const getGetUploadPageImageRouteQueryOptions = <TData = Awaited<ReturnType<typeof getUploadPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(uploadId: UploadId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadPageImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUploadPageImageRouteQueryKey(uploadId,pageIndex,resolution);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUploadPageImageRoute>>> = ({ signal }) => getUploadPageImageRoute(uploadId,pageIndex,resolution, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(uploadId && pageIndex && resolution), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUploadPageImageRoute>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetUploadPageImageRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getUploadPageImageRoute>>>
export type GetUploadPageImageRouteQueryError = AxiosError<HTTPValidationError>


export function useGetUploadPageImageRoute<TData = Awaited<ReturnType<typeof getUploadPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    pageIndex: number,
    resolution: PageResolution, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadPageImageRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUploadPageImageRoute>>,
          TError,
          Awaited<ReturnType<typeof getUploadPageImageRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetUploadPageImageRoute<TData = Awaited<ReturnType<typeof getUploadPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadPageImageRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUploadPageImageRoute>>,
          TError,
          Awaited<ReturnType<typeof getUploadPageImageRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetUploadPageImageRoute<TData = Awaited<ReturnType<typeof getUploadPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadPageImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Upload Page Image Route
 */

export function useGetUploadPageImageRoute<TData = Awaited<ReturnType<typeof getUploadPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadPageImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetUploadPageImageRouteQueryOptions(uploadId,pageIndex,resolution,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Upload Extracted Image Route
 */
export const getUploadExtractedImageRoute = (
    uploadId: UploadId,
    pageIndex: number,
    indexInPage: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/uploads/extracted_image/${uploadId}/${pageIndex}/${indexInPage}.jpg`,options
    );
  }


export const getGetUploadExtractedImageRouteQueryKey = (uploadId: UploadId,
    pageIndex: number,
    indexInPage: number,) => {
    return [`/uploads/extracted_image/${uploadId}/${pageIndex}/${indexInPage}.jpg`] as const;
    }

    
export const getGetUploadExtractedImageRouteQueryOptions = <TData = Awaited<ReturnType<typeof getUploadExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(uploadId: UploadId,
    pageIndex: number,
    indexInPage: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadExtractedImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUploadExtractedImageRouteQueryKey(uploadId,pageIndex,indexInPage);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUploadExtractedImageRoute>>> = ({ signal }) => getUploadExtractedImageRoute(uploadId,pageIndex,indexInPage, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(uploadId && pageIndex && indexInPage), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUploadExtractedImageRoute>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetUploadExtractedImageRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getUploadExtractedImageRoute>>>
export type GetUploadExtractedImageRouteQueryError = AxiosError<HTTPValidationError>


export function useGetUploadExtractedImageRoute<TData = Awaited<ReturnType<typeof getUploadExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    pageIndex: number,
    indexInPage: number, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadExtractedImageRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUploadExtractedImageRoute>>,
          TError,
          Awaited<ReturnType<typeof getUploadExtractedImageRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetUploadExtractedImageRoute<TData = Awaited<ReturnType<typeof getUploadExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    pageIndex: number,
    indexInPage: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadExtractedImageRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUploadExtractedImageRoute>>,
          TError,
          Awaited<ReturnType<typeof getUploadExtractedImageRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetUploadExtractedImageRoute<TData = Awaited<ReturnType<typeof getUploadExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    pageIndex: number,
    indexInPage: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadExtractedImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Upload Extracted Image Route
 */

export function useGetUploadExtractedImageRoute<TData = Awaited<ReturnType<typeof getUploadExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    pageIndex: number,
    indexInPage: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadExtractedImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetUploadExtractedImageRouteQueryOptions(uploadId,pageIndex,indexInPage,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Update Upload Metadata Route
 */
export const updateUploadMetadataRoute = (
    updateUploadMetadataRequest: UpdateUploadMetadataRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/uploads/update_metadata`,
      updateUploadMetadataRequest,options
    );
  }



export const getUpdateUploadMetadataRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUploadMetadataRoute>>, TError,{data: UpdateUploadMetadataRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateUploadMetadataRoute>>, TError,{data: UpdateUploadMetadataRequest}, TContext> => {
    
const mutationKey = ['updateUploadMetadataRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUploadMetadataRoute>>, {data: UpdateUploadMetadataRequest}> = (props) => {
          const {data} = props ?? {};

          return  updateUploadMetadataRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateUploadMetadataRouteMutationResult = NonNullable<Awaited<ReturnType<typeof updateUploadMetadataRoute>>>
    export type UpdateUploadMetadataRouteMutationBody = UpdateUploadMetadataRequest
    export type UpdateUploadMetadataRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Upload Metadata Route
 */
export const useUpdateUploadMetadataRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUploadMetadataRoute>>, TError,{data: UpdateUploadMetadataRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updateUploadMetadataRoute>>,
        TError,
        {data: UpdateUploadMetadataRequest},
        TContext
      > => {

      const mutationOptions = getUpdateUploadMetadataRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Cover Image Route
 */
export const getCoverImageRoute = (
    uploadId: UploadId,
    resolution: PageResolution, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/uploads/cover_image/${uploadId}/${resolution}.jpg`,options
    );
  }


export const getGetCoverImageRouteQueryKey = (uploadId: UploadId,
    resolution: PageResolution,) => {
    return [`/uploads/cover_image/${uploadId}/${resolution}.jpg`] as const;
    }

    
export const getGetCoverImageRouteQueryOptions = <TData = Awaited<ReturnType<typeof getCoverImageRoute>>, TError = AxiosError<HTTPValidationError>>(uploadId: UploadId,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCoverImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCoverImageRouteQueryKey(uploadId,resolution);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCoverImageRoute>>> = ({ signal }) => getCoverImageRoute(uploadId,resolution, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(uploadId && resolution), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCoverImageRoute>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetCoverImageRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getCoverImageRoute>>>
export type GetCoverImageRouteQueryError = AxiosError<HTTPValidationError>


export function useGetCoverImageRoute<TData = Awaited<ReturnType<typeof getCoverImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    resolution: PageResolution, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCoverImageRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCoverImageRoute>>,
          TError,
          Awaited<ReturnType<typeof getCoverImageRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetCoverImageRoute<TData = Awaited<ReturnType<typeof getCoverImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCoverImageRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCoverImageRoute>>,
          TError,
          Awaited<ReturnType<typeof getCoverImageRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetCoverImageRoute<TData = Awaited<ReturnType<typeof getCoverImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCoverImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Cover Image Route
 */

export function useGetCoverImageRoute<TData = Awaited<ReturnType<typeof getCoverImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCoverImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetCoverImageRouteQueryOptions(uploadId,resolution,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create Upload Route
 */
export const createUploadRoute = (
    bodyCreateUploadRoute: BodyCreateUploadRoute, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UploadId>> => {
    
    const formData = new FormData();
formData.append('file', bodyCreateUploadRoute.file)
formData.append('upload_id', bodyCreateUploadRoute.upload_id)
formData.append('infer_metadata', bodyCreateUploadRoute.infer_metadata.toString())

    return axios.default.post(
      `/uploads/create`,
      formData,options
    );
  }



export const getCreateUploadRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUploadRoute>>, TError,{data: BodyCreateUploadRoute}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createUploadRoute>>, TError,{data: BodyCreateUploadRoute}, TContext> => {
    
const mutationKey = ['createUploadRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createUploadRoute>>, {data: BodyCreateUploadRoute}> = (props) => {
          const {data} = props ?? {};

          return  createUploadRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateUploadRouteMutationResult = NonNullable<Awaited<ReturnType<typeof createUploadRoute>>>
    export type CreateUploadRouteMutationBody = BodyCreateUploadRoute
    export type CreateUploadRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Upload Route
 */
export const useCreateUploadRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUploadRoute>>, TError,{data: BodyCreateUploadRoute}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createUploadRoute>>,
        TError,
        {data: BodyCreateUploadRoute},
        TContext
      > => {

      const mutationOptions = getCreateUploadRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Create Upload From Url Route
 */
export const createUploadFromUrlRoute = (
    createUploadFromUrlRequest: CreateUploadFromUrlRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<UploadId>> => {
    
    
    return axios.default.post(
      `/uploads/create_from_url`,
      createUploadFromUrlRequest,options
    );
  }



export const getCreateUploadFromUrlRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUploadFromUrlRoute>>, TError,{data: CreateUploadFromUrlRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createUploadFromUrlRoute>>, TError,{data: CreateUploadFromUrlRequest}, TContext> => {
    
const mutationKey = ['createUploadFromUrlRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createUploadFromUrlRoute>>, {data: CreateUploadFromUrlRequest}> = (props) => {
          const {data} = props ?? {};

          return  createUploadFromUrlRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateUploadFromUrlRouteMutationResult = NonNullable<Awaited<ReturnType<typeof createUploadFromUrlRoute>>>
    export type CreateUploadFromUrlRouteMutationBody = CreateUploadFromUrlRequest
    export type CreateUploadFromUrlRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Upload From Url Route
 */
export const useCreateUploadFromUrlRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUploadFromUrlRoute>>, TError,{data: CreateUploadFromUrlRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createUploadFromUrlRoute>>,
        TError,
        {data: CreateUploadFromUrlRequest},
        TContext
      > => {

      const mutationOptions = getCreateUploadFromUrlRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Query Crossref
 */
export const queryCrossref = (
    queryCrossrefRequest: QueryCrossrefRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<CrossrefResponse>> => {
    
    
    return axios.default.post(
      `/uploads/query_crossref`,
      queryCrossrefRequest,options
    );
  }



export const getQueryCrossrefMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof queryCrossref>>, TError,{data: QueryCrossrefRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof queryCrossref>>, TError,{data: QueryCrossrefRequest}, TContext> => {
    
const mutationKey = ['queryCrossref'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof queryCrossref>>, {data: QueryCrossrefRequest}> = (props) => {
          const {data} = props ?? {};

          return  queryCrossref(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type QueryCrossrefMutationResult = NonNullable<Awaited<ReturnType<typeof queryCrossref>>>
    export type QueryCrossrefMutationBody = QueryCrossrefRequest
    export type QueryCrossrefMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Query Crossref
 */
export const useQueryCrossref = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof queryCrossref>>, TError,{data: QueryCrossrefRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof queryCrossref>>,
        TError,
        {data: QueryCrossrefRequest},
        TContext
      > => {

      const mutationOptions = getQueryCrossrefMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Query Google Books
 */
export const queryGoogleBooks = (
    queryGoogleBooksRequest: QueryGoogleBooksRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GoogleBooksResponse>> => {
    
    
    return axios.default.post(
      `/uploads/query_google_books`,
      queryGoogleBooksRequest,options
    );
  }



export const getQueryGoogleBooksMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof queryGoogleBooks>>, TError,{data: QueryGoogleBooksRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof queryGoogleBooks>>, TError,{data: QueryGoogleBooksRequest}, TContext> => {
    
const mutationKey = ['queryGoogleBooks'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof queryGoogleBooks>>, {data: QueryGoogleBooksRequest}> = (props) => {
          const {data} = props ?? {};

          return  queryGoogleBooks(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type QueryGoogleBooksMutationResult = NonNullable<Awaited<ReturnType<typeof queryGoogleBooks>>>
    export type QueryGoogleBooksMutationBody = QueryGoogleBooksRequest
    export type QueryGoogleBooksMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Query Google Books
 */
export const useQueryGoogleBooks = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof queryGoogleBooks>>, TError,{data: QueryGoogleBooksRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof queryGoogleBooks>>,
        TError,
        {data: QueryGoogleBooksRequest},
        TContext
      > => {

      const mutationOptions = getQueryGoogleBooksMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Infer Upload Metadata
 */
export const inferUploadMetadata = (
    inferUploadMetadataRequest: InferUploadMetadataRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<InferUploadMetadataResponse>> => {
    
    
    return axios.default.post(
      `/uploads/infer_metadata`,
      inferUploadMetadataRequest,options
    );
  }



export const getInferUploadMetadataMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof inferUploadMetadata>>, TError,{data: InferUploadMetadataRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof inferUploadMetadata>>, TError,{data: InferUploadMetadataRequest}, TContext> => {
    
const mutationKey = ['inferUploadMetadata'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof inferUploadMetadata>>, {data: InferUploadMetadataRequest}> = (props) => {
          const {data} = props ?? {};

          return  inferUploadMetadata(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type InferUploadMetadataMutationResult = NonNullable<Awaited<ReturnType<typeof inferUploadMetadata>>>
    export type InferUploadMetadataMutationBody = InferUploadMetadataRequest
    export type InferUploadMetadataMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Infer Upload Metadata
 */
export const useInferUploadMetadata = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof inferUploadMetadata>>, TError,{data: InferUploadMetadataRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof inferUploadMetadata>>,
        TError,
        {data: InferUploadMetadataRequest},
        TContext
      > => {

      const mutationOptions = getInferUploadMetadataMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Upload Toc
 */
export const getUploadToc = (
    uploadId: UploadId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TocSection[]>> => {
    
    
    return axios.default.get(
      `/uploads/toc/${uploadId}`,options
    );
  }


export const getGetUploadTocQueryKey = (uploadId: UploadId,) => {
    return [`/uploads/toc/${uploadId}`] as const;
    }

    
export const getGetUploadTocQueryOptions = <TData = Awaited<ReturnType<typeof getUploadToc>>, TError = AxiosError<HTTPValidationError>>(uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadToc>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUploadTocQueryKey(uploadId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUploadToc>>> = ({ signal }) => getUploadToc(uploadId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(uploadId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUploadToc>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetUploadTocQueryResult = NonNullable<Awaited<ReturnType<typeof getUploadToc>>>
export type GetUploadTocQueryError = AxiosError<HTTPValidationError>


export function useGetUploadToc<TData = Awaited<ReturnType<typeof getUploadToc>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadToc>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUploadToc>>,
          TError,
          Awaited<ReturnType<typeof getUploadToc>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetUploadToc<TData = Awaited<ReturnType<typeof getUploadToc>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadToc>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUploadToc>>,
          TError,
          Awaited<ReturnType<typeof getUploadToc>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetUploadToc<TData = Awaited<ReturnType<typeof getUploadToc>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadToc>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Upload Toc
 */

export function useGetUploadToc<TData = Awaited<ReturnType<typeof getUploadToc>>, TError = AxiosError<HTTPValidationError>>(
 uploadId: UploadId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUploadToc>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetUploadTocQueryOptions(uploadId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Bootstrap Session
 */
export const bootstrapSession = (
    userSessionId: UserSessionId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/user/bootstrap_session/${userSessionId}`,options
    );
  }


export const getBootstrapSessionQueryKey = (userSessionId: UserSessionId,) => {
    return [`/user/bootstrap_session/${userSessionId}`] as const;
    }

    
export const getBootstrapSessionQueryOptions = <TData = Awaited<ReturnType<typeof bootstrapSession>>, TError = AxiosError<HTTPValidationError>>(userSessionId: UserSessionId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getBootstrapSessionQueryKey(userSessionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof bootstrapSession>>> = ({ signal }) => bootstrapSession(userSessionId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(userSessionId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type BootstrapSessionQueryResult = NonNullable<Awaited<ReturnType<typeof bootstrapSession>>>
export type BootstrapSessionQueryError = AxiosError<HTTPValidationError>


export function useBootstrapSession<TData = Awaited<ReturnType<typeof bootstrapSession>>, TError = AxiosError<HTTPValidationError>>(
 userSessionId: UserSessionId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof bootstrapSession>>,
          TError,
          Awaited<ReturnType<typeof bootstrapSession>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useBootstrapSession<TData = Awaited<ReturnType<typeof bootstrapSession>>, TError = AxiosError<HTTPValidationError>>(
 userSessionId: UserSessionId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof bootstrapSession>>,
          TError,
          Awaited<ReturnType<typeof bootstrapSession>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useBootstrapSession<TData = Awaited<ReturnType<typeof bootstrapSession>>, TError = AxiosError<HTTPValidationError>>(
 userSessionId: UserSessionId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Bootstrap Session
 */

export function useBootstrapSession<TData = Awaited<ReturnType<typeof bootstrapSession>>, TError = AxiosError<HTTPValidationError>>(
 userSessionId: UserSessionId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof bootstrapSession>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getBootstrapSessionQueryOptions(userSessionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Attempts to locate an RSS feed URL from a given webpage URL by:
- Fetching the html
- Checking for <link> tags with type="application/rss+xml" or "application/atom+xml"

Returns first valid feed URL found or 404s if none found

raises:
    HTTPException(404): if no feed url could be found
 * @summary Guess Rss Url
 */
export const guessRssUrl = (
    params: GuessRssUrlParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string>> => {
    
    
    return axios.default.post(
      `/feeds/guess_rss_url`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getGuessRssUrlMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof guessRssUrl>>, TError,{params: GuessRssUrlParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof guessRssUrl>>, TError,{params: GuessRssUrlParams}, TContext> => {
    
const mutationKey = ['guessRssUrl'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof guessRssUrl>>, {params: GuessRssUrlParams}> = (props) => {
          const {params} = props ?? {};

          return  guessRssUrl(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GuessRssUrlMutationResult = NonNullable<Awaited<ReturnType<typeof guessRssUrl>>>
    
    export type GuessRssUrlMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Guess Rss Url
 */
export const useGuessRssUrl = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof guessRssUrl>>, TError,{params: GuessRssUrlParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof guessRssUrl>>,
        TError,
        {params: GuessRssUrlParams},
        TContext
      > => {

      const mutationOptions = getGuessRssUrlMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Fetches and parses an RSS feed to preview its content and check for duplicates.

args:
    rss_url: direct url to rss feed
    url: original webpage url
    auth: auth context for user permissions

returns:
    PreviewRssFeedResponse containing:
    - parsed feed preview with metadata and recent items
    - list of any existing feeds with same rss_url
 * @summary Preview Rss Feed
 */
export const previewRssFeed = (
    params: PreviewRssFeedParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PreviewRssFeedResponse>> => {
    
    
    return axios.default.post(
      `/feeds/preview_rss_feed`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getPreviewRssFeedMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof previewRssFeed>>, TError,{params: PreviewRssFeedParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof previewRssFeed>>, TError,{params: PreviewRssFeedParams}, TContext> => {
    
const mutationKey = ['previewRssFeed'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof previewRssFeed>>, {params: PreviewRssFeedParams}> = (props) => {
          const {params} = props ?? {};

          return  previewRssFeed(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PreviewRssFeedMutationResult = NonNullable<Awaited<ReturnType<typeof previewRssFeed>>>
    
    export type PreviewRssFeedMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Preview Rss Feed
 */
export const usePreviewRssFeed = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof previewRssFeed>>, TError,{params: PreviewRssFeedParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof previewRssFeed>>,
        TError,
        {params: PreviewRssFeedParams},
        TContext
      > => {

      const mutationOptions = getPreviewRssFeedMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Index Feed Channel Route
 */
export const indexFeedChannelRoute = (
    indexFeedRequest: IndexFeedRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<boolean>> => {
    
    
    return axios.default.post(
      `/feeds/index_feed_channel`,
      indexFeedRequest,options
    );
  }



export const getIndexFeedChannelRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof indexFeedChannelRoute>>, TError,{data: IndexFeedRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof indexFeedChannelRoute>>, TError,{data: IndexFeedRequest}, TContext> => {
    
const mutationKey = ['indexFeedChannelRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof indexFeedChannelRoute>>, {data: IndexFeedRequest}> = (props) => {
          const {data} = props ?? {};

          return  indexFeedChannelRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type IndexFeedChannelRouteMutationResult = NonNullable<Awaited<ReturnType<typeof indexFeedChannelRoute>>>
    export type IndexFeedChannelRouteMutationBody = IndexFeedRequest
    export type IndexFeedChannelRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Index Feed Channel Route
 */
export const useIndexFeedChannelRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof indexFeedChannelRoute>>, TError,{data: IndexFeedRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof indexFeedChannelRoute>>,
        TError,
        {data: IndexFeedRequest},
        TContext
      > => {

      const mutationOptions = getIndexFeedChannelRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Refetch a feed channel by fetching the feed again and updating the feed items
 * @summary Refetch Feed Channel
 */
export const refetchFeedChannel = (
    params: RefetchFeedChannelParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/feeds/refetch_feed_channel`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getRefetchFeedChannelMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof refetchFeedChannel>>, TError,{params: RefetchFeedChannelParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof refetchFeedChannel>>, TError,{params: RefetchFeedChannelParams}, TContext> => {
    
const mutationKey = ['refetchFeedChannel'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof refetchFeedChannel>>, {params: RefetchFeedChannelParams}> = (props) => {
          const {params} = props ?? {};

          return  refetchFeedChannel(params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RefetchFeedChannelMutationResult = NonNullable<Awaited<ReturnType<typeof refetchFeedChannel>>>
    
    export type RefetchFeedChannelMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Refetch Feed Channel
 */
export const useRefetchFeedChannel = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof refetchFeedChannel>>, TError,{params: RefetchFeedChannelParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof refetchFeedChannel>>,
        TError,
        {params: RefetchFeedChannelParams},
        TContext
      > => {

      const mutationOptions = getRefetchFeedChannelMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Feed Item Page Image Route
 */
export const getFeedItemPageImageRoute = (
    feedItemId: FeedItemId,
    pageIndex: number,
    resolution: PageResolution, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/feeds/page_image/${feedItemId}/${pageIndex}/${resolution}.jpg`,options
    );
  }


export const getGetFeedItemPageImageRouteQueryKey = (feedItemId: FeedItemId,
    pageIndex: number,
    resolution: PageResolution,) => {
    return [`/feeds/page_image/${feedItemId}/${pageIndex}/${resolution}.jpg`] as const;
    }

    
export const getGetFeedItemPageImageRouteQueryOptions = <TData = Awaited<ReturnType<typeof getFeedItemPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(feedItemId: FeedItemId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemPageImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFeedItemPageImageRouteQueryKey(feedItemId,pageIndex,resolution);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFeedItemPageImageRoute>>> = ({ signal }) => getFeedItemPageImageRoute(feedItemId,pageIndex,resolution, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(feedItemId && pageIndex && resolution), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFeedItemPageImageRoute>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetFeedItemPageImageRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getFeedItemPageImageRoute>>>
export type GetFeedItemPageImageRouteQueryError = AxiosError<HTTPValidationError>


export function useGetFeedItemPageImageRoute<TData = Awaited<ReturnType<typeof getFeedItemPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId,
    pageIndex: number,
    resolution: PageResolution, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemPageImageRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFeedItemPageImageRoute>>,
          TError,
          Awaited<ReturnType<typeof getFeedItemPageImageRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetFeedItemPageImageRoute<TData = Awaited<ReturnType<typeof getFeedItemPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemPageImageRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFeedItemPageImageRoute>>,
          TError,
          Awaited<ReturnType<typeof getFeedItemPageImageRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetFeedItemPageImageRoute<TData = Awaited<ReturnType<typeof getFeedItemPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemPageImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Feed Item Page Image Route
 */

export function useGetFeedItemPageImageRoute<TData = Awaited<ReturnType<typeof getFeedItemPageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemPageImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetFeedItemPageImageRouteQueryOptions(feedItemId,pageIndex,resolution,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Feed Item Extracted Image Route
 */
export const getFeedItemExtractedImageRoute = (
    feedItemId: FeedItemId,
    pageIndex: number,
    indexInPage: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/feeds/extracted_image/${feedItemId}/${pageIndex}/${indexInPage}.jpg`,options
    );
  }


export const getGetFeedItemExtractedImageRouteQueryKey = (feedItemId: FeedItemId,
    pageIndex: number,
    indexInPage: number,) => {
    return [`/feeds/extracted_image/${feedItemId}/${pageIndex}/${indexInPage}.jpg`] as const;
    }

    
export const getGetFeedItemExtractedImageRouteQueryOptions = <TData = Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(feedItemId: FeedItemId,
    pageIndex: number,
    indexInPage: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFeedItemExtractedImageRouteQueryKey(feedItemId,pageIndex,indexInPage);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>> = ({ signal }) => getFeedItemExtractedImageRoute(feedItemId,pageIndex,indexInPage, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(feedItemId && pageIndex && indexInPage), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetFeedItemExtractedImageRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>>
export type GetFeedItemExtractedImageRouteQueryError = AxiosError<HTTPValidationError>


export function useGetFeedItemExtractedImageRoute<TData = Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId,
    pageIndex: number,
    indexInPage: number, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>,
          TError,
          Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetFeedItemExtractedImageRoute<TData = Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId,
    pageIndex: number,
    indexInPage: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>,
          TError,
          Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetFeedItemExtractedImageRoute<TData = Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId,
    pageIndex: number,
    indexInPage: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Feed Item Extracted Image Route
 */

export function useGetFeedItemExtractedImageRoute<TData = Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId,
    pageIndex: number,
    indexInPage: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemExtractedImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetFeedItemExtractedImageRouteQueryOptions(feedItemId,pageIndex,indexInPage,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Feed Item Pdf
 */
export const getFeedItemPdf = (
    feedItemId: FeedItemId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/feeds/pdf/${feedItemId}`,options
    );
  }


export const getGetFeedItemPdfQueryKey = (feedItemId: FeedItemId,) => {
    return [`/feeds/pdf/${feedItemId}`] as const;
    }

    
export const getGetFeedItemPdfQueryOptions = <TData = Awaited<ReturnType<typeof getFeedItemPdf>>, TError = AxiosError<HTTPValidationError>>(feedItemId: FeedItemId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemPdf>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFeedItemPdfQueryKey(feedItemId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFeedItemPdf>>> = ({ signal }) => getFeedItemPdf(feedItemId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(feedItemId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFeedItemPdf>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetFeedItemPdfQueryResult = NonNullable<Awaited<ReturnType<typeof getFeedItemPdf>>>
export type GetFeedItemPdfQueryError = AxiosError<HTTPValidationError>


export function useGetFeedItemPdf<TData = Awaited<ReturnType<typeof getFeedItemPdf>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemPdf>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFeedItemPdf>>,
          TError,
          Awaited<ReturnType<typeof getFeedItemPdf>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetFeedItemPdf<TData = Awaited<ReturnType<typeof getFeedItemPdf>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemPdf>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFeedItemPdf>>,
          TError,
          Awaited<ReturnType<typeof getFeedItemPdf>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetFeedItemPdf<TData = Awaited<ReturnType<typeof getFeedItemPdf>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemPdf>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Feed Item Pdf
 */

export function useGetFeedItemPdf<TData = Awaited<ReturnType<typeof getFeedItemPdf>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemPdf>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetFeedItemPdfQueryOptions(feedItemId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Feed Item Toc
 */
export const getFeedItemToc = (
    feedItemId: FeedItemId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TocSection[]>> => {
    
    
    return axios.default.get(
      `/feeds/toc/${feedItemId}`,options
    );
  }


export const getGetFeedItemTocQueryKey = (feedItemId: FeedItemId,) => {
    return [`/feeds/toc/${feedItemId}`] as const;
    }

    
export const getGetFeedItemTocQueryOptions = <TData = Awaited<ReturnType<typeof getFeedItemToc>>, TError = AxiosError<HTTPValidationError>>(feedItemId: FeedItemId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemToc>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFeedItemTocQueryKey(feedItemId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFeedItemToc>>> = ({ signal }) => getFeedItemToc(feedItemId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(feedItemId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFeedItemToc>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetFeedItemTocQueryResult = NonNullable<Awaited<ReturnType<typeof getFeedItemToc>>>
export type GetFeedItemTocQueryError = AxiosError<HTTPValidationError>


export function useGetFeedItemToc<TData = Awaited<ReturnType<typeof getFeedItemToc>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemToc>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFeedItemToc>>,
          TError,
          Awaited<ReturnType<typeof getFeedItemToc>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetFeedItemToc<TData = Awaited<ReturnType<typeof getFeedItemToc>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemToc>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getFeedItemToc>>,
          TError,
          Awaited<ReturnType<typeof getFeedItemToc>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetFeedItemToc<TData = Awaited<ReturnType<typeof getFeedItemToc>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemToc>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Feed Item Toc
 */

export function useGetFeedItemToc<TData = Awaited<ReturnType<typeof getFeedItemToc>>, TError = AxiosError<HTTPValidationError>>(
 feedItemId: FeedItemId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFeedItemToc>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetFeedItemTocQueryOptions(feedItemId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Dummy Resource
 */
export const dummyResource = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Resource>> => {
    
    
    return axios.default.post(
      `/dummy/resource`,undefined,options
    );
  }



export const getDummyResourceMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyResource>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof dummyResource>>, TError,void, TContext> => {
    
const mutationKey = ['dummyResource'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dummyResource>>, void> = () => {
          

          return  dummyResource(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DummyResourceMutationResult = NonNullable<Awaited<ReturnType<typeof dummyResource>>>
    
    export type DummyResourceMutationError = AxiosError<unknown>

    /**
 * @summary Dummy Resource
 */
export const useDummyResource = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyResource>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof dummyResource>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDummyResourceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Dummy Resource Ref
 */
export const dummyResourceRef = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ResourceRef>> => {
    
    
    return axios.default.post(
      `/dummy/resource_ref`,undefined,options
    );
  }



export const getDummyResourceRefMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyResourceRef>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof dummyResourceRef>>, TError,void, TContext> => {
    
const mutationKey = ['dummyResourceRef'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dummyResourceRef>>, void> = () => {
          

          return  dummyResourceRef(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DummyResourceRefMutationResult = NonNullable<Awaited<ReturnType<typeof dummyResourceRef>>>
    
    export type DummyResourceRefMutationError = AxiosError<unknown>

    /**
 * @summary Dummy Resource Ref
 */
export const useDummyResourceRef = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyResourceRef>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof dummyResourceRef>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDummyResourceRefMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Dummy Table Request
 */
export const dummyTableRequest = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TableUpdateEvent>> => {
    
    
    return axios.default.post(
      `/dummy/table_events`,undefined,options
    );
  }



export const getDummyTableRequestMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyTableRequest>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof dummyTableRequest>>, TError,void, TContext> => {
    
const mutationKey = ['dummyTableRequest'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dummyTableRequest>>, void> = () => {
          

          return  dummyTableRequest(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DummyTableRequestMutationResult = NonNullable<Awaited<ReturnType<typeof dummyTableRequest>>>
    
    export type DummyTableRequestMutationError = AxiosError<unknown>

    /**
 * @summary Dummy Table Request
 */
export const useDummyTableRequest = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyTableRequest>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof dummyTableRequest>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDummyTableRequestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Dummy Field
 */
export const dummyField = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Field>> => {
    
    
    return axios.default.post(
      `/dummy/field`,undefined,options
    );
  }



export const getDummyFieldMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyField>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof dummyField>>, TError,void, TContext> => {
    
const mutationKey = ['dummyField'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dummyField>>, void> = () => {
          

          return  dummyField(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DummyFieldMutationResult = NonNullable<Awaited<ReturnType<typeof dummyField>>>
    
    export type DummyFieldMutationError = AxiosError<unknown>

    /**
 * @summary Dummy Field
 */
export const useDummyField = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyField>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof dummyField>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDummyFieldMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Dummy Shapes
 */
export const dummyShapes = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<DummyShapes200>> => {
    
    
    return axios.default.post(
      `/dummy/shapes`,undefined,options
    );
  }



export const getDummyShapesMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyShapes>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof dummyShapes>>, TError,void, TContext> => {
    
const mutationKey = ['dummyShapes'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dummyShapes>>, void> = () => {
          

          return  dummyShapes(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DummyShapesMutationResult = NonNullable<Awaited<ReturnType<typeof dummyShapes>>>
    
    export type DummyShapesMutationError = AxiosError<unknown>

    /**
 * @summary Dummy Shapes
 */
export const useDummyShapes = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyShapes>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof dummyShapes>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDummyShapesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Dummy Step State
 */
export const dummyStepState = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<StepState>> => {
    
    
    return axios.default.post(
      `/dummy/step_state`,undefined,options
    );
  }



export const getDummyStepStateMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyStepState>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof dummyStepState>>, TError,void, TContext> => {
    
const mutationKey = ['dummyStepState'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof dummyStepState>>, void> = () => {
          

          return  dummyStepState(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DummyStepStateMutationResult = NonNullable<Awaited<ReturnType<typeof dummyStepState>>>
    
    export type DummyStepStateMutationError = AxiosError<unknown>

    /**
 * @summary Dummy Step State
 */
export const useDummyStepState = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof dummyStepState>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof dummyStepState>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getDummyStepStateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Web Search Route
 */
export const webSearchRoute = (
    webSearchResource: WebSearchResource, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WebSearchResponse>> => {
    
    
    return axios.default.post(
      `/web-search`,
      webSearchResource,options
    );
  }



export const getWebSearchRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof webSearchRoute>>, TError,{data: WebSearchResource}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof webSearchRoute>>, TError,{data: WebSearchResource}, TContext> => {
    
const mutationKey = ['webSearchRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof webSearchRoute>>, {data: WebSearchResource}> = (props) => {
          const {data} = props ?? {};

          return  webSearchRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type WebSearchRouteMutationResult = NonNullable<Awaited<ReturnType<typeof webSearchRoute>>>
    export type WebSearchRouteMutationBody = WebSearchResource
    export type WebSearchRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Web Search Route
 */
export const useWebSearchRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof webSearchRoute>>, TError,{data: WebSearchResource}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof webSearchRoute>>,
        TError,
        {data: WebSearchResource},
        TContext
      > => {

      const mutationOptions = getWebSearchRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Web Search Route
 */
export const getWebSearchRoute = (
    webSearchId: WebSearchId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<WebSearchResponse>> => {
    
    
    return axios.default.get(
      `/web-search/${webSearchId}`,options
    );
  }


export const getGetWebSearchRouteQueryKey = (webSearchId: WebSearchId,) => {
    return [`/web-search/${webSearchId}`] as const;
    }

    
export const getGetWebSearchRouteQueryOptions = <TData = Awaited<ReturnType<typeof getWebSearchRoute>>, TError = AxiosError<HTTPValidationError>>(webSearchId: WebSearchId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebSearchRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWebSearchRouteQueryKey(webSearchId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWebSearchRoute>>> = ({ signal }) => getWebSearchRoute(webSearchId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(webSearchId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWebSearchRoute>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetWebSearchRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getWebSearchRoute>>>
export type GetWebSearchRouteQueryError = AxiosError<HTTPValidationError>


export function useGetWebSearchRoute<TData = Awaited<ReturnType<typeof getWebSearchRoute>>, TError = AxiosError<HTTPValidationError>>(
 webSearchId: WebSearchId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebSearchRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWebSearchRoute>>,
          TError,
          Awaited<ReturnType<typeof getWebSearchRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetWebSearchRoute<TData = Awaited<ReturnType<typeof getWebSearchRoute>>, TError = AxiosError<HTTPValidationError>>(
 webSearchId: WebSearchId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebSearchRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWebSearchRoute>>,
          TError,
          Awaited<ReturnType<typeof getWebSearchRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetWebSearchRoute<TData = Awaited<ReturnType<typeof getWebSearchRoute>>, TError = AxiosError<HTTPValidationError>>(
 webSearchId: WebSearchId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebSearchRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Web Search Route
 */

export function useGetWebSearchRoute<TData = Awaited<ReturnType<typeof getWebSearchRoute>>, TError = AxiosError<HTTPValidationError>>(
 webSearchId: WebSearchId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebSearchRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetWebSearchRouteQueryOptions(webSearchId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Webpage Page Image Route
 */
export const getWebpagePageImageRoute = (
    webpageId: WebpageId,
    pageIndex: number,
    resolution: PageResolution, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/webpages/page_image/${webpageId}/${pageIndex}/${resolution}.jpg`,options
    );
  }


export const getGetWebpagePageImageRouteQueryKey = (webpageId: WebpageId,
    pageIndex: number,
    resolution: PageResolution,) => {
    return [`/webpages/page_image/${webpageId}/${pageIndex}/${resolution}.jpg`] as const;
    }

    
export const getGetWebpagePageImageRouteQueryOptions = <TData = Awaited<ReturnType<typeof getWebpagePageImageRoute>>, TError = AxiosError<HTTPValidationError>>(webpageId: WebpageId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpagePageImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWebpagePageImageRouteQueryKey(webpageId,pageIndex,resolution);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWebpagePageImageRoute>>> = ({ signal }) => getWebpagePageImageRoute(webpageId,pageIndex,resolution, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(webpageId && pageIndex && resolution), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWebpagePageImageRoute>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetWebpagePageImageRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getWebpagePageImageRoute>>>
export type GetWebpagePageImageRouteQueryError = AxiosError<HTTPValidationError>


export function useGetWebpagePageImageRoute<TData = Awaited<ReturnType<typeof getWebpagePageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId,
    pageIndex: number,
    resolution: PageResolution, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpagePageImageRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWebpagePageImageRoute>>,
          TError,
          Awaited<ReturnType<typeof getWebpagePageImageRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetWebpagePageImageRoute<TData = Awaited<ReturnType<typeof getWebpagePageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpagePageImageRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWebpagePageImageRoute>>,
          TError,
          Awaited<ReturnType<typeof getWebpagePageImageRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetWebpagePageImageRoute<TData = Awaited<ReturnType<typeof getWebpagePageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpagePageImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Webpage Page Image Route
 */

export function useGetWebpagePageImageRoute<TData = Awaited<ReturnType<typeof getWebpagePageImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId,
    pageIndex: number,
    resolution: PageResolution, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpagePageImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetWebpagePageImageRouteQueryOptions(webpageId,pageIndex,resolution,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Webpage Extracted Image Route
 */
export const getWebpageExtractedImageRoute = (
    webpageId: WebpageId,
    pageIndex: number,
    indexInPage: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/webpages/extracted_image/${webpageId}/${pageIndex}/${indexInPage}.jpg`,options
    );
  }


export const getGetWebpageExtractedImageRouteQueryKey = (webpageId: WebpageId,
    pageIndex: number,
    indexInPage: number,) => {
    return [`/webpages/extracted_image/${webpageId}/${pageIndex}/${indexInPage}.jpg`] as const;
    }

    
export const getGetWebpageExtractedImageRouteQueryOptions = <TData = Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(webpageId: WebpageId,
    pageIndex: number,
    indexInPage: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWebpageExtractedImageRouteQueryKey(webpageId,pageIndex,indexInPage);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>> = ({ signal }) => getWebpageExtractedImageRoute(webpageId,pageIndex,indexInPage, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(webpageId && pageIndex && indexInPage), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetWebpageExtractedImageRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>>
export type GetWebpageExtractedImageRouteQueryError = AxiosError<HTTPValidationError>


export function useGetWebpageExtractedImageRoute<TData = Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId,
    pageIndex: number,
    indexInPage: number, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>,
          TError,
          Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetWebpageExtractedImageRoute<TData = Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId,
    pageIndex: number,
    indexInPage: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>,
          TError,
          Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetWebpageExtractedImageRoute<TData = Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId,
    pageIndex: number,
    indexInPage: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Webpage Extracted Image Route
 */

export function useGetWebpageExtractedImageRoute<TData = Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId,
    pageIndex: number,
    indexInPage: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpageExtractedImageRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetWebpageExtractedImageRouteQueryOptions(webpageId,pageIndex,indexInPage,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Webpage Pdf
 */
export const getWebpagePdf = (
    webpageId: WebpageId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/webpages/pdf/${webpageId}`,options
    );
  }


export const getGetWebpagePdfQueryKey = (webpageId: WebpageId,) => {
    return [`/webpages/pdf/${webpageId}`] as const;
    }

    
export const getGetWebpagePdfQueryOptions = <TData = Awaited<ReturnType<typeof getWebpagePdf>>, TError = AxiosError<HTTPValidationError>>(webpageId: WebpageId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpagePdf>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWebpagePdfQueryKey(webpageId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWebpagePdf>>> = ({ signal }) => getWebpagePdf(webpageId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(webpageId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWebpagePdf>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetWebpagePdfQueryResult = NonNullable<Awaited<ReturnType<typeof getWebpagePdf>>>
export type GetWebpagePdfQueryError = AxiosError<HTTPValidationError>


export function useGetWebpagePdf<TData = Awaited<ReturnType<typeof getWebpagePdf>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpagePdf>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWebpagePdf>>,
          TError,
          Awaited<ReturnType<typeof getWebpagePdf>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetWebpagePdf<TData = Awaited<ReturnType<typeof getWebpagePdf>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpagePdf>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWebpagePdf>>,
          TError,
          Awaited<ReturnType<typeof getWebpagePdf>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetWebpagePdf<TData = Awaited<ReturnType<typeof getWebpagePdf>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpagePdf>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Webpage Pdf
 */

export function useGetWebpagePdf<TData = Awaited<ReturnType<typeof getWebpagePdf>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpagePdf>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetWebpagePdfQueryOptions(webpageId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Index Webpage Route
 */
export const indexWebpageRoute = (
    indexWebpageRequest: IndexWebpageRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/webpages/index_webpage`,
      indexWebpageRequest,options
    );
  }



export const getIndexWebpageRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof indexWebpageRoute>>, TError,{data: IndexWebpageRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof indexWebpageRoute>>, TError,{data: IndexWebpageRequest}, TContext> => {
    
const mutationKey = ['indexWebpageRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof indexWebpageRoute>>, {data: IndexWebpageRequest}> = (props) => {
          const {data} = props ?? {};

          return  indexWebpageRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type IndexWebpageRouteMutationResult = NonNullable<Awaited<ReturnType<typeof indexWebpageRoute>>>
    export type IndexWebpageRouteMutationBody = IndexWebpageRequest
    export type IndexWebpageRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Index Webpage Route
 */
export const useIndexWebpageRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof indexWebpageRoute>>, TError,{data: IndexWebpageRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof indexWebpageRoute>>,
        TError,
        {data: IndexWebpageRequest},
        TContext
      > => {

      const mutationOptions = getIndexWebpageRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Webpage Toc
 */
export const getWebpageToc = (
    webpageId: WebpageId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TocSection[]>> => {
    
    
    return axios.default.get(
      `/webpages/toc/${webpageId}`,options
    );
  }


export const getGetWebpageTocQueryKey = (webpageId: WebpageId,) => {
    return [`/webpages/toc/${webpageId}`] as const;
    }

    
export const getGetWebpageTocQueryOptions = <TData = Awaited<ReturnType<typeof getWebpageToc>>, TError = AxiosError<HTTPValidationError>>(webpageId: WebpageId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpageToc>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWebpageTocQueryKey(webpageId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWebpageToc>>> = ({ signal }) => getWebpageToc(webpageId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(webpageId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWebpageToc>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetWebpageTocQueryResult = NonNullable<Awaited<ReturnType<typeof getWebpageToc>>>
export type GetWebpageTocQueryError = AxiosError<HTTPValidationError>


export function useGetWebpageToc<TData = Awaited<ReturnType<typeof getWebpageToc>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpageToc>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWebpageToc>>,
          TError,
          Awaited<ReturnType<typeof getWebpageToc>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetWebpageToc<TData = Awaited<ReturnType<typeof getWebpageToc>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpageToc>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWebpageToc>>,
          TError,
          Awaited<ReturnType<typeof getWebpageToc>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetWebpageToc<TData = Awaited<ReturnType<typeof getWebpageToc>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpageToc>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Webpage Toc
 */

export function useGetWebpageToc<TData = Awaited<ReturnType<typeof getWebpageToc>>, TError = AxiosError<HTTPValidationError>>(
 webpageId: WebpageId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWebpageToc>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetWebpageTocQueryOptions(webpageId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create Table Route
 */
export const createTableRoute = (
    createTableRequest: CreateTableRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/tables/create`,
      createTableRequest,options
    );
  }



export const getCreateTableRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTableRoute>>, TError,{data: CreateTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createTableRoute>>, TError,{data: CreateTableRequest}, TContext> => {
    
const mutationKey = ['createTableRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTableRoute>>, {data: CreateTableRequest}> = (props) => {
          const {data} = props ?? {};

          return  createTableRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateTableRouteMutationResult = NonNullable<Awaited<ReturnType<typeof createTableRoute>>>
    export type CreateTableRouteMutationBody = CreateTableRequest
    export type CreateTableRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Table Route
 */
export const useCreateTableRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTableRoute>>, TError,{data: CreateTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createTableRoute>>,
        TError,
        {data: CreateTableRequest},
        TContext
      > => {

      const mutationOptions = getCreateTableRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Table Records Route
 */
export const getTableRecordsRoute = (
    params: GetTableRecordsRouteParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetTableRecordsResponse>> => {
    
    
    return axios.default.get(
      `/tables/records`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetTableRecordsRouteQueryKey = (params: GetTableRecordsRouteParams,) => {
    return [`/tables/records`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTableRecordsRouteQueryOptions = <TData = Awaited<ReturnType<typeof getTableRecordsRoute>>, TError = AxiosError<HTTPValidationError>>(params: GetTableRecordsRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableRecordsRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTableRecordsRouteQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTableRecordsRoute>>> = ({ signal }) => getTableRecordsRoute(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTableRecordsRoute>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetTableRecordsRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getTableRecordsRoute>>>
export type GetTableRecordsRouteQueryError = AxiosError<HTTPValidationError>


export function useGetTableRecordsRoute<TData = Awaited<ReturnType<typeof getTableRecordsRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: GetTableRecordsRouteParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableRecordsRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTableRecordsRoute>>,
          TError,
          Awaited<ReturnType<typeof getTableRecordsRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetTableRecordsRoute<TData = Awaited<ReturnType<typeof getTableRecordsRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: GetTableRecordsRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableRecordsRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTableRecordsRoute>>,
          TError,
          Awaited<ReturnType<typeof getTableRecordsRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetTableRecordsRoute<TData = Awaited<ReturnType<typeof getTableRecordsRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: GetTableRecordsRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableRecordsRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Table Records Route
 */

export function useGetTableRecordsRoute<TData = Awaited<ReturnType<typeof getTableRecordsRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: GetTableRecordsRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableRecordsRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetTableRecordsRouteQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Table Size Route
 */
export const getTableSizeRoute = (
    params: GetTableSizeRouteParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<GetTableSizeResponse>> => {
    
    
    return axios.default.get(
      `/tables/size`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetTableSizeRouteQueryKey = (params: GetTableSizeRouteParams,) => {
    return [`/tables/size`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTableSizeRouteQueryOptions = <TData = Awaited<ReturnType<typeof getTableSizeRoute>>, TError = AxiosError<HTTPValidationError>>(params: GetTableSizeRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableSizeRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTableSizeRouteQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTableSizeRoute>>> = ({ signal }) => getTableSizeRoute(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTableSizeRoute>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetTableSizeRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getTableSizeRoute>>>
export type GetTableSizeRouteQueryError = AxiosError<HTTPValidationError>


export function useGetTableSizeRoute<TData = Awaited<ReturnType<typeof getTableSizeRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: GetTableSizeRouteParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableSizeRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTableSizeRoute>>,
          TError,
          Awaited<ReturnType<typeof getTableSizeRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetTableSizeRoute<TData = Awaited<ReturnType<typeof getTableSizeRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: GetTableSizeRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableSizeRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTableSizeRoute>>,
          TError,
          Awaited<ReturnType<typeof getTableSizeRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetTableSizeRoute<TData = Awaited<ReturnType<typeof getTableSizeRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: GetTableSizeRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableSizeRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Table Size Route
 */

export function useGetTableSizeRoute<TData = Awaited<ReturnType<typeof getTableSizeRoute>>, TError = AxiosError<HTTPValidationError>>(
 params: GetTableSizeRouteParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTableSizeRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetTableSizeRouteQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Watch Table
 */
export const watchTable = (
    watchTableRequest: WatchTableRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/tables/watch`,
      watchTableRequest,options
    );
  }



export const getWatchTableMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof watchTable>>, TError,{data: WatchTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof watchTable>>, TError,{data: WatchTableRequest}, TContext> => {
    
const mutationKey = ['watchTable'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof watchTable>>, {data: WatchTableRequest}> = (props) => {
          const {data} = props ?? {};

          return  watchTable(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type WatchTableMutationResult = NonNullable<Awaited<ReturnType<typeof watchTable>>>
    export type WatchTableMutationBody = WatchTableRequest
    export type WatchTableMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Watch Table
 */
export const useWatchTable = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof watchTable>>, TError,{data: WatchTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof watchTable>>,
        TError,
        {data: WatchTableRequest},
        TContext
      > => {

      const mutationOptions = getWatchTableMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Rename Table Route
 */
export const renameTableRoute = (
    renameTableRequest: RenameTableRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/tables/rename`,
      renameTableRequest,options
    );
  }



export const getRenameTableRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameTableRoute>>, TError,{data: RenameTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof renameTableRoute>>, TError,{data: RenameTableRequest}, TContext> => {
    
const mutationKey = ['renameTableRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof renameTableRoute>>, {data: RenameTableRequest}> = (props) => {
          const {data} = props ?? {};

          return  renameTableRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RenameTableRouteMutationResult = NonNullable<Awaited<ReturnType<typeof renameTableRoute>>>
    export type RenameTableRouteMutationBody = RenameTableRequest
    export type RenameTableRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Rename Table Route
 */
export const useRenameTableRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameTableRoute>>, TError,{data: RenameTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof renameTableRoute>>,
        TError,
        {data: RenameTableRequest},
        TContext
      > => {

      const mutationOptions = getRenameTableRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Tables Route
 */
export const deleteTablesRoute = (
    deleteTablesRequest: DeleteTablesRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<boolean>> => {
    
    
    return axios.default.post(
      `/tables/delete`,
      deleteTablesRequest,options
    );
  }



export const getDeleteTablesRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTablesRoute>>, TError,{data: DeleteTablesRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteTablesRoute>>, TError,{data: DeleteTablesRequest}, TContext> => {
    
const mutationKey = ['deleteTablesRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTablesRoute>>, {data: DeleteTablesRequest}> = (props) => {
          const {data} = props ?? {};

          return  deleteTablesRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteTablesRouteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTablesRoute>>>
    export type DeleteTablesRouteMutationBody = DeleteTablesRequest
    export type DeleteTablesRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Tables Route
 */
export const useDeleteTablesRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTablesRoute>>, TError,{data: DeleteTablesRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteTablesRoute>>,
        TError,
        {data: DeleteTablesRequest},
        TContext
      > => {

      const mutationOptions = getDeleteTablesRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Endpoint that creates resource indexes for testing. Eventually, we'll move
this to run on server init.
 * @summary Create Resource Indexes Route
 */
export const createResourceIndexesRoute = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<boolean>> => {
    
    
    return axios.default.post(
      `/tables/create_resource_indexes`,undefined,options
    );
  }



export const getCreateResourceIndexesRouteMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createResourceIndexesRoute>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createResourceIndexesRoute>>, TError,void, TContext> => {
    
const mutationKey = ['createResourceIndexesRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createResourceIndexesRoute>>, void> = () => {
          

          return  createResourceIndexesRoute(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateResourceIndexesRouteMutationResult = NonNullable<Awaited<ReturnType<typeof createResourceIndexesRoute>>>
    
    export type CreateResourceIndexesRouteMutationError = AxiosError<unknown>

    /**
 * @summary Create Resource Indexes Route
 */
export const useCreateResourceIndexesRoute = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createResourceIndexesRoute>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createResourceIndexesRoute>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getCreateResourceIndexesRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Endpoint that cleans up resource indexes for testing.
 * @summary Cleanup Resource Indexes Route
 */
export const cleanupResourceIndexesRoute = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<boolean>> => {
    
    
    return axios.default.post(
      `/tables/cleanup_resource_indexes`,undefined,options
    );
  }



export const getCleanupResourceIndexesRouteMutationOptions = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cleanupResourceIndexesRoute>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof cleanupResourceIndexesRoute>>, TError,void, TContext> => {
    
const mutationKey = ['cleanupResourceIndexesRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cleanupResourceIndexesRoute>>, void> = () => {
          

          return  cleanupResourceIndexesRoute(axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CleanupResourceIndexesRouteMutationResult = NonNullable<Awaited<ReturnType<typeof cleanupResourceIndexesRoute>>>
    
    export type CleanupResourceIndexesRouteMutationError = AxiosError<unknown>

    /**
 * @summary Cleanup Resource Indexes Route
 */
export const useCleanupResourceIndexesRoute = <TError = AxiosError<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cleanupResourceIndexesRoute>>, TError,void, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof cleanupResourceIndexesRoute>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getCleanupResourceIndexesRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Add Field Route
 */
export const addFieldRoute = (
    addFieldRequest: AddFieldRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/tables/fields/add_field`,
      addFieldRequest,options
    );
  }



export const getAddFieldRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addFieldRoute>>, TError,{data: AddFieldRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof addFieldRoute>>, TError,{data: AddFieldRequest}, TContext> => {
    
const mutationKey = ['addFieldRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addFieldRoute>>, {data: AddFieldRequest}> = (props) => {
          const {data} = props ?? {};

          return  addFieldRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddFieldRouteMutationResult = NonNullable<Awaited<ReturnType<typeof addFieldRoute>>>
    export type AddFieldRouteMutationBody = AddFieldRequest
    export type AddFieldRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Add Field Route
 */
export const useAddFieldRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addFieldRoute>>, TError,{data: AddFieldRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof addFieldRoute>>,
        TError,
        {data: AddFieldRequest},
        TContext
      > => {

      const mutationOptions = getAddFieldRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Add Relationship Route
 */
export const addRelationshipRoute = (
    addRelationshipRequest: AddRelationshipRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/tables/fields/add_relationship`,
      addRelationshipRequest,options
    );
  }



export const getAddRelationshipRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addRelationshipRoute>>, TError,{data: AddRelationshipRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof addRelationshipRoute>>, TError,{data: AddRelationshipRequest}, TContext> => {
    
const mutationKey = ['addRelationshipRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addRelationshipRoute>>, {data: AddRelationshipRequest}> = (props) => {
          const {data} = props ?? {};

          return  addRelationshipRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddRelationshipRouteMutationResult = NonNullable<Awaited<ReturnType<typeof addRelationshipRoute>>>
    export type AddRelationshipRouteMutationBody = AddRelationshipRequest
    export type AddRelationshipRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Add Relationship Route
 */
export const useAddRelationshipRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addRelationshipRoute>>, TError,{data: AddRelationshipRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof addRelationshipRoute>>,
        TError,
        {data: AddRelationshipRequest},
        TContext
      > => {

      const mutationOptions = getAddRelationshipRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Field Route
 */
export const updateFieldRoute = (
    updateFieldRequest: UpdateFieldRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/tables/fields/update`,
      updateFieldRequest,options
    );
  }



export const getUpdateFieldRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateFieldRoute>>, TError,{data: UpdateFieldRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateFieldRoute>>, TError,{data: UpdateFieldRequest}, TContext> => {
    
const mutationKey = ['updateFieldRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateFieldRoute>>, {data: UpdateFieldRequest}> = (props) => {
          const {data} = props ?? {};

          return  updateFieldRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateFieldRouteMutationResult = NonNullable<Awaited<ReturnType<typeof updateFieldRoute>>>
    export type UpdateFieldRouteMutationBody = UpdateFieldRequest
    export type UpdateFieldRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Field Route
 */
export const useUpdateFieldRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateFieldRoute>>, TError,{data: UpdateFieldRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updateFieldRoute>>,
        TError,
        {data: UpdateFieldRequest},
        TContext
      > => {

      const mutationOptions = getUpdateFieldRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Select Field Properties Route
 */
export const updateSelectFieldPropertiesRoute = (
    updateSelectFieldPropertiesRequest: UpdateSelectFieldPropertiesRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/tables/fields/update_select_field_properties`,
      updateSelectFieldPropertiesRequest,options
    );
  }



export const getUpdateSelectFieldPropertiesRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSelectFieldPropertiesRoute>>, TError,{data: UpdateSelectFieldPropertiesRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateSelectFieldPropertiesRoute>>, TError,{data: UpdateSelectFieldPropertiesRequest}, TContext> => {
    
const mutationKey = ['updateSelectFieldPropertiesRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateSelectFieldPropertiesRoute>>, {data: UpdateSelectFieldPropertiesRequest}> = (props) => {
          const {data} = props ?? {};

          return  updateSelectFieldPropertiesRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateSelectFieldPropertiesRouteMutationResult = NonNullable<Awaited<ReturnType<typeof updateSelectFieldPropertiesRoute>>>
    export type UpdateSelectFieldPropertiesRouteMutationBody = UpdateSelectFieldPropertiesRequest
    export type UpdateSelectFieldPropertiesRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Select Field Properties Route
 */
export const useUpdateSelectFieldPropertiesRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSelectFieldPropertiesRoute>>, TError,{data: UpdateSelectFieldPropertiesRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updateSelectFieldPropertiesRoute>>,
        TError,
        {data: UpdateSelectFieldPropertiesRequest},
        TContext
      > => {

      const mutationOptions = getUpdateSelectFieldPropertiesRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Datetime Time Format Route
 */
export const updateDatetimeTimeFormatRoute = (
    updateDatetimeTimeFormatRequest: UpdateDatetimeTimeFormatRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/tables/fields/update_datetime_time_format`,
      updateDatetimeTimeFormatRequest,options
    );
  }



export const getUpdateDatetimeTimeFormatRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDatetimeTimeFormatRoute>>, TError,{data: UpdateDatetimeTimeFormatRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateDatetimeTimeFormatRoute>>, TError,{data: UpdateDatetimeTimeFormatRequest}, TContext> => {
    
const mutationKey = ['updateDatetimeTimeFormatRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateDatetimeTimeFormatRoute>>, {data: UpdateDatetimeTimeFormatRequest}> = (props) => {
          const {data} = props ?? {};

          return  updateDatetimeTimeFormatRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateDatetimeTimeFormatRouteMutationResult = NonNullable<Awaited<ReturnType<typeof updateDatetimeTimeFormatRoute>>>
    export type UpdateDatetimeTimeFormatRouteMutationBody = UpdateDatetimeTimeFormatRequest
    export type UpdateDatetimeTimeFormatRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Datetime Time Format Route
 */
export const useUpdateDatetimeTimeFormatRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDatetimeTimeFormatRoute>>, TError,{data: UpdateDatetimeTimeFormatRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updateDatetimeTimeFormatRoute>>,
        TError,
        {data: UpdateDatetimeTimeFormatRequest},
        TContext
      > => {

      const mutationOptions = getUpdateDatetimeTimeFormatRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Field Route
 */
export const deleteFieldRoute = (
    deleteFieldRequest: DeleteFieldRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/tables/fields/delete`,
      deleteFieldRequest,options
    );
  }



export const getDeleteFieldRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFieldRoute>>, TError,{data: DeleteFieldRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFieldRoute>>, TError,{data: DeleteFieldRequest}, TContext> => {
    
const mutationKey = ['deleteFieldRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFieldRoute>>, {data: DeleteFieldRequest}> = (props) => {
          const {data} = props ?? {};

          return  deleteFieldRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFieldRouteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFieldRoute>>>
    export type DeleteFieldRouteMutationBody = DeleteFieldRequest
    export type DeleteFieldRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Field Route
 */
export const useDeleteFieldRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFieldRoute>>, TError,{data: DeleteFieldRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteFieldRoute>>,
        TError,
        {data: DeleteFieldRequest},
        TContext
      > => {

      const mutationOptions = getDeleteFieldRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Relationship Route
 */
export const deleteRelationshipRoute = (
    deleteRelationshipRequest: DeleteRelationshipRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/tables/fields/delete_relationship`,
      deleteRelationshipRequest,options
    );
  }



export const getDeleteRelationshipRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRelationshipRoute>>, TError,{data: DeleteRelationshipRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteRelationshipRoute>>, TError,{data: DeleteRelationshipRequest}, TContext> => {
    
const mutationKey = ['deleteRelationshipRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteRelationshipRoute>>, {data: DeleteRelationshipRequest}> = (props) => {
          const {data} = props ?? {};

          return  deleteRelationshipRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteRelationshipRouteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteRelationshipRoute>>>
    export type DeleteRelationshipRouteMutationBody = DeleteRelationshipRequest
    export type DeleteRelationshipRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Relationship Route
 */
export const useDeleteRelationshipRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRelationshipRoute>>, TError,{data: DeleteRelationshipRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteRelationshipRoute>>,
        TError,
        {data: DeleteRelationshipRequest},
        TContext
      > => {

      const mutationOptions = getDeleteRelationshipRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Insert Empty Records Route
 */
export const insertEmptyRecordsRoute = (
    insertEmptyRecordsRequest: InsertEmptyRecordsRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/tables/records/insert_empty_many`,
      insertEmptyRecordsRequest,options
    );
  }



export const getInsertEmptyRecordsRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof insertEmptyRecordsRoute>>, TError,{data: InsertEmptyRecordsRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof insertEmptyRecordsRoute>>, TError,{data: InsertEmptyRecordsRequest}, TContext> => {
    
const mutationKey = ['insertEmptyRecordsRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof insertEmptyRecordsRoute>>, {data: InsertEmptyRecordsRequest}> = (props) => {
          const {data} = props ?? {};

          return  insertEmptyRecordsRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type InsertEmptyRecordsRouteMutationResult = NonNullable<Awaited<ReturnType<typeof insertEmptyRecordsRoute>>>
    export type InsertEmptyRecordsRouteMutationBody = InsertEmptyRecordsRequest
    export type InsertEmptyRecordsRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Insert Empty Records Route
 */
export const useInsertEmptyRecordsRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof insertEmptyRecordsRoute>>, TError,{data: InsertEmptyRecordsRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof insertEmptyRecordsRoute>>,
        TError,
        {data: InsertEmptyRecordsRequest},
        TContext
      > => {

      const mutationOptions = getInsertEmptyRecordsRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Records Route
 */
export const updateRecordsRoute = (
    updateRecordsRequest: UpdateRecordsRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/tables/records/update_records`,
      updateRecordsRequest,options
    );
  }



export const getUpdateRecordsRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateRecordsRoute>>, TError,{data: UpdateRecordsRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updateRecordsRoute>>, TError,{data: UpdateRecordsRequest}, TContext> => {
    
const mutationKey = ['updateRecordsRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateRecordsRoute>>, {data: UpdateRecordsRequest}> = (props) => {
          const {data} = props ?? {};

          return  updateRecordsRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateRecordsRouteMutationResult = NonNullable<Awaited<ReturnType<typeof updateRecordsRoute>>>
    export type UpdateRecordsRouteMutationBody = UpdateRecordsRequest
    export type UpdateRecordsRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Records Route
 */
export const useUpdateRecordsRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateRecordsRoute>>, TError,{data: UpdateRecordsRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updateRecordsRoute>>,
        TError,
        {data: UpdateRecordsRequest},
        TContext
      > => {

      const mutationOptions = getUpdateRecordsRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Records Route
 */
export const deleteRecordsRoute = (
    deleteRecordsRequest: DeleteRecordsRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/tables/records/delete`,
      deleteRecordsRequest,options
    );
  }



export const getDeleteRecordsRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRecordsRoute>>, TError,{data: DeleteRecordsRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteRecordsRoute>>, TError,{data: DeleteRecordsRequest}, TContext> => {
    
const mutationKey = ['deleteRecordsRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteRecordsRoute>>, {data: DeleteRecordsRequest}> = (props) => {
          const {data} = props ?? {};

          return  deleteRecordsRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteRecordsRouteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteRecordsRoute>>>
    export type DeleteRecordsRouteMutationBody = DeleteRecordsRequest
    export type DeleteRecordsRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Records Route
 */
export const useDeleteRecordsRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRecordsRoute>>, TError,{data: DeleteRecordsRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteRecordsRoute>>,
        TError,
        {data: DeleteRecordsRequest},
        TContext
      > => {

      const mutationOptions = getDeleteRecordsRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Run a query and return the results. Used for previewing computed tables.
 * @summary Run Query Route
 */
export const runQueryRoute = (
    runQueryRequest: RunQueryRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<RunQueryResponse>> => {
    
    
    return axios.default.post(
      `/tables/views/run_query`,
      runQueryRequest,options
    );
  }



export const getRunQueryRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof runQueryRoute>>, TError,{data: RunQueryRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof runQueryRoute>>, TError,{data: RunQueryRequest}, TContext> => {
    
const mutationKey = ['runQueryRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof runQueryRoute>>, {data: RunQueryRequest}> = (props) => {
          const {data} = props ?? {};

          return  runQueryRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RunQueryRouteMutationResult = NonNullable<Awaited<ReturnType<typeof runQueryRoute>>>
    export type RunQueryRouteMutationBody = RunQueryRequest
    export type RunQueryRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Run Query Route
 */
export const useRunQueryRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof runQueryRoute>>, TError,{data: RunQueryRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof runQueryRoute>>,
        TError,
        {data: RunQueryRequest},
        TContext
      > => {

      const mutationOptions = getRunQueryRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Create Computed Table Route
 */
export const createComputedTableRoute = (
    createComputedTableRequest: CreateComputedTableRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/tables/views/create_computed_table`,
      createComputedTableRequest,options
    );
  }



export const getCreateComputedTableRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createComputedTableRoute>>, TError,{data: CreateComputedTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createComputedTableRoute>>, TError,{data: CreateComputedTableRequest}, TContext> => {
    
const mutationKey = ['createComputedTableRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createComputedTableRoute>>, {data: CreateComputedTableRequest}> = (props) => {
          const {data} = props ?? {};

          return  createComputedTableRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateComputedTableRouteMutationResult = NonNullable<Awaited<ReturnType<typeof createComputedTableRoute>>>
    export type CreateComputedTableRouteMutationBody = CreateComputedTableRequest
    export type CreateComputedTableRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Computed Table Route
 */
export const useCreateComputedTableRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createComputedTableRoute>>, TError,{data: CreateComputedTableRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createComputedTableRoute>>,
        TError,
        {data: CreateComputedTableRequest},
        TContext
      > => {

      const mutationOptions = getCreateComputedTableRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Uploads
 */
export const uploads = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/uploads`,options
    );
  }


export const getUploadsQueryKey = () => {
    return [`/shapes/uploads`] as const;
    }

    
export const getUploadsQueryOptions = <TData = Awaited<ReturnType<typeof uploads>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof uploads>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUploadsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof uploads>>> = ({ signal }) => uploads({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof uploads>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type UploadsQueryResult = NonNullable<Awaited<ReturnType<typeof uploads>>>
export type UploadsQueryError = AxiosError<unknown>


export function useUploads<TData = Awaited<ReturnType<typeof uploads>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof uploads>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof uploads>>,
          TError,
          Awaited<ReturnType<typeof uploads>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useUploads<TData = Awaited<ReturnType<typeof uploads>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof uploads>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof uploads>>,
          TError,
          Awaited<ReturnType<typeof uploads>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useUploads<TData = Awaited<ReturnType<typeof uploads>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof uploads>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Uploads
 */

export function useUploads<TData = Awaited<ReturnType<typeof uploads>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof uploads>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getUploadsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Feed Channels
 */
export const feedChannels = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/feed_channels`,options
    );
  }


export const getFeedChannelsQueryKey = () => {
    return [`/shapes/feed_channels`] as const;
    }

    
export const getFeedChannelsQueryOptions = <TData = Awaited<ReturnType<typeof feedChannels>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof feedChannels>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFeedChannelsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof feedChannels>>> = ({ signal }) => feedChannels({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof feedChannels>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type FeedChannelsQueryResult = NonNullable<Awaited<ReturnType<typeof feedChannels>>>
export type FeedChannelsQueryError = AxiosError<unknown>


export function useFeedChannels<TData = Awaited<ReturnType<typeof feedChannels>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof feedChannels>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof feedChannels>>,
          TError,
          Awaited<ReturnType<typeof feedChannels>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useFeedChannels<TData = Awaited<ReturnType<typeof feedChannels>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof feedChannels>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof feedChannels>>,
          TError,
          Awaited<ReturnType<typeof feedChannels>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useFeedChannels<TData = Awaited<ReturnType<typeof feedChannels>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof feedChannels>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Feed Channels
 */

export function useFeedChannels<TData = Awaited<ReturnType<typeof feedChannels>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof feedChannels>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getFeedChannelsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Feed Items
 */
export const feedItems = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/feed_items`,options
    );
  }


export const getFeedItemsQueryKey = () => {
    return [`/shapes/feed_items`] as const;
    }

    
export const getFeedItemsQueryOptions = <TData = Awaited<ReturnType<typeof feedItems>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof feedItems>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFeedItemsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof feedItems>>> = ({ signal }) => feedItems({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof feedItems>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type FeedItemsQueryResult = NonNullable<Awaited<ReturnType<typeof feedItems>>>
export type FeedItemsQueryError = AxiosError<unknown>


export function useFeedItems<TData = Awaited<ReturnType<typeof feedItems>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof feedItems>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof feedItems>>,
          TError,
          Awaited<ReturnType<typeof feedItems>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useFeedItems<TData = Awaited<ReturnType<typeof feedItems>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof feedItems>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof feedItems>>,
          TError,
          Awaited<ReturnType<typeof feedItems>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useFeedItems<TData = Awaited<ReturnType<typeof feedItems>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof feedItems>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Feed Items
 */

export function useFeedItems<TData = Awaited<ReturnType<typeof feedItems>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof feedItems>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getFeedItemsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Tables
 */
export const tables = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/tables`,options
    );
  }


export const getTablesQueryKey = () => {
    return [`/shapes/tables`] as const;
    }

    
export const getTablesQueryOptions = <TData = Awaited<ReturnType<typeof tables>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tables>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTablesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tables>>> = ({ signal }) => tables({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tables>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type TablesQueryResult = NonNullable<Awaited<ReturnType<typeof tables>>>
export type TablesQueryError = AxiosError<unknown>


export function useTables<TData = Awaited<ReturnType<typeof tables>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof tables>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof tables>>,
          TError,
          Awaited<ReturnType<typeof tables>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useTables<TData = Awaited<ReturnType<typeof tables>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tables>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof tables>>,
          TError,
          Awaited<ReturnType<typeof tables>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useTables<TData = Awaited<ReturnType<typeof tables>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tables>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Tables
 */

export function useTables<TData = Awaited<ReturnType<typeof tables>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof tables>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getTablesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Fields
 */
export const fields = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/fields`,options
    );
  }


export const getFieldsQueryKey = () => {
    return [`/shapes/fields`] as const;
    }

    
export const getFieldsQueryOptions = <TData = Awaited<ReturnType<typeof fields>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fields>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFieldsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof fields>>> = ({ signal }) => fields({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof fields>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type FieldsQueryResult = NonNullable<Awaited<ReturnType<typeof fields>>>
export type FieldsQueryError = AxiosError<unknown>


export function useFields<TData = Awaited<ReturnType<typeof fields>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof fields>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof fields>>,
          TError,
          Awaited<ReturnType<typeof fields>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useFields<TData = Awaited<ReturnType<typeof fields>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fields>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof fields>>,
          TError,
          Awaited<ReturnType<typeof fields>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useFields<TData = Awaited<ReturnType<typeof fields>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fields>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Fields
 */

export function useFields<TData = Awaited<ReturnType<typeof fields>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof fields>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getFieldsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Users
 */
export const users = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/users`,options
    );
  }


export const getUsersQueryKey = () => {
    return [`/shapes/users`] as const;
    }

    
export const getUsersQueryOptions = <TData = Awaited<ReturnType<typeof users>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof users>>> = ({ signal }) => users({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type UsersQueryResult = NonNullable<Awaited<ReturnType<typeof users>>>
export type UsersQueryError = AxiosError<unknown>


export function useUsers<TData = Awaited<ReturnType<typeof users>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof users>>,
          TError,
          Awaited<ReturnType<typeof users>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useUsers<TData = Awaited<ReturnType<typeof users>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof users>>,
          TError,
          Awaited<ReturnType<typeof users>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useUsers<TData = Awaited<ReturnType<typeof users>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Users
 */

export function useUsers<TData = Awaited<ReturnType<typeof users>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof users>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getUsersQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Search Results
 */
export const searchResults = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/search_results`,options
    );
  }


export const getSearchResultsQueryKey = () => {
    return [`/shapes/search_results`] as const;
    }

    
export const getSearchResultsQueryOptions = <TData = Awaited<ReturnType<typeof searchResults>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchResults>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSearchResultsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof searchResults>>> = ({ signal }) => searchResults({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof searchResults>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type SearchResultsQueryResult = NonNullable<Awaited<ReturnType<typeof searchResults>>>
export type SearchResultsQueryError = AxiosError<unknown>


export function useSearchResults<TData = Awaited<ReturnType<typeof searchResults>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchResults>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof searchResults>>,
          TError,
          Awaited<ReturnType<typeof searchResults>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useSearchResults<TData = Awaited<ReturnType<typeof searchResults>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchResults>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof searchResults>>,
          TError,
          Awaited<ReturnType<typeof searchResults>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useSearchResults<TData = Awaited<ReturnType<typeof searchResults>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchResults>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Search Results
 */

export function useSearchResults<TData = Awaited<ReturnType<typeof searchResults>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchResults>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getSearchResultsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Search Results Full
 */
export const searchResultsFull = (
    searchId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/search_results_full/${searchId}`,options
    );
  }


export const getSearchResultsFullQueryKey = (searchId: string,) => {
    return [`/shapes/search_results_full/${searchId}`] as const;
    }

    
export const getSearchResultsFullQueryOptions = <TData = Awaited<ReturnType<typeof searchResultsFull>>, TError = AxiosError<HTTPValidationError>>(searchId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchResultsFull>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSearchResultsFullQueryKey(searchId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof searchResultsFull>>> = ({ signal }) => searchResultsFull(searchId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(searchId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof searchResultsFull>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type SearchResultsFullQueryResult = NonNullable<Awaited<ReturnType<typeof searchResultsFull>>>
export type SearchResultsFullQueryError = AxiosError<HTTPValidationError>


export function useSearchResultsFull<TData = Awaited<ReturnType<typeof searchResultsFull>>, TError = AxiosError<HTTPValidationError>>(
 searchId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchResultsFull>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof searchResultsFull>>,
          TError,
          Awaited<ReturnType<typeof searchResultsFull>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useSearchResultsFull<TData = Awaited<ReturnType<typeof searchResultsFull>>, TError = AxiosError<HTTPValidationError>>(
 searchId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchResultsFull>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof searchResultsFull>>,
          TError,
          Awaited<ReturnType<typeof searchResultsFull>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useSearchResultsFull<TData = Awaited<ReturnType<typeof searchResultsFull>>, TError = AxiosError<HTTPValidationError>>(
 searchId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchResultsFull>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Search Results Full
 */

export function useSearchResultsFull<TData = Awaited<ReturnType<typeof searchResultsFull>>, TError = AxiosError<HTTPValidationError>>(
 searchId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof searchResultsFull>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getSearchResultsFullQueryOptions(searchId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Web Search Results
 */
export const webSearchResults = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/web_search_results`,options
    );
  }


export const getWebSearchResultsQueryKey = () => {
    return [`/shapes/web_search_results`] as const;
    }

    
export const getWebSearchResultsQueryOptions = <TData = Awaited<ReturnType<typeof webSearchResults>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof webSearchResults>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWebSearchResultsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof webSearchResults>>> = ({ signal }) => webSearchResults({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof webSearchResults>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type WebSearchResultsQueryResult = NonNullable<Awaited<ReturnType<typeof webSearchResults>>>
export type WebSearchResultsQueryError = AxiosError<unknown>


export function useWebSearchResults<TData = Awaited<ReturnType<typeof webSearchResults>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof webSearchResults>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof webSearchResults>>,
          TError,
          Awaited<ReturnType<typeof webSearchResults>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useWebSearchResults<TData = Awaited<ReturnType<typeof webSearchResults>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof webSearchResults>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof webSearchResults>>,
          TError,
          Awaited<ReturnType<typeof webSearchResults>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useWebSearchResults<TData = Awaited<ReturnType<typeof webSearchResults>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof webSearchResults>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Web Search Results
 */

export function useWebSearchResults<TData = Awaited<ReturnType<typeof webSearchResults>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof webSearchResults>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getWebSearchResultsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Web Search Results Full
 */
export const webSearchResultsFull = (
    webSearchId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/web_search_results_full/${webSearchId}`,options
    );
  }


export const getWebSearchResultsFullQueryKey = (webSearchId: string,) => {
    return [`/shapes/web_search_results_full/${webSearchId}`] as const;
    }

    
export const getWebSearchResultsFullQueryOptions = <TData = Awaited<ReturnType<typeof webSearchResultsFull>>, TError = AxiosError<HTTPValidationError>>(webSearchId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof webSearchResultsFull>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWebSearchResultsFullQueryKey(webSearchId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof webSearchResultsFull>>> = ({ signal }) => webSearchResultsFull(webSearchId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(webSearchId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof webSearchResultsFull>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type WebSearchResultsFullQueryResult = NonNullable<Awaited<ReturnType<typeof webSearchResultsFull>>>
export type WebSearchResultsFullQueryError = AxiosError<HTTPValidationError>


export function useWebSearchResultsFull<TData = Awaited<ReturnType<typeof webSearchResultsFull>>, TError = AxiosError<HTTPValidationError>>(
 webSearchId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof webSearchResultsFull>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof webSearchResultsFull>>,
          TError,
          Awaited<ReturnType<typeof webSearchResultsFull>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useWebSearchResultsFull<TData = Awaited<ReturnType<typeof webSearchResultsFull>>, TError = AxiosError<HTTPValidationError>>(
 webSearchId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof webSearchResultsFull>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof webSearchResultsFull>>,
          TError,
          Awaited<ReturnType<typeof webSearchResultsFull>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useWebSearchResultsFull<TData = Awaited<ReturnType<typeof webSearchResultsFull>>, TError = AxiosError<HTTPValidationError>>(
 webSearchId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof webSearchResultsFull>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Web Search Results Full
 */

export function useWebSearchResultsFull<TData = Awaited<ReturnType<typeof webSearchResultsFull>>, TError = AxiosError<HTTPValidationError>>(
 webSearchId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof webSearchResultsFull>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getWebSearchResultsFullQueryOptions(webSearchId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Webpages
 */
export const webpages = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/webpages`,options
    );
  }


export const getWebpagesQueryKey = () => {
    return [`/shapes/webpages`] as const;
    }

    
export const getWebpagesQueryOptions = <TData = Awaited<ReturnType<typeof webpages>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof webpages>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getWebpagesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof webpages>>> = ({ signal }) => webpages({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof webpages>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type WebpagesQueryResult = NonNullable<Awaited<ReturnType<typeof webpages>>>
export type WebpagesQueryError = AxiosError<unknown>


export function useWebpages<TData = Awaited<ReturnType<typeof webpages>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof webpages>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof webpages>>,
          TError,
          Awaited<ReturnType<typeof webpages>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useWebpages<TData = Awaited<ReturnType<typeof webpages>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof webpages>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof webpages>>,
          TError,
          Awaited<ReturnType<typeof webpages>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useWebpages<TData = Awaited<ReturnType<typeof webpages>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof webpages>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Webpages
 */

export function useWebpages<TData = Awaited<ReturnType<typeof webpages>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof webpages>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getWebpagesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Pages
 */
export const pages = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/pages`,options
    );
  }


export const getPagesQueryKey = () => {
    return [`/shapes/pages`] as const;
    }

    
export const getPagesQueryOptions = <TData = Awaited<ReturnType<typeof pages>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPagesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pages>>> = ({ signal }) => pages({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type PagesQueryResult = NonNullable<Awaited<ReturnType<typeof pages>>>
export type PagesQueryError = AxiosError<unknown>


export function usePages<TData = Awaited<ReturnType<typeof pages>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof pages>>,
          TError,
          Awaited<ReturnType<typeof pages>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function usePages<TData = Awaited<ReturnType<typeof pages>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof pages>>,
          TError,
          Awaited<ReturnType<typeof pages>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function usePages<TData = Awaited<ReturnType<typeof pages>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Pages
 */

export function usePages<TData = Awaited<ReturnType<typeof pages>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pages>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getPagesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Pages Links
 */
export const pagesLinks = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/pages_links`,options
    );
  }


export const getPagesLinksQueryKey = () => {
    return [`/shapes/pages_links`] as const;
    }

    
export const getPagesLinksQueryOptions = <TData = Awaited<ReturnType<typeof pagesLinks>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pagesLinks>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPagesLinksQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pagesLinks>>> = ({ signal }) => pagesLinks({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pagesLinks>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type PagesLinksQueryResult = NonNullable<Awaited<ReturnType<typeof pagesLinks>>>
export type PagesLinksQueryError = AxiosError<unknown>


export function usePagesLinks<TData = Awaited<ReturnType<typeof pagesLinks>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof pagesLinks>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof pagesLinks>>,
          TError,
          Awaited<ReturnType<typeof pagesLinks>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function usePagesLinks<TData = Awaited<ReturnType<typeof pagesLinks>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pagesLinks>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof pagesLinks>>,
          TError,
          Awaited<ReturnType<typeof pagesLinks>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function usePagesLinks<TData = Awaited<ReturnType<typeof pagesLinks>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pagesLinks>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Pages Links
 */

export function usePagesLinks<TData = Awaited<ReturnType<typeof pagesLinks>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pagesLinks>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getPagesLinksQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Pages Operations
 */
export const pagesOperations = (
    pageId: PageId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/pages_operations/${pageId}`,options
    );
  }


export const getPagesOperationsQueryKey = (pageId: PageId,) => {
    return [`/shapes/pages_operations/${pageId}`] as const;
    }

    
export const getPagesOperationsQueryOptions = <TData = Awaited<ReturnType<typeof pagesOperations>>, TError = AxiosError<HTTPValidationError>>(pageId: PageId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pagesOperations>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPagesOperationsQueryKey(pageId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pagesOperations>>> = ({ signal }) => pagesOperations(pageId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(pageId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pagesOperations>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type PagesOperationsQueryResult = NonNullable<Awaited<ReturnType<typeof pagesOperations>>>
export type PagesOperationsQueryError = AxiosError<HTTPValidationError>


export function usePagesOperations<TData = Awaited<ReturnType<typeof pagesOperations>>, TError = AxiosError<HTTPValidationError>>(
 pageId: PageId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof pagesOperations>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof pagesOperations>>,
          TError,
          Awaited<ReturnType<typeof pagesOperations>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function usePagesOperations<TData = Awaited<ReturnType<typeof pagesOperations>>, TError = AxiosError<HTTPValidationError>>(
 pageId: PageId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pagesOperations>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof pagesOperations>>,
          TError,
          Awaited<ReturnType<typeof pagesOperations>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function usePagesOperations<TData = Awaited<ReturnType<typeof pagesOperations>>, TError = AxiosError<HTTPValidationError>>(
 pageId: PageId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pagesOperations>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Pages Operations
 */

export function usePagesOperations<TData = Awaited<ReturnType<typeof pagesOperations>>, TError = AxiosError<HTTPValidationError>>(
 pageId: PageId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pagesOperations>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getPagesOperationsQueryOptions(pageId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Pages Awareness
 */
export const pagesAwareness = (
    pageId: PageId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/pages_awareness/${pageId}`,options
    );
  }


export const getPagesAwarenessQueryKey = (pageId: PageId,) => {
    return [`/shapes/pages_awareness/${pageId}`] as const;
    }

    
export const getPagesAwarenessQueryOptions = <TData = Awaited<ReturnType<typeof pagesAwareness>>, TError = AxiosError<HTTPValidationError>>(pageId: PageId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pagesAwareness>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPagesAwarenessQueryKey(pageId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pagesAwareness>>> = ({ signal }) => pagesAwareness(pageId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(pageId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pagesAwareness>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type PagesAwarenessQueryResult = NonNullable<Awaited<ReturnType<typeof pagesAwareness>>>
export type PagesAwarenessQueryError = AxiosError<HTTPValidationError>


export function usePagesAwareness<TData = Awaited<ReturnType<typeof pagesAwareness>>, TError = AxiosError<HTTPValidationError>>(
 pageId: PageId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof pagesAwareness>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof pagesAwareness>>,
          TError,
          Awaited<ReturnType<typeof pagesAwareness>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function usePagesAwareness<TData = Awaited<ReturnType<typeof pagesAwareness>>, TError = AxiosError<HTTPValidationError>>(
 pageId: PageId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pagesAwareness>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof pagesAwareness>>,
          TError,
          Awaited<ReturnType<typeof pagesAwareness>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function usePagesAwareness<TData = Awaited<ReturnType<typeof pagesAwareness>>, TError = AxiosError<HTTPValidationError>>(
 pageId: PageId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pagesAwareness>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Pages Awareness
 */

export function usePagesAwareness<TData = Awaited<ReturnType<typeof pagesAwareness>>, TError = AxiosError<HTTPValidationError>>(
 pageId: PageId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pagesAwareness>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getPagesAwarenessQueryOptions(pageId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Steps
 */
export const steps = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/steps`,options
    );
  }


export const getStepsQueryKey = () => {
    return [`/shapes/steps`] as const;
    }

    
export const getStepsQueryOptions = <TData = Awaited<ReturnType<typeof steps>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof steps>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStepsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof steps>>> = ({ signal }) => steps({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof steps>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type StepsQueryResult = NonNullable<Awaited<ReturnType<typeof steps>>>
export type StepsQueryError = AxiosError<unknown>


export function useSteps<TData = Awaited<ReturnType<typeof steps>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof steps>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof steps>>,
          TError,
          Awaited<ReturnType<typeof steps>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useSteps<TData = Awaited<ReturnType<typeof steps>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof steps>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof steps>>,
          TError,
          Awaited<ReturnType<typeof steps>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useSteps<TData = Awaited<ReturnType<typeof steps>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof steps>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Steps
 */

export function useSteps<TData = Awaited<ReturnType<typeof steps>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof steps>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getStepsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary User Sessions
 */
export const userSessions = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/user_sessions`,options
    );
  }


export const getUserSessionsQueryKey = () => {
    return [`/shapes/user_sessions`] as const;
    }

    
export const getUserSessionsQueryOptions = <TData = Awaited<ReturnType<typeof userSessions>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof userSessions>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUserSessionsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof userSessions>>> = ({ signal }) => userSessions({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof userSessions>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type UserSessionsQueryResult = NonNullable<Awaited<ReturnType<typeof userSessions>>>
export type UserSessionsQueryError = AxiosError<unknown>


export function useUserSessions<TData = Awaited<ReturnType<typeof userSessions>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof userSessions>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof userSessions>>,
          TError,
          Awaited<ReturnType<typeof userSessions>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useUserSessions<TData = Awaited<ReturnType<typeof userSessions>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof userSessions>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof userSessions>>,
          TError,
          Awaited<ReturnType<typeof userSessions>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useUserSessions<TData = Awaited<ReturnType<typeof userSessions>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof userSessions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary User Sessions
 */

export function useUserSessions<TData = Awaited<ReturnType<typeof userSessions>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof userSessions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getUserSessionsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Assistant Sessions
 */
export const assistantSessions = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/assistant_sessions`,options
    );
  }


export const getAssistantSessionsQueryKey = () => {
    return [`/shapes/assistant_sessions`] as const;
    }

    
export const getAssistantSessionsQueryOptions = <TData = Awaited<ReturnType<typeof assistantSessions>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assistantSessions>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAssistantSessionsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof assistantSessions>>> = ({ signal }) => assistantSessions({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof assistantSessions>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type AssistantSessionsQueryResult = NonNullable<Awaited<ReturnType<typeof assistantSessions>>>
export type AssistantSessionsQueryError = AxiosError<unknown>


export function useAssistantSessions<TData = Awaited<ReturnType<typeof assistantSessions>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof assistantSessions>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof assistantSessions>>,
          TError,
          Awaited<ReturnType<typeof assistantSessions>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useAssistantSessions<TData = Awaited<ReturnType<typeof assistantSessions>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assistantSessions>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof assistantSessions>>,
          TError,
          Awaited<ReturnType<typeof assistantSessions>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useAssistantSessions<TData = Awaited<ReturnType<typeof assistantSessions>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assistantSessions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Assistant Sessions
 */

export function useAssistantSessions<TData = Awaited<ReturnType<typeof assistantSessions>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof assistantSessions>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getAssistantSessionsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Messages
 */
export const messages = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/messages`,options
    );
  }


export const getMessagesQueryKey = () => {
    return [`/shapes/messages`] as const;
    }

    
export const getMessagesQueryOptions = <TData = Awaited<ReturnType<typeof messages>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof messages>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getMessagesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof messages>>> = ({ signal }) => messages({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof messages>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type MessagesQueryResult = NonNullable<Awaited<ReturnType<typeof messages>>>
export type MessagesQueryError = AxiosError<unknown>


export function useMessages<TData = Awaited<ReturnType<typeof messages>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof messages>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof messages>>,
          TError,
          Awaited<ReturnType<typeof messages>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useMessages<TData = Awaited<ReturnType<typeof messages>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof messages>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof messages>>,
          TError,
          Awaited<ReturnType<typeof messages>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useMessages<TData = Awaited<ReturnType<typeof messages>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof messages>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Messages
 */

export function useMessages<TData = Awaited<ReturnType<typeof messages>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof messages>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getMessagesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Spans
 */
export const spans = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/spans`,options
    );
  }


export const getSpansQueryKey = () => {
    return [`/shapes/spans`] as const;
    }

    
export const getSpansQueryOptions = <TData = Awaited<ReturnType<typeof spans>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof spans>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSpansQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof spans>>> = ({ signal }) => spans({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof spans>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type SpansQueryResult = NonNullable<Awaited<ReturnType<typeof spans>>>
export type SpansQueryError = AxiosError<unknown>


export function useSpans<TData = Awaited<ReturnType<typeof spans>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof spans>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof spans>>,
          TError,
          Awaited<ReturnType<typeof spans>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useSpans<TData = Awaited<ReturnType<typeof spans>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof spans>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof spans>>,
          TError,
          Awaited<ReturnType<typeof spans>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useSpans<TData = Awaited<ReturnType<typeof spans>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof spans>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Spans
 */

export function useSpans<TData = Awaited<ReturnType<typeof spans>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof spans>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getSpansQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Pending Resources
 */
export const pendingResources = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/shapes/pending_resources`,options
    );
  }


export const getPendingResourcesQueryKey = () => {
    return [`/shapes/pending_resources`] as const;
    }

    
export const getPendingResourcesQueryOptions = <TData = Awaited<ReturnType<typeof pendingResources>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pendingResources>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPendingResourcesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof pendingResources>>> = ({ signal }) => pendingResources({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof pendingResources>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type PendingResourcesQueryResult = NonNullable<Awaited<ReturnType<typeof pendingResources>>>
export type PendingResourcesQueryError = AxiosError<unknown>


export function usePendingResources<TData = Awaited<ReturnType<typeof pendingResources>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof pendingResources>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof pendingResources>>,
          TError,
          Awaited<ReturnType<typeof pendingResources>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function usePendingResources<TData = Awaited<ReturnType<typeof pendingResources>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pendingResources>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof pendingResources>>,
          TError,
          Awaited<ReturnType<typeof pendingResources>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function usePendingResources<TData = Awaited<ReturnType<typeof pendingResources>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pendingResources>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Pending Resources
 */

export function usePendingResources<TData = Awaited<ReturnType<typeof pendingResources>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof pendingResources>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getPendingResourcesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Create Page Route
 */
export const createPageRoute = (
    createPageRequest: CreatePageRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/pages/create`,
      createPageRequest,options
    );
  }



export const getCreatePageRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPageRoute>>, TError,{data: CreatePageRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createPageRoute>>, TError,{data: CreatePageRequest}, TContext> => {
    
const mutationKey = ['createPageRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPageRoute>>, {data: CreatePageRequest}> = (props) => {
          const {data} = props ?? {};

          return  createPageRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreatePageRouteMutationResult = NonNullable<Awaited<ReturnType<typeof createPageRoute>>>
    export type CreatePageRouteMutationBody = CreatePageRequest
    export type CreatePageRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Page Route
 */
export const useCreatePageRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPageRoute>>, TError,{data: CreatePageRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createPageRoute>>,
        TError,
        {data: CreatePageRequest},
        TContext
      > => {

      const mutationOptions = getCreatePageRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Page Route
 */
export const updatePageRoute = (
    updatePageRequest: UpdatePageRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.put(
      `/pages/update`,
      updatePageRequest,options
    );
  }



export const getUpdatePageRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePageRoute>>, TError,{data: UpdatePageRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof updatePageRoute>>, TError,{data: UpdatePageRequest}, TContext> => {
    
const mutationKey = ['updatePageRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updatePageRoute>>, {data: UpdatePageRequest}> = (props) => {
          const {data} = props ?? {};

          return  updatePageRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdatePageRouteMutationResult = NonNullable<Awaited<ReturnType<typeof updatePageRoute>>>
    export type UpdatePageRouteMutationBody = UpdatePageRequest
    export type UpdatePageRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Update Page Route
 */
export const useUpdatePageRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePageRoute>>, TError,{data: UpdatePageRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof updatePageRoute>>,
        TError,
        {data: UpdatePageRequest},
        TContext
      > => {

      const mutationOptions = getUpdatePageRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Pages Route
 */
export const deletePagesRoute = (
    deletePagesRequest: DeletePagesRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.delete(
      `/pages/delete`,{data:
      deletePagesRequest, ...options}
    );
  }



export const getDeletePagesRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePagesRoute>>, TError,{data: DeletePagesRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deletePagesRoute>>, TError,{data: DeletePagesRequest}, TContext> => {
    
const mutationKey = ['deletePagesRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePagesRoute>>, {data: DeletePagesRequest}> = (props) => {
          const {data} = props ?? {};

          return  deletePagesRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeletePagesRouteMutationResult = NonNullable<Awaited<ReturnType<typeof deletePagesRoute>>>
    export type DeletePagesRouteMutationBody = DeletePagesRequest
    export type DeletePagesRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Delete Pages Route
 */
export const useDeletePagesRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePagesRoute>>, TError,{data: DeletePagesRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deletePagesRoute>>,
        TError,
        {data: DeletePagesRequest},
        TContext
      > => {

      const mutationOptions = getDeletePagesRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Save Page Operation Route
 */
export const savePageOperationRoute = (
    pageOperationRequest: PageOperationRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/pages/operation`,
      pageOperationRequest,options
    );
  }



export const getSavePageOperationRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof savePageOperationRoute>>, TError,{data: PageOperationRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof savePageOperationRoute>>, TError,{data: PageOperationRequest}, TContext> => {
    
const mutationKey = ['savePageOperationRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof savePageOperationRoute>>, {data: PageOperationRequest}> = (props) => {
          const {data} = props ?? {};

          return  savePageOperationRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SavePageOperationRouteMutationResult = NonNullable<Awaited<ReturnType<typeof savePageOperationRoute>>>
    export type SavePageOperationRouteMutationBody = PageOperationRequest
    export type SavePageOperationRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Save Page Operation Route
 */
export const useSavePageOperationRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof savePageOperationRoute>>, TError,{data: PageOperationRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof savePageOperationRoute>>,
        TError,
        {data: PageOperationRequest},
        TContext
      > => {

      const mutationOptions = getSavePageOperationRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Save Page Awareness Route
 */
export const savePageAwarenessRoute = (
    pageAwarenessRequest: PageAwarenessRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/pages/awareness`,
      pageAwarenessRequest,options
    );
  }



export const getSavePageAwarenessRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof savePageAwarenessRoute>>, TError,{data: PageAwarenessRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof savePageAwarenessRoute>>, TError,{data: PageAwarenessRequest}, TContext> => {
    
const mutationKey = ['savePageAwarenessRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof savePageAwarenessRoute>>, {data: PageAwarenessRequest}> = (props) => {
          const {data} = props ?? {};

          return  savePageAwarenessRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SavePageAwarenessRouteMutationResult = NonNullable<Awaited<ReturnType<typeof savePageAwarenessRoute>>>
    export type SavePageAwarenessRouteMutationBody = PageAwarenessRequest
    export type SavePageAwarenessRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Save Page Awareness Route
 */
export const useSavePageAwarenessRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof savePageAwarenessRoute>>, TError,{data: PageAwarenessRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof savePageAwarenessRoute>>,
        TError,
        {data: PageAwarenessRequest},
        TContext
      > => {

      const mutationOptions = getSavePageAwarenessRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Create Message Route
 */
export const createMessageRoute = (
    createMessageRequest: CreateMessageRequest, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.post(
      `/messages/`,
      createMessageRequest,options
    );
  }



export const getCreateMessageRouteMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createMessageRoute>>, TError,{data: CreateMessageRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createMessageRoute>>, TError,{data: CreateMessageRequest}, TContext> => {
    
const mutationKey = ['createMessageRoute'];
const {mutation: mutationOptions, axios: axiosOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, axios: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createMessageRoute>>, {data: CreateMessageRequest}> = (props) => {
          const {data} = props ?? {};

          return  createMessageRoute(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateMessageRouteMutationResult = NonNullable<Awaited<ReturnType<typeof createMessageRoute>>>
    export type CreateMessageRouteMutationBody = CreateMessageRequest
    export type CreateMessageRouteMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Create Message Route
 */
export const useCreateMessageRoute = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createMessageRoute>>, TError,{data: CreateMessageRequest}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createMessageRoute>>,
        TError,
        {data: CreateMessageRequest},
        TContext
      > => {

      const mutationOptions = getCreateMessageRouteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Entities
 */
export const getEntities = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EdgarEntityResource[]>> => {
    
    
    return axios.default.get(
      `/edgar/entities`,options
    );
  }


export const getGetEntitiesQueryKey = () => {
    return [`/edgar/entities`] as const;
    }

    
export const getGetEntitiesQueryOptions = <TData = Awaited<ReturnType<typeof getEntities>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEntities>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEntitiesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEntities>>> = ({ signal }) => getEntities({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEntities>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetEntitiesQueryResult = NonNullable<Awaited<ReturnType<typeof getEntities>>>
export type GetEntitiesQueryError = AxiosError<unknown>


export function useGetEntities<TData = Awaited<ReturnType<typeof getEntities>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEntities>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getEntities>>,
          TError,
          Awaited<ReturnType<typeof getEntities>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetEntities<TData = Awaited<ReturnType<typeof getEntities>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEntities>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getEntities>>,
          TError,
          Awaited<ReturnType<typeof getEntities>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetEntities<TData = Awaited<ReturnType<typeof getEntities>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEntities>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Entities
 */

export function useGetEntities<TData = Awaited<ReturnType<typeof getEntities>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEntities>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetEntitiesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Edgar Entity Route
 */
export const getEdgarEntityRoute = (
    entityCik: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EdgarEntityWithFilings>> => {
    
    
    return axios.default.get(
      `/edgar/entities/${entityCik}`,options
    );
  }


export const getGetEdgarEntityRouteQueryKey = (entityCik: string,) => {
    return [`/edgar/entities/${entityCik}`] as const;
    }

    
export const getGetEdgarEntityRouteQueryOptions = <TData = Awaited<ReturnType<typeof getEdgarEntityRoute>>, TError = AxiosError<HTTPValidationError>>(entityCik: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEdgarEntityRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEdgarEntityRouteQueryKey(entityCik);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEdgarEntityRoute>>> = ({ signal }) => getEdgarEntityRoute(entityCik, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(entityCik), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEdgarEntityRoute>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetEdgarEntityRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getEdgarEntityRoute>>>
export type GetEdgarEntityRouteQueryError = AxiosError<HTTPValidationError>


export function useGetEdgarEntityRoute<TData = Awaited<ReturnType<typeof getEdgarEntityRoute>>, TError = AxiosError<HTTPValidationError>>(
 entityCik: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEdgarEntityRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getEdgarEntityRoute>>,
          TError,
          Awaited<ReturnType<typeof getEdgarEntityRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetEdgarEntityRoute<TData = Awaited<ReturnType<typeof getEdgarEntityRoute>>, TError = AxiosError<HTTPValidationError>>(
 entityCik: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEdgarEntityRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getEdgarEntityRoute>>,
          TError,
          Awaited<ReturnType<typeof getEdgarEntityRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetEdgarEntityRoute<TData = Awaited<ReturnType<typeof getEdgarEntityRoute>>, TError = AxiosError<HTTPValidationError>>(
 entityCik: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEdgarEntityRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Edgar Entity Route
 */

export function useGetEdgarEntityRoute<TData = Awaited<ReturnType<typeof getEdgarEntityRoute>>, TError = AxiosError<HTTPValidationError>>(
 entityCik: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEdgarEntityRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetEdgarEntityRouteQueryOptions(entityCik,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Edgar Filing Route
 */
export const getEdgarFilingRoute = (
    accessionNumber: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EdgarFilingFull>> => {
    
    
    return axios.default.get(
      `/edgar/filings/${accessionNumber}`,options
    );
  }


export const getGetEdgarFilingRouteQueryKey = (accessionNumber: string,) => {
    return [`/edgar/filings/${accessionNumber}`] as const;
    }

    
export const getGetEdgarFilingRouteQueryOptions = <TData = Awaited<ReturnType<typeof getEdgarFilingRoute>>, TError = AxiosError<HTTPValidationError>>(accessionNumber: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEdgarFilingRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEdgarFilingRouteQueryKey(accessionNumber);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEdgarFilingRoute>>> = ({ signal }) => getEdgarFilingRoute(accessionNumber, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(accessionNumber), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEdgarFilingRoute>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetEdgarFilingRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getEdgarFilingRoute>>>
export type GetEdgarFilingRouteQueryError = AxiosError<HTTPValidationError>


export function useGetEdgarFilingRoute<TData = Awaited<ReturnType<typeof getEdgarFilingRoute>>, TError = AxiosError<HTTPValidationError>>(
 accessionNumber: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEdgarFilingRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getEdgarFilingRoute>>,
          TError,
          Awaited<ReturnType<typeof getEdgarFilingRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetEdgarFilingRoute<TData = Awaited<ReturnType<typeof getEdgarFilingRoute>>, TError = AxiosError<HTTPValidationError>>(
 accessionNumber: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEdgarFilingRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getEdgarFilingRoute>>,
          TError,
          Awaited<ReturnType<typeof getEdgarFilingRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetEdgarFilingRoute<TData = Awaited<ReturnType<typeof getEdgarFilingRoute>>, TError = AxiosError<HTTPValidationError>>(
 accessionNumber: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEdgarFilingRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Edgar Filing Route
 */

export function useGetEdgarFilingRoute<TData = Awaited<ReturnType<typeof getEdgarFilingRoute>>, TError = AxiosError<HTTPValidationError>>(
 accessionNumber: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEdgarFilingRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetEdgarFilingRouteQueryOptions(accessionNumber,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Edgar Document
 */
export const getEdgarDocument = (
    accessionNumber: string,
    sequence: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EdgarDocumentWithFiling>> => {
    
    
    return axios.default.get(
      `/edgar/document/${accessionNumber}/${sequence}`,options
    );
  }


export const getGetEdgarDocumentQueryKey = (accessionNumber: string,
    sequence: number,) => {
    return [`/edgar/document/${accessionNumber}/${sequence}`] as const;
    }

    
export const getGetEdgarDocumentQueryOptions = <TData = Awaited<ReturnType<typeof getEdgarDocument>>, TError = AxiosError<HTTPValidationError>>(accessionNumber: string,
    sequence: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEdgarDocument>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEdgarDocumentQueryKey(accessionNumber,sequence);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEdgarDocument>>> = ({ signal }) => getEdgarDocument(accessionNumber,sequence, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(accessionNumber && sequence), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEdgarDocument>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetEdgarDocumentQueryResult = NonNullable<Awaited<ReturnType<typeof getEdgarDocument>>>
export type GetEdgarDocumentQueryError = AxiosError<HTTPValidationError>


export function useGetEdgarDocument<TData = Awaited<ReturnType<typeof getEdgarDocument>>, TError = AxiosError<HTTPValidationError>>(
 accessionNumber: string,
    sequence: number, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEdgarDocument>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getEdgarDocument>>,
          TError,
          Awaited<ReturnType<typeof getEdgarDocument>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetEdgarDocument<TData = Awaited<ReturnType<typeof getEdgarDocument>>, TError = AxiosError<HTTPValidationError>>(
 accessionNumber: string,
    sequence: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEdgarDocument>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getEdgarDocument>>,
          TError,
          Awaited<ReturnType<typeof getEdgarDocument>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetEdgarDocument<TData = Awaited<ReturnType<typeof getEdgarDocument>>, TError = AxiosError<HTTPValidationError>>(
 accessionNumber: string,
    sequence: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEdgarDocument>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Edgar Document
 */

export function useGetEdgarDocument<TData = Awaited<ReturnType<typeof getEdgarDocument>>, TError = AxiosError<HTTPValidationError>>(
 accessionNumber: string,
    sequence: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEdgarDocument>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetEdgarDocumentQueryOptions(accessionNumber,sequence,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Relationships
 */
export const getRelationships = (
    accessionNumber: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EdgarEntityFiling[]>> => {
    
    
    return axios.default.get(
      `/edgar/relationships/${accessionNumber}`,options
    );
  }


export const getGetRelationshipsQueryKey = (accessionNumber: string,) => {
    return [`/edgar/relationships/${accessionNumber}`] as const;
    }

    
export const getGetRelationshipsQueryOptions = <TData = Awaited<ReturnType<typeof getRelationships>>, TError = AxiosError<HTTPValidationError>>(accessionNumber: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRelationships>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRelationshipsQueryKey(accessionNumber);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRelationships>>> = ({ signal }) => getRelationships(accessionNumber, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(accessionNumber), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRelationships>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetRelationshipsQueryResult = NonNullable<Awaited<ReturnType<typeof getRelationships>>>
export type GetRelationshipsQueryError = AxiosError<HTTPValidationError>


export function useGetRelationships<TData = Awaited<ReturnType<typeof getRelationships>>, TError = AxiosError<HTTPValidationError>>(
 accessionNumber: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRelationships>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getRelationships>>,
          TError,
          Awaited<ReturnType<typeof getRelationships>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetRelationships<TData = Awaited<ReturnType<typeof getRelationships>>, TError = AxiosError<HTTPValidationError>>(
 accessionNumber: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRelationships>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getRelationships>>,
          TError,
          Awaited<ReturnType<typeof getRelationships>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetRelationships<TData = Awaited<ReturnType<typeof getRelationships>>, TError = AxiosError<HTTPValidationError>>(
 accessionNumber: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRelationships>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Relationships
 */

export function useGetRelationships<TData = Awaited<ReturnType<typeof getRelationships>>, TError = AxiosError<HTTPValidationError>>(
 accessionNumber: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRelationships>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetRelationshipsQueryOptions(accessionNumber,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Edgar Document Pdf Route
 */
export const getEdgarDocumentPdfRoute = (
    accessionNumber: string,
    sequence: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/edgar/pdf/${accessionNumber}/${sequence}`,options
    );
  }


export const getGetEdgarDocumentPdfRouteQueryKey = (accessionNumber: string,
    sequence: number,) => {
    return [`/edgar/pdf/${accessionNumber}/${sequence}`] as const;
    }

    
export const getGetEdgarDocumentPdfRouteQueryOptions = <TData = Awaited<ReturnType<typeof getEdgarDocumentPdfRoute>>, TError = AxiosError<HTTPValidationError>>(accessionNumber: string,
    sequence: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEdgarDocumentPdfRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEdgarDocumentPdfRouteQueryKey(accessionNumber,sequence);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEdgarDocumentPdfRoute>>> = ({ signal }) => getEdgarDocumentPdfRoute(accessionNumber,sequence, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(accessionNumber && sequence), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEdgarDocumentPdfRoute>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetEdgarDocumentPdfRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getEdgarDocumentPdfRoute>>>
export type GetEdgarDocumentPdfRouteQueryError = AxiosError<HTTPValidationError>


export function useGetEdgarDocumentPdfRoute<TData = Awaited<ReturnType<typeof getEdgarDocumentPdfRoute>>, TError = AxiosError<HTTPValidationError>>(
 accessionNumber: string,
    sequence: number, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEdgarDocumentPdfRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getEdgarDocumentPdfRoute>>,
          TError,
          Awaited<ReturnType<typeof getEdgarDocumentPdfRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetEdgarDocumentPdfRoute<TData = Awaited<ReturnType<typeof getEdgarDocumentPdfRoute>>, TError = AxiosError<HTTPValidationError>>(
 accessionNumber: string,
    sequence: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEdgarDocumentPdfRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getEdgarDocumentPdfRoute>>,
          TError,
          Awaited<ReturnType<typeof getEdgarDocumentPdfRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetEdgarDocumentPdfRoute<TData = Awaited<ReturnType<typeof getEdgarDocumentPdfRoute>>, TError = AxiosError<HTTPValidationError>>(
 accessionNumber: string,
    sequence: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEdgarDocumentPdfRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Edgar Document Pdf Route
 */

export function useGetEdgarDocumentPdfRoute<TData = Awaited<ReturnType<typeof getEdgarDocumentPdfRoute>>, TError = AxiosError<HTTPValidationError>>(
 accessionNumber: string,
    sequence: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEdgarDocumentPdfRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetEdgarDocumentPdfRouteQueryOptions(accessionNumber,sequence,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get Search Edgar Documents Route
 */
export const getSearchEdgarDocumentsRoute = (
    searchId: SearchId, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EdgarDocumentWithFiling[]>> => {
    
    
    return axios.default.get(
      `/edgar/search_documents/${searchId}`,options
    );
  }


export const getGetSearchEdgarDocumentsRouteQueryKey = (searchId: SearchId,) => {
    return [`/edgar/search_documents/${searchId}`] as const;
    }

    
export const getGetSearchEdgarDocumentsRouteQueryOptions = <TData = Awaited<ReturnType<typeof getSearchEdgarDocumentsRoute>>, TError = AxiosError<HTTPValidationError>>(searchId: SearchId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchEdgarDocumentsRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSearchEdgarDocumentsRouteQueryKey(searchId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSearchEdgarDocumentsRoute>>> = ({ signal }) => getSearchEdgarDocumentsRoute(searchId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(searchId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSearchEdgarDocumentsRoute>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetSearchEdgarDocumentsRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getSearchEdgarDocumentsRoute>>>
export type GetSearchEdgarDocumentsRouteQueryError = AxiosError<HTTPValidationError>


export function useGetSearchEdgarDocumentsRoute<TData = Awaited<ReturnType<typeof getSearchEdgarDocumentsRoute>>, TError = AxiosError<HTTPValidationError>>(
 searchId: SearchId, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchEdgarDocumentsRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getSearchEdgarDocumentsRoute>>,
          TError,
          Awaited<ReturnType<typeof getSearchEdgarDocumentsRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetSearchEdgarDocumentsRoute<TData = Awaited<ReturnType<typeof getSearchEdgarDocumentsRoute>>, TError = AxiosError<HTTPValidationError>>(
 searchId: SearchId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchEdgarDocumentsRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getSearchEdgarDocumentsRoute>>,
          TError,
          Awaited<ReturnType<typeof getSearchEdgarDocumentsRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetSearchEdgarDocumentsRoute<TData = Awaited<ReturnType<typeof getSearchEdgarDocumentsRoute>>, TError = AxiosError<HTTPValidationError>>(
 searchId: SearchId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchEdgarDocumentsRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Search Edgar Documents Route
 */

export function useGetSearchEdgarDocumentsRoute<TData = Awaited<ReturnType<typeof getSearchEdgarDocumentsRoute>>, TError = AxiosError<HTTPValidationError>>(
 searchId: SearchId, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchEdgarDocumentsRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetSearchEdgarDocumentsRouteQueryOptions(searchId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get the table of contents for an Edgar document.
 * @summary Get Edgar Document Toc Route
 */
export const getEdgarDocumentTocRoute = (
    accessionNumber: string,
    sequence: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TocSection[]>> => {
    
    
    return axios.default.get(
      `/edgar/documents/${accessionNumber}/${sequence}/toc`,options
    );
  }


export const getGetEdgarDocumentTocRouteQueryKey = (accessionNumber: string,
    sequence: number,) => {
    return [`/edgar/documents/${accessionNumber}/${sequence}/toc`] as const;
    }

    
export const getGetEdgarDocumentTocRouteQueryOptions = <TData = Awaited<ReturnType<typeof getEdgarDocumentTocRoute>>, TError = AxiosError<HTTPValidationError>>(accessionNumber: string,
    sequence: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEdgarDocumentTocRoute>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEdgarDocumentTocRouteQueryKey(accessionNumber,sequence);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEdgarDocumentTocRoute>>> = ({ signal }) => getEdgarDocumentTocRoute(accessionNumber,sequence, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(accessionNumber && sequence), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEdgarDocumentTocRoute>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetEdgarDocumentTocRouteQueryResult = NonNullable<Awaited<ReturnType<typeof getEdgarDocumentTocRoute>>>
export type GetEdgarDocumentTocRouteQueryError = AxiosError<HTTPValidationError>


export function useGetEdgarDocumentTocRoute<TData = Awaited<ReturnType<typeof getEdgarDocumentTocRoute>>, TError = AxiosError<HTTPValidationError>>(
 accessionNumber: string,
    sequence: number, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEdgarDocumentTocRoute>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getEdgarDocumentTocRoute>>,
          TError,
          Awaited<ReturnType<typeof getEdgarDocumentTocRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetEdgarDocumentTocRoute<TData = Awaited<ReturnType<typeof getEdgarDocumentTocRoute>>, TError = AxiosError<HTTPValidationError>>(
 accessionNumber: string,
    sequence: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEdgarDocumentTocRoute>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getEdgarDocumentTocRoute>>,
          TError,
          Awaited<ReturnType<typeof getEdgarDocumentTocRoute>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetEdgarDocumentTocRoute<TData = Awaited<ReturnType<typeof getEdgarDocumentTocRoute>>, TError = AxiosError<HTTPValidationError>>(
 accessionNumber: string,
    sequence: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEdgarDocumentTocRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Edgar Document Toc Route
 */

export function useGetEdgarDocumentTocRoute<TData = Awaited<ReturnType<typeof getEdgarDocumentTocRoute>>, TError = AxiosError<HTTPValidationError>>(
 accessionNumber: string,
    sequence: number, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEdgarDocumentTocRoute>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetEdgarDocumentTocRouteQueryOptions(accessionNumber,sequence,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Dev endpoint for previewing a tab in the assistant UI without having to
run the session loop.

The wildcard path following the /dev/assistant_ui/ prefix is the path of the tab to
preview; e.g. to view a webpage tab, you would use
/dev/assistant_ui/web/webpage/{webpage_id}.

Any query parameters will also be passed through to the tab.
 * @summary Dev Assistant Ui
 */
export const devAssistantUi = (
    path: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    
    return axios.default.get(
      `/dev/assistant_ui/${path}`,options
    );
  }


export const getDevAssistantUiQueryKey = (path: string,) => {
    return [`/dev/assistant_ui/${path}`] as const;
    }

    
export const getDevAssistantUiQueryOptions = <TData = Awaited<ReturnType<typeof devAssistantUi>>, TError = AxiosError<HTTPValidationError>>(path: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof devAssistantUi>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDevAssistantUiQueryKey(path);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof devAssistantUi>>> = ({ signal }) => devAssistantUi(path, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(path), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof devAssistantUi>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type DevAssistantUiQueryResult = NonNullable<Awaited<ReturnType<typeof devAssistantUi>>>
export type DevAssistantUiQueryError = AxiosError<HTTPValidationError>


export function useDevAssistantUi<TData = Awaited<ReturnType<typeof devAssistantUi>>, TError = AxiosError<HTTPValidationError>>(
 path: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof devAssistantUi>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof devAssistantUi>>,
          TError,
          Awaited<ReturnType<typeof devAssistantUi>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useDevAssistantUi<TData = Awaited<ReturnType<typeof devAssistantUi>>, TError = AxiosError<HTTPValidationError>>(
 path: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof devAssistantUi>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof devAssistantUi>>,
          TError,
          Awaited<ReturnType<typeof devAssistantUi>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useDevAssistantUi<TData = Awaited<ReturnType<typeof devAssistantUi>>, TError = AxiosError<HTTPValidationError>>(
 path: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof devAssistantUi>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Dev Assistant Ui
 */

export function useDevAssistantUi<TData = Awaited<ReturnType<typeof devAssistantUi>>, TError = AxiosError<HTTPValidationError>>(
 path: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof devAssistantUi>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getDevAssistantUiQueryOptions(path,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Test Error
 */
export const testError = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string>> => {
    
    
    return axios.default.get(
      `/dev/test_error`,options
    );
  }


export const getTestErrorQueryKey = () => {
    return [`/dev/test_error`] as const;
    }

    
export const getTestErrorQueryOptions = <TData = Awaited<ReturnType<typeof testError>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof testError>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTestErrorQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof testError>>> = ({ signal }) => testError({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof testError>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type TestErrorQueryResult = NonNullable<Awaited<ReturnType<typeof testError>>>
export type TestErrorQueryError = AxiosError<unknown>


export function useTestError<TData = Awaited<ReturnType<typeof testError>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof testError>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof testError>>,
          TError,
          Awaited<ReturnType<typeof testError>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useTestError<TData = Awaited<ReturnType<typeof testError>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof testError>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof testError>>,
          TError,
          Awaited<ReturnType<typeof testError>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useTestError<TData = Awaited<ReturnType<typeof testError>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof testError>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Test Error
 */

export function useTestError<TData = Awaited<ReturnType<typeof testError>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof testError>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getTestErrorQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Ping
 */
export const ping = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string>> => {
    
    
    return axios.default.get(
      `/ping`,options
    );
  }


export const getPingQueryKey = () => {
    return [`/ping`] as const;
    }

    
export const getPingQueryOptions = <TData = Awaited<ReturnType<typeof ping>>, TError = AxiosError<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPingQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof ping>>> = ({ signal }) => ping({ signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type PingQueryResult = NonNullable<Awaited<ReturnType<typeof ping>>>
export type PingQueryError = AxiosError<unknown>


export function usePing<TData = Awaited<ReturnType<typeof ping>>, TError = AxiosError<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof ping>>,
          TError,
          Awaited<ReturnType<typeof ping>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function usePing<TData = Awaited<ReturnType<typeof ping>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof ping>>,
          TError,
          Awaited<ReturnType<typeof ping>>
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function usePing<TData = Awaited<ReturnType<typeof ping>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Ping
 */

export function usePing<TData = Awaited<ReturnType<typeof ping>>, TError = AxiosError<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof ping>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getPingQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}
