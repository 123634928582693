import { TextEditor } from "@/components/editor";
import { AssistantPresenceIcon } from "@/components/layout/right-sidebar/assistant-presence-icon";
import { StepComponent } from "@/components/layout/right-sidebar/step-component";
import { useAssistantSessionStore } from "@/contexts/app-context/db-store/db-store-hooks";
import { AssistantSessionTabState } from "@/contexts/assistant-session/tab-state";
import { cleanXmlText } from "@/lib/formatting";
import type { AssistantSessionId } from "@api/schemas";
import {
	ArrowElbowDownRight,
	ArrowLeft,
	WarningCircle,
} from "@phosphor-icons/react";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { AnimatePresence } from "motion/react";
import { Fragment, useEffect, useMemo, useRef } from "react";

const SingleSessionViewer = observer(function SingleSessionViewer() {
	const { tabState } = Route.useLoaderData();
	const navigate = useNavigate();
	// biome-ignore lint/style/noNonNullAssertion: <explanation>
	const assistantSessionId = tabState.assistantSessionId!;

	const bottomRef = useRef<HTMLDivElement>(null);
	const assistantSessionStore = useAssistantSessionStore();
	const steps = assistantSessionStore.getSteps(assistantSessionId);

	// Scroll to bottom if there are new steps
	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		bottomRef.current?.scrollIntoView({ behavior: "smooth" });
	}, [steps.length]);

	const assistantSession = tabState.assistantSession.unwrapOr(null);
	const parentSession = assistantSession
		? assistantSessionStore.getParentSession(assistantSessionId).unwrapOr(null)
		: null;

	const parentSessionGoal = useMemo(() => {
		return parentSession ? cleanXmlText(parentSession.goal) : null;
	}, [parentSession]);

	if (!assistantSession) {
		// TODO(John): fix
		return null;
	}

	return (
		<div className="flex h-full w-full flex-col">
			{/* Header */}
			<div className="flex flex-col gap-2 border-neutral-100 border-b p-4">
				<div className="flex flex-col">
					<button
						type="button"
						className="mx-3 mb-1.5 flex h-5 items-center gap-1 px-1 text-neutral-500 text-xs hover:bg-neutral-100"
						onClick={() => {
							if (parentSession) {
								navigate({
									to: "/assistant-session/$session-id",
									params: {
										"session-id": parentSession.assistant_session_id,
									},
								});
							} else {
								navigate({
									to: "/assistant-session",
								});
							}
						}}
					>
						{parentSessionGoal ? (
							<>
								<ArrowElbowDownRight className=" inline-block flex-none align-top" />
								<span className="min-w-0 grow truncate">
									{parentSessionGoal}
								</span>
							</>
						) : (
							<>
								<ArrowLeft className=" inline-block flex-none align-top" />
								<span>All sessions</span>
							</>
						)}
					</button>
					<div className="flex items-start gap-2">
						<AssistantPresenceIcon assistantSessionId={assistantSessionId} />
						<div className="flex flex-col gap-1">
							<TextEditor
								key={assistantSession.goal}
								className="font-medium [&_.ProseMirror]:text-sm"
								options={{
									content: assistantSession.goal,
									editable: false,
								}}
							/>
						</div>
					</div>
				</div>
			</div>

			{/* Steps */}
			<div className="flex max-h-screen min-h-0 w-full grow flex-col gap-4 overflow-y-auto py-4">
				<AnimatePresence>
					{steps.map((step, index) => {
						return (
							<Fragment key={step.step_id}>
								<StepComponent step={step} stepIndex={index} />
								{index !== steps.length - 1 && <hr />}
							</Fragment>
						);
					})}
				</AnimatePresence>

				{assistantSession.end_status && (
					<div className="flex w-full min-w-0 flex-col items-center justify-center gap-2 p-4 text-neutral-500 text-xs">
						<span>
							Session ended at{" "}
							{dayjs(assistantSession.end_status.end_time).format(
								"MMM D, h:mm A",
							)}
						</span>
						{assistantSession.end_status.code === "error" && (
							<span className="inline-flex min-w-0 items-center gap-1 rounded-md border border-red-700 bg-red-50 px-2 py-1 text-red-700">
								<WarningCircle className="shrink-0" size={16} />
								<span className="grow break-all">
									{assistantSession.end_status.message}
								</span>
							</span>
						)}
					</div>
				)}
				{/* invisible div at bottom for scroll target */}
				<div ref={bottomRef} />
			</div>
		</div>
	);
});

export const Route = createFileRoute("/assistant-session/$session-id")({
	component: SingleSessionViewer,
	loader: ({ context: { tab }, params }) => {
		const tabState = new AssistantSessionTabState(
			tab,
			params["session-id"] as AssistantSessionId,
		);
		return {
			tabState,
		};
	},
});
