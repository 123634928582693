import type { BaseRendererProps } from "@/components/table/scalar-renderers/renderer-props";
import { cn } from "@/lib/utils";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { toast } from "sonner";

type NumberRendererProps = BaseRendererProps<"real">;

export const NumberRenderer = observer((props: NumberRendererProps) => {
	const [newValue, setNewValue] = useState<string>(
		props.value?.toString() ?? "",
	);

	useEffect(() => {
		setNewValue(props.value?.toString() ?? "");
	}, [props.value]);

	const onSubmit = () => {
		// if the new value is empty, clear the cell
		if (newValue.trim() === "") {
			props.onUpdate?.(null);
			return;
		}
		const newNumber = Number.parseFloat(newValue);
		if (Number.isNaN(newNumber)) {
			toast.error("Invalid number");
			setNewValue("");
			return;
		}
		if (newNumber !== props.value) {
			props.onUpdate?.(newNumber);
		}
	};

	return (
		<input
			type="text"
			value={newValue}
			onChange={(e) => {
				setNewValue(e.target.value);
			}}
			readOnly={!props.editable}
			onBlur={() => {
				onSubmit();
			}}
			className={cn(
				"flex h-full w-full min-w-0 truncate border-0 p-1 text-right text-neutral-800 text-sm",
			)}
			onKeyDown={(e) => {
				if (e.key === "Enter") {
					e.currentTarget.blur();
				}
			}}
		/>
	);
});
