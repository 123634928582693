import {
	getFieldColumn,
	getRecordLinkColumn,
	getUserTableSelectorColumn,
} from "@/components/table/columns";
import { FieldCreatorPopover } from "@/components/table/field-creator-popover";
import { BaseSortManager } from "@/components/table/sort-popover";
import { ADD_COLUMN_ID } from "@/components/table/utils";
import {
	type TablesStore,
	UserTableState,
} from "@/contexts/tables/stores/table-store";
import { TableViewContext } from "@/contexts/tables/use-table-context";
import { BaseTabState } from "@/contexts/tabs/base-tab-state";
import type { Tab } from "@/contexts/tabs/tabs-context";
import { makeAutoObservableAbstract } from "@/lib/make-auto-observable-abstract";
import type {
	FieldId,
	Record,
	ResourceLink,
	SortField,
	TableId,
	TableResource,
} from "@api/schemas";
import { Code, Copy, Plus, Table } from "@phosphor-icons/react";
import { type ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { makeAutoObservable, observe } from "mobx";
import { toast } from "sonner";

class TableSortManager extends BaseSortManager {
	viewState: TableViewState;

	constructor(viewState: TableViewState) {
		super();
		this.viewState = viewState;
	}

	/**
	 * Returns the sort fields for the table view.
	 */
	get sortFields(): SortField[] {
		return this.viewState.sortFields;
	}

	/**
	 * Adds a sort field to the table.
	 */
	public addSortField(fieldId: FieldId) {
		const newSortFields = [...this.sortFields];
		// Don't add duplicates
		if (!newSortFields.some((sf) => sf.field_id === fieldId)) {
			newSortFields.push({
				field_id: fieldId,
				direction: "asc", // Default to ascending
			});
			this.viewState.sortFields = newSortFields;
		}
	}

	/**
	 * Removes a sort field by its ID.
	 */
	public removeSortField(fieldId: string) {
		this.viewState.sortFields = this.sortFields.filter(
			(sf) => sf.field_id !== fieldId,
		);
	}

	/**
	 * Toggle the direction (asc/desc) of a sort field.
	 */
	public updateSortDirection(index: number, direction: SortField["direction"]) {
		const newSortFields = [...this.sortFields];
		if (newSortFields[index]) {
			newSortFields[index] = {
				...newSortFields[index],
				direction,
			};
			this.viewState.sortFields = newSortFields;
		}
	}

	/**
	 * Updates the field_id of a sort field.
	 */
	public updateSortField(index: number, fieldId: FieldId) {
		const newSortFields = [...this.sortFields];
		if (newSortFields[index]) {
			newSortFields[index] = {
				...newSortFields[index],
				field_id: fieldId,
			};
			this.viewState.sortFields = newSortFields;
		}
	}

	/**
	 * Moves a sort field from one index to another.
	 */
	public moveSortField(fromIndex: number, toIndex: number) {
		const newSortFields = [...this.sortFields];
		if (
			fromIndex >= 0 &&
			fromIndex < newSortFields.length &&
			toIndex >= 0 &&
			toIndex < newSortFields.length &&
			fromIndex !== toIndex
		) {
			// Remove item from current position
			const [removed] = newSortFields.splice(fromIndex, 1);
			// Insert at new position
			newSortFields.splice(toIndex, 0, removed);
			this.viewState.sortFields = newSortFields;
		}
	}

	/**
	 * Clears all sort fields.
	 */
	public clearSortFields() {
		this.viewState.sortFields = [];
	}

	/**
	 * Lists all the available fields that can be added as another sort.
	 *
	 * We only include fields that are not already used as sort fields.
	 */
	public get availableSortFieldIds(): FieldId[] {
		return this.viewState.tableState.sortedFields
			.map((field) => field.field_id)
			.filter(
				(fieldId) =>
					!this.viewState.sortFields.some((sf) => sf.field_id === fieldId),
			);
	}
}

/**
 * Tracks the state of a table being viewed.
 *
 * This state is required anytime a table is rendered. It stores settings that
 * affect how the user sees a table, such as the current page (cursor and limit),
 * the current record (if any), and if dev mode is enabled.
 */
export class TableViewState {
	tablesStore: TablesStore;

	readonly tableId: TableId;
	pageIdx: number;
	pageSize: number;
	sortFields: SortField[] = [];
	sortState: TableSortManager;

	view: "table" | "record" = "table";

	devMode = false;

	// the record ID of the current record in record view mode
	#visibleRecordLink: ResourceLink | null = null;

	/**
	 * Double map of the DOM elements for each cell, as keyed by (recordLink, fieldId).
	 */
	cellRefs: Map<ResourceLink, Map<FieldId, HTMLDivElement>> = new Map();

	/**
	 * Observer on the recentCellUpdates stream, which triggers the cell
	 * update animation.
	 */
	#cellUpdateListener: () => void;

	constructor(props: {
		tablesStore: TablesStore;
		tableId: TableId;
		pageIdx: number;
		pageSize: number;
		initialVisibleRecordLink?: ResourceLink;
	}) {
		makeAutoObservable(this);
		this.tablesStore = props.tablesStore;
		this.tableId = props.tableId;
		this.pageIdx = props.pageIdx;
		this.pageSize = props.pageSize;
		this.sortState = new TableSortManager(this);

		this.#cellUpdateListener = observe(
			this.tableState.syncedMap.recentUpdates,
			(change) => {
				// Shouldn't be possible because this is an append-only log
				if (change.type === "update") {
					return;
				}

				for (const event of change.added) {
					// Skip reset events for now
					if (event.event_type === "reset") {
						continue;
					}

					// Ignore events made by the current user
					if (event.actor_id === this.tablesStore.appState.userId) {
						continue;
					}

					for (const op of event.ops) {
						switch (op.op) {
							case "update": {
								for (const fieldId of Object.keys(op.updated_cell_values)) {
									const recordLink = op.record_link;
									const recordRefs = this.cellRefs.get(recordLink);
									if (!recordRefs) {
										continue;
									}
									const cell = recordRefs.get(fieldId as FieldId);

									// Animate the update as a brief background color change
									if (cell) {
										cell.animate(
											[
												{ backgroundColor: "transparent" },
												// Offset determines the time at which the max color is applied,
												// in this case we set it to 20% into the animation.
												{
													backgroundColor: "var(--color-blue-100)",
													offset: 0.2,
												},
												{ backgroundColor: "transparent" },
											],
											{
												duration: 1000,
												easing: "ease-out",
											},
										);
									}
								}
								break;
							}
							case "insert": {
								break;
							}
							case "delete": {
								break;
							}
						}
					}
				}
			},
		);

		// TODO: table watching logic needs to be adapted for cases where the
		// record link is not part of the page being viewed. Possible solutions:
		// - Have the table watch route compute the correct page the record is on,
		//   and redirect to that page.
		// - Fetch this record separately.

		// if (initialVisibleRecordLink) {
		// 	const rowIndex = this.tableState.sortedRecords.findIndex(
		// 		(row) => row.link === initialVisibleRecordLink,
		// 	);
		// 	if (rowIndex !== -1) {
		// 		this.view = "record";
		// 		this.#visibleRecordLink = initialVisibleRecordLink;
		// 	}
		// }
	}

	updatePaginationProps({
		pageIdx,
		pageSize,
	}: { pageIdx: number; pageSize: number }) {
		this.pageIdx = pageIdx;
		this.pageSize = pageSize;
	}

	/**
	 * The state corresponding to the current page being viewed is managed
	 * separately by the TablesStore. Given a cursor and limit, the TablesStore
	 * will return the corresponding table state.
	 *
	 * This separation ensures that if the user is viewing the same page of a
	 * table in multiple tabs, we do not reinitialize a separate manager and
	 * watcher for each instance.
	 */
	get tableState() {
		return this.tablesStore.getTableStateById({
			tableId: this.tableId,
			pageIdx: this.pageIdx,
			pageSize: this.pageSize,
			sortFields: this.sortState.sortFields,
		});
	}

	get visibleRecordLink(): ResourceLink | null {
		if (this.#visibleRecordLink === null) {
			return null;
		}
		if (!this.tableState.optimisticMap.has(this.#visibleRecordLink)) {
			this.#visibleRecordLink = null;
			this.view = "table";
		}
		return this.#visibleRecordLink;
	}

	setVisibleRecordLink(recordLink: ResourceLink | null): void {
		this.#visibleRecordLink = recordLink;
	}

	get visibleRecordRowIndex(): number | null {
		if (this.visibleRecordLink === null) {
			return null;
		}
		return this.tableState.recordIndexMap.get(this.visibleRecordLink) ?? null;
	}

	getRowLink = (row: Record): ResourceLink => {
		return row.link;
	};

	get rows(): Record[] {
		return this.tableState.sortedRecords;
	}

	get columns() {
		const columnHelper = createColumnHelper<Record>();
		const sortedFields = this.tableState.sortedFields;

		const columns: ColumnDef<Record>[] = [
			getRecordLinkColumn({
				resetScroll: false,
				didInit: this.tableState.initState.ready,
			}),
		];
		if (this.tableState instanceof UserTableState) {
			columns.unshift(
				getUserTableSelectorColumn({
					tableState: this.tableState,
					didInit: this.tableState.initState.ready,
				}),
			);
		}

		for (const field of sortedFields) {
			columns.push(
				getFieldColumn({
					field,
					tableViewState: this,
					didInit: this.tableState.initState.ready,
				}),
			);
		}

		if (this.tableState instanceof UserTableState) {
			columns.push(
				columnHelper.display({
					id: ADD_COLUMN_ID,
					header: () => (
						<FieldCreatorPopover tableState={this.tableState as UserTableState}>
							<button
								type="button"
								className="flex h-full w-8 items-center justify-center hover:bg-neutral-100"
							>
								<span className="flex h-full w-full select-none items-center justify-between gap-2 truncate px-1.5 py-1 font-normal text-neutral-600 hover:bg-neutral-100">
									<Plus className="text-lg " />
								</span>
							</button>
						</FieldCreatorPopover>
					),
				}),
			);
		}

		if (this.devMode) {
			columns.splice(
				0,
				0,
				columnHelper.display({
					id: "recordId",
					header: () => (
						<div className="flex h-full w-full select-none items-center gap-2 truncate p-1 font-normal hover:bg-neutral-100">
							<Code />
							Record ID
						</div>
					),
					cell: ({ row }) => (
						<div className="flex items-center gap-1 p-1 text-xs">
							<button
								type="button"
								className="rounded-md border p-1 hover:bg-neutral-100"
								onClick={() => {
									navigator.clipboard.writeText(row.original.link);
									toast.success("Record link copied to clipboard");
								}}
							>
								<Copy />
							</button>
							<pre className="text-neutral-500">
								{row.original.link.split("?record_id=")[1]}
							</pre>
						</div>
					),
					size: 300,
				}),
			);
		}
		return columns;
	}

	dispose() {
		this.#cellUpdateListener();
	}
}

/**
 * The state of a table tab.
 */
export class TableTabState extends BaseTabState {
	tableResource: TableResource;
	tableViewState: TableViewState;

	constructor(props: {
		tab: Tab;
		tableId: TableId;
		pageIdx: number;
		pageSize: number;
	}) {
		super(props.tab);
		makeAutoObservableAbstract(this);

		const tableResource =
			props.tab.tabStore.appState.workspace.tables.getResourceById(
				props.tableId,
			);

		if (tableResource.isErr()) {
			throw new Error("Table not found");
		}

		this.tableResource = tableResource.value;
		this.tableViewState = new TableViewState({
			tablesStore: props.tab.tabStore.appState.workspace.tables,
			tableId: props.tableId,
			pageIdx: props.pageIdx,
			pageSize: props.pageSize,
		});
	}

	get head() {
		return {
			icon: Table,
			label: this.tableResource.name,
			resourceRef: {
				type: "table" as const,
				resource_id: this.tableResource.table_id,
			},
		};
	}
}

export const TableViewProvider = ({
	tableViewState,
	children,
}: {
	tableViewState: TableViewState;
	children: React.ReactNode;
}) => {
	return (
		<TableViewContext.Provider value={tableViewState}>
			{children}
		</TableViewContext.Provider>
	);
};

/**
 * Tab state for the tables home page.
 */
export class TablesHomeTabState extends BaseTabState {
	constructor(tab: Tab) {
		super(tab);
		makeAutoObservableAbstract(this);
	}

	get head() {
		return {
			icon: Table,
			label: "Tables",
		};
	}
}
