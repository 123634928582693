import type { PdfSearchParams } from "@/contexts/pdfviewer-context";
import { BaseTabState } from "@/contexts/tabs/base-tab-state";
import type { Tab } from "@/contexts/tabs/tabs-context";
import { makeAutoObservableAbstract } from "@/lib/make-auto-observable-abstract";
import type { WebpageId } from "@api/schemas";
import { Globe } from "@phosphor-icons/react";

export class WebpageTabState extends BaseTabState {
	searchParams: PdfSearchParams;
	webpageId: WebpageId;

	constructor(tab: Tab, webpageId: WebpageId, searchParams: PdfSearchParams) {
		super(tab);
		makeAutoObservableAbstract(this);

		this.webpageId = webpageId;
		this.searchParams = searchParams;
	}

	// We load the webpage this way instead of in the constructor because
	// the store may sync after we initialize the tab.
	get webpage() {
		return this.tab.tabStore.appState.workspace.webpages.getResourceById(
			this.webpageId,
		);
	}

	get head() {
		if (this.webpage.isErr()) {
			return {
				icon: Globe,
				label: "Unknown Webpage",
			};
		}
		let label = this.webpage.value.name;
		if (this.searchParams.page_index !== undefined) {
			label += ` p.${this.searchParams.page_index + 1}`;
		}
		return {
			icon: Globe,
			label,
			resourceRef: {
				type: "webpage" as const,
				resource_id: this.webpageId,
			},
		};
	}
}
