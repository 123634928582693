import { DraggableRow, DraggedRowPreview } from "@/components/table/table-row";
import { useTableSensors } from "@/components/table/utils";
import { DragOverlay } from "@dnd-kit/core";
import {
	DndContext,
	type DragEndEvent,
	type DragOverEvent,
	type DragStartEvent,
	pointerWithin,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import type { Table } from "@tanstack/react-table";
import type { VirtualItem, Virtualizer } from "@tanstack/react-virtual";
import { useMemo, useState } from "react";

export type RowDndContextProps<TData, TRowId extends string> = {
	table: Table<TData>;
	selectedRowIds: Set<TRowId>;
	moveRows: (rowIds: Set<TRowId>, precedingRowId: TRowId | null) => void;
	addRow?: (precedingRowId: TRowId | null) => void;
	virtualRows: VirtualItem[];
	rowVirtualizer: Virtualizer<HTMLDivElement, HTMLDivElement>;
};

/**
 * DND context for row drag and drop.
 *
 * Explicitly not an observer. See comment in TableRow.tsx.
 */
export const RowDndContext = <TData, TRowId extends string>(
	props: RowDndContextProps<TData, TRowId>,
) => {
	const [draggedRowId, setDraggedRowId] = useState<TRowId | null>(null);
	const [overRowId, setOverRowId] = useState<TRowId | null>(null);
	const [draggingRowIds, setDraggingRowIds] = useState<Set<TRowId> | null>(
		null,
	);
	//
	// Get rows being dragged for drag overlay
	const draggingRows = useMemo(() => {
		if (!draggingRowIds) return [];
		return props.table
			.getRowModel()
			.rows.filter((row) => draggingRowIds.has(row.id as TRowId));
	}, [props.table.getRowModel, draggingRowIds]);

	const sensors = useTableSensors();

	function handleRowDragStart(event: DragStartEvent) {
		const activeDroppableId = event.active.id;

		const draggedId = activeDroppableId as TRowId;
		// Get selected ids from the current state
		setDraggedRowId(draggedId);

		const draggingIds = props.selectedRowIds.has(draggedId)
			? props.selectedRowIds
			: new Set([draggedId]);
		setDraggingRowIds(draggingIds);
	}

	function handleRowDragOver(event: DragOverEvent) {
		const over = event.over;
		if (!over) return;
		setOverRowId(over.id as TRowId);
	}

	function handleRowDragEnd(event: DragEndEvent) {
		const { active, over } = event;

		setDraggedRowId(null);
		setOverRowId(null);
		setDraggingRowIds(null);

		if (!over) return;

		const draggedRowId = active.id as TRowId;
		const overRowId = over.id as TRowId;
		// Get selected ids from the current state
		const draggingIds = props.selectedRowIds.has(draggedRowId)
			? props.selectedRowIds
			: new Set([draggedRowId]);

		props.moveRows(draggingIds, overRowId);
	}

	return (
		<DndContext
			collisionDetection={pointerWithin}
			modifiers={[restrictToVerticalAxis]}
			onDragStart={handleRowDragStart}
			onDragOver={handleRowDragOver}
			onDragEnd={handleRowDragEnd}
			sensors={sensors}
		>
			{props.virtualRows.map((virtualRow) => {
				const row = props.table.getRowModel().rows[virtualRow.index];
				return (
					<DraggableRow
						key={row.id}
						overRowId={overRowId}
						row={row}
						virtualRow={virtualRow}
						rowVirtualizer={props.rowVirtualizer}
					/>
				);
			})}
			<DragOverlay>
				{draggedRowId && (
					<div
						className="flex flex-col border"
						style={{
							width: props.table.getCenterTotalSize(),
						}}
					>
						{draggingRows.map((row) => (
							<DraggedRowPreview key={`drag-preview-${row.id}`} row={row} />
						))}
					</div>
				)}
			</DragOverlay>
		</DndContext>
	);
};
