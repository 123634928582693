import {
	CommandDialog,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
} from "@/components/ui/command";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import type { LocalSearchableResourceRef } from "@/contexts/local-search-store";
import { PageTabState } from "@/contexts/pages/tab-state";
import { LinkTarget } from "@/contexts/tabs/router-types";
import { useTabStore } from "@/contexts/tabs/use-tab-store";
import { resourceRefToLinkProps } from "@/lib/paths";
import { useAuth } from "@clerk/clerk-react";
import { FilePlus, Gear, MagnifyingGlass, Table } from "@phosphor-icons/react";
import type { IconProps } from "@phosphor-icons/react";
import type { LinkProps } from "@tanstack/react-router";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

// Create a reusable CommandItemWrapper component
type CommandItemWrapperProps = {
	icon: React.ForwardRefExoticComponent<
		IconProps & React.RefAttributes<SVGSVGElement>
	>;
	text: string;
	onSelect: () => void;
	value?: string;
	className?: string;
	iconClassName?: string;
};

const CommandItemWrapper = ({
	icon: Icon,
	text,
	onSelect,
	value,
	className = "",
	iconClassName = "",
}: CommandItemWrapperProps) => {
	return (
		<CommandItem onSelect={onSelect} value={value} className={className}>
			<Icon className={iconClassName} />
			<span>{text}</span>
		</CommandItem>
	);
};

const ResourceCommandItem = observer(function ResourceCommandItem({
	resourceRef,
	navigateWrapper,
}: {
	resourceRef: LocalSearchableResourceRef;
	navigateWrapper: (location: LinkProps) => void;
}) {
	const path = resourceRefToLinkProps(resourceRef);
	const tabStore = useTabStore();
	const head = tabStore.getTabHead(path);
	return (
		<CommandItem
			onSelect={() => {
				navigateWrapper(path);
			}}
		>
			<head.icon className="mr-2 h-4 w-4" />
			{head.label}
		</CommandItem>
	);
});

export const CommandKDialog = observer(() => {
	const appContext = useAppContext();
	const tabStore = useTabStore();
	const { signOut } = useAuth();

	const localSearchStore = appContext.localSearchStore;
	const fileSearchResults = localSearchStore.searchResources(
		appContext.commandKState.value,
	);

	useEffect(() => {
		const down = (e: KeyboardEvent) => {
			if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
				e.preventDefault();
				appContext.commandKState.toggleOpen();
			}
		};

		document.addEventListener("keydown", down);
		return () => document.removeEventListener("keydown", down);
	}, [appContext]);

	const navigateWrapper = (location: LinkProps) => {
		tabStore.openLink(location, LinkTarget.NewTab);
		appContext.commandKState.open = false;
	};

	let activeTabCommands: React.ReactNode;
	const activeTab = tabStore.activeTab;
	if (activeTab === null) {
		activeTabCommands = null;
	} else {
		const currentRouteId = activeTab.router.state.matches.at(-1)?.routeId;
		switch (currentRouteId) {
			case "/pages/$page-id": {
				if (!(activeTab.state instanceof PageTabState)) {
					activeTabCommands = null;
				} else {
					const editor = activeTab.state.editor;
					if (editor === null) {
						activeTabCommands = null;
					} else {
						activeTabCommands = (
							<CommandGroup heading="Pages">
								<CommandItemWrapper
									icon={FilePlus}
									text="Create new page and link"
									onSelect={() => {
										appContext.workspace.pages.createPage({
											onLocalSuccess: (newPage) => {
												// Insert link at cursor position
												editor
													.chain()
													.focus()
													.insertContent({
														type: "resourceLink",
														attrs: {
															id: `/pages/${newPage.page_id}`,
														},
													})
													.run();

												// Open new page in new tab
												tabStore.openLink(
													{
														to: "/pages/$page-id",
														params: {
															"page-id": newPage.page_id,
														},
													},
													LinkTarget.NewPane,
												);
												appContext.commandKState.open = false;
											},
										});
									}}
								/>
								<CommandItemWrapper
									icon={Table}
									text="Embed a table"
									onSelect={() => {
										editor.chain().focus().addIframe({ src: null }).run();
									}}
								/>
							</CommandGroup>
						);
					}
				}
				break;
			}
			default:
				activeTabCommands = null;
		}
	}

	return (
		<CommandDialog
			open={appContext.commandKState.open}
			onOpenChange={(open) => {
				appContext.commandKState.open = open;
			}}
			modal={false}
		>
			<CommandInput
				placeholder="Type a command or search..."
				value={appContext.commandKState.value}
				onValueChange={appContext.commandKState.setValue}
				onKeyDown={(e) => {
					if (e.key === "Escape") {
						e.preventDefault();
						e.stopPropagation();
						appContext.commandKState.open = false;
					}
				}}
			/>
			<CommandList>
				{activeTabCommands}
				<CommandEmpty>No results found.</CommandEmpty>
				<CommandGroup heading="Navigation">
					<CommandItemWrapper
						icon={MagnifyingGlass}
						text="Navigate to Search"
						onSelect={() => {
							navigateWrapper({
								to: "/search",
							});
						}}
					/>
				</CommandGroup>

				<CommandGroup heading="Resources">
					{fileSearchResults.map((result) => {
						return (
							<ResourceCommandItem
								key={result.resource_id}
								resourceRef={result}
								navigateWrapper={navigateWrapper}
							/>
						);
					})}
					{fileSearchResults.length === 0 && (
						<div className="py-1 text-center text-neutral-500 text-sm">
							No results found.
						</div>
					)}
				</CommandGroup>
				<CommandSeparator />
				<CommandGroup heading="Settings">
					<CommandItemWrapper
						icon={Gear}
						text="Sign out"
						className="text-red-700"
						iconClassName="mr-2 h-4 w-4 text-red-700"
						onSelect={() => {
							signOut();
						}}
					/>
				</CommandGroup>
			</CommandList>
		</CommandDialog>
	);
});
