import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localeData);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(calendar);
dayjs.tz.setDefault("UTC");

export const API_ENDPOINT_HTTP =
	import.meta.env.VITE_APP_API_BASE_URL ?? "http://localhost:8000";

export const API_ENDPOINT_WS =
	import.meta.env.VITE_APP_API_ENDPOINT_WS ?? "ws://localhost:8000";

export const IS_DEV = import.meta.env.DEV;

export const VITE_APP_ENV = import.meta.env.VITE_APP_ENV as
	| "development"
	| "staging"
	| "production";

export const CLERK_PUBLISHABLE_KEY =
	import.meta.env.VITE_APP_CLERK_PUBLISHABLE_KEY ??
	new Error("Missing Clerk Publishable Key");
