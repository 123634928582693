import type { Tab } from "@/contexts/tabs/tabs-context";
import type { ResourceRef } from "@api/schemas/resourceRef";
import type { Icon } from "@phosphor-icons/react";

/**
 * Base class for all tab states.
 */
export abstract class BaseTabState {
	tab: Tab;

	// For rendering the tab header as well as previews.
	abstract head: {
		icon: Icon;
		label: string;
		// If the tab points to a resource
		resourceRef?: ResourceRef;
	};

	constructor(tab: Tab) {
		this.tab = tab;
		this.tab.setState(this);
	}
}
