import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { usePDFViewerContext } from "@/contexts/pdfviewer-context";
import { cn } from "@/lib/utils";
import { autorun } from "mobx";
import { observer } from "mobx-react-lite";
import { type CSSProperties, useEffect, useState } from "react";
import { flushSync } from "react-dom";
import { Thumbnail as PdfThumbnail } from "react-pdf";
import { useResizeDetector } from "react-resize-detector";
import { FixedSizeList } from "react-window";

const THUMBNAIL_HEIGHT = 256;

const Thumbnail = ({
	index,
	style,
}: { index: number; style: CSSProperties }) => {
	const pdfViewerContext = usePDFViewerContext();
	return (
		<div
			className="flex w-full flex-col items-center"
			style={{ ...style, paddingTop: 8, paddingBottom: 8 }}
		>
			<PdfThumbnail
				pageNumber={index + 1}
				className="max-w-full shadow-sm"
				height={THUMBNAIL_HEIGHT - 8 * 2 - 16}
			/>
			<span
				className={cn(
					"mt-1.5 px-1 text-neutral-500 text-xs",
					pdfViewerContext.currentPageIndex === index && " text-neutral-800",
				)}
				style={{
					height: 16,
				}}
			>
				Page {(index + 1).toLocaleString()}
			</span>
		</div>
	);
};

/**
 * Using a separate component for the thumbnail container because initializing
 * the resize detector in the parent component causes an infinite rerender loop,
 * probably something to do with how the tabs are rendered.
 */
const ThumbnailContainer = () => {
	const pdfViewerContext = usePDFViewerContext();

	const { height: containerHeight, ref: containerRef } = useResizeDetector();

	return (
		<div className="flex min-h-0 grow bg-neutral-100" ref={containerRef}>
			{pdfViewerContext.numPages &&
			pdfViewerContext.pdfBytes &&
			containerHeight ? (
				<FixedSizeList
					height={containerHeight}
					itemCount={pdfViewerContext.numPages}
					itemSize={THUMBNAIL_HEIGHT}
					width="100%"
					className="pb-6"
				>
					{Thumbnail}
				</FixedSizeList>
			) : null}
		</div>
	);
};

/**
 * Provides a collapsible sidebar for PDF navigation with two tabs:
 *
 * 1. Outline Tab: Displays the table of contents (TOC) extracted from the PDF document.
 *    - Automatically highlights the TOC entry corresponding to the current page
 *    - Indents entries based on their heading level in the document
 *    - Clicking on a TOC entry navigates to the corresponding page
 *
 * 2. Thumbnails Tab: Shows thumbnail previews of all pages in the document.
 *    - Uses react-pdf's Thumbnail component to render page previews
 *    - Leverages intersection observer for efficient lazy loading of thumbnails
 *    - Highlights the thumbnail of the currently visible page
 *
 * Note: needs to be nested in a react-pdf <Document> component to work because
 * react-pdf's Thumbnail component needs access to the PDF document context.
 */
export const PDFSidebar = observer(() => {
	const pdfViewerContext = usePDFViewerContext();

	const toc = pdfViewerContext.toc;

	const [activeTocIndex, setActiveTocIndex] = useState<number | null>(null);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(
		function syncActiveTocIndex() {
			const dispose = autorun(() => {
				// find the first toc item that matches the current page index
				const index = toc?.findIndex(
					(x) => x.page_idx >= pdfViewerContext.currentPageIndex,
				);
				if (index !== undefined) {
					setActiveTocIndex(index);
				}
			});

			return () => {
				dispose();
			};
		},
		[toc],
	);

	return (
		<div
			className={cn(
				"flex h-full shrink-0 flex-col border-neutral-200 border-r bg-neutral-50",
				// make this w-0 when the sidebar is hidden so that we still make the request
				// to get the upload data before the user can open the sidebar
				pdfViewerContext.showSidebar ? "w-56" : "w-0 overflow-hidden",
			)}
		>
			<Tabs
				defaultValue="toc"
				className="flex h-full min-h-0 w-full flex-col bg-white"
			>
				<TabsList className="sticky top-0 flex-none bg-white">
					<TabsTrigger value="toc" className="gap-1">
						<span>Outline</span>
					</TabsTrigger>
					<TabsTrigger value="thumbnails" className="gap-1">
						<span>Thumbnails</span>
					</TabsTrigger>
				</TabsList>
				<TabsContent value="toc" asChild className="mt-0">
					{toc ? (
						<div className="flex flex-col overflow-auto pb-3">
							{toc.map((x, index) => {
								return (
									<button
										type="button"
										// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
										key={index}
										className={cn(
											"w-full px-3 py-1.5 text-left text-xs hover:bg-neutral-50",
											activeTocIndex === index
												? "bg-neutral-100 text-neutral-900"
												: "text-neutral-500",
										)}
										style={{
											paddingLeft: `${x.heading_level * 8 + 8}px`,
										}}
										onClick={() => {
											// Use flushSync to ensure the scroll is applied before the state update
											flushSync(() => {
												pdfViewerContext.listRef?.scrollToItem(x.page_idx);
											});
											// Although this tracks the scrolled position, we set this manually
											// because the scroll position can sometimes indicate the wrong page
											// if the pages are larger than the viewport.
											pdfViewerContext.setCurrentPageIndex(x.page_idx);
											setActiveTocIndex(index);
										}}
									>
										{x.title}
									</button>
								);
							})}
						</div>
					) : null}
				</TabsContent>
				<TabsContent value="thumbnails" asChild className="mt-0 bg-neutral-100">
					<ThumbnailContainer />
				</TabsContent>
			</Tabs>
		</div>
	);
});
