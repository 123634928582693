import { BaseTabState } from "@/contexts/tabs/base-tab-state";
import type { Tab } from "@/contexts/tabs/tabs-context";
import { makeAutoObservableAbstract } from "@/lib/make-auto-observable-abstract";
import type { PageId, PageResource } from "@api/schemas";
import { File, FileText, House } from "@phosphor-icons/react";
import type { Editor } from "@tiptap/core";
import type { Result } from "neverthrow";

export class PagesIndexTabState extends BaseTabState {
	constructor(tab: Tab) {
		super(tab);
		makeAutoObservableAbstract(this);
	}

	get head() {
		return {
			icon: File,
			label: "Pages",
		};
	}
}

export class PageTabState extends BaseTabState {
	pageId: PageId;
	editor: Editor | null = null;

	constructor(tab: Tab, pageId: PageId) {
		super(tab);
		makeAutoObservableAbstract(this);
		this.pageId = pageId;
	}

	setEditor(editor: Editor) {
		this.editor = editor;
	}

	get page(): Result<PageResource, Error> {
		return this.tab.tabStore.appState.workspace.pages.map.get(this.pageId);
	}

	get head() {
		return this.page.match(
			(page) => {
				// Compare the home page's ID to the actual page's ID.
				if (page.is_home) {
					return {
						icon: House,
						label: page.name,
						resourceRef: {
							type: "page" as const,
							resource_id: page.page_id,
						},
					};
				}
				return {
					icon: FileText,
					label: page.name,
					resourceRef: {
						type: "page" as const,
						resource_id: page.page_id,
					},
				};
			},
			() => {
				// If the page failed to load, you can return a fallback header.
				// For instance, an error header message or a default resource reference.
				return {
					icon: FileText,
					label: "Unknown",
					resourceRef: {
						type: "page" as const,
						resource_id: this.pageId,
					},
				};
			},
		);
	}
}
