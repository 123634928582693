import { Button } from "@/components/ui/button";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "@/components/ui/command";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { useAppContext } from "@/contexts/app-context/use-app-context";
import type { MessageTabState } from "@/contexts/messages/tab-state";
import {
	ResourceLinkComponent,
	ResourceLinkContent,
} from "@/plugins/resource-link";
import { PaperPlaneRight, Plus, X } from "@phosphor-icons/react";
import type { ParsedLocation } from "@tanstack/react-router";
import { EditorContent } from "@tiptap/react";
import { reaction, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { FixedSizeList } from "react-window";

const AttachmentRow = ({
	index,
	data,
	style,
}: {
	index: number;
	data: {
		items: string[];
		tabState: MessageTabState;
	};
	style: React.CSSProperties;
}) => {
	const { items, tabState } = data;
	const result = items[index];

	return (
		<CommandItem
			className="text-xs"
			key={result}
			value={result}
			onSelect={() => {
				runInAction(() => {
					if (!tabState.attachments.includes(result)) {
						tabState.attachments.push(result);
					}
					tabState.showAttachmentsMenu = false;
				});
			}}
			style={style}
		>
			<ResourceLinkContent
				linkProps={{ href: result }}
				className="truncate text-neutral-800 text-xs hover:bg-neutral-100 hover:underline"
			/>
		</CommandItem>
	);
};

export const MessageInput = observer(function MessageInput({
	tabState,
}: {
	tabState: MessageTabState;
}) {
	const appContext = useAppContext();

	/**
	 * Sync the active tab location with the attached locations.
	 *
	 * Because the user can edit this array themselves, this updates the attached locations array only in two conditions:
	 * 1. The array is empty.
	 * 2. The array consisted of solely the previous active tab location.
	 */

	// biome-ignore lint/correctness/useExhaustiveDependencies: don't need to update this when the tabState changes
	useEffect(
		function syncActiveTabLocation() {
			let previousActiveTabLocation: ParsedLocation | null = null;

			const dispose = reaction(
				() => [appContext.tabStore.activeTabLocation],
				([activeTabLocation]) => {
					if (activeTabLocation === previousActiveTabLocation) {
						return;
					}

					if (activeTabLocation) {
						// if there are no attached locations, add the active tab location
						if (tabState.attachments.length === 0) {
							tabState.attachments.push(activeTabLocation.href);
						}

						// if the attached locations array consisted of solely the previous active tab location, update it
						if (
							tabState.attachments.length === 1 &&
							previousActiveTabLocation?.href === tabState.attachments[0]
						) {
							tabState.attachments[0] = activeTabLocation.href;
						}
					}

					previousActiveTabLocation = activeTabLocation;
				},
			);
			return () => dispose();
		},
		[appContext],
	);

	return (
		<div className="mx-2 flex flex-col border focus-within:border-blue-300">
			<div className="flex w-full flex-wrap items-center gap-1 px-2 pt-2">
				{/* Attach additional tab to the message input. */}
				<Popover
					open={tabState.showAttachmentsMenu}
					onOpenChange={(open) => {
						runInAction(() => {
							tabState.showAttachmentsMenu = open;
						});
					}}
				>
					<PopoverTrigger asChild>
						<Button
							variant="outline"
							className="h-6 w-6 rounded-sm p-1 text-neutral-500"
						>
							<Plus size={14} />
						</Button>
					</PopoverTrigger>
					<PopoverContent className="p-0" align="start" side="top">
						<Command shouldFilter={false} className="p-0">
							<CommandInput
								placeholder="Search resources..."
								value={tabState.attachmentsQuery}
								onValueChange={(value) => {
									runInAction(() => {
										tabState.attachmentsQuery = value;
									});
								}}
							/>
							<CommandList
								style={{ height: "256px" }}
								className="overflow-hidden p-0"
							>
								<CommandEmpty>No resources found.</CommandEmpty>
								<CommandGroup className="p-0">
									<FixedSizeList
										itemData={{
											items: tabState.attachmentResults,
											tabState,
										}}
										itemSize={28}
										height={256}
										itemCount={tabState.attachmentResults.length}
										width={"100%"}
									>
										{AttachmentRow}
									</FixedSizeList>
								</CommandGroup>
							</CommandList>
						</Command>
					</PopoverContent>
				</Popover>
				{tabState.attachments.map((href, index) => (
					<div
						key={href}
						className="flex h-6 items-center divide-x overflow-hidden rounded-sm border border-neutral-200"
					>
						<ResourceLinkComponent linkProps={{ href }} className="">
							<ResourceLinkContent
								linkProps={{ href }}
								className="max-w-36 truncate p-1 text-neutral-800 text-xs hover:bg-neutral-100 hover:underline"
							/>
						</ResourceLinkComponent>
						<button
							type="button"
							className="p-1 text-neutral-500 text-xs hover:bg-neutral-100"
							onClick={() => {
								runInAction(() => {
									tabState.attachments.splice(index, 1);
								});
							}}
						>
							<X size={14} />
						</button>
					</div>
				))}
			</div>
			<EditorContent
				editor={tabState.editor}
				className="prose prose-sm prose-neutral w-full prose-p:text-neutral-900 prose-p:first:mt-0 prose-p:last:mb-0"
			/>
			<div className="flex w-full justify-end border-neutral-100 border-t bg-white">
				<Button
					className="m-1 h-8 w-8 p-0"
					variant="ghost"
					onClick={() => {
						const parentMessageResult = tabState.parentMessage;
						if (parentMessageResult.isErr()) {
							return;
						}
						const messageId = parentMessageResult.value?.message_id ?? null;
						appContext.messageStore.createMessage({
							content: tabState.editorContent.html,
							parentMessageId: messageId,
							attachments: tabState.attachments,
							onLocalSuccess: (newMessage) => {
								if (messageId === null) {
									tabState.tab.router.navigate({
										to: "/message/$message-id",
										params: {
											"message-id": newMessage.message_id,
										},
									});
								}
								tabState.editor.commands.setContent("");
							},
						});
					}}
					disabled={!tabState.editorContent.text.trim().length}
				>
					<PaperPlaneRight size={16} />
				</Button>
			</div>
		</div>
	);
});
