import type { AppState } from "@/contexts/app-context/app-context";
import { createTableId, createWriteId } from "@/lib/id-generators";
import { OptimisticAction, type Transaction } from "@/lib/sync/action-executor";
import {
	createComputedTableRoute,
	createTableRoute,
	deleteTablesRoute,
	renameTableRoute,
} from "@api/fastAPI";
import type {
	GroupByQuery,
	Query,
	RawSQLQuery,
	SelectQuery,
	TableId,
	UserId,
	UserTableResource,
	WriteId,
} from "@api/schemas";

export function createTableResource(
	name: string,
	userId: UserId,
	query: Query | null,
): UserTableResource {
	const newTableId = createTableId();
	const createdAt = new Date().toISOString();
	const tableResource: UserTableResource = {
		table_id: newTableId,
		name: name,
		created_at: createdAt,
		creator_id: userId,
		integration_type: null,
		resource_type: null,
		query: query,
		deleted_at: null,
		write_id: createWriteId(),
	};
	return tableResource;
}

export class RenameTableAction extends OptimisticAction<
	AppState,
	{
		tableId: TableId;
		newName: string;
	},
	{
		writeId: WriteId;
	},
	void
> {
	async local(
		tx: Transaction,
		state: AppState,
	): Promise<{
		writeId: WriteId;
	}> {
		const table = state.workspace.tables.getResourceById(this.args.tableId);
		if (table.isErr()) {
			throw new Error(`Table with ID ${this.args.tableId} not found`);
		}
		const writeId = createWriteId();
		state.workspace.tables.map.update(tx, table.value.table_id, {
			name: this.args.newName,
			write_id: writeId,
		});
		return { writeId };
	}

	async remote(context: {
		localResult: { writeId: WriteId };
	}): Promise<void> {
		await renameTableRoute({
			table_id: this.args.tableId,
			new_name: this.args.newName,
			write_id: context.localResult.writeId,
		});
	}
}

export class CreateTableAction extends OptimisticAction<
	AppState,
	{
		name: string;
		afterLocalCallback?: (newTableResource: UserTableResource) => void;
	},
	{
		newTableResource: UserTableResource;
	},
	void
> {
	async local(
		tx: Transaction,
		state: AppState,
	): Promise<{
		newTableResource: UserTableResource;
	}> {
		const newTableResource = createTableResource(
			this.args.name,
			state.userId,
			null,
		);
		state.workspace.tables.map.insert(tx, newTableResource);
		if (this.args.afterLocalCallback) {
			this.args.afterLocalCallback(newTableResource);
		}
		return { newTableResource };
	}

	async remote(context: {
		localResult: { newTableResource: UserTableResource };
	}): Promise<void> {
		await createTableRoute({
			table: context.localResult.newTableResource,
		});
	}
}

/**
 * This action executes a soft delete in the backend by setting the deleted_at
 * field, but on the frontend this acts as a hard delete because our shape
 * filter excludes tables with deleted_at set.
 */
export class DeleteTablesAction extends OptimisticAction<
	AppState,
	{
		tableIds: Set<TableId>;
	},
	void,
	void
> {
	async local(tx: Transaction, state: AppState): Promise<void> {
		for (const tableId of this.args.tableIds) {
			const table = state.workspace.tables.getResourceById(tableId);
			if (table.isErr()) {
				throw new Error(`Table with ID ${tableId} not found`);
			}
			state.workspace.tables.map.delete(tx, tableId);
		}
	}

	async remote(): Promise<void> {
		await deleteTablesRoute({ table_ids: Array.from(this.args.tableIds) });
	}
}

// Type guards to identify Query subtypes
function isSelectQuery(query: Query): query is SelectQuery {
	return "from_table_id" in query && "selected_fields" in query;
}

function isGroupByQuery(query: Query): query is GroupByQuery {
	return "from_table_id" in query && "grouping_fields" in query;
}

function isRawSQLQuery(query: Query): query is RawSQLQuery {
	return "sql_query" in query && !("from_table_id" in query);
}

export class CreateComputedTableAction extends OptimisticAction<
	AppState,
	{
		query: Query;
	},
	{
		newTableResource: UserTableResource;
	},
	void
> {
	async local(
		tx: Transaction,
		state: AppState,
	): Promise<{
		newTableResource: UserTableResource;
	}> {
		let tableName: string;
		if (isSelectQuery(this.args.query) || isGroupByQuery(this.args.query)) {
			// Handle Query with from_table_id
			const fromTable = state.workspace.tables.getResourceById(
				this.args.query.from_table_id,
			);
			if (fromTable.isErr()) {
				throw new Error(
					`Table with ID ${this.args.query.from_table_id} not found`,
				);
			}
			const fromTableName = fromTable.value.name;
			tableName = `Computed Table (from ${fromTableName})`;
		} else if (isRawSQLQuery(this.args.query)) {
			// Handle SQL Query
			const sqlPreview = this.args.query.sql_query
				.substring(0, 30)
				.replace(/\s+/g, " ")
				.trim();
			tableName = `SQL Table (${sqlPreview}...)`;
		} else {
			// Fallback case
			tableName = "Computed Table";
		}

		const newTableResource: UserTableResource = createTableResource(
			tableName,
			state.userId,
			this.args.query,
		);
		state.workspace.tables.map.insert(tx, newTableResource);
		return { newTableResource };
	}

	async remote(context: {
		localResult: { newTableResource: UserTableResource };
	}): Promise<void> {
		await createComputedTableRoute({
			table: context.localResult.newTableResource,
		});
	}
}
