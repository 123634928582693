import { StatusBadge } from "@/components/layout/right-sidebar/running-status";
import { Collapsible, CollapsibleTrigger } from "@/components/ui/collapsible";
import { CollapsibleContent } from "@/components/ui/collapsible";
import {
	useMessageStore,
	useSearchStore,
	useWebSearchStore,
} from "@/contexts/app-context/db-store/db-store-hooks";
import { useTabStore } from "@/contexts/tabs/use-tab-store";
import { ResourceLinkComponent } from "@/plugins/resource-link";
import type {
	CloseTabSpanAttributes,
	CreatePageSpanAttributes,
	IndexWebpageSpanAttributes,
	NavigateTabSpanAttributes,
	OpenTabSpanAttributes,
	RunSearchSpanAttributes,
	RunWebSearchSpanAttributes,
	SendMessageSpanAttributes,
	Span,
	UpdateCellSpanAttributes,
	UpdatePageSpanAttributes,
} from "@api/schemas";
import {
	CaretRight,
	Chat,
	CloudArrowDown,
	Globe,
	type Icon,
	MagnifyingGlass,
	X,
} from "@phosphor-icons/react";
import { observer } from "mobx-react-lite";
import { cloneElement, useState } from "react";

interface CollapsibleSpanProps {
	span: Span;
	Icon: Icon;
	header: React.ReactElement<React.HTMLAttributes<HTMLSpanElement>, "span">;
	children?: React.ReactNode;
}

const CollapsibleSpan = observer(function CollapsibleSpan({
	span,
	Icon,
	header,
	children,
}: CollapsibleSpanProps) {
	const [open, setOpen] = useState(false);
	const hasError = span.end_status?.code === "error";
	const errorMessage =
		span.end_status && hasError && "message" in span.end_status
			? span.end_status.message
			: undefined;

	return (
		<Collapsible open={open} onOpenChange={setOpen}>
			{/* Header */}
			<div className="flex h-7 items-center justify-between text-xs">
				<span className="inline-flex min-w-0 grow items-center text-neutral-500">
					<Icon className="z-10 mr-3 flex-none bg-white" />
					{cloneElement(header, {
						className: `${header.props.className || ""} grow truncate mr-2`,
					})}
					{children && (
						<CollapsibleTrigger className="group flex-none">
							<CaretRight className="group-data-[state=open]:rotate-90" />
						</CollapsibleTrigger>
					)}
				</span>
				<StatusBadge
					startTime={span.start_time}
					endStatus={
						span.end_status
							? {
									endTime: span.end_status.end_time,
									code: span.end_status.code,
									message: errorMessage,
								}
							: null
					}
				/>
			</div>
			{children && (
				<CollapsibleContent className="flex flex-col">
					<div className="mt-2 text-xs">{children}</div>
				</CollapsibleContent>
			)}
		</Collapsible>
	);
});

interface ResourceSpanWrapperProps {
	span: Span;
	href: string;
	prefix: string;
}

const ResourceSpanWrapper = observer(function ResourceSpanWrapper({
	span,
	href,
	prefix,
}: ResourceSpanWrapperProps) {
	const tabStore = useTabStore();
	const head = tabStore.getTabHead({ href });
	return (
		<CollapsibleSpan
			span={span}
			Icon={head.icon}
			header={
				<span>
					{prefix}
					<ResourceLinkComponent
						linkProps={{ href }}
						className="gap-1 underline"
					>
						{head.label}
					</ResourceLinkComponent>
				</span>
			}
		/>
	);
});

const SendMessageSpan = observer(function SendMessageSpan({
	span,
}: {
	span: Span & { attributes: SendMessageSpanAttributes };
}) {
	const messageStore = useMessageStore();
	const message = messageStore.messages
		.get(span.attributes.message_id)
		.unwrapOr(null);

	if (!message)
		return (
			<CollapsibleSpan
				span={span}
				Icon={Chat}
				header={<span>Sent Message</span>}
			/>
		);

	return (
		<ResourceSpanWrapper
			span={span}
			href={`/message/${message.message_id}`}
			prefix="Sent "
		/>
	);
});

const OpenTabSpan = observer(function OpenTabSpan({
	span,
}: {
	span: Span & { attributes: OpenTabSpanAttributes };
}) {
	return (
		<ResourceSpanWrapper
			span={span}
			href={span.attributes.initial_path}
			prefix="Opened "
		/>
	);
});

const NavigateTabSpan = observer(function NavigateTabSpan({
	span,
}: {
	span: Span & { attributes: NavigateTabSpanAttributes };
}) {
	return (
		<ResourceSpanWrapper
			span={span}
			href={span.attributes.new_path}
			prefix="Navigated to "
		/>
	);
});

const CloseTabSpan = observer(function CloseTabSpan({
	span,
}: {
	span: Span & { attributes: CloseTabSpanAttributes };
}) {
	return (
		<CollapsibleSpan span={span} Icon={X} header={<span>Closed Tab</span>} />
	);
});

const RunSearchSpan = observer(function RunSearchSpan({
	span,
}: {
	span: Span & { attributes: RunSearchSpanAttributes };
}) {
	const searchStore = useSearchStore();
	const search = searchStore
		.getResourceById(span.attributes.search_id)
		.unwrapOr(null);

	if (!search) return <div>Search not found</div>;

	return (
		<CollapsibleSpan
			span={span}
			Icon={MagnifyingGlass}
			header={<span>Searched "{search.query}"</span>}
		/>
	);
});

const IndexWebpageSpan = observer(function IndexWebpageSpan({
	span,
}: {
	span: Span & { attributes: IndexWebpageSpanAttributes };
}) {
	return (
		<CollapsibleSpan
			span={span}
			Icon={CloudArrowDown}
			header={
				<span>
					Indexed{" "}
					<a href={span.attributes.href} className="underline">
						{span.attributes.href}
					</a>
				</span>
			}
		/>
	);
});

const RunWebSearchSpan = observer(function RunWebSearchSpan({
	span,
}: {
	span: Span & { attributes: RunWebSearchSpanAttributes };
}) {
	const webSearchStore = useWebSearchStore();
	const webSearch = webSearchStore
		.getResourceById(span.attributes.web_search_id)
		.unwrapOr(null);

	if (!webSearch) return <div>Web Search not found</div>;

	return (
		<CollapsibleSpan
			span={span}
			Icon={Globe}
			header={<span>Web Searched "{webSearch.query}"</span>}
		/>
	);
});

const CreatePageSpan = observer(function CreatePageSpan({
	span,
}: {
	span: Span & { attributes: CreatePageSpanAttributes };
}) {
	return (
		<ResourceSpanWrapper
			span={span}
			href={`/pages/${span.attributes.new_page_id}`}
			prefix="Created "
		/>
	);
});

const UpdatePageSpan = observer(function UpdatePageSpan({
	span,
}: {
	span: Span & { attributes: UpdatePageSpanAttributes };
}) {
	return (
		<ResourceSpanWrapper
			span={span}
			href={`/pages/${span.attributes.page_id}`}
			prefix="Updated "
		/>
	);
});

const UpdateCellSpan = observer(function UpdateCellSpan({
	span,
}: {
	span: Span & { attributes: UpdateCellSpanAttributes };
}) {
	return (
		<ResourceSpanWrapper
			span={span}
			// Should probably link to reference
			href={`/tables/${span.attributes.table_id}`}
			prefix="Updated "
		/>
	);
});

export const SpanComponent = observer(function SpanComponent({
	span,
}: {
	span: Span;
}) {
	switch (span.attributes.type) {
		case "send_message": {
			return (
				<SendMessageSpan
					span={span as Span & { attributes: SendMessageSpanAttributes }}
				/>
			);
		}
		case "open_tab": {
			return (
				<OpenTabSpan
					span={span as Span & { attributes: OpenTabSpanAttributes }}
				/>
			);
		}
		case "navigate_tab": {
			return (
				<NavigateTabSpan
					span={span as Span & { attributes: NavigateTabSpanAttributes }}
				/>
			);
		}
		case "close_tab": {
			return (
				<CloseTabSpan
					span={span as Span & { attributes: CloseTabSpanAttributes }}
				/>
			);
		}
		case "run_search": {
			return (
				<RunSearchSpan
					span={span as Span & { attributes: RunSearchSpanAttributes }}
				/>
			);
		}
		case "index_webpage": {
			return (
				<IndexWebpageSpan
					span={span as Span & { attributes: IndexWebpageSpanAttributes }}
				/>
			);
		}
		case "run_web_search": {
			return (
				<RunWebSearchSpan
					span={span as Span & { attributes: RunWebSearchSpanAttributes }}
				/>
			);
		}

		case "create_page": {
			return (
				<CreatePageSpan
					span={span as Span & { attributes: CreatePageSpanAttributes }}
				/>
			);
		}

		case "update_page": {
			return (
				<UpdatePageSpan
					span={span as Span & { attributes: UpdatePageSpanAttributes }}
				/>
			);
		}

		case "update_cell": {
			return (
				<UpdateCellSpan
					span={span as Span & { attributes: UpdateCellSpanAttributes }}
				/>
			);
		}

		default: {
			const _exhaustiveCheck: never = span.attributes;
			return _exhaustiveCheck;
		}
	}
});
