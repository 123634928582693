import { TableComponent } from "@/components/table/table-component";
import { EdgarHomeTabState } from "@/contexts/edgar/tab-state";
import { useEdgarEntitiesIndex } from "@/contexts/resource-indexes/hooks";
import { createFileRoute } from "@tanstack/react-router";
import { observer } from "mobx-react-lite";

const EdgarEmptyState = observer(() => {
	return (
		<div className="flex h-full flex-col items-center justify-center">
			<h1 className="px-4 text-center text-neutral-500">No entities</h1>
		</div>
	);
});

export const EdgarPage = observer(() => {
	const edgarEntitiesIndex = useEdgarEntitiesIndex();

	return (
		<div className="flex h-full max-h-full min-h-0 w-full flex-col px-16 pt-4">
			<h1 className="px-2 font-bold text-2xl">SEC EDGAR</h1>
			<h2 className="px-2 text-neutral-500 text-sm">
				Entities with recent filings
			</h2>
			<div className="flex w-full border-b px-4 pt-4 pb-4" />
			{edgarEntitiesIndex.rows.length > 0 ? (
				<div className="relative flex grow flex-col">
					<TableComponent
						getRowId={(row) => row.cik}
						getRowLink={(row) => row.link}
						columns={edgarEntitiesIndex.columns}
						data={edgarEntitiesIndex.rows}
						maxHeight={500}
						rowSelectionPopoverTop={0}
					/>
				</div>
			) : (
				<EdgarEmptyState />
			)}
		</div>
	);
});

export const Route = createFileRoute("/edgar/")({
	component: EdgarPage,
	loader: ({ context: { tab } }) => {
		const tabState = new EdgarHomeTabState(tab);
		return {
			tabState,
		};
	},
});
