import { IS_DEV } from "@/config";
import { CommandKState } from "@/contexts/app-context/cmd-k-state";
import { SpanStore } from "@/contexts/app-context/db-store/span-store";
import { UserSessionStore } from "@/contexts/app-context/db-store/user-session-store";
import { UserStore } from "@/contexts/app-context/db-store/user-store";
import { DevSettings } from "@/contexts/app-context/dev-settings";
import { LeftSidebarState } from "@/contexts/app-context/left-sidebar-state";
import { RightSidebarState } from "@/contexts/app-context/right-sidebar-state";
import { AppContext } from "@/contexts/app-context/use-app-context";
import { AssistantSessionStore } from "@/contexts/assistant/stores/assistant-session-store";
import { StepStore } from "@/contexts/assistant/stores/step-store";
import { EdgarEntitiesStore } from "@/contexts/edgar/stores/edgar-stores";
import {
	FeedChannelsStore,
	FeedItemsStore,
} from "@/contexts/feeds/stores/feed-stores";
import { LocalSearchStore } from "@/contexts/local-search-store";
import { MessageStore } from "@/contexts/messages/stores/message-store";
import { PageLinkStore, PageStore } from "@/contexts/pages/page-store";
import { PendingResourcesStore } from "@/contexts/pending-resources";
import { EdgarEntitiesIndex } from "@/contexts/resource-indexes/edgar-entities";
import { FeedsIndex } from "@/contexts/resource-indexes/feeds";
import { PagesIndex } from "@/contexts/resource-indexes/pages";
import { TablesIndex } from "@/contexts/resource-indexes/tables";
import { UploadsIndex } from "@/contexts/resource-indexes/uploads";
import { WebpagesIndex } from "@/contexts/resource-indexes/webpages";
import { SearchStore } from "@/contexts/search/stores/search-store";
import { FieldsStore } from "@/contexts/tables/stores/field-store";
import { TablesStore } from "@/contexts/tables/stores/table-store";
import { TabStore } from "@/contexts/tabs/tabs-context";
import { UploadsStore } from "@/contexts/uploads/stores/upload-store";
import { WebSearchStore } from "@/contexts/web/stores/web-search-store";
import { WebpageStore } from "@/contexts/web/stores/webpage-store";
import { createUserSessionId } from "@/lib/id-generators";
import { ActionExecutor } from "@/lib/sync/action-executor";
import { bootstrapSession } from "@api/fastAPI";
import type { UserId, UserSessionId } from "@api/schemas";
import { useAuth } from "@clerk/clerk-react";
import * as Sentry from "@sentry/react";
import { useMediaQuery } from "@uidotdev/usehooks";
import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { type ReactNode, useEffect, useState } from "react";
import { toast } from "sonner";

interface Workspace {
	uploads: UploadsStore;
	feedChannels: FeedChannelsStore;
	feedItems: FeedItemsStore;
	tables: TablesStore;
	fields: FieldsStore;
	webpages: WebpageStore;
	pages: PageStore;
	pageLinks: PageLinkStore;
}

/**
 * TODO(John): think about where to put these
 */
interface ResourceIndexes {
	uploads: UploadsIndex;
	pages: PagesIndex;
	webpages: WebpagesIndex;
	tables: TablesIndex;
	feeds: FeedsIndex;
	edgarEntities: EdgarEntitiesIndex;
}

export class AppState {
	userId: UserId;
	getToken: ReturnType<typeof useAuth>["getToken"];
	userStore: UserStore;
	userSessionStore: UserSessionStore;
	workspace: Workspace;
	resourceIndexes: ResourceIndexes;

	localSearchStore: LocalSearchStore;
	pendingResources: PendingResourcesStore;

	tabStore: TabStore;
	searchStore: SearchStore;
	webSearchStore: WebSearchStore;
	edgarEntitiesStore: EdgarEntitiesStore;
	userSessionId: UserSessionId;

	// database stores
	messageStore: MessageStore;
	assistantSessionStore: AssistantSessionStore;
	stepStore: StepStore;
	spanStore: SpanStore;

	// UI State
	leftSidebarState: LeftSidebarState;
	rightSidebarState: RightSidebarState;
	commandKState: CommandKState;
	showAddFeedDialog = false;

	actionQueue: ActionExecutor<AppState>;

	devSettings: DevSettings;

	constructor(
		userId: UserId,
		getToken: ReturnType<typeof useAuth>["getToken"],
	) {
		this.getToken = getToken;
		makeAutoObservable(this);
		this.userId = userId;

		this.userStore = new UserStore(this);
		this.pendingResources = new PendingResourcesStore(this);

		// Do not initialize these as field defaults because they may require
		// access to AppState methods in their constructors
		this.userSessionStore = new UserSessionStore(this);
		this.tabStore = new TabStore(this);
		this.searchStore = new SearchStore(this);
		this.webSearchStore = new WebSearchStore(this);
		this.edgarEntitiesStore = new EdgarEntitiesStore(this);
		this.messageStore = new MessageStore(this);
		this.assistantSessionStore = new AssistantSessionStore(this);
		this.stepStore = new StepStore(this);
		this.spanStore = new SpanStore(this);
		this.actionQueue = new ActionExecutor(this);
		this.devSettings = new DevSettings();

		// TODO(John): does workspace still make sense to use?
		this.workspace = {
			uploads: new UploadsStore(this),
			feedChannels: new FeedChannelsStore(this),
			feedItems: new FeedItemsStore(this),
			tables: new TablesStore(this),
			fields: new FieldsStore(this),
			webpages: new WebpageStore(this),
			pages: new PageStore(this),
			pageLinks: new PageLinkStore(this),
		};
		this.resourceIndexes = {
			uploads: new UploadsIndex(this.workspace.uploads, this.tabStore),
			pages: new PagesIndex(this.workspace.pages, this.tabStore),
			webpages: new WebpagesIndex(this.workspace.webpages, this.tabStore),
			tables: new TablesIndex(this.workspace.tables, this.tabStore),
			feeds: new FeedsIndex(this.workspace.feedChannels, this.tabStore),
			edgarEntities: new EdgarEntitiesIndex(
				this.edgarEntitiesStore,
				this.tabStore,
			),
		};

		this.leftSidebarState = new LeftSidebarState(this);
		this.rightSidebarState = new RightSidebarState(this);
		this.commandKState = new CommandKState(this);
		this.showAddFeedDialog = false;

		makePersistable(this.rightSidebarState, {
			name: "RightSidebarState",
			properties: ["showRightSidebar"],
			storage: window.localStorage,
		});
		makePersistable(this.leftSidebarState, {
			name: "LeftSidebarState",
			properties: ["showSidebar"],
			storage: window.localStorage,
		});
		makePersistable(this.devSettings, {
			name: "DevSettings",
			properties: ["showSearchResultScores"],
			storage: window.localStorage,
		});
		makePersistable(this.searchStore, {
			name: "SearchStore",
			properties: [
				{
					key: "recentSearchIds",
					// we need to serialize this ourselves because Sets will
					// serialize to {} by default
					serialize: (value) => Array.from(value),
					deserialize: (value) => new Set(value),
				},
			],
			storage: window.localStorage,
		});
		makePersistable(this.webSearchStore, {
			name: "WebSearchStore",
			properties: [
				{
					key: "recentSearchIds",
					// we need to serialize this ourselves because Sets will
					// serialize to {} by default
					serialize: (value) => Array.from(value),
					deserialize: (value) => new Set(value),
				},
			],
			storage: window.localStorage,
		});

		// Initialize this last because it depends on other stores
		// TODO: Better indicate dependencies between stores
		this.localSearchStore = new LocalSearchStore(this);
		this.userSessionId = this.#initializeUserSessionId(userId);
		bootstrapSession(this.userSessionId).catch((err) => {
			Sentry.captureException(err);
			if (IS_DEV) {
				toast.error(`${err}`);
			} else {
				toast.error("Failed to initialize session. Please refresh the page.");
			}
		});
	}

	#initializeUserSessionId(userId: UserId, forceNew = false): UserSessionId {
		const STORAGE_KEY = "userIdUserSessionIdRecord";
		let record: Record<UserId, UserSessionId> = {};

		if (!forceNew) {
			const recordString = localStorage.getItem(STORAGE_KEY);
			if (recordString) {
				try {
					record = JSON.parse(recordString) as Record<UserId, UserSessionId>;
				} catch (error) {
					console.warn("Failed to parse userIdUserSessionIdRecord:", error);
					localStorage.removeItem(STORAGE_KEY);
				}
			}
		}

		// Hacking validation; should really validate the prefix and the ULID
		if (userId in record && record[userId].startsWith("user-session")) {
			return record[userId];
		}

		const newUserSessionId = createUserSessionId();
		record[userId] = newUserSessionId;
		try {
			localStorage.setItem(STORAGE_KEY, JSON.stringify(record));
		} catch (error) {
			console.error("Failed to set userIdUserSessionIdRecord:", error);
		}

		return newUserSessionId;
	}

	getTokenOrThrow = async (): Promise<string> => {
		const token = await this.getToken();
		if (!token) {
			throw new Error("No token");
		}
		return token;
	};
}

type AppProviderProps = {
	children: ReactNode;
};

let didInit = false;

export const AppProvider = ({ children }: AppProviderProps) => {
	const { userId, getToken } = useAuth();
	const [appState, setAppState] = useState<AppState | null>(null);
	const isSmallDevice = useMediaQuery("only screen and (max-width: 768px)");

	useEffect(
		function hideLeftSidebarOnSmallDevices() {
			if (!appState) {
				return;
			}
			runInAction(() => {
				appState.leftSidebarState.setShowSidebar(!isSmallDevice);
			});
		},
		[isSmallDevice, appState],
	);

	useEffect(
		function initAppState() {
			if (!userId) {
				return;
			}
			if (!didInit) {
				didInit = true;
				setAppState(new AppState(userId as UserId, getToken));
			}
		},
		[userId, getToken],
	);

	if (!appState) {
		return null;
	}

	return <AppContext.Provider value={appState}>{children}</AppContext.Provider>;
};
