import { BooleanRenderer } from "@/components/table/scalar-renderers/boolean-renderer";
import { DatetimeRenderer } from "@/components/table/scalar-renderers/datetime-renderer";
import { NumberRenderer } from "@/components/table/scalar-renderers/number-renderer";
import { RelationshipRenderer } from "@/components/table/scalar-renderers/relationship-renderer";
import { SelectRenderer } from "@/components/table/scalar-renderers/select-renderer";
import { TextRenderer } from "@/components/table/scalar-renderers/text-renderer";
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { UserTableState } from "@/contexts/tables/stores/table-store";
import { cn } from "@/lib/utils";
import { DatetimeFormat, type SelectOptionLabel } from "@api/schemas";
import type { ResourceLink, SelectOption } from "@api/schemas";
import { CaretRight } from "@phosphor-icons/react";
import { WarningDiamond } from "@phosphor-icons/react";
import * as Sentry from "@sentry/react";
import type { CellContext } from "@tanstack/react-table";
import { observer } from "mobx-react-lite";
import { Fragment } from "react/jsx-runtime";

/**
 * Aggregate cell component for all types of cells that are rendered within a
 * Tanstack table.
 *
 * Cells may or may not be associated with a TableState that has actual Fields
 * (e.g. query results). The column's metadata should indicate whether the cell
 * represents a field or not.
 *
 * May or may not be rendered as a field value; is sufficient to render with
 * just a data type and a value.
 *
 * @prop value - The value of the cell.
 * @prop context - The context of the cell, from Tanstack.
 */
export const Cell = observer(function Cell<TData, TValue>(props: {
	cellValue: unknown;
	context: CellContext<TData, TValue>;
	onUpdate?: (value: unknown) => void;
	registerRef?: (ref: HTMLDivElement | null) => void;
}) {
	const isViewField =
		props.context.column.columnDef.meta?.tableMeta?.field?.type === "view";

	return (
		<div
			className={cn("h-full w-full p-0.5", isViewField && "bg-purple-50/30")}
			ref={props.registerRef}
		>
			<CellErrorBoundary>
				<CellValueRenderer
					cellValue={props.cellValue}
					onUpdate={props.onUpdate}
					context={props.context}
				/>
			</CellErrorBoundary>
		</div>
	);
});

/**
 * Error boundary specifically for cell rendering errors.
 * This prevents a rendering error in one cell from crashing the entire table.
 */
const CellErrorBoundary = ({ children }: { children: React.ReactNode }) => {
	return (
		<Sentry.ErrorBoundary
			fallback={() => (
				<div className="flex h-full w-full items-center justify-center rounded bg-red-50 p-1 text-red-600 text-xs">
					<WarningDiamond className="mr-1 h-3 w-3" weight="fill" />
					<span className="truncate">Error rendering cell</span>
				</div>
			)}
		>
			{children}
		</Sentry.ErrorBoundary>
	);
};

/**
 * Array data is placed inside a collapsible. For nested array types (e.g.
 * TEXT[][]), collapsibles will be placed inside each other.
 */
export const ArrayDataCollapsible = ({
	children,
	defaultOpen,
	numElements,
}: {
	children: React.ReactNode[];
	defaultOpen?: boolean;
	numElements: number;
}) => {
	return (
		<Collapsible
			className="group flex flex-col gap-2"
			defaultOpen={defaultOpen}
		>
			<CollapsibleTrigger className="flex items-center gap-1 text-neutral-500 text-xs">
				<span>{numElements} items</span>
				<CaretRight className="group-data-[state=open]:rotate-90" />
			</CollapsibleTrigger>
			<CollapsibleContent className="flex w-full flex-col pl-2">
				{children}
			</CollapsibleContent>
		</Collapsible>
	);
};

const MAX_ARRAY_ELEMENTS = 10;

/**
 * Note that any renderers within arrays are always not editable.
 */
const CellValueRenderer = observer(function CellValueRenderer<
	TData,
	TValue,
>(props: {
	cellValue: unknown;
	onUpdate?: (value: unknown) => void;
	context: CellContext<TData, TValue>;
}) {
	// biome-ignore lint/style/noNonNullAssertion: must always define meta
	const meta = props.context.column.columnDef.meta!;
	const dataType = meta.dataType;
	const tableMeta = meta.tableMeta;
	const field = tableMeta?.field;
	const tableState = tableMeta?.tableState;
	const editable = meta.editable;

	const renderers = {
		boolean: (value: unknown) => {
			/**
			 * Despite server-side boolean values being non-nullable to
			 * avoid confusion, freshly-added boolean cells can be
			 * undefined -> null until we get a response from the
			 * server. In the meantime, we'll coerce them to the
			 * default value.
			 */
			let resolvedValue = value;
			if (value === null) {
				if (field?.type === "boolean") {
					resolvedValue = field.properties.default_value;
				} else {
					resolvedValue = false;
				}
			}
			if (typeof resolvedValue !== "boolean") {
				throw new Error(
					`Expected boolean value, got ${resolvedValue} with type ${typeof resolvedValue}`,
				);
			}
			return (
				<BooleanRenderer
					value={resolvedValue}
					editable={editable}
					onUpdate={props.onUpdate}
				/>
			);
		},

		real: (value: unknown) => {
			if (typeof value !== "number" && value !== null) {
				throw new Error(
					`Expected number value, got ${value} with type ${typeof value}`,
				);
			}
			return (
				<NumberRenderer
					value={value}
					editable={editable}
					onUpdate={props.onUpdate}
				/>
			);
		},

		text: (value: unknown) => {
			if (typeof value !== "string" && value !== null) {
				throw new Error(
					`Expected string value, got ${value} with type ${typeof value}`,
				);
			}
			return (
				<TextRenderer
					value={value}
					editable={editable}
					onUpdate={props.onUpdate}
				/>
			);
		},

		timestamptz: (value: unknown) => {
			if (typeof value !== "string" && value !== null) {
				throw new Error(
					`Expected string value, got ${value} with type ${typeof value}`,
				);
			}

			// TODO(John): the time format should be dependent on whether the
			// underlying data type is a TIMESTAMPTZ (show time) or DATE (hide
			// time). However, right now we only use TIMESTAMPTZ, so only explicit
			// datetime fields can have dates (view fields always show time).
			const timeFormat =
				field?.type === "datetime"
					? field.properties.time_format
					: DatetimeFormat.twelve_hour;

			return (
				<DatetimeRenderer
					value={value}
					editable={editable}
					onUpdate={props.onUpdate}
					timeFormat={timeFormat}
				/>
			);
		},

		record_link: (value: unknown) => {
			if (typeof value !== "string" && value !== null) {
				throw new Error(
					`Expected string value, got ${value} with type ${typeof value}`,
				);
			}

			return (
				<RelationshipRenderer
					value={value as ResourceLink | null}
					editable={editable}
					onUpdate={props.onUpdate}
				/>
			);
		},

		select_option: (value: unknown) => {
			if (typeof value !== "string" && value !== null) {
				throw new Error(
					`Expected string value, got ${value} with type ${typeof value}`,
				);
			}
			const onAddSelectOption =
				tableState instanceof UserTableState
					? (option: SelectOption) => {
							tableState.addSelectOption({
								// biome-ignore lint/style/noNonNullAssertion: non-null because tableState is non-null
								fieldId: field!.field_id,
								newOption: option,
							});
						}
					: undefined;
			const options =
				field?.type === "select" ? field.properties.options : undefined;
			return (
				<SelectRenderer
					value={value as SelectOptionLabel | null}
					editable={editable}
					onUpdate={props.onUpdate}
					options={options}
					onAddSelectOption={onAddSelectOption}
				/>
			);
		},
	};

	// Special handling for relationship fields because they have data type
	// with array_depth 1
	// TODO(John): it feels like there should be a better way to handle this...
	// I smell inappropriately coupled concepts
	if (field?.type === "relationship") {
		return (
			<RelationshipRenderer
				value={props.cellValue as ResourceLink | ResourceLink[] | null}
				editable={editable}
				onUpdate={props.onUpdate}
				foreignTableId={field.properties.foreign_table_id}
				foreignFieldId={field.properties.foreign_field_id}
			/>
		);
	}

	switch (dataType.array_depth) {
		case 0: {
			return renderers[dataType.scalar_type](props.cellValue);
		}
		case 1: {
			if (props.cellValue !== null && !Array.isArray(props.cellValue)) {
				throw new Error("Expected array value");
			}
			if (props.cellValue === null) {
				return (
					<ArrayDataCollapsible defaultOpen={false} numElements={0}>
						{[]}
					</ArrayDataCollapsible>
				);
			}

			return (
				<ArrayDataCollapsible
					defaultOpen={true}
					numElements={props.cellValue.length}
				>
					{props.cellValue.slice(0, MAX_ARRAY_ELEMENTS).map((item, index) => (
						// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
						<Fragment key={index}>
							{renderers[dataType.scalar_type](item)}
						</Fragment>
					))}
					{props.cellValue.length > MAX_ARRAY_ELEMENTS && (
						<span className="text-neutral-500 text-xs">
							+{props.cellValue.length - MAX_ARRAY_ELEMENTS} more
						</span>
					)}
				</ArrayDataCollapsible>
			);
		}
		default:
			// TODO: We don't handle editing or viewing arrays of depth >= 2. However,
			// I imagine we could display the Postgres stringified version of the data
			// (e.g. {{"a", "b"}}). Note that Postgres doesn't actually validate
			// array data types above 1.
			return (
				<div className="h-full w-full p-0.5 font-mono text-neutral-500 text-sm">
					{dataType.scalar_type}
					{"[]".repeat(dataType.array_depth)}
				</div>
			);
	}
});
