import { FeedChannelTabState } from "@/contexts/feeds/tab-state";
import {
	type HighlightRequest,
	pdfSearchSchema,
} from "@/contexts/pdfviewer-context";
import { zodValidator } from "@/lib/zod-validator";
import { FeedChannelPage } from "@/pages/tabs/feeds/feed-channel/$feed-channel-id";
import type { FeedItemId } from "@api/schemas";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/feeds/feed-item/$feed-item-id")({
	component: () => {
		const { tabState } = Route.useLoaderData();
		return <FeedChannelPage tabState={tabState} />;
	},
	validateSearch: zodValidator(pdfSearchSchema),
	loaderDeps: ({ search }) => ({ search }),
	loader: ({ params, context: { tab }, deps }) => {
		const hasHighlight = "textToHighlight" in deps.search;

		// If already a FeedChannelTabState, we need to get the feed channel id from it
		if (tab.state instanceof FeedChannelTabState) {
			tab.state.setActiveFeedItem(params["feed-item-id"] as FeedItemId);
			return {
				tabState: tab.state,
			};
		}
		const tabState = new FeedChannelTabState({
			tab,
			activeFeedItemId: params["feed-item-id"] as FeedItemId,
			searchParams: deps.search,
			highlightQuery: hasHighlight
				? (deps.search as HighlightRequest)
				: undefined,
		});
		return {
			tabState,
		};
	},
});
