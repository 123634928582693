import { PDFSidebar } from "@/components/pdf/pdfsidebar";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import {
	PAGE_PADDING,
	PDFViewerProvider,
	type PdfHeaderMetadata,
	type PdfSearchParams,
	usePDFViewerContext,
} from "@/contexts/pdfviewer-context";
import { cn, formatAuthors } from "@/lib/utils";
import type { TocSection } from "@api/schemas";
import {
	Info,
	MagnifyingGlassMinus,
	MagnifyingGlassPlus,
	SidebarSimple,
} from "@phosphor-icons/react";
import * as Sentry from "@sentry/react";
import { autorun, runInAction, toJS } from "mobx";
import { observer } from "mobx-react-lite";
import type { PDFDocumentProxy } from "pdfjs-dist";
import type React from "react";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import {
	Document,
	type PageProps,
	Page as ReactPDFPage,
	pdfjs,
} from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useResizeDetector } from "react-resize-detector";
import { BarLoader } from "react-spinners";
import { VariableSizeList } from "react-window";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.js";

// Render options for the <Document> component
const RENDERER_OPTIONS = {
	cMapPacked: true,
	devicePixelRatio: 1,
};

/**
 * Navbar component that contains the page number, zoom controls, and download options
 */
const PDFNavbar = observer(() => {
	const pdfViewerContext = usePDFViewerContext();
	const { currentPageIndex, pdf } = pdfViewerContext;

	const [currentPageNumber, setCurrentPageNumber] = useState(
		currentPageIndex + 1,
	);
	const { headerMetadata } = pdfViewerContext;
	useEffect(() => {
		setCurrentPageNumber(currentPageIndex + 1);
	}, [currentPageIndex]);

	if (!pdf) return null;

	return (
		<div className="z-20 flex h-14 shrink-0 select-none items-center justify-between gap-2 overflow-auto border-neutral-200 border-b bg-white px-3">
			<div className="flex min-w-48 items-center gap-2 ">
				<button
					type="button"
					onClick={(e) => {
						e.preventDefault();
						pdfViewerContext.toggleSidebar();
					}}
					className="p-2 text-neutral-500 hover:bg-neutral-100 hover:text-neutral-800"
				>
					<SidebarSimple size={16} />
				</button>
				<div className="ml-2 flex min-w-0 flex-col truncate">
					<h1 className="min-w-0 truncate pr-4 font-semibold text-neutral-800 text-sm">
						{headerMetadata.title}
					</h1>
					<h2 className="min-w-0 truncate text-neutral-600 text-sm">
						{formatAuthors({
							authors: headerMetadata.authors,
							truncate: true,
						})}
					</h2>
				</div>
			</div>

			<div className="flex shrink-0 items-center gap-1">
				<Popover>
					<PopoverTrigger>
						<Tooltip>
							<TooltipTrigger>
								<Button variant="ghost" size="icon">
									<Info />
								</Button>
							</TooltipTrigger>
							<TooltipContent>
								<p>Document info</p>
							</TooltipContent>
						</Tooltip>
					</PopoverTrigger>
					<PopoverContent className="flex flex-col gap-2">
						<div>
							<Label>Title</Label>
							<p className="text-xs">{headerMetadata.title}</p>
						</div>
						<div>
							<Label>Authors</Label>
							<p className="text-xs">
								{formatAuthors({
									authors: headerMetadata.authors,
									truncate: false,
								})}
							</p>
						</div>
						{headerMetadata.url ? (
							<div>
								<Label>Retrieved from</Label>
								<div>
									<a
										className="break-all text-blue-500 text-xs hover:underline"
										href={headerMetadata.url}
										target="_blank"
										rel="noreferrer"
									>
										{headerMetadata.url}
									</a>
								</div>
							</div>
						) : null}
					</PopoverContent>
				</Popover>

				<div className="flex select-none items-center">
					<Tooltip>
						<TooltipTrigger
							className="ml-0.5 p-2 text-neutral-500 hover:bg-neutral-200 hover:text-neutral-900"
							onClick={() => {
								runInAction(() => {
									pdfViewerContext.pdfScale = Math.max(
										pdfViewerContext.pdfScale - 0.25,
										0.25,
									);
								});
							}}
						>
							<MagnifyingGlassMinus weight="bold" size={16} />
						</TooltipTrigger>
						<TooltipContent>Zoom out</TooltipContent>
					</Tooltip>
					<Tooltip>
						<TooltipTrigger
							className="p-2 text-neutral-500 hover:bg-neutral-200 hover:text-neutral-900"
							onClick={() => {
								runInAction(() => {
									pdfViewerContext.pdfScale = pdfViewerContext.pdfScale + 0.25;
								});
							}}
						>
							<MagnifyingGlassPlus weight="bold" size={16} />
						</TooltipTrigger>
						<TooltipContent>Zoom in</TooltipContent>
					</Tooltip>
				</div>
				<h2 className="text-neutral-600 text-xs">
					Page{" "}
					<input
						value={currentPageNumber}
						onChange={(e) => {
							const value = Number.parseInt(e.target.value);
							if (
								Number.isNaN(value) ||
								value > pdf.document.numPages ||
								value < 1
							)
								return;

							setCurrentPageNumber(value);
						}}
						disabled={!pdf}
						className="w-10 rounded-sm border bg-white px-1 py-0.5 shadow-inner outline-hidden [appearance:textfield] focus:border-neutral-300 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
						type="number"
						onBlur={() => {
							if (currentPageNumber === currentPageIndex + 1) return;
							pdfViewerContext.listRef?.scrollToItem(currentPageNumber - 1);
						}}
					/>{" "}
					of {pdf.document.numPages}
				</h2>

				{/* <DropdownMenu>
					<DropdownMenuTrigger className="rounded-lg p-2 text-neutral-500 hover:bg-neutral-200 hover:text-neutral-900">
						<DownloadSimple weight="bold" />
					</DropdownMenuTrigger>
					<DropdownMenuContent>
						<DropdownMenuItem
							onClick={() => {
								uploadsStore.downloadUploadPdf(upload.upload_id);
							}}
						>
							Processed PDF
						</DropdownMenuItem>
						<DropdownMenuItem
							onClick={() => {
								uploadsStore.downloadOriginalUploadResource(upload.upload_id);
							}}
						>
							Original {upload.mimetype.toUpperCase()}
						</DropdownMenuItem>
					</DropdownMenuContent>
				</DropdownMenu> */}
			</div>
		</div>
	);
});

/**
 * Data passed to the PDFPage component
 */
type PageData = {
	pdfScale: number;
	pages: Map<
		{
			pageNumber: number;
		},
		HTMLElement
	>;
};

/**
 * Classifies a span based on its position relative to a highlight
 */
enum SpanClass {
	outside = "outside",
	left_edge = "left_edge",
	inside = "inside",
	right_edge = "right_edge",
}

/**
 * Compute span classes given a highlight result.
 */
const ClassifySpan = ({
	pageIndex,
	itemIndex,
	firstSpan,
	lastSpan,
}: {
	pageIndex: number;
	itemIndex: number;
	firstSpan: {
		pageIndex: number;
		itemIndex: number;
	};
	lastSpan: {
		pageIndex: number;
		itemIndex: number;
	};
}): SpanClass => {
	// Check if outside the span range
	if (pageIndex < firstSpan.pageIndex || pageIndex > lastSpan.pageIndex) {
		return SpanClass.outside;
	}

	// Check if on the first page of the span
	if (pageIndex === firstSpan.pageIndex) {
		if (itemIndex < firstSpan.itemIndex) return SpanClass.outside;
		if (itemIndex === firstSpan.itemIndex) {
			return SpanClass.left_edge;
		}
	}

	// Check if on the last page of the span
	if (pageIndex === lastSpan.pageIndex) {
		if (itemIndex > lastSpan.itemIndex) return SpanClass.outside;
		if (itemIndex === lastSpan.itemIndex) return SpanClass.right_edge;
	}

	// If none of the above conditions are met, it's inside the span
	return SpanClass.inside;
};

/**
 * Component that renders a single page of the PDF
 */
const PDFPage: React.FC<{
	index: number;
	data: PageData;
	style: React.CSSProperties;
}> = memo(function PDFPage({ index, data, style }) {
	const { pdfScale } = data;
	const pdfViewerContext = usePDFViewerContext();

	const [rendered, setRendered] = useState(false);

	const onRenderSuccess = useCallback<
		NonNullable<PageProps["onRenderSuccess"]>
	>(() => {
		setRendered(true);
	}, []);

	/**
	 * Custom text renderer that highlights the text based on the highlight result
	 * Needs to be extended to work with array of highlight results
	 */
	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const textRenderer = useCallback<
		NonNullable<PageProps["customTextRenderer"]>
	>(
		(textItem) => {
			for (const [
				_,
				highlightResult,
			] of pdfViewerContext.highlightResults.entries()) {
				if (!highlightResult) continue;
				const { firstSpan, lastSpan, firstSpanCharIdx, lastSpanCharIdx } =
					highlightResult;

				const spanClass = ClassifySpan({
					pageIndex: textItem.pageIndex,
					itemIndex: textItem.itemIndex,
					firstSpan,
					lastSpan,
				});

				if (spanClass === SpanClass.outside) continue;
				if (spanClass === SpanClass.left_edge) {
					return `${textItem.str.slice(0, firstSpanCharIdx)}<mark>${textItem.str.slice(
						firstSpanCharIdx,
					)}</mark>`;
				}
				if (spanClass === SpanClass.inside)
					return `<mark>${textItem.str}</mark>`;
				if (spanClass === SpanClass.right_edge) {
					return `<mark>${textItem.str.slice(0, lastSpanCharIdx)}</mark>${textItem.str.slice(
						lastSpanCharIdx,
					)}`;
				}
			}
			return textItem.str;
		},
		[toJS(pdfViewerContext.highlightResults)],
	);

	return (
		<div {...{ style }} key={index}>
			<div
				className="m-auto flex min-w-min justify-center"
				style={{
					padding: PAGE_PADDING,
				}}
			>
				{/* Used to position the loading state */}
				<div className="relative h-full max-h-max min-h-min w-full min-w-min max-w-max">
					<ReactPDFPage
						{...{ pageNumber: index + 1 }}
						{...{ scale: pdfScale }}
						renderAnnotationLayer
						onRenderSuccess={onRenderSuccess}
						className={cn(
							"overflow-hidden rounded-sm shadow-xs",
							rendered ? "opacity-100" : "opacity-0",
						)}
						customTextRenderer={textRenderer}
					/>
				</div>
			</div>
		</div>
	);
});

const PDFLoading = () => {
	return (
		<div className="flex grow items-center justify-center">
			<div className="w-48">
				<BarLoader color={"#4A5568"} loading={true} height={4} width={"100%"} />
			</div>
		</div>
	);
};

/**
 * Main PDF viewer component. Wrapped in a provider to give access to the PDFViewerContext
 */
const _PDFViewer: React.FC = observer(function _PDFViewer() {
	const pdfViewerContext = usePDFViewerContext();

	// current zoom level
	const pdfScale = pdfViewerContext.pdfScale;
	// previous zoom level
	const [prevPdfScale, setPrevPdfScale] = useState(pdfScale);

	const scrollOffset = useRef(0);
	// Setter function for the container ref
	// used instead of a standard ref because the context needs access to the ref
	// for scrolling to a page
	const setListRef = useCallback(
		(ref: VariableSizeList | null) => {
			pdfViewerContext.listRef = ref;
		},
		[pdfViewerContext],
	);

	// track container height so we can pass it to the VirtualizedList
	const { height: containerHeight, ref: containerRef } = useResizeDetector();

	// If we have highlights, we need to scroll to it without first loading the document
	// on the first page. Otherwise, we can just initialize the scroll offset to 0.
	// Null means we need to calculate the scroll offset later
	const [initialScrollOffset, setInitialScrollOffset] = useState<number | null>(
		pdfViewerContext.highlightRequests.size > 0 ? null : 0,
	);

	useEffect(
		function handleResize() {
			if (!pdfViewerContext.listRef) {
				return;
			}
			// reset the offsets used by the virtualized list
			pdfViewerContext.listRef.resetAfterIndex(0);
			if (prevPdfScale === pdfScale) return;
			pdfViewerContext.listRef.scrollTo(
				(scrollOffset.current * pdfScale) / prevPdfScale,
			);
			setPrevPdfScale(pdfScale);
		},
		[pdfScale, prevPdfScale, pdfViewerContext.listRef],
	);

	useEffect(
		function handleHighlightResults() {
			const dispose = autorun(() => {
				if (pdfViewerContext.pdf) {
					pdfViewerContext.calculateHighlightResults();
				}
			});

			return () => {
				dispose();
			};
		},
		[pdfViewerContext],
	);

	/**
	 * Sync the scroll offset for the active highlight / page index, if provided
	 */
	// biome-ignore lint/correctness/useExhaustiveDependencies: not needed for mobx
	useEffect(
		function syncScrollOffset() {
			// This runs on every change to the search params as well as the active highlight.
			const dispose = autorun(() => {
				const { searchParams } = pdfViewerContext;
				const pageIndex =
					"pageIndex" in searchParams ? searchParams.page_index : null;
				const highlightResult = pdfViewerContext.activeHighlightResult;

				if (!pdfViewerContext.pdf) return;
				if (!pageIndex && !highlightResult) return;

				// If we haven't set the initial scroll offset yet, do so now
				// We have to set the initial scroll offset this way because
				// we wait for the PDF to load after the context is initialized
				if (initialScrollOffset === null) {
					let pageToScrollTo: number;

					if (highlightResult) {
						pageToScrollTo = highlightResult.firstSpan.pageIndex;
					} else if (pageIndex) {
						pageToScrollTo = pageIndex;
					} else {
						return;
					}

					let totalHeight = 0;

					// Sum the height of all pages up to the first page of the highlight
					for (let i = 0; i < pageToScrollTo; i++) {
						const dimension = pdfViewerContext.pdf.pageDimensions.get(i);
						if (!dimension) {
							Sentry.captureMessage(
								`No dimension found for page ${i} in PDFViewer`,
							);
							return;
						}
						totalHeight += dimension[1] * pdfScale;
					}

					setInitialScrollOffset(totalHeight);
				} else {
					if (highlightResult) {
						pdfViewerContext.listRef?.scrollToItem(
							highlightResult.firstSpan.pageIndex,
						);
					} else if (pageIndex) {
						pdfViewerContext.listRef?.scrollToItem(pageIndex);
					}
				}
			});

			return () => {
				dispose();
			};
		},
		[pdfViewerContext],
	);

	/**
	 * Extract the PDF object from the PDFViewerContext so the null check works
	 */
	const pdf = pdfViewerContext.pdf;

	return (
		<div className="flex h-full w-full flex-col">
			<PDFNavbar />
			<div
				ref={containerRef}
				className="min-h-0 grow overflow-y-auto scroll-smooth bg-neutral-200 shadow-inner "
			>
				<Document
					file={pdfViewerContext.pdfBytes}
					onLoadSuccess={(document: PDFDocumentProxy) => {
						pdfViewerContext.initPdf(document);
					}}
					loading={<PDFLoading />}
					noData={<PDFLoading />}
					options={RENDERER_OPTIONS}
					className="flex h-full grow items-center justify-center"
					onItemClick={(e) => {
						if (!pdfViewerContext.listRef) {
							Sentry.captureMessage(
								"Virtualized list ref not found in PDFViewer on item click",
							);
							return;
						}
						pdfViewerContext.listRef.scrollToItem(e.pageIndex);
					}}
					// open external links in new tab
					externalLinkRel="noreferrer"
					externalLinkTarget="_blank"
				>
					<PDFSidebar />
					{/* Don't render the document until we have all page dimensions! */}
					{/* Note that we check for undefined instead of falsy because the container height is detected
						as 0 when the tab is not in view / moved around */}
					{containerHeight !== undefined &&
						pdf &&
						initialScrollOffset !== null && (
							<VariableSizeList
								ref={setListRef}
								width={"100%"}
								height={containerHeight}
								itemCount={pdf.document.numPages ?? 0}
								itemSize={(index: number) => {
									const dimension = pdf.pageDimensions.get(index);
									if (!dimension) {
										console.error("No dimension found for page", index);
										return 768;
									}
									return dimension[1] * pdfScale;
								}}
								overscanCount={2}
								className={cn("py-2 transition-opacity duration-500")}
								itemData={{
									pdfScale,
								}}
								onItemsRendered={({ visibleStopIndex }) => {
									pdfViewerContext.setCurrentPageIndex(visibleStopIndex);
								}}
								onScroll={({ scrollOffset: newScrollOffset }) => {
									scrollOffset.current = newScrollOffset;
								}}
								initialScrollOffset={initialScrollOffset}
								// This allows the list to inititate with the correct total height,
								// preventing a flicker when scrolling down
								estimatedItemSize={pdf.averagePageHeight * pdfScale}
							>
								{PDFPage}
							</VariableSizeList>
						)}
				</Document>
			</div>
		</div>
	);
});

/**
 * Main entry point for rendering PDF documents.
 *
 * @param {Object} props - Component props
 * @param {PdfSearchParams} props.searchParams - Parameters for searching within the PDF, including page index or highlight requests
 * @param {() => Promise<Uint8Array>} props.loader - Function that loads and returns the PDF binary data
 * @param {() => Promise<TocSection[]>} props.tocLoader - Function that loads and returns the table of contents sections
 * @param {PdfHeaderMetadata} props.headerMetadata - Metadata about the PDF document (title, authors, URL)
 * @param {string} props.documentId - Unique identifier for the document, used as a key for the provider to force remounts when the document is changed (i.e. switching between documents in the same tab.)
 */
export const PDFViewer: React.FC<{
	searchParams: PdfSearchParams;
	loader: () => Promise<Uint8Array>;
	tocLoader: () => Promise<TocSection[]>;
	headerMetadata: PdfHeaderMetadata;
	documentId: string;
}> = ({ searchParams, loader, tocLoader, headerMetadata, documentId }) => {
	return (
		<PDFViewerProvider
			key={documentId}
			{...{
				searchParams,
				loader,
				tocLoader,
				headerMetadata,
			}}
		>
			<_PDFViewer />
		</PDFViewerProvider>
	);
};
