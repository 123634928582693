import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";
import { DotsThree } from "@phosphor-icons/react";
import type { LinkProps } from "@tanstack/react-router";
import { useNavigate } from "@tanstack/react-router";
import { observer } from "mobx-react-lite";

/**
 * A popover component that displays an input field for direct page navigation.
 *
 * @param props.totalPages - The total number of pages available
 * @param props.defaultPageNumber - The default page number to display in the input
 * @param props.getPageLinkProps - Function that returns link props for a given page index
 */
const EllipsisPopover = (props: {
	totalPages: number;
	defaultPageNumber: number;
	getPageLinkProps: (pageIdx: number) => LinkProps;
}) => {
	const navigate = useNavigate();

	return (
		<Popover>
			<PopoverTrigger className="flex cursor-pointer items-center justify-center rounded px-0.5 py-1 text-center hover:bg-neutral-100">
				<DotsThree weight="bold" className="h-full" />
			</PopoverTrigger>
			<PopoverContent className="max-w-max p-1">
				<input
					className="w-12 rounded border border-neutral-200 p-1 text-center"
					type="number"
					defaultValue={props.defaultPageNumber}
					onBlur={(e) => {
						navigate(
							props.getPageLinkProps(
								Math.max(
									1,
									Math.min(Number.parseInt(e.target.value), props.totalPages),
								),
							),
						);
					}}
				/>
			</PopoverContent>
		</Popover>
	);
};

/**
 * A link component for pagination that can display either a page number or an icon.
 *
 * @param props.pageIdx - The zero-based index of the page this link points to
 * @param props.icon - Optional icon to display instead of the page number
 * @param props.getLinkProps - Function that returns link props for a given page index
 *
 * @remarks
 * This is a button rather than a link because when rendering embedded tables,
 * we do not want to navigate the entire tab to a new page, but rather only the
 * iframe.
 */
export const PageLink = observer(function PageLinkComponent(props: {
	pageIdx: number;
	icon?: React.ReactNode;
	getLinkProps: (pageIdx: number) => LinkProps;
}) {
	const { pageIdx, icon, getLinkProps } = props;
	const navigate = useNavigate();

	return (
		<button
			type="button"
			onClick={() => {
				navigate(getLinkProps(pageIdx));
			}}
			className="flex cursor-pointer items-center justify-center rounded py-0.5 text-center hover:bg-neutral-100"
			style={{
				width: `${pageIdx.toString().length + 1.5}ch`,
			}}
		>
			{icon ?? (pageIdx + 1).toLocaleString()}
		</button>
	);
});

/**
 * General pagination component for tables that displays links to navigate between pages.
 * Shows first and last pages, the current page, and contextual navigation options.
 *
 * @param props.totalPages - The total number of pages available
 * @param props.pageIdx - The zero-based index of the current page
 * @param props.getPageLinkProps - Function that returns link props for a given page index
 */
export const TablePaginator = observer(function TablePaginator(props: {
	totalPages: number;
	pageIdx: number;
	getPageLinkProps: (pageIdx: number) => LinkProps;
}) {
	const { totalPages, pageIdx, getPageLinkProps } = props;

	if (totalPages <= 1) {
		return null;
	}

	return (
		<div className="flex items-stretch justify-center gap-0.5 text-neutral-700 text-sm">
			{pageIdx > 0 ? (
				<PageLink
					pageIdx={pageIdx - 1}
					getLinkProps={getPageLinkProps}
					icon={<CaretLeft className="h-full" weight="bold" />}
				/>
			) : null}

			{/* Link to the first page, unless we're already on it */}
			{pageIdx > 0 ? (
				<PageLink pageIdx={0} getLinkProps={getPageLinkProps} />
			) : null}

			{/* If we're on the third page, show a link to the second page */}
			{pageIdx === 2 ? (
				<PageLink pageIdx={1} getLinkProps={getPageLinkProps} />
			) : null}

			{/* If we're past that, show an ellipsis */}
			{pageIdx > 2 ? (
				<EllipsisPopover
					totalPages={totalPages}
					defaultPageNumber={Math.floor(pageIdx / 2) + 1}
					getPageLinkProps={getPageLinkProps}
				/>
			) : null}

			{/* Indicate the current page */}
			<div
				className="flex items-center justify-center rounded bg-neutral-100 py-0.5 text-center"
				style={{
					width: `${(pageIdx + 1).toString().length + 1.5}ch`,
				}}
			>
				{(pageIdx + 1).toLocaleString()}
			</div>

			{/* If we're before the second to last page, show an ellipsis */}
			{pageIdx < totalPages - 3 ? (
				<EllipsisPopover
					totalPages={totalPages}
					defaultPageNumber={
						pageIdx + Math.floor((totalPages - 1 - pageIdx) / 2) + 1
					}
					getPageLinkProps={getPageLinkProps}
				/>
			) : null}

			{/* If we're on the third to last page, show a link to the second to last page */}
			{pageIdx === totalPages - 3 ? (
				<PageLink pageIdx={totalPages - 2} getLinkProps={getPageLinkProps} />
			) : null}

			{/* Link to the last page, unless we're already on it */}
			{pageIdx < totalPages - 1 ? (
				<PageLink pageIdx={totalPages - 1} getLinkProps={getPageLinkProps} />
			) : null}

			{pageIdx < totalPages - 1 ? (
				<PageLink
					pageIdx={pageIdx + 1}
					getLinkProps={getPageLinkProps}
					icon={<CaretRight className="h-full" weight="bold" />}
				/>
			) : null}
		</div>
	);
});
