import type { AppState } from "@/contexts/app-context/app-context";
import { SearchIndexTabState } from "@/contexts/search/tab-state";
import type { BaseTabState } from "@/contexts/tabs/base-tab-state";
import type { LinkTarget } from "@/contexts/tabs/router-types";
import { createTabId } from "@/lib/id-generators";
import { resourceRefToLinkProps } from "@/lib/paths";
import { routeTree } from "@/pages/routeTree.gen";
import type {
	AssistantSessionId,
	Tab as AssistantTab,
	ResourceLink,
	ResourceRef,
	TabId,
} from "@api/schemas";
import { Question, SpinnerGap } from "@phosphor-icons/react";
import { parseHref } from "@tanstack/history";
import {
	type LinkProps,
	type ParsedLocation,
	createMemoryHistory,
	createRouter,
} from "@tanstack/react-router";
import type {
	AddPanelPositionOptions,
	DockviewApi,
	DockviewGroupDropLocation,
	DockviewGroupPanel,
	Position,
	TabDragEvent,
	WillShowOverlayLocationEvent,
} from "dockview-react";
import { makeAutoObservable, reaction, runInAction } from "mobx";

// top level, just for type safety
const memoryHistory = createMemoryHistory();
// Register the router instance for type safety
const router = createRouter({
	routeTree,
	history: memoryHistory,
	context: {
		// biome-ignore lint/style/noNonNullAssertion: <explanation>
		tab: undefined!,
	},
});

declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router;
	}
}

export class Tab {
	tabStore: TabStore;
	id: TabId;
	router: typeof router;
	state: BaseTabState;

	// Used to track the "active path" between all tabs
	parsedLocation: ParsedLocation;

	constructor(props: {
		tabsState: TabStore;
		initialLocation: LinkProps;
		tabId?: TabId;
	}) {
		makeAutoObservable(this);
		this.id = props.tabId ?? createTabId();
		this.tabStore = props.tabsState;
		// This should immediately get overwritten by the router navigate
		// loader
		this.state = new SearchIndexTabState(this);
		const memoryHistory = createMemoryHistory({
			initialEntries: [this.tabStore.linkPropsToHref(props.initialLocation)],
		});
		this.router = createRouter({
			routeTree,
			history: memoryHistory,
			context: {
				tab: this,
			},
			scrollRestoration: true,
			getScrollRestorationKey: (location) => {
				return location.href;
			},
		});
		this.parsedLocation = this.router.latestLocation;
		this.router.subscribe("onResolved", ({ toLocation }) => {
			runInAction(() => {
				this.parsedLocation = toLocation;
			});
		});
	}

	setState(state: BaseTabState) {
		this.state = state;
	}
}

interface TrackedAssistantSession {
	assistantSessionId: AssistantSessionId;
	group: DockviewGroupPanel;
	tabs: Map<TabId, Tab>;
	dispose: () => void;
}

export class TabStore {
	appState: AppState;

	/**
	 * Flat map of all tags in the store and their contained state
	 */
	tabs: Map<TabId, Tab> = new Map();
	activeTab: Tab | null = null;
	trackedAssistantSessions: Map<AssistantSessionId, TrackedAssistantSession> =
		new Map();

	private _dockviewApi: DockviewApi | null = null;
	// See getTabHead
	private _headCache: Map<string, { head: BaseTabState["head"] }> = new Map();

	constructor(appState: AppState) {
		makeAutoObservable(
			this,
			{},
			{
				autoBind: true,
			},
		);
		this.appState = appState;
	}

	get dockviewApi() {
		if (!this._dockviewApi) {
			throw new Error("Dockview API not initialized");
		}
		return this._dockviewApi;
	}

	initLayout(api: DockviewApi) {
		this._dockviewApi = api;

		// Prevent dragging assistant session tabs. Wondering if Assistant
		// sessions should be moved into a separate dockview entirely.
		this._dockviewApi.onWillDragPanel((event: TabDragEvent) => {
			const maybeAssistantSessionId = event.panel.group.params
				?.assistantSessionId as AssistantSessionId | undefined;
			if (
				maybeAssistantSessionId &&
				this.trackedAssistantSessions.has(maybeAssistantSessionId)
			) {
				event.nativeEvent.preventDefault();
			}
		});

		// When dragging groups, we need

		/**
		 * Determines whether the event will move the group as a whole. We only
		 * want to allow these moves when moving a tracked assistant group.
		 *
		 * Edge dropping should be impossible (see tabs.tsx)
		 */
		function dropMovesWholeGroup({
			kind,
			position,
		}: {
			kind: DockviewGroupDropLocation;
			position: Position;
		}) {
			if ((kind === "content" && position !== "center") || kind === "edge") {
				return true;
			}
			return false;
		}

		// Prevent dragging an assistant group onto another group
		// Assistant groups can be dragged to different positions though
		this._dockviewApi.onWillShowOverlay(
			(event: WillShowOverlayLocationEvent) => {
				const draggingGroupId = event.getData()?.groupId;
				if (!draggingGroupId) {
					console.error("No dragging group id found");
					return;
				}
				const maybeAssistantSessionId = event.api.getGroup(draggingGroupId)
					?.params?.assistantSessionId as AssistantSessionId | undefined;
				if (maybeAssistantSessionId && !dropMovesWholeGroup(event)) {
					event.preventDefault();
				}
			},
		);

		this._dockviewApi.onDidActivePanelChange((event) => {
			if (event === undefined) {
				this.activeTab = null;
				return;
			}
			const tabId = event.id as TabId;
			const tab = this.tabs.get(tabId);
			if (!tab) {
				console.error("Tab not found", tabId);
				return;
			}
			this.activeTab = tab;
		});

		this.tabs.clear();
		// Default layout: one group, one tab with the search tab
		// TODO(John): save and restore layout from last session
		// To do this, we can use the params field to store the path of each tab and serialize it
		// (with updateParameters)
		this.addTab({
			initialLocation: {
				to: "/search",
			},
		});
	}

	createTab = ({ initialLocation }: { initialLocation: LinkProps }): Tab => {
		const newTab = new Tab({
			tabsState: this,
			initialLocation,
		});
		return newTab;
	};

	addTab = ({
		initialLocation,
		position,
	}: { initialLocation: LinkProps; position?: AddPanelPositionOptions }) => {
		const newTab = new Tab({
			tabsState: this,
			initialLocation,
		});
		this.tabs.set(newTab.id, newTab);
		this.dockviewApi.addPanel({
			id: newTab.id,
			component: "main",
			position,
		});
	};

	/**
	 * TODO(John): could take in the panel object instead of tabId if we store
	 * the panel within the component
	 */
	closeTab = (tabId: TabId) => {
		const tab = this.tabs.get(tabId);
		if (!tab) throw new Error("Tab not found");
		const panel = this.dockviewApi.getPanel(tabId);
		if (!panel) throw new Error("Panel not found");

		this.tabs.delete(tabId);
		this.dockviewApi.removePanel(panel);

		// If you close a tab and there are no tabs left, you should create a
		// new tab in a new group
		if (this.tabs.size === 0) {
			this.addTab({
				initialLocation: {
					to: "/search",
				},
				// Open to left so it always creates a new group
				// Don't want it to add within an assistant group
				position: {
					direction: "left",
				},
			});
		}
	};

	closeGroup = (group: DockviewGroupPanel) => {
		const panelsToClose = [...group.panels];
		for (const panel of panelsToClose) {
			this.closeTab(panel.id as TabId);
		}
	};

	get activeTabLocation(): ParsedLocation | null {
		const activeTab = this.activeTab;
		if (!activeTab) return null;

		// make this reactive to routerState changes
		const parsedLocation = activeTab.parsedLocation;
		return parsedLocation;
	}

	get allTabLocations(): ParsedLocation[] {
		return Array.from(this.tabs.values()).map((tab) => tab.parsedLocation);
	}

	/**
	 * Serializes a link props object into an href string.
	 */
	linkPropsToHref(linkProps: LinkProps): string {
		// LinkProps with hrefs will be converted the root at / with
		// router.buildLocation, so return them directly
		if (linkProps.href) {
			return linkProps.href;
		}

		return router.buildLocation(linkProps).href;
	}

	/**
	 * Gets the head for a tab and caches the result.
	 *
	 * The caching is not necessarily for speed, though it helps, and more for
	 * reactivity. We need this function to include a read to a stable
	 * observable (I tried creating an observable.box within the function, but
	 * it doesn't work because the observable gets destroyed and recreated on
	 * every rerender). We use the _headCache to store any heads we've already
	 * loaded.
	 *
	 * We cache the tabState instead of the head so that we can keep it
	 * reactive to changes in the head; we need the property access to happen
	 * in the function body.
	 */
	getTabHead(linkProps: LinkProps): BaseTabState["head"] {
		const href = this.linkPropsToHref(linkProps);

		const cachedTabState = this._headCache.get(href);
		if (cachedTabState) return cachedTabState.head;

		this._headCache.set(href, {
			head: {
				icon: SpinnerGap,
				label: "Loading...",
			},
		});

		const loadHead = async () => {
			try {
				// For some reason, passing in the href into "to" causes
				// Tanstack router to consider search params as part of the
				// last path param segment

				// Create a mock tab to route to the href. May want to explore
				// making this a singleton if we run into performance issues
				// with router creation.

				// Note that sharing a single shadowTab across multiple loadHead
				// calls will also run into issues with tabs that reuse state, such
				// as the feed channel tab (different feed items inside a feed channel
				// will reuse the same tab state).
				const shadowTab = new Tab({
					tabsState: this,
					initialLocation: {
						to: "/search",
					},
				});

				const { pathname, search } = parseHref(href, undefined);
				// biome-ignore lint/style/noNonNullAssertion: Tanstack fills in a default parseSearch if you don't pass in a custom one
				const searchParams = router.options.parseSearch!(search);
				const matches = await shadowTab.router.preloadRoute({
					to: pathname,
					search: searchParams,
				});
				const match = matches?.at(-1);
				if (!match) throw new Error(`No match found for ${href}`);

				// Need to get the match again because loaderData seems to not be
				// available on the first match
				const loadedMatch = shadowTab.router.getMatch(match.id);
				const loaderData = loadedMatch?.loaderData;
				if (!loaderData) {
					throw new Error(
						`No loader data found for ${href}! This could be because loading the tabState failed.`,
					);
				}
				runInAction(() => {
					this._headCache.set(href, loaderData.tabState);
				});
			} catch (error) {
				runInAction(() => {
					this._headCache.set(href, {
						head: {
							icon: Question,
							label: "Unknown",
						},
					});
				});
			}
		};
		loadHead();

		// biome-ignore lint/style/noNonNullAssertion: <explanation>
		return this._headCache.get(href)!.head;
	}

	/**
	 * Serializes a resource ref into an href string.
	 */
	resourceRefToHref(resourceRef: ResourceRef): ResourceLink {
		const link = this.linkPropsToHref(
			resourceRefToLinkProps(resourceRef),
		) as ResourceLink;
		return link;
	}

	/**
	 * Convenience function to get the head for a tab that is associated with
	 * a resource.
	 */
	getResourceRefTabHead(resourceRef: ResourceRef): BaseTabState["head"] {
		return this.getTabHead(resourceRefToLinkProps(resourceRef));
	}

	/**
	 * Handles navigation when clicks happen outside of a tab (e.g. one of the
	 * sidebars).
	 */
	openLink(location: LinkProps, target: LinkTarget) {
		switch (target) {
			case "self":
				if (this.activeTab) {
					this.activeTab.router.navigate(location);
				} else {
					this.#openLinkInNewTab(location);
				}
				break;
			case "new-tab":
				this.#openLinkInNewTab(location);
				break;
			case "new-pane":
				this.#openLinkInNewTabset(location);
				break;
		}
	}

	getAssistantSessionId(group: DockviewGroupPanel) {
		return group.params?.assistantSessionId as AssistantSessionId | undefined;
	}

	getTrackedSession(
		group: DockviewGroupPanel,
	): TrackedAssistantSession | undefined {
		const assistantSessionId = this.getAssistantSessionId(group);
		if (!assistantSessionId) return undefined;
		return this.trackedAssistantSessions.get(assistantSessionId);
	}

	/**
	 * Creates a new tab in a user tabset.
	 * If no user tabset exists, creates one.
	 *
	 * This is a private method; to open a link, call openLink instead.
	 */
	#openLinkInNewTab = (initialLocation: LinkProps) => {
		const activeGroup = this.dockviewApi.activeGroup;
		if (
			activeGroup !== undefined &&
			this.getTrackedSession(activeGroup) === undefined
		) {
			this.addTab({
				initialLocation,
				position: {
					referenceGroup: activeGroup,
				},
			});
		} else {
			// Find the first user tabset
			const userTabset = this.dockviewApi.groups.find(
				(group) => this.getTrackedSession(group) === undefined,
			);
			if (!userTabset)
				throw new Error("No user tabset found. Should be impossible.");
			this.addTab({
				initialLocation,
				position: {
					referenceGroup: userTabset,
				},
			});
		}
	};

	/**
	 * Creates a new tab in a new pane to the right of the active tabset.
	 *
	 * This is a private method; to open a link, call openLink instead.
	 */
	#openLinkInNewTabset = (initialLocation: LinkProps) => {
		const newTabSet = this.dockviewApi.addGroup({ direction: "right" });
		this.addTab({
			initialLocation,
			position: {
				referenceGroup: newTabSet,
			},
		});
	};

	#addAssistantTab = (
		assistantTab: AssistantTab,
		tabsMap: Map<TabId, Tab>,
		group: DockviewGroupPanel,
	) => {
		const newTab = new Tab({
			tabId: assistantTab.tab_id,
			tabsState: this,
			initialLocation: { href: assistantTab.path },
		});
		tabsMap.set(assistantTab.tab_id, newTab);
		this.dockviewApi.addPanel({
			id: newTab.id,
			component: "main",
			position: { referenceGroup: group },
		});
	};

	#updateAssistantTab = (
		assistantTab: AssistantTab,
		tabsMap: Map<TabId, Tab>,
	) => {
		const tab = tabsMap.get(assistantTab.tab_id);
		if (!tab) throw new Error("Tab not found");
		tab.router.navigate({ href: assistantTab.path });
	};

	#closeAssistantTab = (tabId: TabId, tabsMap: Map<TabId, Tab>) => {
		const panel = this.dockviewApi.getPanel(tabId);
		if (!panel) throw new Error("Panel not found");
		this.dockviewApi.removePanel(panel);
		tabsMap.delete(tabId);
	};

	#syncAssistantTabs = (
		openTabs: AssistantTab[],
		tabsMap: Map<TabId, Tab>,
		group: DockviewGroupPanel,
	) => {
		// Handle removed tabs
		const openTabIds = new Set(openTabs.map((tab) => tab.tab_id));
		for (const tabId of tabsMap.keys()) {
			if (!openTabIds.has(tabId)) {
				this.#closeAssistantTab(tabId, tabsMap);
			}
		}

		// Handle added/updated tabs
		for (const assistantTab of openTabs) {
			const existingTab = tabsMap.get(assistantTab.tab_id);
			if (!existingTab) {
				this.#addAssistantTab(assistantTab, tabsMap, group);
			} else if (existingTab.parsedLocation?.href !== assistantTab.path) {
				this.#updateAssistantTab(assistantTab, tabsMap);
			}
		}
	};

	/**
	 * Tracks tabs for a given assistant session
	 */
	startTrackingSession = (assistantSessionId: AssistantSessionId) => {
		if (this.trackedAssistantSessions.has(assistantSessionId)) {
			console.log("Assistant session already tracked", assistantSessionId);
			return;
		}
		const tabsMap = new Map<TabId, Tab>();
		// The addGroup implementation is wonky and you can pass in an ID but
		// it won't be used. We'll have to use the params field to store the
		// ID.
		const newTabSet = this.dockviewApi.addGroup({
			direction: "right",
		});
		newTabSet.update({
			params: {
				assistantSessionId: assistantSessionId,
			},
		});
		// Disable dragging tabs into this group
		newTabSet.locked = true;

		// Typically, reactions shouldn't be used to update observables, but
		// Tabs are a bit weird in that they have a stateful router that needs
		// to be navigated. We'll use a reaction to control the dependencies
		const dispose = reaction(
			() => {
				const assistantSession = this.appState.assistantSessionStore
					.getById(assistantSessionId)
					.unwrapOr(null);
				if (!assistantSession) throw new Error("Assistant session not found");
				const trackedSession =
					this.trackedAssistantSessions.get(assistantSessionId);
				if (!trackedSession) throw new Error("Tracked session not found");
				return {
					openTabs: assistantSession.open_tabs,
					tabs: trackedSession.tabs,
					group: trackedSession.group,
				};
			},
			(value) => {
				this.#syncAssistantTabs(value.openTabs, value.tabs, value.group);
			},
		);

		// Initial sync
		const assistantSession = this.appState.assistantSessionStore
			.getById(assistantSessionId)
			.unwrapOr(null);
		if (!assistantSession) throw new Error("Assistant session not found");
		this.#syncAssistantTabs(assistantSession.open_tabs, tabsMap, newTabSet);

		this.trackedAssistantSessions.set(assistantSessionId, {
			assistantSessionId,
			tabs: tabsMap,
			group: newTabSet,
			dispose,
		});
	};

	stopTrackingSession = (assistantSessionId: AssistantSessionId) => {
		const trackedSession =
			this.trackedAssistantSessions.get(assistantSessionId);
		if (!trackedSession) throw new Error("Assistant session not found");
		trackedSession.dispose();
		trackedSession.group.api.close();
		this.trackedAssistantSessions.delete(assistantSessionId);
	};
}
