import { TextEditor } from "@/components/editor";
import { AssistantPresenceIcon } from "@/components/layout/right-sidebar/assistant-presence-icon";
import { InteractionEventComponent } from "@/components/layout/right-sidebar/interaction-event-component";
import { SpanComponent } from "@/components/layout/right-sidebar/spans";
import {
	useAssistantSessionStore,
	useSpanStore,
} from "@/contexts/app-context/db-store/db-store-hooks";
import {
	ResourceLinkComponent,
	ResourceLinkContent,
} from "@/plugins/resource-link";
import type {
	AssistantContent,
	AssistantSessionId,
	CompletedForkStepState,
	CompletedInteractStepState,
	ErroredInteractStepState,
	InteractionEvent,
	RespondingStepState,
	RunningForkStepState,
	RunningInteractStepState,
	Span,
	StepId,
	Tab,
} from "@api/schemas";
import {
	AppWindow,
	ArrowsClockwise,
	Power,
	Spinner,
	TreeView,
	WarningOctagon,
} from "@phosphor-icons/react";
import { observer } from "mobx-react-lite";
import { Result } from "neverthrow";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

/**
 * Base component for the current activity of an assistant session.
 *
 * For each live session, we display what it's currently doing. The options
 * are:
 * - Thinking: from the start of a step to the end of the LLM response
 * - One of the following actions:
 *   - Interacting
 *   - Forking
 *   - Ending
 *
 * TODO(John): maybe this shouldn't be collapsible and just default open
 */
const CurrentActivityComponent = observer(function CurrentActivityCollapsible({
	icon,
	label,
	children,
}: {
	icon: React.ReactNode;
	label: string;
	children?: React.ReactNode;
}) {
	return (
		<div className="relative flex flex-col font-mono">
			<div className="absolute top-0 bottom-0 left-2.5 border-l border-dashed" />
			<div className="group flex items-start gap-2 text-neutral-700 text-xs">
				<div className="-mt-0.5 z-10 flex h-5 w-5 flex-none items-center justify-center bg-neutral-100">
					{icon}
				</div>
				<span>{label}</span>
			</div>
			<div className="ml-2.5">{children}</div>
		</div>
	);
});

type InteractStepEvent =
	| {
			type: "span";
			span: Span;
	  }
	| {
			type: "interaction";
			interactionEvent: InteractionEvent;
	  };

function getInteractStepEventTime(event: InteractStepEvent): string {
	switch (event.type) {
		case "span":
			return event.span.start_time;
		case "interaction":
			return event.interactionEvent.timestamp;
	}
}

const InteractingActivity = observer(function InteractingActivity({
	stepId,
	stepState,
}: {
	stepId: StepId;
	stepState:
		| RunningInteractStepState
		| ErroredInteractStepState
		| CompletedInteractStepState;
}) {
	const spanStore = useSpanStore();
	const spans = spanStore.getForStep(stepId);
	const events: InteractStepEvent[] = [
		...stepState.completed_events.map((interactionEvent) => ({
			type: "interaction" as const,
			interactionEvent,
		})),
		...spans.map((span) => ({
			type: "span" as const,
			span,
		})),
	].sort((a, b) => {
		return (
			new Date(getInteractStepEventTime(a)).getTime() -
			new Date(getInteractStepEventTime(b)).getTime()
		);
	});

	return (
		<CurrentActivityComponent icon={<AppWindow />} label={stepState.status}>
			<div className="-ml-1.5 mt-1">
				{events.map((event) => {
					switch (event.type) {
						case "span":
							return (
								<SpanComponent key={event.span.span_id} span={event.span} />
							);
						case "interaction":
							return (
								<InteractionEventComponent
									key={event.interactionEvent.timestamp}
									interactionEvent={event.interactionEvent}
								/>
							);
					}
				})}
			</div>
		</CurrentActivityComponent>
	);
});

const ForkingActivity = observer(function ForkingActivity({
	stepState,
}: {
	stepState: RunningForkStepState | CompletedForkStepState;
}) {
	const assistantSessionStore = useAssistantSessionStore();
	const forkedSessions = Result.combine(
		stepState.forked_sessions.map((assistantSessionId) =>
			assistantSessionStore.getById(assistantSessionId),
		),
	).unwrapOr([]);
	return (
		<CurrentActivityComponent icon={<TreeView />} label={stepState.status}>
			<div className="-ml-6 mt-1">
				{forkedSessions.map((assistantSession) => (
					<LiveSessionCard
						key={assistantSession.assistant_session_id}
						assistantSessionId={assistantSession.assistant_session_id}
					/>
				))}
			</div>
		</CurrentActivityComponent>
	);
});

function getThinkingFromRawResponse(
	rawResponse: AssistantContent,
): string | null {
	const thinkingBlock = rawResponse.find((block) => block.type === "thinking");
	if (thinkingBlock === undefined) {
		return null;
	}
	return thinkingBlock.text;
}

const ThinkingActivity = observer(function ThinkingActivity({
	respondingState,
}: {
	respondingState: RespondingStepState;
}) {
	const thinking =
		getThinkingFromRawResponse(respondingState.raw_response) ??
		"Waiting for response...";
	const thinkingDivRef = useRef<HTMLDivElement>(null);
	const isAtBottomRef = useRef(true);

	// Use a layout effect to scroll after DOM updates but before browser paint
	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useLayoutEffect(() => {
		if (thinkingDivRef.current === null) return;
		if (isAtBottomRef.current) {
			thinkingDivRef.current.scrollTop = thinkingDivRef.current.scrollHeight;
		}
	}, [thinking]);

	// Update isAtBottom whenever we scroll
	useEffect(() => {
		if (thinkingDivRef.current === null) return;
		const div = thinkingDivRef.current;
		const updateIsAtBottom = () => {
			isAtBottomRef.current =
				div.scrollHeight - div.clientHeight <= div.scrollTop + 1;
		};

		updateIsAtBottom();
		div.addEventListener("scroll", updateIsAtBottom);
		return () => {
			div.removeEventListener("scroll", updateIsAtBottom);
		};
	}, []);

	return (
		<CurrentActivityComponent
			icon={<Spinner className="animate-spin" />}
			label="Thinking..."
		>
			<div
				ref={thinkingDivRef}
				className="mx-4.5 mt-2 h-24 overflow-y-auto text-neutral-500 text-xs"
			>
				{thinking}
			</div>
		</CurrentActivityComponent>
	);
});

const OpenTabComponent = observer(function OpenTabComponent({
	tab,
}: {
	tab: Tab;
}) {
	return (
		<ResourceLinkComponent
			linkProps={{ href: tab.path }}
			className="group inline-flex shrink-0 rounded-sm bg-neutral-50 px-1 py-0.5 hover:bg-neutral-100"
		>
			<ResourceLinkContent
				linkProps={{ href: tab.path }}
				className="p-0 font-sans text-neutral-700 text-xs group-hover:text-neutral-950"
			/>
		</ResourceLinkComponent>
	);
});

const LiveSessionCard = observer(function LiveSessionCard({
	assistantSessionId,
}: {
	assistantSessionId: AssistantSessionId;
}) {
	const assistantSessionStore = useAssistantSessionStore();
	const assistantSessionResult =
		assistantSessionStore.getById(assistantSessionId);

	if (assistantSessionResult.isErr()) {
		return (
			<div className="flex flex-col px-3 py-4">
				<span className="text-neutral-500 text-sm">Session not found</span>
			</div>
		);
	}

	const assistantSession = assistantSessionResult.value;
	const steps = assistantSessionStore.getSteps(
		assistantSession.assistant_session_id,
	);
	const currentStep = steps.at(-1);
	let currentActivity: React.ReactNode;
	if (currentStep === undefined) {
		currentActivity = (
			<ThinkingActivity
				respondingState={{
					type: "responding",
					raw_response: [],
				}}
			/>
		);
	} else {
		switch (currentStep.state.type) {
			case "responding":
				currentActivity = (
					<ThinkingActivity respondingState={currentStep.state} />
				);
				break;
			case "received_invalid_response":
				currentActivity = (
					<CurrentActivityComponent
						icon={<ArrowsClockwise className="animate-spin" />}
						label="Received invalid response. Retrying..."
					/>
				);
				break;
			case "running_interact":
			case "errored_interact":
			case "completed_interact":
				currentActivity = (
					<InteractingActivity
						stepId={currentStep.step_id}
						stepState={currentStep.state}
					/>
				);
				break;
			case "running_fork":
			case "completed_fork":
				currentActivity = <ForkingActivity stepState={currentStep.state} />;
				break;
			case "end_session":
				currentActivity = (
					<CurrentActivityComponent
						icon={<Power />}
						label={currentStep.state.status}
					/>
				);
				break;
			case "unexpected_error":
				currentActivity = (
					<CurrentActivityComponent
						icon={<WarningOctagon />}
						label="An unexpected error occurred. Try starting a new session!"
					/>
				);
				break;
			default: {
				const _exhaustiveCheck: never = currentStep.state;
				return _exhaustiveCheck;
			}
		}
	}

	return (
		<div className="flex flex-col px-3 py-4">
			<div className="mb-2 flex items-start gap-2">
				<AssistantPresenceIcon
					assistantSessionId={assistantSession.assistant_session_id}
				/>
				<TextEditor
					className="max-w-full font-medium font-sans"
					options={{
						content: assistantSession.goal,
						editorProps: {
							attributes: {
								class: "line-clamp-1",
							},
						},
						editable: false,
					}}
				/>
			</div>
			<div className="pl-7.5">
				<div className="w-full overflow-x-auto pb-2">
					<div className="flex gap-1">
						{assistantSession.open_tabs.map((tab) => (
							<OpenTabComponent key={tab.tab_id} tab={tab} />
						))}
					</div>
				</div>
				{currentActivity}
			</div>
		</div>
	);
});

export const LiveSessionsViewer = observer(function LiveSessionsViewer({
	isOpen,
}: {
	isOpen: boolean;
}) {
	const assistantSessionStore = useAssistantSessionStore();
	const rootLiveSessions = assistantSessionStore.rootLiveSessions;
	const [visibleSessionIds, setVisibleSessionIds] = useState<
		AssistantSessionId[]
	>([]);
	useEffect(() => {
		if (isOpen) {
			// When the collapsible is open, we want to keep completed sessions
			// in the view even if they're not live. So, changes to
			// liveSessions should be purely additive.
			setVisibleSessionIds((prevIds) => {
				const prevIdSet = new Set(prevIds);
				const newSessionIds = rootLiveSessions
					.filter(
						(assistantSession) =>
							!prevIdSet.has(assistantSession.assistant_session_id),
					)
					.map((session) => session.assistant_session_id);

				// Simply append new sessions to the end
				return [...prevIds, ...newSessionIds];
			});
		} else {
			setVisibleSessionIds([]);
		}
	}, [isOpen, rootLiveSessions]);

	return (
		<div className="flex h-full flex-col divide-y">
			{visibleSessionIds.length > 0 ? (
				visibleSessionIds.map((assistantSessionId) => (
					<LiveSessionCard
						key={assistantSessionId}
						assistantSessionId={assistantSessionId}
					/>
				))
			) : (
				<div className="flex h-full w-full items-center justify-center">
					<span className="text-neutral-500 text-sm">No live sessions</span>
				</div>
			)}
		</div>
	);
});
