// modified from @tanstack/zod-adapter to store the `schema` object for introspection in route comparison
import type {
	ZodTypeLike,
	zodValidatorAdapter,
	zodValidatorOptions,
} from "@tanstack/zod-adapter";
import { ZodEffects } from "zod";

export const zodValidator = <
	TOptions extends ZodTypeLike | zodValidatorOptions,
>(
	options: TOptions,
): zodValidatorAdapter<TOptions> & { schema: ZodTypeLike } => {
	const input = "input" in options ? options.input : "input";
	const output = "output" in options ? options.output : "output";
	const _input = "schema" in options ? options.schema._input : options._input;
	const _output =
		"schema" in options ? options.schema._output : options._output;
	const rawSchema = "schema" in options ? options.schema : options;
	// If rawSchema is a ZodEffects, unwrap it to get the underlying ZodObject.
	const unwrappedSchema =
		rawSchema instanceof ZodEffects ? rawSchema.sourceType() : rawSchema;
	return {
		types: {
			input: input === "output" ? _output : _input,
			output: output === "input" ? _input : _output,
		},
		schema: unwrappedSchema,
		parse: (input) =>
			"schema" in options ? options.schema.parse(input) : options.parse(input),
	};
};
