import {
	ADD_COLUMN_ID,
	sanitizeForCSSVariableName,
} from "@/components/table/utils";
import { cn } from "@/lib/utils";
import { useDroppable } from "@dnd-kit/core";
import { type Cell, type Row, flexRender } from "@tanstack/react-table";
import type { VirtualItem, Virtualizer } from "@tanstack/react-virtual";

const RowCell = <TData,>({ cell }: { cell: Cell<TData, unknown> }) => {
	const isPinned = cell.column.getIsPinned();
	return (
		<>
			{/* Marker if dragged column is hovering over this column */}
			{/* Made visible by HeaderDndContext and index.css */}
			<div
				data-drag-indicator-column-id={cell.column.id}
				className="z-10 w-0 p-0 opacity-0 ring-1 ring-blue-500"
			/>
			<div
				aria-label="td"
				className={cn(
					// a set height is required to have the cell contents use height: 100%
					// without this, the cell contents will be vertically centered
					"whitespace-break-space flex shrink-0 p-0",
					cell.column.id !== ADD_COLUMN_ID && "border-r",
					// Outline for the selection
					"focus-within:[&::after]:pointer-events-none focus-within:[&::after]:absolute focus-within:[&::after]:inset-0 focus-within:[&::after]:border-2 focus-within:[&::after]:border-blue-300 focus-within:[&::after]:content-['']",
				)}
				style={{
					width: `calc(var(--col-${sanitizeForCSSVariableName(
						cell.column.id,
					)}-size) * 1px)`,
					position: isPinned ? "sticky" : "relative",
					zIndex: isPinned ? 1 : 0,
					left: isPinned ? `${cell.column.getStart("left")}px` : 0,
				}}
			>
				{flexRender(cell.column.columnDef.cell, cell.getContext())}
			</div>
		</>
	);
};

export type TableRowProps<TData> = {
	row: Row<TData>;
	virtualRow: VirtualItem;
	rowVirtualizer: Virtualizer<HTMLDivElement, HTMLDivElement>;
};

interface BaseRowProps<TData> extends TableRowProps<TData> {
	refCallback?: (node: HTMLDivElement | null) => void;
	children?: React.ReactNode;
}

/**
 * Base component for table rows that handles common styling and structure.
 *
 * This is explicitly NOT an observer so we can avoid memoizing. This is
 * because, whenever data or a selection changes, Tanstack will cause a
 * rerender of the parent Table component. We want React to attempt to rerender
 * every row/cell; rerendering the rows should be cheap, and we can prevent the
 * more expensive re-renders of cell content by memoizing the cell components.
 */
const BaseRow = <TData,>(props: BaseRowProps<TData>) => {
	return (
		<div
			aria-label="tr"
			data-index={props.virtualRow.index} // needed for dynamic row height measurement
			ref={(node) => {
				props.rowVirtualizer.measureElement(node);
				props.refCallback?.(node);
			}}
			className={cn(
				"flex cursor-pointer items-stretch",
				props.row.getIsSelected() ? "bg-blue-50" : "",
				"group/table-row",
				props.row.index > 0 ? "border-t" : "",
			)}
			style={{
				display: "flex",
				position: "absolute",
				top: props.virtualRow.start,
				width: "100%",
			}}
		>
			{props.row.getVisibleCells().map((cell) => (
				<RowCell key={cell.id} cell={cell} />
			))}
			{props.children}
		</div>
	);
};

/**
 * Renders a non-draggable virtualized table row with dynamic height measurement.
 *
 * @template TData - The data type for the table rows.
 */
export const TableRow = <TData,>(props: TableRowProps<TData>) => {
	return (
		<BaseRow
			row={props.row}
			virtualRow={props.virtualRow}
			rowVirtualizer={props.rowVirtualizer}
		/>
	);
};

/**
 * Wraps a table row and provides drag-and-drop functionality.
 *
 * @template TData - The data type for the table rows.
 * @template TRowId - The type for the unique identifier of the table rows (extends string).
 */
export const DraggableRow = <TData, TRowId extends string>(
	props: TableRowProps<TData> & {
		overRowId: TRowId | null;
	},
) => {
	const { setNodeRef: setDropNodeRef } = useDroppable({
		id: props.row.id,
	});
	const isDraggedOver = props.overRowId === props.row.id;
	const isDragPreview = props.virtualRow === null;

	return (
		<BaseRow
			row={props.row}
			virtualRow={props.virtualRow}
			rowVirtualizer={props.rowVirtualizer}
			refCallback={(ref) => {
				setDropNodeRef(ref);
			}}
		>
			{/* Marker if dragged row is hovering over this row */}
			{isDraggedOver && !isDragPreview ? (
				<RowDropIndicator rowId={props.row.id} />
			) : null}
		</BaseRow>
	);
};

/**
 * Renders a semi-transparent preview of a table row during drag operations.
 *
 * @template TData - The data type for the table rows.
 * @template TRowId - The type for the unique identifier of the table rows (extends string).
 */
export const DraggedRowPreview = <TData,>(props: {
	row: Row<TData>;
}) => {
	return (
		<div
			aria-label="tr"
			className={cn(
				"opacity-50",
				"flex cursor-pointer items-stretch",
				props.row.getIsSelected() ? "bg-blue-50" : "",
				"group/table-row",
			)}
			style={{
				display: "flex",
				position: "relative",
				width: "100%",
			}}
		>
			{props.row.getVisibleCells().map((cell) => (
				<RowCell key={cell.id} cell={cell} />
			))}
		</div>
	);
};

export const RowDropIndicator = ({ rowId }: { rowId: string }) => {
	return (
		<div
			key={`row-drop-indicator-${rowId}`}
			className="absolute z-50 h-0 w-full p-0 ring-1 ring-blue-500"
		/>
	);
};
