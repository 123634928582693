import type { SearchParams } from "@/contexts/search/stores/search-store";
import { BaseTabState } from "@/contexts/tabs/base-tab-state";
import type { Tab } from "@/contexts/tabs/tabs-context";
import { makeAutoObservableAbstract } from "@/lib/make-auto-observable-abstract";
import type {
	EdgarDocumentWithFiling,
	FeedItemResource,
	ResourceResult,
	SearchFull,
	SearchId,
	UploadResource,
	WebpageResource,
} from "@api/schemas";
import { FileMagnifyingGlass } from "@phosphor-icons/react";
import * as Sentry from "@sentry/react";
import { makeAutoObservable } from "mobx";
import { type Result, err, ok } from "neverthrow";

// TODO(John): steal from server
export interface EnrichedResourceResult {
	resource:
		| FeedItemResource
		| UploadResource
		| WebpageResource
		| EdgarDocumentWithFiling;
	result: ResourceResult;
}

class SearchForm {
	tab: Tab;
	config: SearchParams;
	// UI components
	showCommandList = false;
	searchInputElement: HTMLInputElement | null = null;

	constructor(tab: Tab, config: SearchParams) {
		makeAutoObservable(this);
		this.tab = tab;
		this.config = config;
	}

	setQuery(query: string) {
		this.config.query = query;
	}

	setShowCommandList(show: boolean) {
		this.showCommandList = show;
	}

	handleSearch() {
		this.tab.tabStore.appState.searchStore.initiateSearch({
			config: this.config,
			onLocalSuccess: (resource) => {
				this.tab.router.navigate({
					to: "/search/result/$search-id",
					params: {
						"search-id": resource.search_id,
					},
				});
			},
		});
	}
}

/**
 * Tab state for the search index page. This is the state for the page that
 * shows the search form with no loaded results.
 */
export class SearchIndexTabState extends BaseTabState {
	form: SearchForm;

	constructor(tab: Tab) {
		super(tab);
		makeAutoObservableAbstract(this);
		this.form = new SearchForm(tab, {
			query: "",
			max_results: null,
		});
	}

	get head() {
		return {
			icon: FileMagnifyingGlass,
			label: "Search",
		};
	}
}

export class SearchResultsTabState extends BaseTabState {
	private resultsLoaded = false;
	form: SearchForm;
	searchId: SearchId;

	constructor(tab: Tab, searchId: SearchId) {
		super(tab);
		makeAutoObservableAbstract(this);
		this.searchId = searchId;
		this.form = new SearchForm(tab, {
			query: "",
			max_results: null,
		});
	}

	get fullResult(): {
		search: SearchFull;
		edgarDocumentMap: Map<string, EdgarDocumentWithFiling>;
	} | null {
		const fullResult = this.tab.tabStore.appState.searchStore.getFullResult(
			this.searchId,
		);
		const edgarDocuments =
			this.tab.tabStore.appState.searchStore.referencedDocuments.get(
				this.searchId,
			);
		if (fullResult === undefined || edgarDocuments?.status !== "loaded") {
			return null;
		}
		if (!this.resultsLoaded) {
			this.resultsLoaded = true;
			this.form.setQuery(fullResult.query);
		}
		const edgarDocumentMap = new Map<string, EdgarDocumentWithFiling>();
		for (const document of edgarDocuments.documents) {
			edgarDocumentMap.set(document.document_id, document);
		}
		return {
			search: fullResult,
			edgarDocumentMap,
		};
	}

	get head() {
		if (this.fullResult === null) {
			return {
				icon: FileMagnifyingGlass,
				label: "Search",
				resourceRef: undefined,
			};
		}
		return {
			icon: FileMagnifyingGlass,
			label: this.fullResult.search.query,
			resourceRef: {
				type: "search-result" as const,
				resource_id: this.searchId,
			},
		};
	}

	/**
	 * For each of the loaded search result's ResourceResults, provide the
	 * actual resource specified.
	 */
	get enrichedResults(): EnrichedResourceResult[] {
		if (this.fullResult === null) {
			return [];
		}
		const results = this.fullResult.search.results;
		const enrichedResults: EnrichedResourceResult[] = [];

		for (const result of results) {
			let resource: Result<EnrichedResourceResult["resource"], Error>;
			switch (result.resource_ref.type) {
				case "feed-item":
					resource =
						this.tab.tabStore.appState.workspace.feedItems.getResourceById(
							result.resource_ref.resource_id,
						);
					break;
				case "upload":
					resource =
						this.tab.tabStore.appState.workspace.uploads.getResourceById(
							result.resource_ref.resource_id,
						);
					break;
				case "webpage":
					resource =
						this.tab.tabStore.appState.workspace.webpages.getResourceById(
							result.resource_ref.resource_id,
						);
					break;
				case "edgar-document": {
					const maybeDocument = this.fullResult.edgarDocumentMap.get(
						result.resource_ref.resource_id,
					);
					if (maybeDocument === undefined) {
						resource = err(new Error("Edgar document not found"));
					} else {
						resource = ok(maybeDocument);
					}
					break;
				}
				default: {
					const _exhaustiveCheck: never = result.resource_ref;
					throw new Error(`Unhandled resource type: ${_exhaustiveCheck}`);
				}
			}
			if (resource.isErr()) {
				Sentry.captureException(resource.error);
				continue;
			}
			enrichedResults.push({
				result,
				resource: resource.value,
			});
		}
		return enrichedResults;
	}
}
