/**
 * Generated by orval v7.7.0 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */

export type FieldType = typeof FieldType[keyof typeof FieldType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FieldType = {
  text: 'text',
  relationship: 'relationship',
  lookup: 'lookup',
  select: 'select',
  number: 'number',
  datetime: 'datetime',
  boolean: 'boolean',
  view: 'view',
} as const;
