import type { BaseRendererProps } from "@/components/table/scalar-renderers/renderer-props";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";
import { DatetimeFormat } from "@api/schemas";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

type DatetimeRendererProps = BaseRendererProps<"timestamptz"> & {
	timeFormat: DatetimeFormat;
};

export const DatetimeRenderer = observer((props: DatetimeRendererProps) => {
	const [open, setOpen] = useState(false);

	const [newValue, setNewValue] = useState(
		// By default, dayjs support parsing ISO 8601 strings
		props.value ? dayjs(props.value) : null,
	);
	useEffect(() => {
		setNewValue(props.value ? dayjs(props.value) : null);
	}, [props.value]);

	const dateFormat =
		props.timeFormat === DatetimeFormat.hidden
			? "MM/DD/YYYY"
			: props.timeFormat === DatetimeFormat.twelve_hour
				? "MM/DD/YYYY hh:mm A"
				: "MM/DD/YYYY HH:mm";

	return (
		<Popover
			open={open}
			onOpenChange={(newOpen) => {
				setOpen(newOpen);
				if (!newOpen && newValue) {
					if (dayjs(props.value).isSame(newValue)) return;
					props.onUpdate?.(newValue ? newValue.utc().toISOString() : null);
				}
			}}
		>
			<PopoverTrigger
				disabled={!props.editable}
				className={cn(
					"flex h-full w-full min-w-0 justify-start truncate p-1 text-sm",
				)}
			>
				{/* We'll let users set a custom datetime format in the future */}
				{newValue ? newValue.local().format(dateFormat) : null}
			</PopoverTrigger>

			<PopoverContent className="w-72 p-2">
				<Calendar
					selectedDate={newValue}
					setSelectedDate={setNewValue}
					timeFormat={props.timeFormat}
				/>
				<Separator className="mt-2" />
				<Button
					onClick={() => {
						props.onUpdate?.(null);
					}}
					variant="ghost"
					className="mt-1 flex w-full cursor-pointer items-center justify-start gap-1"
				>
					Clear
				</Button>
			</PopoverContent>
		</Popover>
	);
});
