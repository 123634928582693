import { LinkTarget } from "@/contexts/tabs/router-types";
import { useTabStore } from "@/contexts/tabs/use-tab-store";
import { type LinkProps, createLink } from "@tanstack/react-router";
import React from "react";

const BaseLinkComponent = React.forwardRef<
	HTMLAnchorElement,
	React.AnchorHTMLAttributes<HTMLAnchorElement>
>((props, ref) => {
	return <a ref={ref} {...props} />;
});

const CreatedLinkComponent = createLink(BaseLinkComponent);

type TabLinkProps = LinkProps &
	React.AnchorHTMLAttributes<HTMLAnchorElement> & {
		target: LinkTarget;
		children?: React.ReactNode;
	};

/**
 * Enhanced link component that integrates with our tab management system.
 * Extends TanStack Router's Link component to support tab-aware navigation.
 *
 * @prop {('self'|'new-tab'|'new-window')} target - Navigation target:
 *   - 'self': Navigate in current tab
 *   - 'new-tab': Open in adjacent tab
 *   - 'new-pane': Open new pane (not currently implemented)
 *
 * @note Known issue: Component has a type mismatch with ref forwarding that requires suppression
 */
export const TabLink = React.forwardRef<HTMLAnchorElement, TabLinkProps>(
	({ target, ...props }, ref) => {
		const tabStore = useTabStore();

		return (
			<CreatedLinkComponent
				{...props}
				// @ts-expect-error ref is not supported
				ref={ref}
				onClick={(e) => {
					e.preventDefault();

					// Following web browser behavior, where shift-clicking
					// opens a new window, and cmd/ctrl-clicking opens a new tab
					const isShift = e.shiftKey;
					const isCmdOrCtrl = e.metaKey || e.ctrlKey;

					if (isCmdOrCtrl) {
						tabStore.openLink(props, LinkTarget.NewTab);
					} else if (isShift) {
						tabStore.openLink(props, LinkTarget.NewPane);
					} else {
						tabStore.openLink(props, LinkTarget.Self);
					}
				}}
			/>
		);
	},
);
