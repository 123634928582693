import { DataTypeIcon } from "@/components/table/data-type-indicators";
import { isFieldPrimary } from "@/contexts/tables/stores/field-store";
import { cn } from "@/lib/utils";
import {
	ArrowDown,
	ArrowUp,
	MagnifyingGlass,
	Star,
} from "@phosphor-icons/react";
import type { Column, SortDirection } from "@tanstack/react-table";
import { observer } from "mobx-react-lite";

const SortingIcon = ({
	sortDirection,
}: { sortDirection: SortDirection | false }) => {
	if (!sortDirection) {
		return null;
	}

	return (
		<span className="rounded-sm border border-blue-300 bg-blue-100 p-0.5 text-blue-500 text-md">
			{sortDirection === "asc" && <ArrowUp weight="bold" />}
			{sortDirection === "desc" && <ArrowDown weight="bold" />}
		</span>
	);
};

/**
 * A column header that represents a field in a table.
 */
export const FieldColumnHeader = observer(
	<TData, TValue>(props: {
		column: Column<TData, TValue>;
		name: string;
	}) => {
		// biome-ignore lint/style/noNonNullAssertion: columnDef.meta is always defined
		const meta = props.column.columnDef.meta!;

		const isPrimary = meta.tableMeta?.field
			? isFieldPrimary(meta.tableMeta.field)
			: false;
		const isViewField = meta.tableMeta?.field?.type === "view";
		const isLookupField = meta.tableMeta?.field?.type === "lookup";

		return (
			<span
				className={cn(
					"flex h-full w-full select-none items-center justify-between gap-2 truncate px-1.5 py-1 font-normal text-neutral-600 hover:bg-neutral-100",
					isViewField ? "bg-purple-50" : isPrimary && "bg-blue-50",
				)}
			>
				<span className="flex shrink-0 grow items-center gap-1.5 truncate">
					<DataTypeIcon dataType={meta.dataType} />
					<span className="truncate ">{props.name}</span>
				</span>
				<div className="flex items-center gap-1">
					{isPrimary && <Star />}
					{isLookupField && <MagnifyingGlass />}
					<SortingIcon sortDirection={props.column.getIsSorted()} />
				</div>
			</span>
		);
	},
);
