import { Cell } from "@/components/table/cell";
import { FieldColumnHeader } from "@/components/table/field-column-header";
import { TableComponent } from "@/components/table/table-component";
import { TablePaginator } from "@/components/table/table-paginator";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import type { QueryResultRowsItem } from "@api/schemas";
import { Spinner } from "@phosphor-icons/react";
import { getRouteApi, useNavigate } from "@tanstack/react-router";
import { createColumnHelper } from "@tanstack/react-table";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useResizeDetector } from "react-resize-detector";

const columnHelper = createColumnHelper<QueryResultRowsItem>();

export const QueryResultTable = observer(function QueryResultTable() {
	const routeApi = getRouteApi("/query/");
	const { tabState: queryState } = routeApi.useLoaderData();
	const [isCreatingNewTable, setIsCreatingNewTable] = useState(false);
	const navigate = useNavigate();

	const { height: tableContainerHeight, ref: tableContainerRef } =
		useResizeDetector();

	async function createButtonHandler() {
		if (!queryState) return;

		try {
			setIsCreatingNewTable(true);
			const newTableResource = await queryState.createComputedTable();
			if (!newTableResource) return;

			// Navigate only after we know the table has been created.
			// If we navigate before the table finishes creation, the active
			// table manager might reach an invalid state.
			navigate({
				to: "/tables/table/$table-id",
				params: {
					"table-id": newTableResource.localResult.newTableResource.table_id,
				},
			});
		} catch (e) {
			console.error(e);
		} finally {
			setIsCreatingNewTable(false);
		}
	}
	if (queryState.result.state === "loading") {
		return "Loading...";
	}

	if (queryState.result.state === "error") {
		return (
			<div className="flex h-full w-full flex-col items-center justify-center gap-2 text-center">
				Could not create computed table. Please make sure the view definition is
				valid.
			</div>
		);
	}

	if (queryState.result.state === "empty") {
		return (
			<div className="flex h-full w-full flex-col items-center justify-center gap-2 text-center">
				{queryState.result.message}
			</div>
		);
	}

	const columns = queryState.result.result.columns.map((queryColumn) => {
		return columnHelper.display({
			id: queryColumn.name,
			header: ({ column }) => (
				<FieldColumnHeader column={column} name={queryColumn.name} />
			),
			cell: observer((props) => {
				return (
					<Cell
						cellValue={props.row.original[queryColumn.name]}
						context={props}
					/>
				);
			}),
			meta: {
				editable: false,
				dataType: queryColumn.data_type,
			},
		});
	});

	return (
		<div className="relative flex h-full w-full grow flex-col">
			<section
				// Container for the table and the footer.
				className="grow overflow-y-hidden"
				ref={tableContainerRef}
			>
				{tableContainerHeight !== undefined ? (
					<TableComponent
						columns={columns}
						data={queryState.result.result.rows}
						maxHeight={tableContainerHeight}
						rowSelectionPopoverTop={0}
					/>
				) : null}
			</section>

			<div className="flex w-full items-center justify-between border-t px-2 py-1">
				<Popover>
					<PopoverTrigger className="cursor-pointer rounded px-2 py-1 text-center text-sm hover:bg-neutral-100">
						View
					</PopoverTrigger>
					<PopoverContent>
						<div className="flex items-center gap-2">
							<Label>Rows per page</Label>
							<Input
								type="number"
								className="w-18"
								defaultValue={queryState.result.result.page_size}
								onBlur={(e) => {
									if (queryState.result.state !== "success") return;
									const newPageSize = Math.min(
										Math.max(1, Number.parseInt(e.target.value)),
										1000,
									);
									if (newPageSize !== queryState.result.result.page_size) {
										navigate({
											to: "/query",
											search: (prev) => ({
												...prev,
												page_size: newPageSize,
											}),
										});
									}
								}}
							/>
						</div>
					</PopoverContent>
				</Popover>
				<TablePaginator
					totalPages={Math.ceil(
						queryState.result.result.total_rows /
							queryState.result.result.page_size,
					)}
					pageIdx={queryState.result.result.page_idx}
					getPageLinkProps={(pageIdx) => ({
						to: "/query",
						search: (prev) => ({
							...prev,
							page_idx: pageIdx,
						}),
					})}
				/>
				<span className="text-neutral-500 text-sm">
					{`${queryState.result.result.total_rows.toLocaleString()} row${
						queryState.result.result.total_rows === 1 ? "" : "s"
					}`}
				</span>
			</div>

			<div className="flex justify-end border-t bg-neutral-50 p-2">
				<Button
					onClick={createButtonHandler}
					disabled={
						// Maybe you would want to check for a valid sql query
						isCreatingNewTable &&
						(queryState.parsedQueryParams.viewMode === "sql_query"
							? true
							: !queryState.parsedQueryParams.fromTableId)
					}
					className="right-4 bottom-4 z-50 max-w-max shadow-lg"
				>
					{isCreatingNewTable ? (
						<Spinner className="animate-spin" />
					) : (
						"Create computed table"
					)}
				</Button>
			</div>
		</div>
	);
});
