/**
 * Figure out how to fix pending stuff
 */

import { API_ENDPOINT_HTTP } from "@/config";
import type { AppState } from "@/contexts/app-context/app-context";
import openapiHashes from "@/generated/openapi-hashes.json";
import { ElectricSyncedMap } from "@/lib/sync/electric";
import type { PendingResource } from "@api/schemas";
import { makeAutoObservable } from "mobx";

export class PendingResourcesStore {
	appState: AppState;
	resources: ElectricSyncedMap<PendingResource, ["pending_resource_id"]>;

	// The time at which the pending resources were last cleared. We want the
	// drawer to be open only if there are pending resources since the last
	// time the popup was dismissed.
	lastCleared: Date = new Date();

	constructor(appState: AppState) {
		makeAutoObservable(this);
		this.appState = appState;
		this.resources = new ElectricSyncedMap({
			shapeUrl: `${API_ENDPOINT_HTTP}/shapes/pending_resources`,
			shapeHash: openapiHashes.PendingResource,
			pKeyFields: ["pending_resource_id"],
			getBearerToken: () => this.appState.getTokenOrThrow(),
		});
	}

	dismissDrawer = () => {
		this.lastCleared = new Date();
	};

	/**
	 * Visible resources: either pending or completed since the last cleared
	 * time.
	 */
	get visibleResources() {
		return Array.from(this.resources.items.values()).filter((resource) => {
			if (resource.end_status === null) {
				return true;
			}
			return new Date(resource.end_status.end_time) > this.lastCleared;
		});
	}

	get pendingResources() {
		return Array.from(this.resources.items.values()).filter(
			(resource) => resource.end_status === null,
		);
	}
}
