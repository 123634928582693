import { FieldTypeIcon } from "@/components/table/data-type-indicators";
import { Button } from "@/components/ui/button";
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
	useFieldsStore,
	useTablesStore,
} from "@/contexts/app-context/db-store/db-store-hooks";
import { useTablesIndex } from "@/contexts/resource-indexes/hooks";
import { cn } from "@/lib/utils";
import type { TableId } from "@api/schemas";
import { CaretRight, PlusCircle, X } from "@phosphor-icons/react";
import { getRouteApi } from "@tanstack/react-router";
import { observer } from "mobx-react-lite";
import { useState } from "react";

const routeApi = getRouteApi("/query/");

const TableDetailsPopup = observer(function TableDetailsPopup({
	tableId,
	setSelectedTableId,
}: {
	tableId: TableId;
	setSelectedTableId: (id: TableId | null) => void;
}) {
	const tablesStore = useTablesStore();
	const table = tablesStore.getResourceById(tableId);
	const fieldStore = useFieldsStore();
	const fields = fieldStore.getFieldsByTableId(tableId);
	const { tabState: queryState } = routeApi.useLoaderData();

	const insertTableId = () => {
		if (queryState?.parsedQueryParams.viewMode === "sql_query") {
			queryState.setDraftSqlQuery(`${queryState.draftSqlQuery}${tableId}`);
		}
	};

	const insertFieldId = (fieldId: string) => {
		if (queryState?.parsedQueryParams.viewMode === "sql_query") {
			queryState.setDraftSqlQuery(`${queryState.draftSqlQuery}${fieldId}`);
		}
	};

	if (table.isErr()) {
		console.error(table.error);
		return null;
	}

	return (
		<div className="flex h-1/2 w-full shrink-0 flex-col border-t bg-white">
			<div className="flex shrink-0 items-center justify-between gap-4 p-2 text-base">
				<span className="truncate">{table.value.name}</span>
				<div className="flex items-center">
					<Button
						variant="ghost"
						size="icon"
						onClick={insertTableId}
						title="Insert table ID into SQL query"
					>
						<PlusCircle size={16} />
					</Button>
					<Button
						variant="ghost"
						size="icon"
						onClick={() => setSelectedTableId(null)}
					>
						<X size={16} />
					</Button>
				</div>
			</div>
			<div className="mx-2 w-fit shrink-0 bg-neutral-50 p-1 font-mono text-neutral-500 text-xs">
				{table.value.table_id}
			</div>
			<div className="mt-4 flex grow flex-col gap-3 overflow-y-auto p-2">
				{Array.from(fields.values()).map((field) => (
					<div key={field.field_id} className="group flex flex-col gap-1">
						<div className="flex items-center justify-between gap-2">
							<div className="flex min-w-0 items-center gap-2">
								<FieldTypeIcon fieldType={field.type} />
								<span className="truncate">{field.name}</span>
							</div>
							<Button
								variant="ghost"
								size="icon"
								className="h-5 w-5 opacity-0 transition-opacity group-hover:opacity-100"
								onClick={() => insertFieldId(field.field_id)}
								title="Insert field ID into SQL query"
							>
								<PlusCircle size={14} />
							</Button>
						</div>
						<div className="w-fit bg-neutral-50 p-1 font-mono text-neutral-500 text-xs">
							{field.field_id}
						</div>
					</div>
				))}
			</div>
		</div>
	);
});

type TableRowProps = {
	table: { table_id: TableId; name: string };
	selectedTableId: TableId | null;
	setSelectedTableId: (id: TableId | null) => void;
};

const TableRow = observer(function TableRow({
	table,
	selectedTableId,
	setSelectedTableId,
}: TableRowProps) {
	return (
		<div className="w-full">
			<div
				className={cn(
					"w-full pr-2 pl-7 hover:bg-neutral-100",
					selectedTableId === table.table_id ? "bg-neutral-100" : "",
				)}
			>
				<button
					type="button"
					className="w-full truncate py-2 text-left"
					onClick={() => {
						if (selectedTableId === table.table_id) {
							setSelectedTableId(null);
						} else {
							setSelectedTableId(table.table_id);
						}
					}}
				>
					{table.name}
				</button>
			</div>
		</div>
	);
});

type TableSectionProps = {
	title: string;
	tables: Array<{ table_id: TableId; name: string }>;
	selectedTableId: TableId | null;
	setSelectedTableId: (id: TableId | null) => void;
	defaultOpen?: boolean;
	className?: string;
};

const TableSection = observer(function TableSection({
	title,
	tables,
	selectedTableId,
	setSelectedTableId,
	defaultOpen = false,
	className = "",
}: TableSectionProps) {
	return (
		<Collapsible defaultOpen={defaultOpen} className={className}>
			<CollapsibleTrigger className="group flex w-full items-center gap-1 p-2 hover:bg-neutral-100">
				<CaretRight size={16} className="group-data-[state=open]:rotate-90" />
				<span>{title}</span>
			</CollapsibleTrigger>
			<CollapsibleContent>
				{tables.map((table) => (
					<TableRow
						key={table.table_id}
						table={table}
						selectedTableId={selectedTableId}
						setSelectedTableId={setSelectedTableId}
					/>
				))}
			</CollapsibleContent>
		</Collapsible>
	);
});

export const TableBrowserSidebar = observer(function TableBrowserSidebar() {
	const tablesIndex = useTablesIndex();
	const [selectedTableId, setSelectedTableId] = useState<TableId | null>(null);

	return (
		<div className="flex h-full w-full flex-col text-sm">
			<div className="flex h-10 items-center border-b px-3 font-medium">
				Table browser
			</div>
			<div className="flex min-h-0 grow flex-col">
				<div className="grow overflow-y-auto">
					<TableSection
						title="My Tables"
						tables={tablesIndex.userRows}
						selectedTableId={selectedTableId}
						setSelectedTableId={setSelectedTableId}
						defaultOpen={true}
					/>
					<TableSection
						title="EDGAR"
						tables={tablesIndex.edgarRows}
						selectedTableId={selectedTableId}
						setSelectedTableId={setSelectedTableId}
						defaultOpen={true}
					/>
				</div>
				{selectedTableId && (
					<TableDetailsPopup
						tableId={selectedTableId}
						setSelectedTableId={setSelectedTableId}
					/>
				)}
			</div>
		</div>
	);
});
