import { API_ENDPOINT_HTTP } from "@/config";
import type { AppState } from "@/contexts/app-context/app-context";
import { DisplayedActionError } from "@/contexts/synced-actions";
import openapiHashes from "@/generated/openapi-hashes.json";
import { createUploadId } from "@/lib/id-generators";
import { ElectricOptimisticMap } from "@/lib/sync/electric";
import {
	createUploadFromUrlRoute,
	createUploadRoute,
	getUploadPdf,
} from "@api/fastAPI";
import { downloadOriginal } from "@api/fastAPI";
import { updateUploadMetadataRoute } from "@api/fastAPI";
import { UploadMimetype } from "@api/schemas";
import type { UploadId, UploadResource } from "@api/schemas";
import { makeAutoObservable } from "mobx";
import type { Result } from "neverthrow";
import { toast } from "sonner";

export class UploadsStore {
	appState: AppState;
	map: ElectricOptimisticMap<UploadResource, ["upload_id"], "write_id">;

	constructor(appState: AppState) {
		this.appState = appState;
		this.map = new ElectricOptimisticMap({
			shapeUrl: `${API_ENDPOINT_HTTP}/shapes/uploads`,
			pKeyFields: ["upload_id"],
			writeIdField: "write_id",
			shapeHash: openapiHashes.UploadResource,
			getBearerToken: this.appState.getTokenOrThrow,
		});
		makeAutoObservable(this);
	}

	// TODO(https://linear.app/village/issue/VIL-5421/return-map-loading-state-when-resource-not-found)
	// this should reflect the map's loading state if not found, so we can render error/loading states correctly
	getResourceById(uploadId: UploadId): Result<UploadResource, Error> {
		return this.map.get(uploadId);
	}

	/**
	 * Downloads the upload (which may not have been uploaded as a PDF) as a
	 * PDF.
	 */
	downloadUploadPdf(uploadId: UploadId) {
		toast.promise(
			async () => {
				const upload = this.getResourceById(uploadId);

				if (upload.isErr()) {
					throw new Error("Upload not found");
				}
				const resp = await getUploadPdf(uploadId, {
					responseType: "blob",
				});

				const url = URL.createObjectURL(resp.data as Blob);
				const a = document.createElement("a");
				a.href = url;
				a.download = `${upload.value.name}.pdf`;
				a.click();
			},
			{
				loading: "Exporting PDF...",
				success: "PDF exported!",
				error: "Failed to export PDF",
			},
		);
	}

	/**
	 * Downloads the originally uploaded file.
	 */
	downloadOriginalUploadResource(uploadId: UploadId) {
		toast.promise(
			async () => {
				const upload = this.getResourceById(uploadId);

				if (upload.isErr()) {
					throw new Error("Upload not found");
				}
				let extension = null;
				switch (upload.value.mimetype) {
					case UploadMimetype.pdf:
						extension = "pdf";
						break;
					case UploadMimetype.epub:
						extension = "epub";
						break;
				}

				if (extension === null) {
					throw new Error("Unsupported file type");
				}

				const resp = await downloadOriginal(uploadId, {
					responseType: "blob",
				});

				const url = URL.createObjectURL(resp.data as Blob);
				const a = document.createElement("a");
				a.href = url;
				a.download = upload.value.filename;
				a.click();
			},
			{
				loading: "Retrieving original...",
				success: "Original file retrieved!",
				error: "Failed to retrieve original file",
			},
		);
	}

	/**
	 * REMOTE ACTIONS
	 */
	updateUploadMetadata = async ({
		uploadId,
		newMetadata,
	}: { uploadId: UploadId; newMetadata: Partial<UploadResource> }) => {
		const originalUpload = this.getResourceById(uploadId);
		if (originalUpload.isErr()) {
			throw new Error(`Upload with id ${uploadId} not found`);
		}
		await updateUploadMetadataRoute({
			upload_id: uploadId,
			new_upload_title: newMetadata.name ?? null,
			new_upload_subtitle: newMetadata.subtitle ?? null,
			new_upload_authors: newMetadata.authors ?? null,
			new_upload_publisher: newMetadata.publisher ?? null,
			new_upload_date_published: newMetadata.date_published ?? null,
		});
		toast.success("Upload metadata updated successfully.");
	};

	createUpload = async ({
		file,
		inferMetadata,
	}: { file: File; inferMetadata: boolean }) => {
		if (
			file.type !== "application/pdf" &&
			file.type !== "application/epub+zip"
		) {
			throw new DisplayedActionError(`Unsupported file type for ${file.name}`);
		}
		const uploadId = createUploadId();
		await createUploadRoute({
			upload_id: uploadId,
			file,
			infer_metadata: inferMetadata,
		});
	};

	createUploadFromUrl = async ({ url }: { url: string }) => {
		const uploadId = createUploadId();
		await createUploadFromUrlRoute({
			url,
			upload_id: uploadId,
		});
	};
}
