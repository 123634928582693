import { ResourceLinkComponent } from "@/plugins/resource-link";
import type { MessageId } from "@api/schemas";
import { createFileRoute } from "@tanstack/react-router";

import { AssistantPresenceIndicator } from "@/components/layout/right-sidebar/assistant-presence";
import { useMessageStore } from "@/contexts/app-context/db-store/db-store-hooks";
import { MessageTabState } from "@/contexts/messages/tab-state";
import { MessageComponent } from "@/pages/tabs/message/-components/message-component";
import { MessageInput } from "@/pages/tabs/message/-components/message-input";
import { ArrowLeft } from "@phosphor-icons/react";
import { observer } from "mobx-react-lite";

/**
 * A message with replies expanded; views a thread of messages.
 */
const ThreadViewer = observer(function ThreadViewer() {
	const { tabState } = Route.useLoaderData();
	const messageStore = useMessageStore();
	const parentMessageResult = tabState.parentMessage;
	if (parentMessageResult.isErr() || !parentMessageResult.value) {
		throw new Error(
			"Impossible: ThreadViewer without a parentMessage. Should be set by the Route loader.",
		);
	}

	const parentMessage = parentMessageResult.value;
	const threadId = parentMessage.message_id;
	const replies = messageStore.getThreadReplies(threadId);

	return (
		<>
			<div className="relative flex min-h-0 w-full grow flex-col">
				{/* Header */}
				<div className="flex w-full flex-none items-center justify-between border-neutral-100 border-b bg-white px-3 py-3">
					<div className="flex items-center gap-1">
						<ResourceLinkComponent
							linkProps={{ href: "/message" }}
							className="h-min w-min items-start p-1"
						>
							<ArrowLeft size={16} />
						</ResourceLinkComponent>
						<span className="font-semibold text-neutral-800 text-sm">
							Thread
						</span>
					</div>
					{/* Presence Indicators */}
					<AssistantPresenceIndicator
						filters={{
							threadId,
							resourceRef: null,
							goal: null,
							path: null,
						}}
					/>
				</div>
				<div className="min-h-0 w-full grow overflow-y-auto">
					{/* Main message */}
					<div className="flex w-full">
						<MessageComponent message={parentMessage} />
					</div>
					{/* Replies in thread */}
					{replies.length > 0 ? (
						<>
							<span className="inline-flex w-full items-center gap-2 px-4 py-2">
								<span className="text-neutral-500 text-xs">
									{replies.length} {replies.length === 1 ? "reply" : "replies"}
								</span>
								<span className="h-[1px] grow bg-neutral-300" />
							</span>
							<div className="flex flex-col pb-4">
								{replies.map((reply) => (
									<MessageComponent key={reply.message_id} message={reply} />
								))}
							</div>
						</>
					) : (
						<div className="flex w-full items-center justify-center p-4">
							<span className="text-neutral-500 text-sm">No replies yet.</span>
						</div>
					)}
				</div>
			</div>
			<MessageInput tabState={tabState} />
		</>
	);
});

export const Route = createFileRoute("/message/$message-id")({
	component: ThreadViewer,
	loader: ({ context: { tab }, params }) => {
		const tabState = new MessageTabState(
			tab,
			params["message-id"] as MessageId,
		);
		return {
			tabState,
		};
	},
});
