import { Cell } from "@/components/table/cell";
import { FieldColumnHeader } from "@/components/table/field-column-header";
import { FieldModifierPopover } from "@/components/table/field-modifier-popover";
import { LINK_COLUMN_ID, SELECT_COLUMN_ID } from "@/components/table/utils";
import { Checkbox } from "@/components/ui/checkbox";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { ResourceLinkComponent } from "@/plugins/resource-link";
import {
	type CellValue,
	type DataType,
	type Field,
	type FieldType,
	type Record,
	ScalarType,
} from "@api/schemas";
import { useDraggable } from "@dnd-kit/core";
import { DotsSixVertical, Link, Plus } from "@phosphor-icons/react";
import { type RowData, createColumnHelper } from "@tanstack/react-table";
import { observer } from "mobx-react-lite";

import "@tanstack/react-table"; //or vue, svelte, solid, qwik, etc.
import { Skeleton } from "@/components/ui/skeleton";
import {
	type ResourceTableState,
	UserTableState,
} from "@/contexts/tables/stores/table-store";
import type { TableViewState } from "@/contexts/tables/tab-state";
import { cn } from "@/lib/utils";

// see https://tanstack.com/table/v8/docs/api/core/column-def#meta
declare module "@tanstack/react-table" {
	// For column meta, the idea is we use this to pass in any information
	// shared by the whole column, i.e. the header and the cell, so it only is
	// calculated once.
	interface ColumnMeta<TData extends RowData, TValue> {
		editable: boolean;
		dataType: DataType;
		tableMeta?: {
			tableState: UserTableState | ResourceTableState;
			field: Field;
		};
	}
}

/**
 * Fallback cell for when table data is still being initialized.
 */
const LoadingCell = ({
	index,
	fieldType,
}: {
	index: number;
	fieldType?: FieldType;
}) => {
	const isViewField = fieldType === "view";
	return (
		<div className={cn("h-8 w-full p-1", isViewField && "bg-purple-50/50")}>
			<Skeleton
				className="h-full w-full"
				style={{
					flexGrow: 1,
					animationDelay: `${index * 250}ms`,
				}}
			/>
		</div>
	);
};

/**
 * Leftmost selector column for user tables.
 *
 * Cell and header are not observers so that they aren't memoized and rerender
 * when the parent table rerenders.
 */
export const getUserTableSelectorColumn = ({
	tableState,
	didInit,
}: {
	tableState: UserTableState;
	didInit: boolean;
}) => {
	const columnHelper = createColumnHelper<Record>();
	return columnHelper.display({
		id: SELECT_COLUMN_ID,
		enableResizing: false,
		header: ({ table }) => (
			<div className="flex h-full w-full items-center justify-end pr-2 pl-0.5">
				<Checkbox
					checked={
						table.getIsAllRowsSelected()
							? true
							: table.getIsSomeRowsSelected()
								? "indeterminate"
								: false
					}
					onCheckedChange={(checked) =>
						table.toggleAllRowsSelected(checked === true)
					}
					disabled={!didInit}
				/>
			</div>
		),
		cell: didInit
			? ({ row }) => {
					const { attributes, listeners, setNodeRef } = useDraggable({
						id: row.original.link,
					});
					return (
						<div
							className="flex h-full min-h-8 w-full items-center justify-between gap-0.5 bg-white pr-2 pl-1.5 text-neutral-400"
							onClick={(e) => {
								e.stopPropagation();
							}}
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									e.stopPropagation();
								}
							}}
						>
							<Tooltip>
								<TooltipTrigger asChild>
									<button
										type="button"
										className="flex rounded-sm p-0.5 text-base opacity-0 hover:bg-neutral-100 hover:text-neutral-700 group-hover/table-row:opacity-100"
										onClick={() => {
											tableState.addRecord({
												precedingRecordLink: row.original.link,
											});
										}}
									>
										<Plus weight="bold" />
									</button>
								</TooltipTrigger>
								<TooltipContent>Insert row below</TooltipContent>
							</Tooltip>
							<button
								className="flex rounded-sm text-lg opacity-0 hover:bg-neutral-100 hover:text-neutral-700 group-hover/table-row:opacity-100"
								ref={setNodeRef}
								{...attributes}
								{...listeners}
							>
								<DotsSixVertical weight="bold" />
							</button>
							<Checkbox
								className={cn(
									"opacity-0 group-hover/table-row:opacity-100",
									row.getIsSelected() && "opacity-100",
								)}
								checked={row.getIsSelected()}
								onCheckedChange={(checked) =>
									row.toggleSelected(checked === true)
								}
								onClick={(e) => {
									e.stopPropagation();
								}}
							/>
						</div>
					);
				}
			: ({ row }) => <LoadingCell index={row.index} />,
		size: 72,
		maxSize: 72,
	});
};

/**
 * Leftmost selector column for resource indexes. These differ from user tables
 * in that 1) they don't have a drag handle, since resource indexes have no
 * user-defined ordering and 2) they don't have an insert row button, because
 * resource creation occurs elsewhere.
 *
 * Cell and header are not observers so that they aren't memoized and rerender
 * when the parent table rerenders.
 */
export const getResourceIndexSelectorColumn = <TData,>() => {
	const columnHelper = createColumnHelper<TData>();

	return columnHelper.display({
		id: SELECT_COLUMN_ID,
		enableResizing: false,
		header: ({ table }) => (
			<div className="flex h-full w-full items-center justify-end pr-2 pl-0.5">
				<Checkbox
					checked={
						table.getIsAllRowsSelected()
							? true
							: table.getIsSomeRowsSelected()
								? "indeterminate"
								: false
					}
					onCheckedChange={(checked) =>
						table.toggleAllRowsSelected(checked === true)
					}
				/>
			</div>
		),
		cell: ({ row }) => {
			return (
				<div className="flex h-full min-h-8 w-full items-center justify-between gap-0.5 bg-white pr-2 pl-1.5">
					<Checkbox
						className={cn(
							"opacity-0 group-hover/table-row:opacity-100",
							row.getIsSelected() && "opacity-100",
						)}
						checked={row.getIsSelected()}
						onCheckedChange={(checked) => {
							row.toggleSelected(checked === true);
						}}
						onClick={(e) => {
							e.stopPropagation();
						}}
					/>
				</div>
			);
		},
		size: 32,
		maxSize: 32,
	});
};

/**
 * Column for displaying a record link.
 *
 * Every table should have a link for the row, which represents the row's
 * resource.
 *
 * @param {Object} params - Configuration parameters
 * @param {boolean} params.resetScroll - Whether to reset the scroll position when the link is clicked
 *
 * We want to reset the scroll position whenever the resource link points to a reference outside of the table.
 * For now, we only do this for the resource indexes, since they are the only tables that point to references
 * outside of themselves. This happens by default anyway because our router has
 * the `scrollRestoration` prop enabled.
 *
 * In standard tables, the record link stays within the table, but modifies the record_link search param.
 * In this instance, resetting the scroll position after navigation will interfere
 * with the table's virtualized scrolling, causing a bunch of undesired rerenders.
 */
export const getRecordLinkColumn = <T extends { link: string }>({
	resetScroll,
	didInit,
}: {
	resetScroll: boolean;
	didInit: boolean;
}) => {
	const columnHelper = createColumnHelper<T>();
	return columnHelper.display({
		id: LINK_COLUMN_ID,
		header: ({ column }) => <FieldColumnHeader column={column} name="Link" />,
		cell: didInit
			? observer((props) => {
					return (
						<ResourceLinkComponent
							linkProps={{ href: props.row.original.link, resetScroll }}
							className="flex h-full w-full items-center justify-center"
						>
							<Link />
						</ResourceLinkComponent>
					);
				})
			: ({ row }) => <LoadingCell index={row.index} />,
		enableResizing: false,
		size: 32,
		meta: {
			editable: false,
			dataType: { scalar_type: ScalarType.record_link, array_depth: 0 },
		},
	});
};

/**
 * Creates a column definition for a field in a user table.
 *
 * This function generates a TanStack Table column configuration for displaying and
 * potentially editing a field within a data table. The column includes header and cell
 * rendering logic with appropriate components based on the field type.
 *
 * @param {Object} params - Configuration parameters
 * @param {FieldId} params.fieldId - Unique identifier for the field
 * @param {number} params.width - Width of the column in pixels
 * @param {boolean} params.isPrimary - Whether this is a primary column (affects reordering behavior)
 * @param {boolean} params.editable - Whether the field values can be edited inline
 *
 * Note that we don't pass a field itself to this function because we do not want
 * to recreate column definitions every time a field changes. Instead, we pass in
 * the ID as well as any render-specific params that Tanstack immediately requires
 * (isPrimary, editable, width). The `header` and `cell` components use the ID to
 * fetch the field metadata as an observable from the table state.
 */
export const getFieldColumn = ({
	field,
	tableViewState,
	didInit,
}: {
	field: Field;
	tableViewState: TableViewState;
	didInit: boolean;
}) => {
	const columnHelper = createColumnHelper<Record>();
	const dataType =
		tableViewState.tablesStore.appState.workspace.fields.getDataType(
			field.field_id,
		);
	if (dataType.isErr()) {
		throw new Error(`Failed to get data type for field ${field.field_id}`);
	}
	const tableState = tableViewState.tableState;
	const editable =
		tableState instanceof UserTableState &&
		field.type !== "view" &&
		field.type !== "lookup";

	return columnHelper.display({
		id: field.field_id,
		header: observer(({ column }) => {
			const header = <FieldColumnHeader column={column} name={field.name} />;
			if (editable) {
				return (
					<FieldModifierPopover tableState={tableState} field={field}>
						{header}
					</FieldModifierPopover>
				);
			}
			return header;
		}),
		cell: didInit
			? observer((props) => {
					return (
						<Cell
							cellValue={props.row.original.cell_values[field.field_id] ?? null}
							onUpdate={
								tableState instanceof UserTableState
									? (value) => {
											tableState.updateCellValue({
												recordLink: props.row.original.link,
												fieldId: field.field_id,
												cellValue: value as CellValue,
											});
										}
									: undefined
							}
							context={props}
							registerRef={
								// Only register refs for user tables, since resource tables don't have live updates
								tableState instanceof UserTableState
									? (ref) => {
											if (ref) {
												const recordRefs = tableViewState.cellRefs.get(
													props.row.original.link,
												);
												if (!recordRefs) {
													tableViewState.cellRefs.set(
														props.row.original.link,
														new Map([[field.field_id, ref]]),
													);
												} else {
													recordRefs.set(field.field_id, ref);
												}
											} else {
												const recordRefs = tableViewState.cellRefs.get(
													props.row.original.link,
												);
												if (!recordRefs) {
													return;
												}
												recordRefs.delete(field.field_id);
											}
										}
									: undefined
							}
						/>
					);
				})
			: ({ row }) => <LoadingCell index={row.index} fieldType={field.type} />,
		size: field.width,
		meta: {
			editable,
			dataType: dataType.value,
			tableMeta: {
				tableState,
				field,
			},
		},
	});
};

/**
 * Column for a resource index.
 */
export const getResourceIndexColumn = <T,>(props: {
	key: string & keyof T;
	name: string;
	scalarType: ScalarType;
	width: number;
}) => {
	const columnHelper = createColumnHelper<T>();
	return columnHelper.display({
		id: props.key,
		header: ({ column }) => (
			<FieldColumnHeader column={column} name={props.name} />
		),
		cell: (cellContext) => {
			return (
				<Cell
					cellValue={cellContext.row.original[props.key]}
					context={cellContext}
				/>
			);
		},
		size: props.width,
		// TODO: improve. resource index fields should have their own field
		// type
		meta: {
			editable: false,
			dataType: { scalar_type: props.scalarType, array_depth: 0 },
		},
	});
};
