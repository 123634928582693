import type {
	FeedChannelId,
	FeedItemId,
	FieldId,
	MessageId,
	PageId,
	QueryId,
	SearchId,
	SpanId,
	StepId,
	TabId,
	TableId,
	TableSubscriptionId,
	UploadId,
	UserId,
	UserSessionId,
	WebSearchId,
	WebpageId,
	WriteId,
} from "@api/schemas";
import { ulid } from "ulidx";

// This is only used in the PDF viewer, but it's convenient to define it here
// so we can use the generator function
export type HighlightId = string & {
	readonly _brand: "HighlightId";
};

type IdMap = {
	step: StepId;
	"feed-channel": FeedChannelId;
	"feed-item": FeedItemId;
	table: TableId;
	field: FieldId;
	upload: UploadId;
	user: UserId;
	"web-search": WebSearchId;
	search: SearchId;
	page: PageId;
	// Session types
	"user-session": UserSessionId;
	message: MessageId;
	tab: TabId;
	write: WriteId;
	webpage: WebpageId;
	span: SpanId;
	highlight: HighlightId;
	"table-subscription": TableSubscriptionId;
	query: QueryId;
};

const generateId = <T extends keyof IdMap>(prefix: T): IdMap[T] => {
	return `${prefix}_${ulid()}`.toLowerCase() as IdMap[T];
};

export const createStepId = () => generateId("step");
export const createFeedChannelId = () => generateId("feed-channel");
export const createFeedItemId = () => generateId("feed-item");
export const createTableId = () => generateId("table");
export const createFieldId = () => generateId("field");
export const createUploadId = () => generateId("upload");
// Session types
export const createUserSessionId = () => generateId("user-session");
export const createMessageId = () => generateId("message");
export const createWebSearchId = () => generateId("web-search");
export const createSearchId = () => generateId("search");
export const createPageId = () => generateId("page");
export const createTabId = () => generateId("tab");
export const createWriteId = () => generateId("write");
export const createWebpageId = () => generateId("webpage");
export const createSpanId = () => generateId("span");
export const createHighlightId = () => generateId("highlight");
export const createTableSubscriptionId = () => generateId("table-subscription");
export const createQueryId = () => generateId("query");
